import { matchRoutes, RouteObject, useLocation } from "react-router-dom";

const useCurrentPath = (routes: RouteObject[]) => {
  const location = useLocation();
  const result = matchRoutes(routes, location);
  if (!result) return null;
  const [{ route }] = result;
  return route.path;
};

export default useCurrentPath;
