import { theme } from "shared-utilities";
import styled from "styled-components";
import { CSSProp, DefaultTheme } from "styled-components/macro";
// import hexRgb from 'hex-rgb';
import { hexToRgb } from "../../utilities/hexToRgb";

const Root = styled.div`
  background-color: ${theme.palette.WHITE};
  border-radius: ${theme.utilities.BORDER_RADIUS_MD}px;
  padding: ${theme.spacingPx(2)};
  box-shadow: 0 0 ${theme.spacingPx(0.75)}
    ${hexToRgb(theme.palette.SHADOW, 0.3)};
`;

interface PaperProps {
  children: React.ReactNode;
  style?: CSSProp<DefaultTheme>;
}

interface OnClickProps {
  onClick?: () => void;
}

// TODO
const Paper: React.FC<PaperProps & OnClickProps> = ({
  children,
  style,
  ...rest
}) => {
  return (
    <Root {...rest} css={style}>
      {children}
    </Root>
  );
};

export default Paper;
