import styled, { createGlobalStyle } from 'styled-components';
import { theme } from '../theme/';

export const GlobalStyle = createGlobalStyle`
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
`;

const FONT_SIZE = {
    xs: '10px',
    sm: '12px',
    md: '16px',
    lg: '30px',
    xl: '40px'
}

const BUTTON_SIZE = {
    sm: '15px',
    lg: '30px'
}

const HEIGHT = {
    full: '100%',
    half: '50%',
    third: '33.3333%',
    quarter: '25%',
}

export const View = styled.div<{ justify?: string; align?: string; padding?: string; }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => p.justify || 'center'};
  align-items: ${(p) => p.align || 'center'};
  color: ${theme.palette.PRIMARY};
  padding: ${(p) => p.padding || "2em"};
  box-sizing: border-box;
`;

export const Panel = styled.div<{ height?: string; justify?: string; align?: string; direction?: string; }>`
  display: flex;
  width: 100%;
  justify-content: ${(p) => p.justify || 'center'};
  align-items: ${(p) => p.align || 'center'};
  flex-direction: ${(p) => p.direction || "column"};
  height: ${(p) => HEIGHT[p.height] || HEIGHT["auto"]};
`;

export const Heading = styled.h1<{ align?: string; size?: string }>`
  color: ${theme.palette.PRIMARY};
  font-size: ${(p) => FONT_SIZE[p.size] || FONT_SIZE.lg };
  font-weight: 100;
  text-align: ${(p) => p.align || "left"};
  font-family: ${theme.typography.FONT_NAME};
`;

export const Paragraph = styled.p<{ align?: string; size?: string; maxWidth?: string }>`
  font-size: ${(p) => FONT_SIZE[p.size] || FONT_SIZE.md };
  font-family: ${theme.typography.FONT_NAME};
  font-weight: 100;
  text-align: ${(p) => p.align || "left"};
  line-height: 1.4em;
  max-width: ${(p) => p.maxWidth || "100%"};
`;

export const Button = styled.button<{background?: string; color?: string; size?: string; rounded?: boolean; inline?: boolean }>`
    width: ${(p) => p.inline ? "auto" : "100%"};
    display: ${(p) => p.inline ? "inline-block" : "block"};
    border-radius: ${(p) => p.rounded ? '60px' : '0'};
    line-height: 0;
    font-family: ${theme.typography.FONT_NAME};
    font-weight: 400;
    font-size: ${(p) => p.size === "sm" ? FONT_SIZE["sm"] : FONT_SIZE["md"]};
    background-color: ${(p) => p.background || theme.palette.BUTTON_PRIMARY};
    cursor: pointer;
    pointer-events: all;
    padding: ${(p) => BUTTON_SIZE[p.size] || BUTTON_SIZE["lg"]};

    &:active {
        opacity: 0.8;
    }

    span {
        color: ${(p) => p.color || theme.palette.SECONDARY};
        font-size: ${(p) => p.size === "sm" ? FONT_SIZE["sm"] : FONT_SIZE["md"]};
    }
`