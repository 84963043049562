/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { keyframes } from "styled-components";
import Heading from "../texts/Heading";

type SelectorItemProps = {
  isActive: any;
};

const CardWrapper = styled.label<SelectorItemProps>`
  padding: 19px 18px 15px 15px;
  border-radius: 4px;
  position: relative;
  display: flex;
  max-width: 340px;
  width: 100%;
  box-sizing: border-box;
  background: ${({ isActive }) => (isActive ? "#D0DEE5" : "#e7eef2")};
`;

const CheckboxWrapper = styled.div`
  display: flex;
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Indicator = styled.div`
  width: 20px;
  height: 20px;
  background: #fff;
  position: absolute;
  border-radius: 3px;
  top: calc(50% - 10px);
  right: 24px;
  border: none;

  ${Input}:not(:disabled):checked & {
    background: #d1d1d1;
  }
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
  ${Input}:checked + &::after {
    display: block;
    top: 3px;
    left: 7px;
    width: 21%;
    height: 49%;
    border: solid #145e7f;
    border-width: 0 0.1em 0.1em 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
  &::disabled {
    cursor: not-allowed;
  }
`;

export type TSelectorCheckboxItemProps = {
  item: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedList: number[];
};

export const SelectorCheckboxItem = ({
  item,
  onChange,
  selectedList,
}: TSelectorCheckboxItemProps) => {
  const isActive = selectedList.find((select) => Number(select) === item.id);

  return (
    <CardWrapper isActive={isActive} htmlFor={item.id}>
      <Heading
        style={{ marginRight: 26 }}
        size="regular"
        marginBottom="0px"
        leftAlign
      >
        {item.label}
      </Heading>
      {/* <CardWrapperText dangerouslySetInnerHTML={{ __html: item.label }} /> */}
      <CheckboxWrapper>
        <Input
          id={item.id}
          name={item.id}
          type="checkbox"
          onChange={onChange}
          checked={!!isActive}
        />
        <Indicator />
      </CheckboxWrapper>
    </CardWrapper>
  );
};
