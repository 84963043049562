import React from "react";
import { ReactComponent as CheckIcon } from "shared-assets/src/icons/Shape.svg";
import {
  WorkoutExercise,
  WorkoutFeedback,
  WorkoutFeedbackCategory,
  WorkoutFeedbackEasyReason,
  WorkoutFeedbackHardReason,
  WorkoutFeedbackPainReason,
  WorkoutFeedbackReason,
} from "shared-interfaces";
import { theme } from "shared-utilities";
import styled from "styled-components";
// import { theme } from "shared-utilities";
import DetailedFeedbackReasonsControl from "./DetailedFeedbackReasonsControl";
import FeedbackComment from "./FeedbackComment";

const ExerciseItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #d7e2de;
  font-family: ${theme.typography.FONT_NAME};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: -0.25px;
  color: #145e7f;
`;

const categoryReducer = (id: number): WorkoutFeedbackCategory | undefined => {
  switch (id) {
    case 1:
      return "easy";
    case 2:
      return "hard";
    case 3:
      return "painful";
  }
};

interface DetailedFeedbackRowProps {
  feedbackIndex: number;
  setFeedbackValue: (feedback: WorkoutFeedback) => void;
  exercise: WorkoutExercise;
  value?: WorkoutFeedback;
  removeFeedbackValue: (value: number) => void;
}

interface ChangeValue {
  reasons?: WorkoutFeedbackReason[];
  comment?: string;
}

const ContentWrapper = styled.div`
  border-bottom: 1px solid #d7e2de !important;
`;

const DetailedFeedbackControl: React.FC<DetailedFeedbackRowProps> = ({
  feedbackIndex,
  setFeedbackValue,
  exercise,
  value,
  removeFeedbackValue,
}) => {
  // State
  const [expanded, setExpanded] = React.useState<boolean>(false);

  // Methods
  const handleExpand = () => setExpanded((v) => !v);

  const handleChangeValue = (values: ChangeValue) => {
    const category = categoryReducer(feedbackIndex);
    const feedback: Partial<WorkoutFeedback> = {
      category,
      date: new Date().toISOString(),
      comment: values.comment ?? value?.comment,
      exerciseId: exercise.exercise.id,
    };

    if (values.reasons || value?.reason) {
      switch (category) {
        case "easy":
          feedback.reason = (values.reasons ??
            value?.reason) as WorkoutFeedbackEasyReason[];
          break;
        case "hard":
          feedback.reason = (values.reasons ??
            value?.reason) as WorkoutFeedbackHardReason[];
          break;
        case "painful":
          feedback.reason = (values.reasons ??
            value?.reason) as WorkoutFeedbackPainReason[];
          break;
      }
    }

    if (!feedback?.comment?.length && !feedback?.reason?.length) {
      removeFeedbackValue(exercise.exercise.id);
      return;
    }

    setFeedbackValue(feedback as WorkoutFeedback);
  };

  const handleChangeReasons = (reasons: WorkoutFeedbackReason[]) =>
    handleChangeValue({ reasons });

  const handleChangeComment = (comment: string) =>
    handleChangeValue({ comment });

  // Renderers
  const renderContent = () => {
    return (
      <ContentWrapper>
        <button
          type="button"
          onClick={handleExpand}
          style={{ width: "100%", padding: 0 }}
        >
          <ExerciseItem>
            <img
              alt="thumbnail"
              src={exercise.exercise.thumbnailUrl}
              style={{ height: 50, width: 50, borderRadius: 5 }}
            />
            <p>{exercise.exercise.name}</p>
          </ExerciseItem>
        </button>
        {expanded && (
          <div>
            <DetailedFeedbackReasonsControl
              value={value?.reason ?? []}
              feedbackIndex={feedbackIndex}
              onChange={handleChangeReasons}
            />
            <FeedbackComment
              comment={value?.comment ?? ""}
              feedbackIndex={feedbackIndex}
              setComment={handleChangeComment}
            />
          </div>
        )}
      </ContentWrapper>
    );
  };

  return <div>{renderContent()}</div>;
};

export default DetailedFeedbackControl;
