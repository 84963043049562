export * from "./spacing";
export * from "./theme";
export * from "./utilities";
export { default as victoryTheme } from "./victory";
export * from "./common";
import * as themes from './themes';

function loadTypography() {
  return process.env.REACT_APP_THEME ? themes[process.env.REACT_APP_THEME].typography : themes.defaultTheme.typography;
}

function loadPalette() {
    return process.env.REACT_APP_THEME ? themes[process.env.REACT_APP_THEME].palette : themes.defaultTheme.palette;
}

function loadScale() {
    return process.env.REACT_APP_THEME ? themes[process.env.REACT_APP_THEME].scales : themes.defaultTheme.scales;
}

export const typography = loadTypography();
export const palette = loadPalette();
export const scales = loadScale();