import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, getPlan, getProgress, getUser } from "shared-redux";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import CloseButton from "../components/buttons/CloseButton";
import Phase from "../components/progress/Phase";
import Paragraph from "../components/texts/Paragraph";

interface IPhaseDescription {
  goal: string;
  videoUrl: string;
  paragraphs: string[];
}

const PHASE_DESCRIPTIONS: IPhaseDescription[] = [
  {
    goal: t("progress_phase_one_goal"),
    videoUrl:
      "https://player.vimeo.com/external/312408088.sd.mp4?s=a67d8763c7fd38ee3cac65fc771d72165121658a&amp;profile_id=164",
    paragraphs: [
      t("progress_phase_one_expect_sentence_one"),
      t("progress_phase_one_expect_sentence_two"),
      t("progress_phase_one_expect_sentence_three"),
    ],
  },
  {
    goal: t("progress_phase_two_goal"),
    videoUrl:
      "https://player.vimeo.com/external/312408158.sd.mp4?s=4f3ff080a7e0e0cd4fc71ff922c47db800700047&profile_id=164",
    paragraphs: [
      t("progress_phase_two_expect_sentence_one"),
      t("progress_phase_two_expect_sentence_two"),
      t("progress_phase_two_expect_sentence_three"),
      t("progress_phase_two_expect_sentence_four"),
    ],
  },
  {
    goal: t("progress_phase_three_goal"),
    videoUrl:
      "https://player.vimeo.com/external/312408115.sd.mp4?s=4f65985c8a2f40636f73c4a85994c5ebbb0d0ae5&profile_id=164",
    paragraphs: [t("progress_phase_three_expect")],
  },
  {
    goal: t("progress_phase_four_goal"),
    videoUrl:
      "https://player.vimeo.com/external/312408061.sd.mp4?s=4fbee0dd3be66f79b3a12b074f3e27fc0bb8cdf7&profile_id=164",
    paragraphs: [
      t("progress_phase_four_expect_sentence_one"),
      t("progress_phase_four_expect_sentence_two"),
      t("progress_phase_four_expect_sentence_three"),
    ],
  },
];

export const ProgressPhaseDescription: React.FC<
  React.PropsWithChildren
> = () => {
  const navigation = useNavigate();

  // Store
  const user = useAppSelector(getUser);
  const plan = useAppSelector(getPlan);
  const progress = useAppSelector(getProgress);

  // Methods
  const handleClose = () => navigation(-1);

  // Renderers
  const renderPhase = () => {
    if (!plan) return;
    const id = progress?.currentPhase || 1;
    const phase = PHASE_DESCRIPTIONS[id - 1];

    return (
      <Phase phase={id} goal={phase.goal} videoUrl={phase.videoUrl}>
        {phase.paragraphs.map((p) => (
          <Paragraph text={p} leftAlign />
        ))}
      </Phase>
    );
  };

  if (!user) return null;
  return (
    <div
      style={{ flex: 1, backgroundColor: theme.palette.WHITE, maxWidth: 950 }}
    >
      {renderPhase()}
      <div style={{ position: "absolute", right: 0, top: 0 }} />
    </div>
  );
};
