import React from "react";
import { IconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const FacebookIcon: React.FC<IconProps> = ({
  size = theme.spacing(2),
  color = theme.palette.WHITE,
  ...props
}) => (
  <svg width={size} height={size} viewBox="0 0 16 16" {...props}>
    <g
      d="M83.286 19c.946 0 1.714.768 1.714 1.714v12.572c0 .946-.768 1.714-1.714 1.714h-4.902v-5.44h2.064L80.84 27h-2.456v-1.662c0-.7.343-1.384 1.443-1.384h1.117v-2.178l-.332-.05a12.982 12.982 0 00-1.65-.123c-2.025 0-3.346 1.226-3.346 3.446V27h-2.25v2.56h2.25V35h-4.902A1.715 1.715 0 0169 33.286V20.714c0-.946.768-1.714 1.714-1.714h12.572z"
      fill={color}
      fillRule="nonzero"
      transform="translate(-69 -19)"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);

export default FacebookIcon;
