import React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';
import { theme } from 'shared-utilities/src/theme/theme';
import { EmojiIconProps } from "shared-interfaces";

const MehIcon: React.FC<EmojiIconProps> = ({ size = theme.spacing(8), selected = true }) => (
  <Svg width={size} height={size} viewBox="0 0 64 64">
    <G fill="none" fillRule="evenodd">
      <Circle fill={selected ? theme.scales.YELLOW : theme.palette.LIGHTEST_GREY} cx={32} cy={32} r={32} />
      <Circle fill="#000" opacity={0.5} cx={17} cy={29} r={3} />
      <Circle fill="#000" opacity={0.5} cx={47} cy={29} r={3} />
      <Path
        d="M24.806 51C23.81 51 23 50.105 23 49s.809-2 1.806-2h16.388c.997 0 1.806.895 1.806 2s-.809 2-1.806 2H24.806z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.5}
      />
    </G>
  </Svg>
);

export default MehIcon;
