import React from "react";
import { theme } from "shared-utilities";
import styled from "styled-components/macro";

const Root = styled.div({
  root: {
    textAlign: "center",
    color: theme.palette.RED,
  },
});

interface ErrorProps {
  text?: string;
  marginBottom?: number;
}

const Error: React.FC<ErrorProps> = ({
  text,
  marginBottom = theme.spacing(2.5),
}) => {
  if (!text) return null;
  return (
    <Root css={[theme.typography.Omnes.REGULAR_TEXT, { marginBottom }]}>
      {text}
    </Root>
  );
};

export default Error;
