import React from "react";
import { omit } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CompletedExercise, WorkoutSummaryRow } from "shared-interfaces";
import {
  useWorkoutSubmitMutation,
  getExercises,
  getMuted,
  getWorkouts,
  setMuted,
  useAppDispatch,
  useAppSelector,
  workoutAddExercise,
  workoutReset,
} from "shared-redux";
import { createErrorResponse, getErrorString } from "shared-utilities";
import CancelSessionModal from "src/components/modal/EndEarlyModal";
import ExercisePlayer from "./ExercisePlayer";

// const newExerciseSound = new Audio.Sound();
const View = "div";
const SafeAreaView = "div";

export const WorkoutScreen: React.FC<React.PropsWithChildren> = () => {
  // Navigation
  const params = useParams();
  const isFocused = true;

  // Refs
  const accumulatedTimeRef = React.useRef<number>(0);

  // Store
  const dispatch = useAppDispatch();
  const workouts = useAppSelector(getWorkouts);
  const exercises = useAppSelector(getExercises);
  const muted = useAppSelector(getMuted);
  const dispatchSetMuted = (muted: boolean) => dispatch(setMuted(muted));
  const dispatchAddExercise = (exercise: CompletedExercise) =>
    dispatch(workoutAddExercise(exercise));
  const dispatchResetExercise = () => dispatch(workoutReset());
  const [dispatchSubmitWorkout, { isLoading, error, isSuccess, reset }] =
    useWorkoutSubmitMutation();
  const navigate = useNavigate();
  const submitWorkoutError = error as any;

  // State
  const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
  const [paused, setPaused] = React.useState<boolean>(false);
  const [started] = React.useState<string>(new Date().toISOString());

  // Miscellaneous
  // useAppStatus((appState, nextAppState) => {
  //   if (appState.match(/inactive|background/) && nextAppState === "active")
  //     return setPaused(false);
  //   if (nextAppState === "background") setPaused(true);
  // });

  // Computed variables
  const workoutKey = params?.workoutType as keyof typeof workouts;
  const exerciseIndex = Number(params.stepIndex) - 1; // route.params.index;
  const workout = workouts[workoutKey];
  const exerciseSummary = workout?.workoutSummary[exerciseIndex];
  const exercise =
    workout?.exercises[exerciseIndex % (workout?.exercises.length ?? 0)];

  // Audio
  const audio = new Audio("/audio/in_session_new_exercise.mp3");
  audio.load();

  // Methods
  const playCompletionSound = async () => {
    if (muted) return;
    try {
      await audio.play();
    } catch (error) {
      // TODO: log error
    }
  };

  const addTime = (seconds: number) => {
    accumulatedTimeRef.current += seconds;
  };

  const showRestTimer = async () => {
    if (!exercise) return;
    dispatchAddExercise({
      exerciseId: exercise.exercise.id,
      ...omit(exercise, "id", "exercise"),
      started,
      ended: new Date().toISOString(),
    });
    navigate(`/workout/${workoutKey}/session/${params.stepIndex}/rest`, {
      replace: true,
    });
  };

  const handleSubmitWorkout = () => {
    dispatchSubmitWorkout({ duration: workoutKey, exercises });
  };

  const handleCompletedExercise = (seconds: number) => {
    if (!workout || !exercise) return;
    addTime(seconds);
    playCompletionSound();

    if (exerciseIndex + 1 === workout.workoutSummary.length)
      return handleSubmitWorkout();
    showRestTimer();
  };

  const handleMute = async () => {
    dispatchSetMuted(!muted);
  };

  const handleShowCancelModal = () => {
    setShowCancelModal(!showCancelModal);
    setPaused(true);
  };

  const handlePause = () => setPaused((v) => !v);

  const handleShowDetails = ({ exercise }: WorkoutSummaryRow) => {
    setPaused(true);
    navigate(`/workout/${workoutKey}/exercise/${exercise.id}`);
  };

  const handleCompleteSession = async () => {
    await handleSubmitWorkout();
    handleShowCancelModal();
  };
  const handleCancelSession = () => {
    handleShowCancelModal();
    dispatchResetExercise();
    navigate(`/workout/${params.workoutType}/intro`);
  };

  React.useEffect(() => {
    if (!isSuccess) return;
    reset();
    navigate(`/workout/${params.workoutType}/trophy`, {
      replace: true,
    });
  }, [isSuccess]);

  React.useEffect(() => {
    if (!submitWorkoutError) return;
    if ("data" in submitWorkoutError) {
      const typedError = createErrorResponse(submitWorkoutError);

      toast.error(
        `Error submitting workout: ${getErrorString(typedError.data.message)}`
      );
    }
  }, [error]);

  if (!exerciseSummary) return null;

  return (
    <View style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <ExercisePlayer
          isFocused={isFocused}
          exercise={exerciseSummary}
          onDone={handleCompletedExercise}
          onPause={handlePause}
          onShowCancelModal={handleShowCancelModal}
          onShowDetails={handleShowDetails}
          onMute={handleMute}
          initialPaused={paused}
          muted={muted}
          loading={isLoading}
        />
        <CancelSessionModal
          isVisible={showCancelModal}
          exercise={workout.workoutSummary[exerciseIndex]}
          onContinueSession={handleShowCancelModal}
          onCompleteSession={handleCompleteSession}
          onCancelSession={handleCancelSession}
        />
      </SafeAreaView>
    </View>
  );
};
