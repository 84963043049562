import { Mutex } from 'async-mutex';
import axios, { AxiosError, AxiosRequestConfig, CancelTokenSource } from 'axios';
import moment from 'moment-timezone';
import { AbortController, environmentVariables } from 'shared-utilities';
import type { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { authSetToken, logout } from './modules';
import type { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/query';
import { RootState } from './store';

export interface IBaseQueryParams {
  baseUrl: string;
  prepareHeaders: <State = any>(
    headers: Headers,
    state: State,
    baseUrl: string
  ) => MaybePromise<Headers>;
}

export interface IBaseQueryArguments {
  url: string;
  method?: AxiosRequestConfig['method'];
  body?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
}

const LANGUAGE_KEYS = {
  en: 'en',
  da: 'da',
};

const fetchNewToken = async (api: BaseQueryApi): Promise<string> => {
  try {
    const tokenReq = await axios({
      withCredentials: true,
      url: `${environmentVariables.SERVERLESS_API}/auth/refresh-token`,
      method: 'POST',
    });
    const { token } = tokenReq.data.data;
    api.dispatch(authSetToken(token));
    return token;
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    console.log('error', axiosError);
    if (err.response.data.message === 'REFRESH_TOKEN_REUSE_ERROR')
      // then logout
      console.log('logout');
    api.dispatch(logout());
  }
};

const mutex = new Mutex();

const makeRequest = async (
  baseUrl: string,
  req: IBaseQueryArguments,
  prepareHeaders: <State = any>(
    headers: Headers,
    state: State,
    baseUrl: string
  ) => MaybePromise<Headers>,
  source: CancelTokenSource,
  api: BaseQueryApi
): Promise<any> => {
  const { url, method = 'GET', body, params } = req;
  const headers = new Headers();
  await prepareHeaders?.(headers, api.getState(), baseUrl);
  const headersObj: Record<string, string> = {};
  headers.forEach((value: string, key: string) => {
    headersObj[key] = value;
  });
  const promise = axios({
    withCredentials: baseUrl === environmentVariables.SERVERLESS_API,
    url: `${baseUrl}/${url}`,
    method,
    data: body,
    headers: headersObj,
    params,
    cancelToken: source.token,
  });

  const unsubscribe = AbortController.shared.addEventListener(() => {
    source.cancel('Cancelled by global AbortController');
  });

  try {
    const result = await promise;
    return result;
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    if (err.response.data.message === 'AUTH_TOKEN_EXPIRED') return err.response.data.message;

    throw axiosError;
  } finally {
    unsubscribe();
  }
};

const axiosBaseQuery =
  ({ baseUrl = '', prepareHeaders }: IBaseQueryParams): BaseQueryFn<IBaseQueryArguments, unknown> =>
  async (req, api) => {
    try {
      const source = axios.CancelToken.source();
      await mutex.waitForUnlock();
      let response = await makeRequest(baseUrl, req, prepareHeaders, source, api);

      if (response === 'AUTH_TOKEN_EXPIRED') {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          try {
            await fetchNewToken(api);
            response = await makeRequest(baseUrl, req, prepareHeaders, source, api);
          } finally {
            release();
          }
        } else {
          await mutex.waitForUnlock();
          response = await makeRequest(baseUrl, req, prepareHeaders, source, api);
        }
      }
      return { data: response.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

const prepareHeaders = (headers: Headers, state: any, baseUrl: string) => {
  const { auth } = state as RootState;
  if (auth.token) headers.set('authorization', `Bearer ${auth.token}`);

  if (baseUrl !== environmentVariables.SERVERLESS_API) return headers;

  const userLocale =
    navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

  headers.set('TimeZone', process.env.REACT_APP_TZ || moment.tz.guess());
  const splitLocale = userLocale.split('-')[0];
  const detectedLanguage = LANGUAGE_KEYS[splitLocale];
  headers.set('Language', process.env.REACT_APP_LANG || detectedLanguage || LANGUAGE_KEYS.en);
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');
  headers.set('Cache-Control', 'no-cache');

  return headers;
};

export const createBaseQuery = (baseUrl = environmentVariables.SERVERLESS_API) =>
  axiosBaseQuery({
    baseUrl,
    prepareHeaders,
  });
