import { createApi } from '@reduxjs/toolkit/query/react';

import {
  SuccessResponse,
  WorkoutFeedback,
  WorkoutSchedule,
  CompletedWorkout,
} from 'shared-interfaces';
import { createBaseQuery } from '../baseQuery';

import { FetchProgressResponse } from './progressApi';

export interface FeedbackSubmitBody {
  id: number;
  feedback: WorkoutFeedback[];
}

export const workoutApi = createApi({
  reducerPath: 'workoutApi',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    workoutSubmit: builder.mutation<SuccessResponse<FetchProgressResponse>, CompletedWorkout>({
      query: (body) => ({
        url: 'user-workout',
        method: 'POST',
        body,
      }),
    }),
    workoutFeedbackSubmit: builder.mutation<
      SuccessResponse<FetchProgressResponse>,
      FeedbackSubmitBody
    >({
      query: ({ id, ...body }) => ({
        url: `user-workout/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    workoutScheduleFetch: builder.query<SuccessResponse<WorkoutSchedule>, void>({
      query: () => ({ url: 'workout-schedule' }),
    }),
    workoutScheduleUpdate: builder.mutation<SuccessResponse<WorkoutSchedule>, WorkoutSchedule>({
      query: (body) => ({
        url: 'workout-schedule',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useWorkoutSubmitMutation,
  useWorkoutFeedbackSubmitMutation,
  useWorkoutScheduleFetchQuery,
  useWorkoutScheduleUpdateMutation,
} = workoutApi;
