import React from "react";
import { IconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const TargetTodoTodayIcon: React.FC<IconProps> = ({
  size = theme.spacing(2.75),
  color = theme.palette.PINK,
}) => (
  <svg width={size} height={size} viewBox="0 0 22 22">
    <circle
      cx={10}
      cy={10}
      r={10}
      transform="translate(1 1)"
      stroke={color}
      strokeWidth={1.26}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default TargetTodoTodayIcon;
