import React from "react";
import { PainAreaKey } from "shared-interfaces";
import styled from "styled-components";

import { Selector } from "../../Selector";
import Heading from "../../texts/Heading";

type TPainAreaQuestionViewProps = {
  updateActivePain: (activePain: PainAreaKey) => void;
};

const PainAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const PainAreaQuestionView = ({
  updateActivePain,
}: TPainAreaQuestionViewProps) => {
  const painAreas = [
    { id: 1, ref: "neck", label: "Neck" },
    { id: 2, ref: "knee", label: "Knee" },
    { id: 3, ref: "lowerBack", label: "Lower Back" },
    { id: 4, ref: "midUpperBack", label: "Mid/Upper Back" },
    { id: 5, ref: "groinHip", label: "Groin/Hip" },
    { id: 6, ref: "lowerLeg", label: "Lower Leg" },
    { id: 7, ref: "upperArm", label: "Upper Arm" },
    { id: 8, ref: "thigh", label: "Thigh" },
    { id: 9, ref: "footAnkle", label: "Foot/Ankle" },
    { id: 10, ref: "shoulder", label: "Shoulder" },
    { id: 11, ref: "elbow", label: "Elbow" },
    { id: 12, ref: "forearm", label: "Forearm" },
    { id: 13, ref: "wristHand", label: "Wrist/Hand" },
  ];
  // const selectorItems = painAreas.map((painArea) => painArea.label);

  const handleActivePainChange = (updatedPain: number) => {
    const updatedPainArea = painAreas.find((p) => p.id === updatedPain);

    if (updatedPainArea) {
      updateActivePain(updatedPainArea.ref as PainAreaKey);
    }
  };

  return (
    <PainAreaWrapper>
      <Heading
        text="Where is your pain?"
        size="medium"
        style={{ paddingTop: 60 }}
      />
      <Selector
        isSubmit={false}
        items={painAreas}
        onChange={handleActivePainChange}
      />
    </PainAreaWrapper>
  );
};

export default PainAreaQuestionView;
