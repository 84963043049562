import React from "react";
import styled from "styled-components";

import { Selector } from "../../Selector";
import Heading from "../../texts/Heading";

type TLocationQuestionViewProps = {
  updateActiveLocation: (activeLocation: string) => void;
};

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const LocationQuestionView = ({
  updateActiveLocation,
}: TLocationQuestionViewProps) => {
  const locations = [{ id: 1, ref: "unitedKingdom", label: "United Kingdom" }];

  const handleActiveLocationChange = (newLocation: number) => {
    const updatedLocation = locations.find((p) => p.id === newLocation);

    if (updatedLocation) {
      updateActiveLocation(updatedLocation.ref as string);
    }
  };

  return (
    <LocationWrapper>
      <Heading
        text="Where are you currently living?"
        size="medium"
        style={{ paddingTop: 60 }}
      />
      <Selector
        isSubmit={false}
        items={locations}
        onChange={handleActiveLocationChange}
      />
    </LocationWrapper>
  );
};

export default LocationQuestionView;
