import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { referralApi, ReferralCodeResponse } from "../api";

import { RootState } from '../store';
import { resetAssessment } from './assessment';
import { logout } from './auth';

declare global {
  // State Interface
  interface ReferralState {
    referralDetails: any | null; // TODO ReferralCodeResponse failing to build because of dependency
  }
}

// Initial State
const initialState: ReferralState = {
  referralDetails: null,
};

// Slice
export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    setReferralDetails: (state, action) => {
      state.referralDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(logout, resetAssessment), (state) => {
      state.referralDetails = null;
    });
    builder.addMatcher(
      isAnyOf(
        referralApi.endpoints.referralCode.matchFulfilled,
        referralApi.endpoints.myReferral.matchFulfilled
      ),
      (state, action) => {
        state.referralDetails = action.payload;
      }
    );
  },
});

// Actions
export const { setReferralDetails } = referralSlice.actions;

// Selectors
export const getReferralDetails = (state: RootState): ReferralCodeResponse | null =>
  state.referral.referralDetails;
export const getRequiresPayment = (state: RootState): boolean =>
  !!getReferralDetails(state)?.paymentRequired;

// Reducer
export default referralSlice.reducer;
