/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { MultipleChoiceQuestion } from "shared-interfaces";
import { MultiSelector } from "src/components/MultiSelector";
import EmphasizedText from "../../texts/EmphasizedText";
import Heading from "../../texts/Heading";
import Subtitle from "../../texts/Subtitle";

interface MultipleChoiceProps {
  item: MultipleChoiceQuestion;
  answers?: any;
  onChange: (id: number, type: string, value: string) => void;
}

const MultipleChoiceQuestionView: React.FC<MultipleChoiceProps> = ({
  item,
  answers,
  onChange,
}) => {
  const selectedQuestionAnswers = answers.find(
    (answer: any) => answer?.question === item.id
  );

  const changeValueHandler = (value: string) => {
    onChange(item.id, item.type, value);
  };

  return (
    <>
      <Heading text={item.title} size="medium" />
      <EmphasizedText text={item.emphasised} />
      <Subtitle text={item.subtitle} />
      <MultiSelector
        items={item?.questionChoices}
        onChange={changeValueHandler}
        value={selectedQuestionAnswers?.input?.value}
      />
    </>
  );
};

export default MultipleChoiceQuestionView;
