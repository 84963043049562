import React from "react";
import moment from "moment";
import { DatePicker } from "react-responsive-datepicker";
import "react-responsive-datepicker/dist/index.css";
import { LanguageKey } from "shared-interfaces";
import { DATE_FORMAT, prettyDate, t, theme } from "shared-utilities";
import styled, { css, CSSProp, DefaultTheme } from "styled-components/macro";
import { View } from "../miscellaneous/View";
import ErrorText from "../texts/ErrorText";
import TextInputField from "./TextInputField";

const styles = {
  input: css({
    // height: 52,
    // padding: 15,
    color: theme.palette.PRIMARY,
    borderRadius: theme.utilities.BORDER_RADIUS,
  }),
};

interface DateInputFieldProps {
  onDatePicked?: (date: Date) => void;
  onSubmitEditing?: () => void;
  placeholder?: string;
  placeholderKey?: string;
  value?: string;
  error?: string | false | null;
  language?: string;
  style?: CSSProp<DefaultTheme>;
  inputStyle?: CSSProp<DefaultTheme>;
  prettyFormat?: boolean;
  minimumDate?: Date;
  editable?: boolean;
}

const DateInputField = React.forwardRef<typeof DatePicker, DateInputFieldProps>(
  (
    {
      onDatePicked,
      onSubmitEditing,
      placeholder,
      placeholderKey,
      value,
      error,
      language = "en_GB",
      style,
      inputStyle,
      prettyFormat,
      minimumDate,
      editable = true,
    },
    ref
  ) => {
    // State
    const [pickerVisible, setPickerVisible] = React.useState<boolean>(false);

    // Methods
    const handleFocus = () => {
      if (!editable) return;
      setPickerVisible(true);
    };
    const handleConfirm = (date: Date | null) => {
      setPickerVisible(false);
      if (!date) return;
      if (onDatePicked) onDatePicked(date);
      if (onSubmitEditing) onSubmitEditing();
    };

    // Computed Variables
    const datetime = value ? moment(value, DATE_FORMAT).toDate() : new Date();
    const pretty = prettyFormat
      ? prettyDate(datetime, language as LanguageKey)
      : value;

    return (
      <View css={style}>
        <View onClick={handleFocus}>
          <TextInputField
            readOnly
            placeholderKey={placeholderKey}
            placeholderTextColor={theme.palette.LIGHT_GREY}
            value={pretty}
            error={error}
            ref={ref}
            editable={editable}
            style={[
              theme.typography.Omnes.REGULAR_TEXT,
              styles.input,
              inputStyle,
            ]}
          />
        </View>
        <DatePicker
          showTitle={false}
          isOpen={pickerVisible}
          onClose={() => setPickerVisible(false)}
          onChange={handleConfirm}
          minDate={minimumDate}
          headerFormat="DD, MM dd"
          colorScheme={theme.palette.PRIMARY}
        />
      </View>
    );
  }
);

export default DateInputField;
