import React from "react";
import Xarrow from "react-xarrows";
import femalePainAreas from "shared-assets/src/data/anatomical_female.json";
import malePainAreas from "shared-assets/src/data/anatomical_male.json";
import { ReactComponent as Hotspot } from "shared-assets/src/icons/hotspot.svg";
import FemaleBack from "shared-assets/src/svg/anatomical/FemaleBack";
import FemaleFront from "shared-assets/src/svg/anatomical/FemaleFront";
import MaleBack from "shared-assets/src/svg/anatomical/MaleBack";
import MaleFront from "shared-assets/src/svg/anatomical/MaleFront";
import { PainAreaKey } from "shared-interfaces";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import { useOnClickOutside } from "usehooks-ts";
import Heading from "../components/texts/Heading";
import Paragraph from "../components/texts/Paragraph";

const Label = styled.div<{ x: number; y: number }>`
  position: absolute;
  left: ${(props) => props.x}%;
  top: ${(props) => props.y}%;
  transform: translate(-50%, -50%);
`;

const Root = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 100%;
  background-size: cover;
`;

const LeftButton = styled.button<{ active: boolean }>`
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: ${theme.palette.VIBRANT_ORANGE};
  border-radius: 15px 0 0 15px;
  background-color: ${(props) =>
    props.active ? theme.palette.VIBRANT_ORANGE : theme.palette.WHITE};
  color: ${(props) =>
    props.active ? theme.palette.WHITE : theme.palette.VIBRANT_ORANGE};
  min-width: 99px;
  min-height: 33px;
`;

const RightButton = styled.button<{ active: boolean }>`
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: ${theme.palette.VIBRANT_ORANGE};
  border-radius: 0 15px 15px 0;
  background-color: ${(props) =>
    props.active ? theme.palette.VIBRANT_ORANGE : theme.palette.WHITE};
  color: ${(props) =>
    props.active ? theme.palette.WHITE : theme.palette.VIBRANT_ORANGE};
  min-width: 99px;
  min-height: 33px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

type TPainAreaQuestionViewProps = {
  painArea: PainAreaKey | null;
  setPainArea: (activePain: PainAreaKey | null) => void;
  btnRef: React.MutableRefObject<any>;
  gender: "male" | "female" | "unknown";
};

export const AssessmentAnatomicalScreen = ({
  painArea,
  setPainArea,
  btnRef,
  gender,
}: TPainAreaQuestionViewProps) => {
  // state
  const [frontOrBack, setFrontOrBack] = React.useState<"front" | "back">(
    "front"
  );
  const [showModal, setShowModal] = React.useState(false);
  const [hotSpotClickedOnThisPage, setHotSpotClickedOnThisPage] =
    React.useState(false);
  const [selectedMirrored, setSelectedMirrored] = React.useState(false);
  const modalRef = React.useRef(null);

  // computed variables
  const windowWidth = window.outerWidth;
  const painAreas = React.useMemo(() => {
    if (gender === "female") return femalePainAreas;
    return malePainAreas;
  }, [gender]);

  const expandedPainAreas = React.useMemo(() => {
    return painAreas.flatMap(({ x, y, ref, side, mirror, label }) => {
      if (mirror) {
        return [
          { ref, x, y, side, label },
          {
            ref,
            x: x + (100 - x * 2),
            y,
            side,
            label,
            mirrored: true,
          },
        ];
      }
      return [{ ref, x, y, side, label }];
    });
  }, [painAreas]);
  const [frontPainAreas, backPainAreas] = React.useMemo(() => {
    return [
      expandedPainAreas.filter((area) => area.side === "front"),
      expandedPainAreas.filter((area) => area.side === "back"),
    ];
  }, [expandedPainAreas]);
  const activePainArea = React.useMemo(() => {
    if (!hotSpotClickedOnThisPage) return undefined;
    return expandedPainAreas.find(
      (area) =>
        area.ref === painArea && Boolean(area.mirrored) === selectedMirrored
    );
  }, [painArea]);

  // methods
  const handleShowModal = () => {
    if (showModal) setPainArea(null);
    setShowModal(!showModal);
  };
  const hotspotClicked = (area: string, mirrored: boolean, ref: string) => {
    setHotSpotClickedOnThisPage(true);
    setSelectedMirrored(mirrored);
    setShowModal(true);
    setPainArea(ref as PainAreaKey);
  };

  // renderers
  const renderModal = () => {
    if (showModal) {
      const area = expandedPainAreas.find(
        (area) =>
          area.ref === painArea && Boolean(area.mirrored) === selectedMirrored
      );
      const { x, y } = area ?? {};
      const topVal = `calc(${y}% - 100px)`;
      return (
        <>
          <Xarrow
            color={theme.palette.VIBRANT_ORANGE}
            zIndex={99}
            showHead={false}
            start="start"
            end={area?.ref ?? ""}
          />
          <div
            id="start"
            ref={modalRef}
            style={{
              position: "absolute",
              top: topVal,
              left: `${x}%`,
              width: "50%",
              transform: `translate(-50%,-50%)`,
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              zIndex: 100,
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            }}
          >
            <Paragraph marginBottom="0px" text={area?.label ?? ""} />
          </div>
        </>
      );
    }
  };
  const renderAnatomicalImage = () => {
    if (frontOrBack === "front") {
      switch (gender) {
        case "male":
          return <MaleFront painAreaSelected={!!painArea} />;
        case "female":
          return <FemaleFront painAreaSelected={!!painArea} />;
        default:
          return <MaleFront painAreaSelected={!!painArea} />;
      }
    } else {
      switch (gender) {
        case "male":
          return <MaleBack painAreaSelected={!!painArea} />;
        case "female":
          return <FemaleBack painAreaSelected={!!painArea} />;
        default:
          return <MaleBack painAreaSelected={!!painArea} />;
      }
    }
  };

  useOnClickOutside(modalRef, (event) => {
    if (!btnRef.current?.contains(event.target)) {
      handleShowModal();
    }
  });

  const renderPainAreas = () => {
    const painAreas = frontOrBack === "front" ? frontPainAreas : backPainAreas;
    if (activePainArea) {
      return (
        <Label x={activePainArea.x} y={activePainArea.y}>
          <Hotspot
            id={activePainArea.ref}
            onClick={() =>
              hotspotClicked(activePainArea.label, false, activePainArea.ref)
            }
          />
        </Label>
      );
    }
    return painAreas.map(({ x, y, label, ref, mirrored }, index) => (
      <Label x={x} y={y} key={index}>
        <Hotspot
          height="21px"
          id={ref}
          onClick={() => hotspotClicked(label, Boolean(mirrored), ref)}
        />
      </Label>
    ));
  };

  return (
    <>
      <Heading
        text={t("primary_pain_question")}
        size="medium"
        style={{ paddingTop: 60 }}
      />
      <ButtonWrapper>
        <LeftButton
          active={frontOrBack === "front"}
          onClick={() => setFrontOrBack("front")}
        >
          Front
        </LeftButton>
        <RightButton
          active={frontOrBack === "back"}
          onClick={() => setFrontOrBack("back")}
        >
          Back
        </RightButton>
      </ButtonWrapper>
      <Root>
        {renderModal()}
        <div
          style={{
            width: windowWidth - 200,
            aspectRatio: 1,
            backgroundColor: "transparent",
            position: "relative",
          }}
        >
          {renderAnatomicalImage()}
        </div>
        {renderPainAreas()}
      </Root>
    </>
  );
};
