import { theme } from "shared-utilities";
import styled from "styled-components/macro";

const TopContainer = styled("div")({
  position: "fixed",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  top: 0,
  right: 0,
  backgroundColor: theme.palette.TOP_BAR_BACKGROUND,
  zIndex: 1,
  height: "56px",
  borderBottom: "2px solid #ebebeb",
});

const Text = styled("div")({
  color: theme.palette.TOP_BAR_TEXT,
  margin: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const LeftButtonContainer = styled("div")({
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "0%",
  transform: "translate(50%, -50%)",
});

const RightButtonContainer = styled("div")({
  display: "flex",
  position: "absolute",
  top: "50%",
  right: "1%",
  transform: "translate(-50%, -50%)",
});

interface TopInfoBarProps {
  title?: string;
  image?: JSX.Element | null;
  leftButton?: React.ReactNode;
  leftButtonOnClick?: () => void;
  rightButton?: React.ReactNode;
  rightButtonOnClick?: () => void;
}

export const TopInfoBar: React.FC<TopInfoBarProps> = ({
  title,
  image,
  leftButton,
  leftButtonOnClick,
  rightButton,
  rightButtonOnClick,
}) => {
  const renderLeftButton = () => {
    if (!leftButton) return null;
    return (
      <LeftButtonContainer onClick={leftButtonOnClick}>
        {leftButton}
      </LeftButtonContainer>
    );
  };

  const renderRightButton = () => {
    if (!rightButton) return null;
    return (
      <RightButtonContainer onClick={rightButtonOnClick}>
        {rightButton}
      </RightButtonContainer>
    );
  };

  const renderTitleOrImage = () => {
    if (title) {
      return (
        <Text
          css={
            theme.typography[theme.typography.FONT_NAME].REGULAR_BOLD_HEADING
          }
        >
          {title}
        </Text>
      );
    }
    if (image) {
      return image;
    }
  };

  return (
    <TopContainer>
      {renderLeftButton()}
      <>{renderTitleOrImage()}</>
      {renderRightButton()}
    </TopContainer>
  );
};
