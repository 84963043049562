import React from "react";
import { theme } from "shared-utilities";
import styled, { css } from "styled-components";
import { CSSProp, DefaultTheme } from "styled-components/macro";
import Heading from "../texts/Heading";

const RootContainer = styled.div``;

const Root = styled.div`
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 20px;
`;
const loadingHeadingStyle = css`
  padding: ${theme.spacingPx(1)};
  padding-right: ${theme.spacingPx(2)};
`;
const headingStyle = css`
  padding: ${theme.spacingPx(1)};
  padding-left: ${theme.spacingPx(3)};
  padding-right: ${theme.spacingPx(3)};
`;

interface SmallButtonProps {
  text: string;
  onPress: () => void;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  loading?: boolean;
  loadingText?: string;
  style?: CSSProp<DefaultTheme>;
  disabled?: boolean;
  // trackingEvent?: string;
}

const SmallButton: React.FC<SmallButtonProps> = ({
  text,
  onPress,
  backgroundColor = theme.palette.WHITE,
  borderColor,
  textColor = theme.palette.PRIMARY,
  loading,
  loadingText,
  style,
  disabled,
  // trackingEvent,
}) => {
  // State
  const [isPressedIn, setIsPressedIn] = React.useState<boolean>(false);

  // Methods
  const handlePress = async () => {
    if (disabled) return;
    onPress();
  };

  const getOpacity = (): number => {
    if (disabled) return 0.5;
    return isPressedIn ? 0.7 : 1.0;
  };

  // Computed Variables
  const dynamicStyles = css({
    backgroundColor,
    borderColor,
    borderWidth: borderColor ? 1 : 0,
    opacity: getOpacity(),
  });

  // Renderers
  if (loading)
    return (
      <RootContainer css={style}>
        <Root css={[dynamicStyles, style]}>
          <div style={{ paddingLeft: theme.spacing(1) }}>
            {/* <ActivityIndicator size="small" color={textColor} /> */}
          </div>
          {text}
          <Heading
            bold
            size="tiny"
            text={loadingText}
            color={textColor}
            marginBottom="0px"
            style={loadingHeadingStyle}
          />
        </Root>
      </RootContainer>
    );

  return (
    <Root css={[dynamicStyles, style]} onClick={handlePress}>
      <Heading
        size="tiny"
        bold
        text={text}
        style={headingStyle}
        color={textColor}
        marginBottom="0px"
      />
    </Root>
  );
};

export default SmallButton;
