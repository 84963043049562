import { Heading, Paragraph } from "shared-utilities/src/ui";

interface Props {
  client?: string;
  logo?: JSX.Element | null;
}

export const InvalidSessionMessage = ({ client, logo }: Props) => {
  return (
    <>
      <div>{logo}</div>
      <Heading align="center" size="lg">
        Logged out
      </Heading>

      <Paragraph align="center" maxWidth="750px">
        Either your session has expired or you&apos;ve logged out. Please click
        the back button or close button at the top of this screen to return to
        {client}. If the problem persists, please contact support.
      </Paragraph>
    </>
  );
};
