import { useNavigate } from "react-router-dom";
import { ReactComponent as InfoIcon } from "shared-assets/src/icons/info.svg";
import { INSTRUCTIONS_OF_USE_LINK, t, theme } from "shared-utilities";
import { ScreenElement, Button } from "src/components";
import styled from "styled-components";
import RoundButton from "../components/buttons/RoundButton";
import Heading from "../components/texts/Heading";

const Title = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: #145e7f;
  margin: 70px 0 9px;
  text-align: left;
  width: 100%;
  max-width: 311px;
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: #145e7f;
  margin: 0;
  margin-bottom: 20px;
  text-align: left;
  max-width: 311px;
`;

const InfoWrapper = styled.div`
  background: #e7eef2;
  max-width: 311px;
  border-radius: 4px;
  padding: 24px 20px 34px;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

const InfoWrapperText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: #145e7f;
  margin: 0;
  text-align: left;
  margin-bottom: 15px;

  a {
    color: #145e7f;
  }
`;

const InfoWrapperTop = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 24px;

  p {
    margin: 0;
  }

  svg {
    width: 30px;

    path {
      fill: #145e7f;
    }

    rect,
    circle {
      fill: #fff;
    }
  }
`;

export const AssessmentCompleteScreen = () => {
  const navigate = useNavigate();

  return (
    <ScreenElement>
      <Title>Good news!</Title>
      <Text>
        Based on you responses, we are confident we can help. Your responses are
        being looked at by a qualified physiotherapist who will be in touch with
        a personalised treatment plan.
      </Text>
      <Text>
        In the meantime, we have provided some basic exercises to get you
        started.
      </Text>
      <InfoWrapper>
        <InfoWrapperTop>
          <InfoIcon />
          <InfoWrapperText>Tips for our in-app care</InfoWrapperText>
        </InfoWrapperTop>
        <InfoWrapperText>• Find a safe space to exercise.</InfoWrapperText>
        <InfoWrapperText>
          • Watch the instruction videos to make sure you are performing the
          exercises properly.
        </InfoWrapperText>
        <InfoWrapperText>
          • Make sure you have read and understood our full{" "}
          <a href={INSTRUCTIONS_OF_USE_LINK} target="_blank" rel="noreferrer">
            instructions for use.
          </a>
        </InfoWrapperText>
      </InfoWrapper>
      <RoundButton
        text={t("btn_continue")}
        onPress={() => navigate("/commitment")}
      />
      {/* <Heading */}
      {/*  size="regular" */}
      {/*  text="Continue" */}
      {/*  marginBottom="0px" */}
      {/*  color={theme.palette.WHITE} */}
      {/* /> */}
      {/* </RoundButton> */}
    </ScreenElement>
  );
};
