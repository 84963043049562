import React from "react";
import { theme, victoryTheme } from "shared-utilities";
import styled, { css } from "styled-components/macro";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
} from "victory";
import { DomainPropType, DomainTuple } from "victory-core";
import graphRange from "../../assets/graph_range.png";
import Paper from "../patterns/Paper";
import Heading from "../texts/Heading";
import Paragraph from "../texts/Paragraph";

const STROKE_WIDTH = 2 * theme.utilities.SCALE;

const ImageContainer = styled.div`
  position: relative;
  object-fit: cover;
  align-items: center;
  margin-top: -2%;
  margin-left: -9%;
`;

const graphCell = (isMultiLayout: any, highlightBorder: any) => {
  return css`
    background-color: ${theme.palette.WHITE};
    padding: 15px;
    border-color: ${theme.palette.LIGHTER_GREY};
    max-width: ${isMultiLayout ? "59%" : "50%"};
    min-width: 300px;
    border-width: ${highlightBorder ? `1px` : `0px`};
    margin: 15px 15px 20px 15px;
    border-radius: 5px;
    flex: auto;
    position: relative;
  `;
};

interface GraphProps<T> {
  isMultiLayout?: boolean;
  domain: DomainTuple;
  value: T[];
  highlightBorder?: boolean;
  title: string;
  subtitle?: string;
  tickGetter: (value: T) => string | number;
  dataFormatter: (value: T) => { x: number | string; y: number | string };
  tickFormatter: (value: string | number) => string;
}

function Graph<T>({
  isMultiLayout,
  domain,
  value,
  highlightBorder,
  title,
  subtitle = "",
  tickGetter,
  dataFormatter,
  tickFormatter,
}: GraphProps<T>): ReturnType<React.FC<GraphProps<T>>> {
  // Computed Variables
  const ticks = React.useMemo(() => value.map(tickGetter), [value, tickGetter]);
  const data = React.useMemo(
    () => value.map(dataFormatter),
    [value, dataFormatter]
  );
  const scopedData = React.useMemo(() => {
    if (data.length >= 8) return data.slice(-8);
    return data;
  }, [data]);

  // Methods
  const getDomain = (): DomainPropType => ({
    x:
      data.length > 7 ? [data.length - 7, data.length] : [0, scopedData.length],
    y: domain,
  });

  const renderLine = () => {
    if (value.length < 2) return null;
    return (
      <VictoryLine
        data={scopedData}
        style={{
          data: { stroke: theme.palette.PINK, strokeWidth: STROKE_WIDTH },
        }}
        interpolation="linear"
      />
    );
  };

  if (!data.length) return null;

  return (
    <Paper style={graphCell(isMultiLayout, highlightBorder)}>
      <Heading size="regular" style={{ textAlign: "left", marginBottom: 0 }}>
        {title}
      </Heading>
      <Paragraph style={{ textAlign: "left" }}>{subtitle}</Paragraph>
      <div
        style={{
          marginTop: 16,
          paddingRight: 12,
          display: "flex",
          flexDirection: "row",
          alignSelf: "center",
        }}
      >
        <div style={{ overflow: "hidden" }}>
          <VictoryChart
            padding={{
              left: 0,
              right: isMultiLayout ? 0 : 50,
              bottom: 30,
              top: 0,
            }}
            height={364}
            theme={victoryTheme}
            domain={getDomain()}
            domainPadding={{ x: [16, 42] }}
          >
            <VictoryAxis
              dependentAxis
              domain={getDomain()}
              orientation="left"
              standalone={false}
              padding={{ right: isMultiLayout ? 0 : 50 }}
              tickValues={[0, 2, 4, 6, 8, 10]}
              // tickValues={[0, 3.33, 6.66, 10]}
              tickFormat={() => ""}
              style={{
                ticks: { stroke: "transparent" },
                axis: { stroke: "transparent" },
              }}
            />
            <VictoryAxis
              // dependentAxis
              orientation="bottom"
              standalone={false}
              tickValues={ticks}
              tickFormat={tickFormatter}
              style={{
                ticks: { stroke: "transparent" },
                tickLabels: {
                  fill: theme.palette.PRIMARY,
                  fontSize: 18,
                },
                axis: { stroke: "transparent" },
                grid: { stroke: "transparent" },
              }}
            />
            {renderLine()}
            <VictoryScatter
              data={scopedData}
              size={10}
              symbol="circle"
              style={{
                data: {
                  fill: theme.palette.WHITE,
                  stroke: theme.palette.PINK,
                  strokeWidth: STROKE_WIDTH,
                },
              }}
            />
          </VictoryChart>
        </div>
        <ImageContainer>
          <img
            style={{
              width: "60%",
              height: "94.5%",
            }}
            src={graphRange}
            alt="graph-range"
          />
        </ImageContainer>
      </div>
    </Paper>
  );
}

export default Graph;
