import { createSlice } from '@reduxjs/toolkit';
import { Learn, LearnArticle } from 'shared-interfaces';
import { learnApi } from '../api';
import { RootState } from '../store';
import { logout } from './auth';

// State Interface
export interface LearnState {
  learn: Learn | null;
}

// Initial State
const initialState: LearnState = {
  learn: null,
};

// Slice
export const learnSlice = createSlice({
  name: 'learn',
  initialState,
  reducers: {
    // logout: (state) => {
    //   state.token = null;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.learn = null;
    });
    builder.addMatcher(learnApi.endpoints.learnFetch.matchFulfilled, (state, action) => {
      state.learn = action.payload.data.learn;
    });
  },
});

// Actions
// export const { logout } = planSlice.actions;

// Selectors
export const getLearn = (state: RootState): Learn | null => state.learn.learn;
export const getRemainingArticles = (state: RootState): number =>
  state.learn.learn?.remainingArticles ?? 0;
export const getNextArticleCountdown = (state: RootState) =>
  state.learn.learn?.nextArticleCountdown ?? 0;
export const getArticles = (state: RootState): LearnArticle[] => state.learn?.learn?.articles ?? [];
export const getArticleBadgeCount = (state: RootState): number => {
  const articles = getArticles(state);
  return articles.length - articles.filter((article) => article.seenAt).length;
};

// Reducer
export default learnSlice.reducer;
