export default {
  questionSections: [
    {
      _id: '60eeb5ab608cd028dcf6f4de',
      questions: [
        {
          initial: false,
          _id: '60f56ec41851c10004b1e188',
          category: 'Your Symptoms',
          choices: [
            {
              _id: '60f56ec41851c10004b1e189',
              ref: 'A',
              value: 'A',
              oldRef: 'which_knee_A',
              label: 'Only left knee',
            },
            {
              _id: '60f56ec41851c10004b1e18b',
              ref: 'B',
              value: 'B',
              oldRef: 'which_knee_B',
              label: 'Only right knee',
            },
            {
              _id: '60f56ec41851c10004b1e18d',
              ref: 'C',
              value: 'C',
              oldRef: 'which_knee_C',
              label: 'Both knees',
            },
          ],
          order: 21,
          ref: 'which_knee',
          type: 'choice',
          validations: [],
          title: 'Is this problem affecting one or both of your knees?',
        },
        {
          initial: false,
          _id: '60eeb73f4ada5600047aedd6',
          choices: [],
          ref: 'description_your_symptoms_knee',
          type: 'description',
          validations: [],
          subtitle:
            'It’s not unusual for knee problems to also give you symptoms above or below your knee and can cause your knee to swell, click, lock, catch or occasionally feel like it is giving way.    \n\nHowever, should you be experiencing any of the following symptoms, these could be a sign of something more serious and we will let you know what to do next.',
        },
        {
          initial: false,
          _id: '60eedf9a4ada5600047aee73',
          category: 'Red flags',
          choices: [
            {
              _id: '60eedf9a4ada5600047aee74',
              ref: 'A',
              value: 'A',
              oldRef: 'red_flag_multi_knee_1_A',
              label: 'Loss of consciousness, fainting or blackouts',
            },
            {
              _id: '60eedf9a4ada5600047aee76',
              ref: 'B',
              value: 'B',
              oldRef: 'red_flag_multi_knee_1_B',
              label: 'Unexplained chest pain ',
            },
            {
              _id: '60eedf9a4ada5600047aee78',
              ref: 'C',
              value: 'C',
              oldRef: 'red_flag_multi_knee_1_C',
              label: 'Unexplained shortness of breath',
            },
            {
              _id: '60eedf9a4ada5600047aee7a',
              ref: 'D',
              value: 'D',
              oldRef: 'red_flag_multi_knee_1_D',
              label: 'Being unable to bear weight or take more than 3 steps on your injured leg',
            },
            {
              _id: '60eedf9a4ada5600047aee7c',
              ref: 'E',
              value: 'E',
              oldRef: 'red_flag_multi_knee_1_E',
              label: 'Pronounced swelling, redness and warmth in either leg ',
            },
            {
              _id: '60eedfc14ada5600047aee90',
              ref: 'F',
              ofType: 'None',
              value: 'F',
              oldRef: 'red_flag_multi_knee_1_F',
              label: 'None of the above',
            },
          ],
          order: 21,
          ref: 'red_flag_multi_knee_1',
          type: 'multiple_choice',
          validations: [],
          title: 'Please select any of the following that apply:',
        },
        {
          initial: false,
          _id: '60eee00f4ada5600047aee97',
          category: 'Red flags',
          choices: [
            {
              _id: '60eee00f4ada5600047aee98',
              ref: 'A',
              value: 'A',
              oldRef: 'red_flag_multi_knee_2_A',
              label: 'Throbbing or cramping pain in either leg ',
            },
            {
              _id: '60eee00f4ada5600047aee9a',
              ref: 'B',
              value: 'B',
              oldRef: 'red_flag_multi_knee_2_B',
              label: 'Unexplained coldness or loss of colour in the foot or toes of either leg ',
            },
            {
              _id: '60eee00f4ada5600047aee9c',
              ref: 'C',
              value: 'C',
              oldRef: 'red_flag_multi_knee_2_C',
              label: 'Flu-like symptoms with a fever above 38°C',
            },
            {
              _id: '60eee00f4ada5600047aee9e',
              ref: 'D',
              value: 'D',
              oldRef: 'red_flag_multi_knee_2_D',
              label: 'Your leg or knee looks deformed or has recently developed a lump',
            },
            {
              _id: '60eee00f4ada5600047aeea0',
              ref: 'E',
              ofType: 'None',
              value: 'E',
              oldRef: 'red_flag_multi_knee_2_E',
              label: 'None of the above',
            },
          ],
          order: 22,
          ref: 'red_flag_multi_knee_2',
          type: 'multiple_choice',
          validations: [],
          title: 'Please select any of the following that apply:',
        },
        {
          initial: false,
          _id: '60eee1514ada5600047aeea9',
          category: 'Your Symptoms',
          choices: [
            {
              _id: '60eee1514ada5600047aeeaa',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_pain_location_A',
              label: 'In the front of the knee',
            },
            {
              _id: '60eee1514ada5600047aeeac',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_pain_location_B',
              label: 'In the back of the knee',
            },
            {
              _id: '60eee1514ada5600047aeeae',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_pain_location_C',
              label: 'Deep inside the knee',
            },
            {
              _id: '60eee1514ada5600047aeeb0',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_pain_location_D',
              label: 'On the inner side of the knee',
            },
            {
              _id: '60eee1514ada5600047aeeb2',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_pain_location_E',
              label: 'On the outer side of the knee',
            },
            {
              _id: '60eee16e4ada5600047aeec3',
              ref: 'F',
              value: 'F',
              oldRef: 'knee_pain_location_F',
              label: 'In or around the kneecap',
            },
            {
              _id: '60eee16e4ada5600047aeec4',
              ref: 'G',
              value: 'G',
              oldRef: 'knee_pain_location_G',
              label: 'Behind the kneecap',
            },
            {
              _id: '60eee16e4ada5600047aeec5',
              ref: 'H',
              value: 'H',
              oldRef: 'knee_pain_location_H',
              label: 'Above the knee',
            },
            {
              _id: '60eee16e4ada5600047aeec6',
              ref: 'I',
              value: 'I',
              oldRef: 'knee_pain_location_I',
              label: 'Below the knee',
            },
            {
              _id: '60eee16e4ada5600047aeec7',
              ref: 'J',
              value: 'J',
              oldRef: 'knee_pain_location_J',
              label: 'Generalized knee pain',
            },
            {
              _id: '60eee1904ada5600047aeee2',
              ref: 'K',
              ofType: 'None',
              value: 'K',
              oldRef: 'knee_pain_location_K',
              label: 'None of the above',
            },
          ],
          order: 23,
          ref: 'knee_pain_location',
          type: 'multiple_choice',
          validations: [],
          title: 'My knee pain/symptoms are located:',
          instructions: '(Select any that apply to you)',
        },
        {
          initial: false,
          _id: '5ce53cc48f51e2000451b7e7',
          ref: 'symptoms_howlong',
          category: 'Basic pain questions',
          type: 'choice',
          choices: [
            {
              _id: '5ce53cc48f51e2000451b7f6',
              ref: 'A',
              value: 'A',
              oldRef: 'symptoms_howlong_A',
              label: 'Less than 2 weeks',
            },
            {
              _id: '5ce53cc48f51e2000451b7f3',
              ref: 'B',
              value: 'B',
              oldRef: 'symptoms_howlong_B',
              label: '2-4 weeks',
            },
            {
              _id: '5ce53cc48f51e2000451b7f0',
              ref: 'C',
              value: 'C',
              oldRef: 'symptoms_howlong_C',
              label: '1-3 months',
            },
            {
              _id: '5ce53cc48f51e2000451b7ed',
              ref: 'D',
              value: 'D',
              oldRef: 'symptoms_howlong_D',
              label: '4-12 months',
            },
            {
              _id: '5ce53cc48f51e2000451b7ea',
              ref: 'F',
              value: 'F',
              oldRef: 'symptoms_howlong_F',
              label: 'Over 1 year',
            },
          ],
          validations: [
            {
              _id: '5ce53cc48f51e2000451b7f9',
              languages: [],
              required: true,
            },
          ],
          order: 3,
          title: 'How long have you been experiencing knee problems?',
        },
        {
          initial: false,
          _id: '5cd580b6e8214d4b6b83b2bf',
          ref: 'symptoms_how',
          category: 'Basic pain questions',
          type: 'choice',
          choices: [
            {
              _id: '5cd580b6e8214d4b6b83b2ce',
              ref: 'A',
              value: 'A',
              oldRef: 'symptoms_how_A',
              label: 'Started suddenly, from awkward movement, lift or position',
            },
            {
              _id: '5cd580b6e8214d4b6b83b2cb',
              ref: 'B',
              value: 'B',
              oldRef: 'symptoms_how_B',
              label: 'Light pain that has become worse',
            },
            {
              _id: '5cd580b6e8214d4b6b83b2c8',
              ref: 'C',
              value: 'C',
              oldRef: 'symptoms_how_C',
              label: 'After a traumatic incident e.g. fall, accident',
              subQuestion: {
                scale: {},
                initial: false,
                _id: '5cd580e5c11fec4c0a5a3c76',
                ref: 'symptoms_how_trauma_details',
                category: 'Basic pain questions',
                type: 'long_text',
                choices: [],
                validations: [
                  {
                    _id: '5cd580e5c11fec4c0a5a3c79',
                    languages: [],
                    required: true,
                  },
                ],
                order: 5,
                title: 'Tell us about the incident',
              },
            },
            {
              _id: '5cd580b6e8214d4b6b83b2c5',
              ref: 'D',
              value: 'D',
              oldRef: 'symptoms_how_D',
              label: 'I woke up with it',
            },
            {
              _id: '5cd580b6e8214d4b6b83b2c2',
              ref: 'E',
              value: 'E',
              oldRef: 'symptoms_how_E',
              label: "I'm not sure",
            },
          ],
          validations: [
            {
              _id: '5cd580b6e8214d4b6b83b2d1',
              languages: [],
              required: true,
            },
          ],
          order: 4,
          title: 'How did your pain/symptoms occur?',
        },
        {
          scale: {
            steps: 11,
            left: 'No\npain',
            center: 'Moderate\npain',
            right: 'Worst\npossible pain',
          },
          initial: false,
          _id: '5cd58012e8214d4b6b83b2b9',
          ref: 'painarea_rating',
          category: 'Basic pain questions',
          type: 'pain_slider',
          choices: [],
          validations: [
            {
              _id: '5cd58012e8214d4b6b83b2be',
              languages: [],
              required: true,
            },
          ],
          order: 2,
          title: 'Over the past week, on average, how would you rate your knee pain?',
        },
        {
          initial: false,
          _id: '5cd577ff11a97a48aa110419',
          ref: 'other_pain',
          category: 'Basic pain questions',
          type: 'multiple_choice',
          choices: [
            {
              _id: '5cd577ff11a97a48aa110434',
              ref: 'A',
              value: 'A',
              oldRef: 'other_pain_A',
              label: 'Neck',
            },
            {
              _id: '5cd577ff11a97a48aa110431',
              ref: 'B',
              value: 'B',
              oldRef: 'other_pain_B',
              label: 'Mid / Upper back',
            },
            {
              _id: '5cd577ff11a97a48aa11042e',
              ref: 'C',
              value: 'C',
              oldRef: 'other_pain_C',
              label: 'Lower back',
            },
            {
              _id: '5cd577ff11a97a48aa11042b',
              ref: 'D',
              value: 'D',
              oldRef: 'other_pain_D',
              label: 'Shoulder',
            },
            {
              _id: '5cd577ff11a97a48aa110428',
              ref: 'E',
              value: 'E',
              oldRef: 'other_pain_E',
              label: 'Elbow / Wrist / Hand',
            },
            {
              _id: '5cd577ff11a97a48aa110425',
              ref: 'F',
              value: 'F',
              oldRef: 'other_pain_F',
              label: 'Hip',
            },
            {
              _id: '5cd577ff11a97a48aa110422',
              ref: 'G',
              value: 'G',
              oldRef: 'other_pain_G',
              label: 'Knee',
            },
            {
              _id: '5cd577ff11a97a48aa11041f',
              ref: 'H',
              value: 'H',
              oldRef: 'other_pain_H',
              label: 'Foot / Ankle',
            },
            {
              _id: '5cf39712c3980c000441eff9',
              ref: 'I',
              ofType: 'None',
              value: 'I',
              oldRef: 'other_pain_I',
              label: 'None of the above',
            },
          ],
          validations: [
            {
              _id: '5cd577ff11a97a48aa110437',
              languages: [],
              required: true,
            },
          ],
          order: 6,
          title: 'Do you have muscle or joint problems anywhere else?',
        },
      ],
      title: 'Your symptoms',
      progressSection: true,
      lastSection: false,
    },
    {
      _id: '6059f17efe547d4aece4a9e8',
      questions: [
        {
          theme: {
            color: 'white',
            illustration: 'illustration_about',
          },
          initial: false,
          _id: '5cd58b030481577babbe2419',
          ref: 'section_intro_about_you',
          category: 'section_intro',
          type: 'section_intro',
          choices: [],
          validations: [],
          title: 'About you',
          subtitle: 'Next, we need some information about you and your lifestyle.',
          button: 'Continue',
        },
        {
          initial: false,
          _id: '5cd586c2d7cae34de78d8702',
          ref: 'demo_occupation',
          category: 'Demographic',
          type: 'long_text',
          choices: [],
          validations: [
            {
              _id: '5cd586c2d7cae34de78d8705',
              languages: [],
              required: true,
            },
          ],
          order: 104,
          title: 'What do you do for a living?',
          placeholder: 'e.g. teacher, student, retired, office worker',
        },
        {
          initial: false,
          _id: '5ce63a2e10980a0004f18ccd',
          ref: 'general_health',
          category: 'Demographic',
          type: 'choice',
          choices: [
            {
              _id: '5ce63a2e10980a0004f18cdc',
              ref: 'A',
              value: 'A',
              oldRef: 'general_health_A',
              label: 'Excellent',
            },
            {
              _id: '5ce63a2e10980a0004f18cd9',
              ref: 'B',
              value: 'B',
              oldRef: 'general_health_B',
              label: 'Very good',
            },
            {
              _id: '5ce63a2e10980a0004f18cd6',
              ref: 'C',
              value: 'C',
              oldRef: 'general_health_C',
              label: 'Good',
            },
            {
              _id: '5ce63a2e10980a0004f18cd3',
              ref: 'D',
              value: 'D',
              oldRef: 'general_health_D',
              label: 'Fair',
            },
            {
              _id: '5ce63a2e10980a0004f18cd0',
              ref: 'E',
              value: 'E',
              oldRef: 'general_health_E',
              label: 'Poor',
            },
          ],
          validations: [
            {
              _id: '5ce63a2e10980a0004f18cdf',
              languages: [],
              required: true,
            },
          ],
          order: 105,
          title: 'In general, how would you describe your health?',
        },
        {
          initial: false,
          _id: '5ce63a4810980a0004f18ce0',
          ref: 'smoke',
          category: 'Demographic',
          type: 'choice',
          choices: [
            {
              _id: '5ce63a4810980a0004f18cef',
              ref: 'E',
              value: 'E',
              oldRef: 'smoke_E',
              label: 'No, I have never smoked',
            },
            {
              _id: '5ce63a4810980a0004f18cec',
              ref: 'D',
              value: 'D',
              oldRef: 'smoke_D',
              label: 'No, but I used to',
            },
            {
              _id: '5ce63a4810980a0004f18ce9',
              ref: 'C',
              value: 'C',
              oldRef: 'smoke_C',
              label: 'Occasionally',
            },
            {
              _id: '5ce63a4810980a0004f18ce6',
              ref: 'B',
              value: 'B',
              oldRef: 'smoke_B',
              label: 'Yes, frequently',
            },
            {
              _id: '5ce63a4810980a0004f18ce3',
              ref: 'A',
              value: 'A',
              oldRef: 'smoke_A',
              label: 'Yes, every day',
            },
          ],
          validations: [
            {
              _id: '5ce63a4810980a0004f18cf2',
              languages: [],
              required: true,
            },
          ],
          order: 106,
          title: 'Do you smoke?',
          subtitle: 'e.g. cigarettes, cigars, pipe, vape, etc.',
        },
        {
          initial: false,
          _id: '5ce63a9710980a0004f18d07',
          ref: 'pal_daily_activity',
          category: 'Physical Activity Level',
          type: 'choice',
          choices: [
            {
              _id: '5ce63a9710980a0004f18d0d',
              ref: 'B',
              value: 'B',
              oldRef: 'pal_daily_activity_B',
              label: 'At least 30 mins in total',
            },
            {
              _id: '5ce63a9710980a0004f18d0a',
              ref: 'A',
              value: 'A',
              oldRef: 'pal_daily_activity_A',
              label: 'Less than 30 mins in total',
            },
          ],
          validations: [
            {
              _id: '5ce63a9710980a0004f18d10',
              languages: [],
              required: true,
            },
          ],
          order: 200,
          title: 'How much daily physical activity do you do?',
          subtitle: 'e.g. walking, cycling to work, a lot of lifting at work',
        },
        {
          initial: false,
          _id: '5cd58757d7cae34de78d8719',
          ref: 'pal_weekly_exercise',
          category: 'Physical Activity Level',
          type: 'choice',
          choices: [
            {
              _id: '5cd58757d7cae34de78d871f',
              ref: 'B',
              value: 'B',
              oldRef: 'pal_weekly_exercise_B',
              label: 'At least twice',
              subQuestion: {
                scale: {},
                initial: false,
                _id: '5cdaab11938cb47ca0caf01f',
                ref: 'pal_weekly_exercise_details',
                category: 'Physical Activity Level',
                type: 'long_text',
                choices: [],
                validations: [
                  {
                    _id: '5cdaab11938cb47ca0caf022',
                    languages: [],
                    required: true,
                  },
                ],
                order: 202,
                title: 'Describe the physical activity and exercise you do',
              },
            },
            {
              _id: '5cd58757d7cae34de78d871c',
              ref: 'A',
              value: 'A',
              oldRef: 'pal_weekly_exercise_A',
              label: 'Less than twice',
            },
          ],
          validations: [
            {
              _id: '5cd58757d7cae34de78d8722',
              languages: [],
              required: true,
            },
          ],
          order: 201,
          title: 'How many times a week do you do moderate to strenuous exercise?',
          subtitle: 'e.g. running, dancing, team sports or gym workouts',
        },
      ],
      title: 'About you',
      progressSection: true,
      lastSection: false,
    },
    {
      _id: '60eee7e1608cd028dcf6f4df',
      questions: [
        {
          theme: {
            color: 'white',
            illustration: 'illustration_activity',
          },
          initial: false,
          _id: '5cd58b4c0481577babbe261c',
          ref: 'section_intro_physical_activity',
          category: 'section_intro',
          type: 'section_intro',
          choices: [],
          validations: [],
          title: 'Pain & activity',
          subtitle: "Let's find out which symptoms you have, and daily activities you might have difficulty with.",
          button: 'Continue',
        },
        {
          initial: false,
          _id: '60f69fe91316880004b3f61e',
          category: 'Pain & activity',
          choices: [
            {
              _id: '60f69fe91316880004b3f61f',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_symptoms_two_weeks_A',
              label: 'Knee stiffness',
            },
            {
              _id: '60f69fe91316880004b3f621',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_symptoms_two_weeks_B',
              label: 'Grinding in the knee',
            },
            {
              _id: '60f69fe91316880004b3f623',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_symptoms_two_weeks_C',
              label: 'Knee clicking',
            },
            {
              _id: '60f69fe91316880004b3f625',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_symptoms_two_weeks_D',
              label: 'The knee catching when moving',
            },
            {
              _id: '60f69fe91316880004b3f627',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_symptoms_two_weeks_E',
              label: 'The knee occasionally giving way or buckling whilst lifting',
            },
            {
              _id: '60f6a0271316880004b3f638',
              ref: 'F',
              value: 'F',
              oldRef: 'knee_symptoms_two_weeks_F',
              label: 'Locking of the knee or inability to straighten',
            },
            {
              _id: '60f6a0271316880004b3f63a',
              ref: 'G',
              value: 'G',
              oldRef: 'knee_symptoms_two_weeks_G',
              label: 'Inability to bend the knee fully',
            },
            {
              _id: '60f6a0271316880004b3f63c',
              ref: 'H',
              value: 'H',
              oldRef: 'knee_symptoms_two_weeks_H',
              label: 'Feeling of instability or weakness in the knee',
            },
            {
              _id: '60f6a0271316880004b3f63e',
              ref: 'I',
              ofType: 'None',
              value: 'I',
              oldRef: 'knee_symptoms_two_weeks_I',
              label: 'None of the above',
            },
          ],
          order: 80,
          ref: 'knee_symptoms_two_weeks',
          type: 'multiple_choice',
          validations: [],
          title: 'Which of the following symptoms have you experienced within the last two weeks:',
        },
        {
          initial: false,
          _id: '60eeee614ada5600047aef8b',
          category: 'Pain & activity',
          choices: [
            {
              _id: '60eeee614ada5600047aef8c',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_standing_A',
              label: 'None',
            },
            {
              _id: '60eeee614ada5600047aef8f',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_standing_B',
              label: 'Mild',
            },
            {
              _id: '60eeee614ada5600047aef91',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_standing_C',
              label: 'Moderate',
            },
            {
              _id: '60eeee614ada5600047aef93',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_standing_D',
              label: 'Severe',
            },
            {
              _id: '60eeee614ada5600047aef95',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_standing_E',
              label: 'Extreme',
            },
          ],
          order: 81,
          ref: 'knee_standing',
          type: 'choice',
          validations: [],
          title: 'How much knee pain have you experienced in the last week when carrying out the following activities?',
          emphasized: 'Standing',
        },
        {
          initial: false,
          _id: '60eeee96608cd028dcf6f4e1',
          category: 'Pain & activity',
          choices: [
            {
              _id: '60eeee614ada5600047aef8c',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_walking_A',
              label: 'None',
            },
            {
              _id: '60eeee614ada5600047aef8f',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_walking_B',
              label: 'Mild',
            },
            {
              _id: '60eeee614ada5600047aef91',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_walking_C',
              label: 'Moderate',
            },
            {
              _id: '60eeee614ada5600047aef93',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_walking_D',
              label: 'Severe',
            },
            {
              _id: '60eeee614ada5600047aef95',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_walking_E',
              label: 'Extreme',
            },
          ],
          order: 82,
          ref: 'knee_walking',
          type: 'choice',
          validations: [],
          title: 'How much knee pain have you experienced in the last week when carrying out the following activities?',
          emphasized: 'Walking on a flat surface',
        },
        {
          initial: false,
          _id: '60eeeeb1608cd028dcf6f4e2',
          category: 'Pain & activity',
          choices: [
            {
              _id: '60eeee614ada5600047aef8c',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_stairs_A',
              label: 'None',
            },
            {
              _id: '60eeee614ada5600047aef8f',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_stairs_B',
              label: 'Mild',
            },
            {
              _id: '60eeee614ada5600047aef91',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_stairs_C',
              label: 'Moderate',
            },
            {
              _id: '60eeee614ada5600047aef93',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_stairs_D',
              label: 'Severe',
            },
            {
              _id: '60eeee614ada5600047aef95',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_stairs_E',
              label: 'Extreme',
            },
          ],
          order: 83,
          ref: 'knee_stairs',
          type: 'choice',
          validations: [],
          title: 'How much knee pain have you experienced in the last week when carrying out the following activities?',
          emphasized: 'Going up or down stairs',
        },
        {
          initial: false,
          _id: '60eeeec3608cd028dcf6f4e3',
          category: 'Pain & activity',
          choices: [
            {
              _id: '60eeee614ada5600047aef8c',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_rising_A',
              label: 'None',
            },
            {
              _id: '60eeee614ada5600047aef8f',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_rising_B',
              label: 'Mild',
            },
            {
              _id: '60eeee614ada5600047aef91',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_rising_C',
              label: 'Moderate',
            },
            {
              _id: '60eeee614ada5600047aef93',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_rising_D',
              label: 'Severe',
            },
            {
              _id: '60eeee614ada5600047aef95',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_rising_E',
              label: 'Extreme',
            },
          ],
          order: 84,
          ref: 'knee_rising',
          type: 'choice',
          validations: [],
          title: 'How much knee pain have you experienced in the last week when carrying out the following activities?',
          emphasized: 'Rising from sitting',
        },
        {
          initial: false,
          _id: '60eeeedd608cd028dcf6f4e4',
          category: 'Pain & activity',
          choices: [
            {
              _id: '60eeee614ada5600047aef8c',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_kneeling_A',
              label: 'None',
            },
            {
              _id: '60eeee614ada5600047aef8f',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_kneeling_B',
              label: 'Mild',
            },
            {
              _id: '60eeee614ada5600047aef91',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_kneeling_C',
              label: 'Moderate',
            },
            {
              _id: '60eeee614ada5600047aef93',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_kneeling_D',
              label: 'Severe',
            },
            {
              _id: '60eeee614ada5600047aef95',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_kneeling_E',
              label: 'Extreme',
            },
          ],
          order: 85,
          ref: 'knee_kneeling',
          type: 'choice',
          validations: [],
          title: 'How much knee pain have you experienced in the last week when carrying out the following activities?',
          emphasized: 'Kneeling',
        },
        {
          initial: false,
          _id: '60eeef09608cd028dcf6f4e5',
          category: 'Pain & activity',
          choices: [
            {
              _id: '60eeee614ada5600047aef8c',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_squatting_A',
              label: 'None',
            },
            {
              _id: '60eeee614ada5600047aef8f',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_squatting_B',
              label: 'Mild',
            },
            {
              _id: '60eeee614ada5600047aef91',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_squatting_C',
              label: 'Moderate',
            },
            {
              _id: '60eeee614ada5600047aef93',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_squatting_D',
              label: 'Severe',
            },
            {
              _id: '60eeee614ada5600047aef95',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_squatting_E',
              label: 'Extreme',
            },
          ],
          order: 86,
          ref: 'knee_squatting',
          type: 'choice',
          validations: [],
          title: 'How much knee pain have you experienced in the last week when carrying out the following activities?',
          emphasized: 'Squatting',
        },
        {
          initial: false,
          _id: '60eeef2b608cd028dcf6f4e6',
          category: 'Pain & activity',
          choices: [
            {
              _id: '60eeee614ada5600047aef8c',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_running_A',
              label: 'None',
            },
            {
              _id: '60eeee614ada5600047aef8f',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_running_B',
              label: 'Mild',
            },
            {
              _id: '60eeee614ada5600047aef91',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_running_C',
              label: 'Moderate',
            },
            {
              _id: '60eeee614ada5600047aef93',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_running_D',
              label: 'Severe',
            },
            {
              _id: '60eeee614ada5600047aef95',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_running_E',
              label: 'Extreme',
            },
          ],
          order: 87,
          ref: 'knee_running',
          type: 'choice',
          validations: [],
          title: 'How much knee pain have you experienced in the last week when carrying out the following activities?',
          emphasized: 'Running/jogging',
        },
        {
          initial: false,
          _id: '60eeef46608cd028dcf6f4e7',
          category: 'Pain & activity',
          choices: [
            {
              _id: '60eeee614ada5600047aef8c',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_after_sport_A',
              label: 'None',
            },
            {
              _id: '60eeee614ada5600047aef8f',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_after_sport_B',
              label: 'Mild',
            },
            {
              _id: '60eeee614ada5600047aef91',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_after_sport_C',
              label: 'Moderate',
            },
            {
              _id: '60eeee614ada5600047aef93',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_after_sport_D',
              label: 'Severe',
            },
            {
              _id: '60eeee614ada5600047aef95',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_after_sport_E',
              label: 'Extreme',
            },
          ],
          order: 88,
          ref: 'knee_after_sport',
          type: 'choice',
          validations: [],
          title: 'How much knee pain have you experienced in the last week when carrying out the following activities?',
          emphasized: 'After sport and recreational activities',
        },
        {
          initial: false,
          _id: '60eeef60608cd028dcf6f4e8',
          category: 'Pain & activity',
          choices: [
            {
              _id: '60eeee614ada5600047aef8c',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_confidence_A',
              label: 'Not at all',
            },
            {
              _id: '60eeee614ada5600047aef8f',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_confidence_B',
              label: 'Mildly',
            },
            {
              _id: '60eeee614ada5600047aef91',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_confidence_C',
              label: 'Moderately',
            },
            {
              _id: '60eeee614ada5600047aef93',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_confidence_D',
              label: 'Severely',
            },
            {
              _id: '60eeee614ada5600047aef95',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_confidence_E',
              label: 'Extremely',
            },
          ],
          order: 89,
          ref: 'knee_confidence',
          type: 'choice',
          validations: [],
          title: 'How much are you troubled by lack of confidence in your knee?',
        },
        {
          initial: false,
          _id: '60eeef78608cd028dcf6f4e9',
          category: 'Pain & activity',
          choices: [
            {
              _id: '60eeee614ada5600047aef8c',
              ref: 'A',
              value: 'A',
              oldRef: 'knee_general_difficulty_A',
              label: 'None',
            },
            {
              _id: '60eeee614ada5600047aef8f',
              ref: 'B',
              value: 'B',
              oldRef: 'knee_general_difficulty_B',
              label: 'Mild',
            },
            {
              _id: '60eeee614ada5600047aef91',
              ref: 'C',
              value: 'C',
              oldRef: 'knee_general_difficulty_C',
              label: 'Moderate',
            },
            {
              _id: '60eeee614ada5600047aef93',
              ref: 'D',
              value: 'D',
              oldRef: 'knee_general_difficulty_D',
              label: 'Severe',
            },
            {
              _id: '60eeee614ada5600047aef95',
              ref: 'E',
              value: 'E',
              oldRef: 'knee_general_difficulty_E',
              label: 'Extreme',
            },
          ],
          order: 90,
          ref: 'knee_general_difficulty',
          type: 'choice',
          validations: [],
          title: 'In general, how much difficulty do you have with your knee?',
        },
        {
          initial: false,
          _id: '5ce649604398da0004710810',
          ref: 'work_sick_days',
          category: 'Work',
          type: 'choice',
          choices: [
            {
              _id: '5ce649604398da000471081f',
              ref: 'A',
              value: 'A',
              oldRef: 'work_sick_days_A',
              label: 'No days',
            },
            {
              _id: '5ce649604398da000471081c',
              ref: 'B',
              value: 'B',
              oldRef: 'work_sick_days_B',
              label: '1 day',
            },
            {
              _id: '5ce649604398da0004710819',
              ref: 'C',
              value: 'C',
              oldRef: 'work_sick_days_C',
              label: '2 days',
            },
            {
              _id: '5ce649604398da0004710816',
              ref: 'D',
              value: 'D',
              oldRef: 'work_sick_days_D',
              label: '3 days',
            },
            {
              _id: '5ce649604398da0004710813',
              ref: 'E',
              value: 'E',
              oldRef: 'work_sick_days_E',
              label: '4 or more days',
            },
          ],
          validations: [
            {
              _id: '5ce649604398da0004710822',
              languages: [],
              required: true,
            },
          ],
          order: 901,
          title:
            'In the last 3 months, have you taken any sick days from your work/studies due to knee pain and if so, how many?',
        },
        {
          initial: false,
          _id: '60f6c5c4b8c1651e201e585b',
          ref: 'painful_activity_knee',
          category: 'Pain and activity',
          type: 'choice',
          choices: [
            {
              _id: '5ce54086b27e56227222e4e4',
              ref: 'A',
              value: 'A',
              oldRef: 'painful_activity_knee_A',
              label: 'Write an important activity',
              subQuestion: {
                scale: {},
                initial: false,
                _id: '5cdfcdfd0481577babeca71c',
                ref: 'painful_activity_custom',
                category: 'Pain and activity',
                type: 'long_text',
                choices: [],
                validations: [
                  {
                    _id: '5ce4105c8a3dd256391827a2',
                    languages: [],
                    required: true,
                  },
                ],
                order: 52,
                title: 'Important activity',
              },
            },
            {
              _id: '5ce54086b27e56227222e4e1',
              ofType: 'Label',
              label: 'Or choose one from the list below',
            },
            {
              _id: '5ce54086b27e56227222e4de',
              ref: 'B',
              value: 'B',
              oldRef: 'painful_activity_knee_B',
              label: 'Reading',
            },
            {
              _id: '5ce54086b27e56227222e4db',
              ref: 'C',
              value: 'C',
              oldRef: 'painful_activity_knee_C',
              label: 'Driving',
            },
            {
              _id: '5ce55609b27e56227222e673',
              ref: 'D',
              value: 'D',
              oldRef: 'painful_activity_knee_D',
              label: 'Running',
            },
            {
              _id: '5cebcb28f85b400004917655',
              ref: 'E',
              value: 'E',
              oldRef: 'painful_activity_knee_E',
              label: 'Carrying shopping',
            },
            {
              _id: '5cebcb28f85b400004917652',
              ref: 'F',
              value: 'F',
              oldRef: 'painful_activity_knee_F',
              label: 'Sleeping',
            },
            {
              _id: '5cebcb28f85b40000491764f',
              ref: 'G',
              value: 'G',
              oldRef: 'painful_activity_knee_G',
              label: 'Working on a computer',
            },
          ],
          validations: [
            {
              _id: '5ce54086b27e56227222e4da',
              languages: [],
              required: true,
            },
          ],
          order: 50,
          title:
            'Identify one important activity that you are unable to do or have difficulty with because of your knee problem?',
        },
        {
          scale: {
            steps: 11,
            left: 'No\npain',
            center: 'Moderate\npain',
            right: 'Worst\npossible pain',
          },
          initial: false,
          _id: '5cdaaa71938cb47ca0caf019',
          ref: 'painful_activity_rating',
          category: 'Pain and activity',
          type: 'pain_slider',
          choices: [],
          validations: [
            {
              _id: '5cdaaa71938cb47ca0caf01e',
              languages: [],
              required: true,
            },
          ],
          order: 53,
          title: 'Today, how would you rate your knee pain during your chosen activity?',
        },
      ],
      title: 'Pain & activity',
      progressSection: true,
      lastSection: false,
    },
    {
      _id: '60eef74f608cd028dcf6f4ea',
      questions: [
        {
          theme: {
            color: 'white',
            illustration: 'illustration_life',
          },
          initial: false,
          _id: '5cd58b2a0481577babbe256b',
          ref: 'section_intro_everyday_life',
          category: 'section_intro',
          type: 'section_intro',
          choices: [],
          validations: [],
          title: 'Everyday life',
          subtitle:
            'Next, we would like to ask you a few questions about your thoughts and feeling relating to your knee problem. Your answers will help us to provide you with the best line of care.',
          button: 'Continue',
        },
        {
          initial: false,
          _id: '5ce64c3a4398da000471086f',
          ref: 'depressed',
          category: 'Psychosocial aspects ',
          type: 'choice',
          choices: [
            {
              _id: '5ce64c3a4398da000471087b',
              ref: 'E',
              value: 'E',
              oldRef: 'depressed_E',
              label: 'Not at all',
            },
            {
              _id: '5ce64c3a4398da0004710878',
              ref: 'D',
              value: 'D',
              oldRef: 'depressed_D',
              label: 'A little',
            },
            {
              _id: '5ce64c3a4398da0004710875',
              ref: 'C',
              value: 'C',
              oldRef: 'depressed_C',
              label: 'Quite a bit',
            },
            {
              _id: '5ce64c3a4398da0004710872',
              ref: 'B',
              value: 'B',
              oldRef: 'depressed_B',
              label: 'Very much',
            },
          ],
          validations: [
            {
              _id: '5ce64c3a4398da000471087e',
              languages: [],
              required: true,
            },
          ],
          order: 1000,
          title: 'During the past week did you feel depressed?',
        },
        {
          initial: false,
          _id: '60f16dcb7857f4000408cafc',
          category: 'Psychosocial aspects',
          choices: [
            {
              _id: '60f16dcb7857f4000408cafd',
              ref: '1',
              value: '1',
              oldRef: 'exercise_pain_relieved_1',
              label: 'Strongly disagree',
            },
            {
              _id: '60f16dcb7857f4000408caff',
              ref: '2',
              value: '2',
              oldRef: 'exercise_pain_relieved_2',
              label: 'Disagree',
            },
            {
              _id: '60f16dcb7857f4000408cb01',
              ref: '3',
              value: '3',
              oldRef: 'exercise_pain_relieved_3',
              label: 'Agree',
            },
            {
              _id: '60f16dcb7857f4000408cb03',
              ref: '4',
              value: '4',
              oldRef: 'exercise_pain_relieved_4',
              label: 'Strongly Agree',
            },
          ],
          order: 1009,
          ref: 'exercise_pain_relieved',
          type: 'choice',
          validations: [],
          title: 'My pain would probably be relieved if I were to exercise',
        },
        {
          initial: false,
          _id: '60f16e137857f4000408cb0c',
          category: 'Psychosocial aspects',
          choices: [
            {
              _id: '60f16e137857f4000408cb0d',
              ref: '1',
              value: '1',
              oldRef: 'aggravates_pain_1',
              label: 'Strongly disagree',
            },
            {
              _id: '60f16e137857f4000408cb0f',
              ref: '2',
              value: '2',
              oldRef: 'aggravates_pain_2',
              label: 'Disagree',
            },
            {
              _id: '60f16e137857f4000408cb11',
              ref: '3',
              value: '3',
              oldRef: 'aggravates_pain_3',
              label: 'Agree',
            },
            {
              _id: '60f16e137857f4000408cb13',
              ref: '4',
              value: '4',
              oldRef: 'aggravates_pain_4',
              label: 'Strongly agree',
            },
          ],
          order: 1010,
          ref: 'aggravates_pain',
          type: 'choice',
          validations: [],
          title: 'Just because something aggravates my pain does not mean it is dangerous',
        },
        {
          initial: false,
          _id: '60f16e7f7857f4000408cb1c',
          category: 'Psychosocial aspects',
          choices: [
            {
              _id: '60f16e7f7857f4000408cb1d',
              ref: 'A',
              value: 'A',
              oldRef: 'manage_symptoms_A',
              label: 'Not at all',
            },
            {
              _id: '60f16e7f7857f4000408cb1f',
              ref: 'B',
              value: 'B',
              oldRef: 'manage_symptoms_B',
              label: 'Slightly',
            },
            {
              _id: '60f16e7f7857f4000408cb21',
              ref: 'C',
              value: 'C',
              oldRef: 'manage_symptoms_C',
              label: 'Moderately',
            },
            {
              _id: '60f16e7f7857f4000408cb23',
              ref: 'D',
              value: 'D',
              oldRef: 'manage_symptoms_D',
              label: 'Very',
            },
            {
              _id: '60f16e7f7857f4000408cb25',
              ref: 'E',
              value: 'E',
              oldRef: 'manage_symptoms_E',
              label: 'Extremely',
            },
          ],
          order: 1011,
          ref: 'manage_symptoms',
          type: 'choice',
          validations: [],
          title:
            'How confident have you felt in being able to manage your knee symptoms by yourself in the last 2 weeks? ',
          subtitle: 'e.g. medication, changing lifestyle',
        },
      ],
      title: 'Everyday life',
      progressSection: true,
      lastSection: false,
    },
    {
      _id: '60f53c89b8c1651e201e5851',
      questions: [
        {
          theme: {
            color: 'white',
            illustration: 'illustration_check',
          },
          initial: false,
          _id: '5cd58b750481577babbe26ee',
          ref: 'section_intro_red_flags',
          category: 'section_intro',
          type: 'section_intro',
          choices: [],
          validations: [],
          title: 'Final check',
          subtitle:
            'Lastly, a few questions about your general health and other symptoms, to rule out anything serious.',
          button: 'Continue',
        },
        {
          initial: false,
          _id: '5ce6516b4398da000471091b',
          ref: 'red_flag_high_blood_pressure',
          category: 'Serious pathology screening',
          type: 'choice',
          choices: [
            {
              _id: '5ce651e84398da0004710926',
              ref: 'A',
              value: 'A',
              oldRef: 'red_flag_high_blood_pressure_A',
              label: 'No',
            },
            {
              _id: '5ce651e84398da0004710923',
              ref: 'B',
              value: 'B',
              oldRef: 'red_flag_high_blood_pressure_B',
              label: 'Yes, stable with medication',
            },
            {
              _id: '5ce651e84398da0004710920',
              ref: 'C',
              value: 'C',
              oldRef: 'red_flag_high_blood_pressure_C',
              label: 'Yes, but uncontrolled',
            },
          ],
          validations: [
            {
              _id: '5ce6516b4398da000471091e',
              languages: [],
              required: true,
            },
          ],
          order: 1100,
          title: 'Do you have medically diagnosed high blood pressure?',
        },
        {
          initial: false,
          _id: '5cd58826d7cae34de78d8723',
          ref: 'red_flag_medication',
          category: 'Serious pathology screening',
          type: 'yes_no',
          choices: [],
          validations: [
            {
              _id: '5cd58826d7cae34de78d8726',
              languages: [],
              required: true,
            },
          ],
          subQuestions: [
            {
              question: {
                scale: {},
                initial: false,
                _id: '5cdbccce60a43f8d8a4d051e',
                ref: 'red_flag_medication_details',
                category: 'Serious pathology screening',
                type: 'long_text',
                choices: [],
                validations: [
                  {
                    _id: '5cdbccce60a43f8d8a4d0521',
                    languages: [],
                    required: true,
                  },
                ],
                order: 1102,
                title: 'Please list the medications you are currently taking and what they are for',
                placeholder: 'Be as specific as possible',
              },
              _id: '5ce694c509529e0004e47734',
              showIf: {
                ref: 'red_flag_medication',
                equals: true,
              },
            },
          ],
          order: 1101,
          title: 'Do you take any prescription medication that does not relate to your pain?',
        },
        {
          initial: false,
          _id: '60f53d091851c10004b1de41',
          category: 'Red flags',
          choices: [
            {
              _id: '60f53d4b1851c10004b1de46',
              ref: 'A',
              value: 'A',
              oldRef: 'red_flag_diabetes_A',
              label: 'Diabetes',
            },
            {
              _id: '60f53d4b1851c10004b1de48',
              ref: 'B',
              value: 'B',
              oldRef: 'red_flag_diabetes_B',
              label: 'Vascular disease (conditions affecting your blood vessels)',
            },
            {
              _id: '60f53d4b1851c10004b1de4a',
              ref: 'C',
              ofType: 'None',
              value: 'C',
              oldRef: 'red_flag_diabetes_C',
              label: 'None of the above',
            },
          ],
          order: 1120,
          ref: 'red_flag_diabetes',
          type: 'multiple_choice',
          validations: [],
          title: 'Have you been diagnosed with any of the following by your doctor?',
        },
        {
          initial: false,
          _id: '60f53d9e1851c10004b1de54',
          category: 'Red flags',
          choices: [],
          order: 1121,
          ref: 'red_flag_imaging_extended',
          type: 'yes_no',
          subQuestions: [
            {
              question: {
                scale: {},
                initial: false,
                _id: '60f53e191851c10004b1de6b',
                category: 'Red flags',
                choices: [],
                order: 1122,
                ref: 'red_flag_imaging_extended_followup',
                type: 'long_text',
                validations: [],
                title:
                  'Can you explain a bit more about what the scan showed and potentially what diagnosis you were given?',
              },
              _id: '60f5698a1851c10004b1e145',
              showIf: {
                ref: 'red_flag_imaging_extended_followup',
                equals: true,
              },
            },
          ],
          validations: [],
          title: 'Within the last year have you had imaging of your knee?',
          subtitle: 'e.g. MRI scan, X-ray or Ultra sound',
        },
        {
          initial: false,
          _id: '60f6a27fb8c1651e201e585a',
          ref: 'red_flag_surgery_extended',
          category: 'Serious pathology screening',
          type: 'choice',
          choices: [
            {
              _id: '5ce653de4398da000471096f',
              ref: 'A',
              value: 'A',
              oldRef: 'red_flag_surgery_extended_A',
              label: 'No',
            },
            {
              _id: '5ce653de4398da000471096c',
              ref: 'B',
              value: 'B',
              oldRef: 'red_flag_surgery_extended_B',
              label: 'Yes, once',
              subQuestion: {
                scale: {},
                initial: false,
                _id: '60f53f9e1851c10004b1de7e',
                category: 'Red flags',
                choices: [],
                order: 1124,
                ref: 'red_flag_ever_had_surgery_followup',
                type: 'long_text',
                validations: [],
                title: 'Can you explain a bit more about the knee surgery/surgeries that you have had ',
              },
            },
            {
              _id: '5ce653de4398da0004710969',
              ref: 'C',
              value: 'C',
              oldRef: 'red_flag_surgery_extended_C',
              label: 'Yes, several times',
              subQuestion: {
                scale: {},
                initial: false,
                _id: '60f53f9e1851c10004b1de7e',
                category: 'Red flags',
                choices: [],
                order: 1124,
                ref: 'red_flag_ever_had_surgery_followup',
                type: 'long_text',
                validations: [],
                title: 'Can you explain a bit more about the knee surgery/surgeries that you have had ',
              },
            },
          ],
          validations: [
            {
              _id: '5ce653de4398da0004710972',
              languages: [],
              required: true,
            },
          ],
          order: 1106,
          title: 'Have you ever had knee surgery',
        },
        {
          initial: false,
          _id: '60f544011851c10004b1debf',
          category: 'Red flags',
          choices: [
            {
              _id: '60f544011851c10004b1dec0',
              ref: 'A',
              value: 'A',
              oldRef: 'red_flag_multi_knee_A',
              label: 'Unexplained night sweats',
            },
            {
              _id: '60f544011851c10004b1dec2',
              ref: 'B',
              value: 'B',
              oldRef: 'red_flag_multi_knee_B',
              label: 'Night pain',
            },
            {
              _id: '60f544011851c10004b1dec4',
              ref: 'C',
              value: 'C',
              oldRef: 'red_flag_multi_knee_C',
              label: 'Significantly reduced appetite',
            },
            {
              _id: '60f544011851c10004b1dec6',
              ref: 'D',
              value: 'D',
              oldRef: 'red_flag_multi_knee_D',
              label: 'Pronounced unexplained fatigue',
            },
            {
              _id: '60f544011851c10004b1dec8',
              ref: 'E',
              value: 'E',
              oldRef: 'red_flag_multi_knee_E',
              label: 'Unplanned weight loss of more than 5% of your bodyweight within the last 6 months',
            },
            {
              _id: '60f544241851c10004b1ded9',
              ref: 'F',
              value: 'F',
              oldRef: 'red_flag_multi_knee_F',
              label: 'Constant pain not relieved by rest or movement',
            },
            {
              _id: '60f544241851c10004b1dedb',
              ref: 'G',
              ofType: 'None',
              value: 'G',
              oldRef: 'red_flag_multi_knee_G',
              label: 'None of the above',
            },
          ],
          order: 1125,
          ref: 'red_flag_multi_knee',
          type: 'multiple_choice',
          validations: [],
          title: 'Have you recently experienced any of the following:',
        },
        {
          initial: false,
          _id: '5ce653de4398da0004710966',
          ref: 'red_flag_cancer',
          category: 'Serious pathology screening',
          type: 'choice',
          choices: [
            {
              _id: '5ce653de4398da000471096f',
              ref: 'A',
              value: 'A',
              oldRef: 'red_flag_cancer_A',
              label: 'No',
            },
            {
              _id: '5ce653de4398da000471096c',
              ref: 'B',
              value: 'B',
              oldRef: 'red_flag_cancer_B',
              label: 'I have previously had cancer, but not anymore',
            },
            {
              _id: '5ce653de4398da0004710969',
              ref: 'C',
              value: 'C',
              oldRef: 'red_flag_cancer_C',
              label: 'I am currently being treated for cancer',
            },
          ],
          validations: [
            {
              _id: '5ce653de4398da0004710972',
              languages: [],
              required: true,
            },
          ],
          subQuestions: [
            {
              question: {
                scale: {},
                initial: false,
                _id: '5ce654ef4398da000471098a',
                ref: 'red_flag_cancer_details_current',
                category: 'Serious pathology screening',
                type: 'long_text',
                choices: [],
                validations: [
                  {
                    _id: '5ce654ef4398da000471098d',
                    languages: [],
                    required: true,
                  },
                ],
                order: 1108,
                title: 'What type of cancer do you have? And what type of treatment are you receiving?',
              },
              _id: '5cee253556c99d0004c3e9ab',
              showIf: {
                ref: 'red_flag_cancer',
                equals: 'C',
              },
            },
            {
              question: {
                scale: {},
                initial: false,
                _id: '5ce654524398da0004710981',
                ref: 'red_flag_cancer_details_past',
                category: 'Serious pathology screening',
                type: 'long_text',
                choices: [],
                validations: [
                  {
                    _id: '5ce654524398da0004710984',
                    languages: [],
                    required: true,
                  },
                ],
                order: 1107,
                title: 'What type of cancer did you have? And are you still seeing your doctor about it?',
              },
              _id: '5cee253556c99d0004c3e9aa',
              showIf: {
                ref: 'red_flag_cancer',
                equals: 'red_flag_cancer_B',
              },
            },
          ],
          order: 1106,
          title: 'Do you currently have or have you previously had cancer?',
        },
        {
          initial: false,
          _id: '60f55c8cb8c1651e201e5852',
          ref: 'red_flag_radiation_knee',
          category: 'Red flags',
          type: 'multiple_choice',
          choices: [
            {
              _id: '5cebc3b1f85b4000049175c0',
              ref: 'A',
              value: 'A',
              oldRef: 'red_flag_radiation_knee_A',
              label: 'Decreased strength in either leg',
            },
            {
              _id: '5cebc3b1f85b4000049175bd',
              ref: 'B',
              value: 'B',
              oldRef: 'red_flag_radiation_knee_B',
              label: 'Altered sensitivity in either leg, foot or toes',
            },
            {
              _id: '5cebc3b1f85b4000049175ba',
              ref: 'C',
              value: 'C',
              oldRef: 'red_flag_radiation_knee_C',
              label: 'Numbness, tingling or "pins and needles" in either leg, foot or toes',
            },
            {
              _id: '5cf399b2c3980c000441f63a',
              ref: 'D',
              ofType: 'None',
              value: 'D',
              oldRef: 'red_flag_radiation_knee_D',
              label: 'None of the above',
            },
          ],
          validations: [
            {
              _id: '5cebc3b1f85b4000049175c3',
              languages: [],
              required: true,
            },
          ],
          order: 1126,
          title: 'Within the last two months, have you experienced any of the following?',
        },
        {
          initial: false,
          _id: '60f55d24b8c1651e201e5853',
          ref: 'red_flag_ra_knee',
          category: 'Red flags',
          type: 'multiple_choice',
          choices: [
            {
              _id: '5ce657434398da0004710a79',
              ref: 'A',
              value: 'A',
              oldRef: 'red_flag_ra_knee_A',
              label: 'I am being seen by a rheumatologist',
            },
            {
              _id: '5ce657434398da0004710a76',
              ref: 'B',
              value: 'B',
              oldRef: 'red_flag_ra_knee_B',
              label: 'I have recently had joint pain in more than one area that started without trauma or injury',
            },
            {
              _id: '5ce657434398da0004710a73',
              ref: 'C',
              value: 'C',
              oldRef: 'red_flag_ra_knee_C',
              label: 'I have joint swelling or warmth that started without trauma or injury',
            },
            {
              _id: '5cf399e9c3980c000441f6d7',
              ref: 'D',
              ofType: 'None',
              value: 'D',
              oldRef: 'red_flag_ra_knee_D',
              label: 'None of the above',
            },
          ],
          validations: [
            {
              _id: '5ce657434398da0004710a7c',
              languages: [],
              required: true,
            },
          ],
          order: 1127,
          title: 'Do any of the following statements apply to you?',
        },
        {
          initial: false,
          _id: '5ce67d964398da0004710b8c',
          ref: 'red_flag_osteoporosis',
          category: 'Serious pathology screening',
          type: 'multiple_choice',
          choices: [
            {
              _id: '5ce67d964398da0004710b9b',
              ref: 'F',
              value: 'F',
              oldRef: 'red_flag_osteoporosis_F',
              label: 'I have been diagnosed with osteoarthritis in my {painArea} (confirmed by X-ray)',
            },
            {
              _id: '5ce67d964398da0004710b98',
              ref: 'A',
              value: 'A',
              oldRef: 'red_flag_osteoporosis_A',
              label: 'I have been diagnosed with osteoporosis (brittle bones)',
            },
            {
              _id: '5ce67d964398da0004710b95',
              ref: 'B',
              value: 'B',
              oldRef: 'red_flag_osteoporosis_B',
              label: 'I have had bone breaks/fractures within the last two years',
            },
            {
              _id: '5ce67d964398da0004710b92',
              ref: 'C',
              value: 'C',
              oldRef: 'red_flag_osteoporosis_C',
              label: 'I have a family history of osteoporosis (brittle bones)',
            },
            {
              _id: '5ce67e964398da0004710bc8',
              ref: 'D',
              ofType: '',
              value: 'D',
              oldRef: 'red_flag_osteoporosis_D',
              label: 'I have taken oral steroids/corticosteroids regularly within the last six months',
            },
            {
              _id: '5cf399dcc3980c000441f6a7',
              ref: 'E',
              ofType: 'None',
              value: 'E',
              oldRef: 'red_flag_osteoporosis_E',
              label: 'None of the above',
            },
          ],
          validations: [
            {
              _id: '5ce67d964398da0004710b9e',
              languages: [],
              required: true,
            },
          ],
          order: 1119,
          title: 'Do any of the following statements apply to you?',
        },
        {
          initial: false,
          _id: '60f55fb61851c10004b1e003',
          category: 'Red flags',
          choices: [
            {
              _id: '60f55fb61851c10004b1e004',
              ref: 'A',
              value: 'A',
              oldRef: 'red_flag_blood_clot_A',
              label: 'I have a previous history of blood clots (deep vein thrombosis)',
            },
            {
              _id: '60f55fb61851c10004b1e008',
              ref: 'B',
              value: 'B',
              oldRef: 'red_flag_blood_clot_B',
              label: 'I have a family history of blood clots (deep vein thrombosis)',
            },
            {
              _id: '60f55fb61851c10004b1e006',
              ref: 'C',
              ofType: 'None',
              value: 'C',
              oldRef: 'red_flag_blood_clot_C',
              label: 'None of the above',
            },
          ],
          order: 1128,
          ref: 'red_flag_blood_clot',
          type: 'multiple_choice',
          validations: [],
          title: 'Do any of the following statements apply to you?',
        },
      ],
      title: 'Final Check',
      progressSection: true,
      lastSection: false,
    },
    {
      _id: '60fed7e396ba4d2310fddf01',
      questions: [
        {
          theme: {
            color: 'white',
            illustration: 'illustration_check',
          },
          initial: false,
          _id: '60fed87e96ba4d2310fddf02',
          ref: 'section_intro_exercise_details',
          category: 'section_intro',
          type: 'section_intro',
          choices: [],
          validations: [],
          title: 'Final check',
          subtitle: 'Finally, a few questions to help your physio find the right exercises for you',
          button: 'Continue',
        },
        {
          initial: false,
          _id: '60f560ac1851c10004b1e015',
          category: 'Exercise Details',
          choices: [
            {
              _id: '60f560ac1851c10004b1e016',
              ref: 'A',
              value: 'A',
              oldRef: 'exercise_location_A',
              label: 'At home',
            },
            {
              _id: '60f560ac1851c10004b1e018',
              ref: 'B',
              value: 'B',
              oldRef: 'exercise_location_B',
              label: 'I have a gym membership and would like to perform my exercises there',
            },
          ],
          order: 1150,
          ref: 'exercise_location',
          type: 'choice',
          validations: [],
          title: 'Where would you like to perform your knee exercises? ',
        },
        {
          initial: false,
          _id: '60f562291851c10004b1e0c7',
          category: 'Exercise Details',
          choices: [
            {
              _id: '60f562291851c10004b1e0c8',
              ref: 'A',
              value: 'A',
              oldRef: 'exercise_equipment_A',
              label: 'Exercise/yoga mat',
            },
            {
              _id: '60f562291851c10004b1e0ca',
              ref: 'B',
              value: 'B',
              oldRef: 'exercise_equipment_B',
              label: 'Long resistance/exercise band',
            },
            {
              _id: '60f562291851c10004b1e0cc',
              ref: 'C',
              value: 'C',
              oldRef: 'exercise_equipment_C',
              label: 'Mini band',
            },
            {
              _id: '60f562291851c10004b1e0ce',
              ref: 'D',
              value: 'D',
              oldRef: 'exercise_equipment_D',
              label: 'Dumbbell',
            },
            {
              _id: '60f562291851c10004b1e0d0',
              ref: 'E',
              value: 'E',
              oldRef: 'exercise_equipment_E',
              label: 'Kettlebell',
            },
            {
              _id: '60f562781851c10004b1e0ec',
              ref: 'F',
              value: 'F',
              oldRef: 'exercise_equipment_F',
              label: 'Barbell',
            },
            {
              _id: '60f562781851c10004b1e0ee',
              ref: 'G',
              value: 'G',
              oldRef: 'exercise_equipment_G',
              label: 'Foam roller',
            },
            {
              _id: '60f562781851c10004b1e0f0',
              ref: 'H',
              value: 'H',
              oldRef: 'exercise_equipment_H',
              label: 'Yoga block',
            },
            {
              _id: '60f562781851c10004b1e0f2',
              ref: 'I',
              ofType: 'None',
              value: 'I',
              oldRef: 'exercise_equipment_I',
              label: 'None of the above',
            },
          ],
          order: 1151,
          ref: 'exercise_equipment',
          type: 'multiple_choice',
          validations: [],
          title: 'Which of the following pieces of equipment do you have access to? ',
        },
        {
          initial: false,
          _id: '60f562f41851c10004b1e0f9',
          category: 'Exercise Details',
          choices: [
            {
              _id: '60f562f41851c10004b1e0fa',
              ref: 'A',
              value: 'A',
              oldRef: 'support_equipment_A',
              label: 'Crutches',
            },
            {
              _id: '60f562f41851c10004b1e0fc',
              ref: 'B',
              value: 'B',
              oldRef: 'support_equipment_B',
              label: 'Zimmer frame',
            },
            {
              _id: '60f562f41851c10004b1e0fe',
              ref: 'C',
              value: 'C',
              oldRef: 'support_equipment_C',
              label: 'A brace',
            },
            {
              _id: '60f562f41851c10004b1e100',
              ref: 'D',
              value: 'D',
              oldRef: 'support_equipment_D',
              label: 'Knee support',
            },
            {
              _id: '60f562f41851c10004b1e102',
              ref: 'E',
              value: 'E',
              oldRef: 'support_equipment_E',
              label: 'Tape or wraps',
            },
            {
              _id: '60f564d21851c10004b1e113',
              ref: 'F',
              ofType: 'None',
              value: 'F',
              oldRef: 'support_equipment_F',
              label: 'None of the above',
            },
          ],
          order: 1152,
          ref: 'support_equipment',
          type: 'multiple_choice',
          validations: [],
          title: 'Are you using any equipment or devices to support your knee or mobility?',
        },
        {
          initial: false,
          _id: '5ce65a4b4398da0004710a92',
          ref: 'anything_else',
          category: 'Anything else',
          type: 'long_text',
          choices: [],
          validations: [
            {
              _id: '5ce65a4b4398da0004710a95',
              languages: [],
              required: false,
            },
          ],
          order: 1200,
          lastQuestion: true,
          title: 'Is there anything else you want to tell us about your knee problem?',
          placeholder: 'Tap to type',
        },
      ],
      title: 'Final Check',
      progressSection: true,
      lastSection: true,
    },
  ],
  questionConditions: [],
};
