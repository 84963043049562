import React from "react";
import { IconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const LockIcon: React.FC<IconProps> = ({
  color = theme.palette.PRIMARY,
  size = theme.spacing(2.375),
}) => (
  <svg width={size * 0.7368421052631579} height={size} viewBox="0 0 14 19">
    <path
      d="M2.882 7.412V4.118a4.118 4.118 0 1 1 8.236 0v3.294H12a2 2 0 0 1 2 2v7.53a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-7.53a2 2 0 0 1 2-2h.882zm1.647
      0h4.942V4.118a2.47 2.47 0 1 0-4.942 0v3.294zM7 16.47a1.647 1.647 0 1 0 0-3.295 1.647 1.647 0 0 0 0 3.295z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export default LockIcon;
