import { theme } from "shared-utilities";
import styled from "styled-components";

const Icon = styled.svg<{ focused?: boolean }>`
  transition: 0.3s ease;
  transform: ${(props) => (props.focused ? "scale(1.2)" : "scale(1)")};

  .accent {
    fill: ${(props) => (props.focused ? theme.palette.ACCENT_PRIMARY : "#fff")};
  }
`;

const TodaySelectedIcon: React.FC<any> = ({
  color = theme.palette.PRIMARY,
  size = theme.spacing(2.5),
  focused = false,
  ...props
}) => {
  return (
    <Icon
      focused={focused}
      width={size}
      height={size}
      viewBox="0 0 39 20"
      {...props}
    >
      <g
        transform="translate(-19 -12) translate(19 13)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <path d="M3.94 3.844S.962 5.735 1.611 12.009c0 0-.409 1.476-.8 2.261-.393.787-.864 2.632 1.366 3.056 2.23.424 23.396.555 23.396.555s4.109-.115 5.983-.441c1.875-.327 3.48-.555 4.508-1.167s1.614-.598 1.597-2.136c-.016-1.538-.648-1.872-1.757-2.323-1.109-.45-6.079-2.217-6.079-2.217l-2.486-.85L16.765.49s-.744-.124-1.344.445c-.6.57-1.087.876-.261 2.01l-2.336 1.8s-2.177 1.804-3.663 1.504c-1.485-.3-2.915-.759-5.221-2.404" />
        <path
          d="M3.94 3.844S.962 5.735 1.611 12.009c0 0-.409 1.476-.8 2.261-.393.787-.864 2.632 1.366 3.056 2.23.424 23.396.555 23.396.555s4.109-.115 5.983-.441c1.875-.327 3.48-.555 4.508-1.167s1.614-.598 1.597-2.136c-.016-1.538-.648-1.872-1.757-2.323-1.109-.45-6.079-2.217-6.079-2.217l-2.486-.85L16.765.49s-.744-.124-1.344.445c-.6.57-1.087.876-.261 2.01l-2.336 1.8s-2.177 1.804-3.663 1.504c-1.485-.3-2.915-.759-5.221-2.404z"
          stroke={color}
        />
        <path d="M15.182 13.71c-4.674-.959-13.57-1.7-13.57-1.7s-.408 1.475-.802 2.262c-.392.785-.863 2.631 1.368 3.055 2.233.424 23.419.555 23.419.555s4.112-.115 5.989-.442c1.876-.326 3.483-.554 4.511-1.165 1.029-.612 1.616-.599 1.6-2.137 0 0-4.897 1.635-9.056 1.483-4.161-.152-8.785-.951-13.459-1.91" />
        <path
          d="M15.182 13.71c-4.674-.959-13.57-1.7-13.57-1.7s-.408 1.475-.802 2.262c-.392.785-.863 2.631 1.368 3.055 2.233.424 23.419.555 23.419.555s4.112-.115 5.989-.442c1.876-.326 3.483-.554 4.511-1.165 1.029-.612 1.616-.599 1.6-2.137 0 0-4.897 1.635-9.056 1.483-4.161-.152-8.785-.951-13.459-1.91z"
          stroke={color}
          strokeLinejoin="round"
          className="accent"
        />
        <path d="M16.765.489s-.744-.124-1.344.445c-.6.569-1.087.876-.26 2.01l-2.337 1.8S10.647 6.55 9.162 6.248c-1.486-.3-2.915-.76-5.222-2.405 0 0-.701.448-1.348 1.623 0 0 .425 1.273 2.576 2.756 2.151 1.482 5.11 1.34 7.992-.34 2.882-1.683 6.917-4.807 6.917-4.807L16.765.49z" />
        <path
          d="M16.765.489s-.744-.124-1.344.445c-.6.569-1.087.876-.26 2.01l-2.337 1.8S10.647 6.55 9.162 6.248c-1.486-.3-2.915-.76-5.222-2.405 0 0-.701.448-1.348 1.623 0 0 .425 1.273 2.576 2.756 2.151 1.482 5.11 1.34 7.992-.34 2.882-1.683 6.917-4.807 6.917-4.807L16.765.49z"
          stroke={color}
          className="accent"
        />
        <path
          d="M24.445 15.284c-1.128-2.595-4.512-3.333-12.822-4.664-8.309-1.33-9.031-5.154-9.031-5.154"
          stroke={color}
        />
        <path d="M9.854 15.973l-4.418-.105a.91.91 0 01-.89-.928.91.91 0 01.933-.885l4.418.104a.91.91 0 01.89.929.91.91 0 01-.933.885" />
        <path d="M18.954 11.976s1.267-3.24 8.384-3.229" stroke={color} />
        <path d="M33.124 15.255c.684-.136 4.573-1.117 4.573-1.117s-.06-.413-.253-1.184c-.194-.772-2.126-1.367-2.126-1.367s-3.456.827-8.069.9c-4.614.072-4.935-2.914-4.935-2.914s-1.889-.006-3.243 2.437c0 0 4.59.865 5.136 3.245 0 0 4.894.802 8.917 0" />
        <path
          d="M33.124 15.255c.684-.136 4.573-1.117 4.573-1.117s-.06-.413-.253-1.184c-.194-.772-2.126-1.367-2.126-1.367s-3.456.827-8.069.9c-4.614.072-4.935-2.914-4.935-2.914s-1.889-.006-3.243 2.437c0 0 4.59.865 5.136 3.245 0 0 4.894.802 8.917 0z"
          stroke={color}
          fill="#FFF"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          d="M20.4751953 5.2951785L20.8728698 5.62213361"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          d="M22.2357442 6.68992327L22.6334186 7.01687837"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.30232558 14.9414966L9.72093023 14.9414966"
        />
      </g>
    </Icon>
  );
};

export default TodaySelectedIcon;
