import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ProgressShoe from "shared-assets/src/svg/icons/ProgressShoe";
import { getProgress, useAppSelector } from "shared-redux";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import "react-circular-progressbar/dist/styles.css";

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const Bar = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 33.5px;
  height: 3px;
  background-color: ${theme.palette.LIGHTEST_GREY};
`;

const BarComplete = styled.div`
  position: absolute;
  left: 0px;
  top: 33.5px;
  height: 3px;
  background-color: ${theme.palette.PINK};
`;

const PhaseSpacer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const AbsMax = styled.div`
  position: absolute;
  display: flex;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  justify-content: center;
  align-items: center;
`;

interface JourneyProps {
  onShowPhase: () => void;
}

const Journey: FC<JourneyProps> = ({ onShowPhase }: JourneyProps) => {
  // Store
  const progress = useAppSelector(getProgress);

  // State
  const [barWidth, setBarWidth] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current) setBarWidth(containerRef.current?.clientWidth);
  }, [containerRef.current]);

  // Computed Variables
  const currentPhase = progress?.currentPhase || 1;
  const completedBarWidth =
    barWidth * (currentPhase - 1) * 0.3 - (currentPhase > 3 ? 0.1 : 0);

  // Renderers
  const renderPhaseActiveCircle = () => {
    const phase = progress?.currentPhase || 1;
    const phaseObject = progress?.phases[phase - 1];
    const completedWorkouts = phaseObject?.completedWorkouts || 0;
    const totalWorkouts = phaseObject?.totalWorkouts || 5;
    const percentageDone = (completedWorkouts / totalWorkouts) * 100;

    return (
      <div onClick={onShowPhase} style={{ display: "flex" }}>
        <div
          style={{
            position: "relative",
            backgroundColor: theme.palette.WHITE,
            width: 70,
            height: 70,
          }}
        >
          <CircularProgressbar
            strokeWidth={4}
            value={percentageDone}
            styles={{
              path: {
                stroke: theme.palette.PINK,
                transform: "rotate(0.75turn)",
                transformOrigin: "center center",
              },
              trail: {
                stroke: theme.palette.LIGHTEST_GREY,
              },
            }}
          />
          <AbsMax>
            <ProgressShoe />
          </AbsMax>
        </div>
      </div>
    );
  };

  const renderCompletedCircle = () => (
    <div
      style={{
        position: "relative",
        display: "flex",
        backgroundColor: theme.palette.WHITE,
        width: 35,
        height: 35,
      }}
    >
      <CircularProgressbar
        strokeWidth={4}
        value={100}
        styles={buildStyles({
          pathColor: theme.palette.PINK,
          trailColor: theme.palette.LIGHTEST_GREY,
        })}
      />
    </div>
  );

  const renderFutureCircle = () => (
    <div
      style={{
        position: "relative",
        display: "flex",
        backgroundColor: theme.palette.WHITE,
        width: 35,
        height: 35,
      }}
    >
      <CircularProgressbar
        strokeWidth={4}
        value={0}
        styles={buildStyles({
          pathColor: theme.palette.PINK,
          trailColor: theme.palette.LIGHTEST_GREY,
        })}
      />
    </div>
  );

  const renderPhaseText = (key: string | number, isLast: boolean) => {
    const phase = progress?.currentPhase || 1;
    const phaseObject = progress?.phases[phase - 1];
    const completedWorkouts = phaseObject?.completedWorkouts || 0;
    const totalWorkouts = phaseObject?.totalWorkouts || 8;

    let alignItems = "center" as CSSProperties["alignItems"];
    if (key === 0) alignItems = "flex-start";
    if (isLast) alignItems = "flex-end";

    return (
      <div style={{ display: "flex", flex: 1 }} key={key}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            alignItems,
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: theme.typography.FONT_NAME,
              fontWeight: 500,
              fontSize: "18px",
              marginBlock: "4px",
              letterSpacing: "-0.2px",
              color: theme.palette.PRIMARY,
              textAlign: "center",
            }}
          >
            {t("progress_phase", { phase })}
          </p>
          {completedWorkouts > 0 && (
            <p
              style={{
                fontFamily: theme.typography.FONT_NAME,
                fontSize: "18px",
                marginBlock: "4px",
                color: theme.palette.PRIMARY,
              }}
            >
              {t("progress_phase_session", {
                completedWorkouts,
                totalWorkouts,
              })}
            </p>
          )}
        </div>
      </div>
    );
  };

  const renderPhaseInactiveCircle = (phase: number, currentPhase: number) => {
    if (phase < currentPhase) return renderCompletedCircle();
    return renderFutureCircle();
  };
  const renderPhase = (phase: number, current: number) =>
    currentPhase === phase
      ? renderPhaseActiveCircle()
      : renderPhaseInactiveCircle(phase, current);
  const renderPhaseTextMap = (_: undefined, i: number, arr: any[]) =>
    currentPhase === i + 1 ? (
      renderPhaseText(i, i === arr.length - 1)
    ) : (
      <div key={i} style={{ display: "flex", flex: 1 }} />
    );

  return (
    <div>
      <Container ref={containerRef}>
        <Bar />
        <BarComplete style={{ width: completedBarWidth }} />
        {renderPhase(1, currentPhase)}
        <PhaseSpacer>
          {renderPhase(2, currentPhase)}
          {renderPhase(3, currentPhase)}
        </PhaseSpacer>
        {renderPhase(4, currentPhase)}
      </Container>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {[...new Array(4)].map(renderPhaseTextMap)}
      </div>
    </div>
  );
};

export default Journey;
