import { createApi } from '@reduxjs/toolkit/query/react';

import { SuccessResponse, CheckinSummary, Progress } from 'shared-interfaces';
import { createBaseQuery } from '../baseQuery';

export interface FetchProgressResponse {
  progress: Progress;
  checkins: CheckinSummary;
}

export const progressApi = createApi({
  reducerPath: 'progressApi',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    progressFetch: builder.query<SuccessResponse<FetchProgressResponse>, void>({
      query: () => ({ url: 'user-progress' }),
    }),
  }),
});

export const { useProgressFetchQuery } = progressApi;
