import React, { useEffect, useState } from "react";

export enum IconKind {
  CROSS = "cross",
  BACK = "back",
  INFO = "info",
}

export const Icon = ({ kind }: { kind: IconKind }) => {
  const [element, setElement] = useState<JSX.Element | null>(null);

  useEffect(() => {
    import(
      `shared-assets/src/themes/${process.env.REACT_APP_THEME}/icons/${kind}.svg`
    ).then((res) => {
      setElement(
        <img src={res.default} width="16px" height="16px" alt={kind} />
      );
    });
  }, [kind]);

  return element;
};
