import * as React from "react";
import { IconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const BackButtonIcon: React.FC<IconProps> = ({
  size = theme.spacing(2),
  color = theme.palette.PRIMARY,
}) => (
  <svg width={size} height={size} viewBox="0 0 11 16" fill="none">
    <path
      d="M0 8.05l.002-.13.01-.081a1.037 1.037 0 01.272-.557l-.073.087.038-.047.038-.043.091-.087L8.518.273a1.167 1.167 0 011.596.09c.388.416.377 1.04-.006 1.443l-.088.083L2.83 8l7.19 6.11c.436.371.502.991.171 1.435l-.077.092a1.168 1.168 0 01-1.5.163l-.096-.073L.375 8.806.287 8.72.23 8.655.189 8.6a1.048 1.048 0 01-.159-.346l-.022-.118L0 8.05z"
      fill={color}
    />
  </svg>
);

export default BackButtonIcon;
