import * as React from "react";
import { IconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const CloseButtonIcon: React.FC<IconProps> = ({
  size = theme.spacing(2),
  color = theme.palette.PRIMARY,
}) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      d="M1.6 1.6l12.8 12.8M1.6 14.4L14.4 1.6"
      stroke={color}
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
  </svg>
);

export default CloseButtonIcon;
