import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const TowelIcon: React.FC<SvgProps & IconProps> = ({
  size = theme.spacing(2.25),
  color = theme.palette.PRIMARY,
  ...props
}) => (
  <Svg width={size * 1.6111111111111112} height={size} viewBox="0 0 29 18" {...props}>
    <G stroke={color} strokeWidth={1.7} fill="none" fillRule="evenodd" strokeLinecap="round">
      <Path d="M24.13 6.323H5.142M24.429 13.259H5.144M5.144 13.259a3.468 3.468 0 1 1 0-6.936M25.374 16.727H5.722M5.302 9.791h19.405M24.707 9.791a3.468 3.468 0 0 1 0 6.936M5.144 16.727a1.734 1.734 0 1 1 0-3.468M24.218 6.323a1.734 1.734 0 1 1 0 3.468" />
      <Path
        d="M25.175 6.227L22.44 2.245a2.348 2.348 0 0 0-1.935-1.018H8.367a2.35 2.35 0 0 0-1.896.962L2.74 7.294"
        strokeLinejoin="round"
      />
    </G>
  </Svg>
);

export default TowelIcon;
