import React from "react";
import styled from "styled-components/macro";

const Root = styled.div`
  margin-top: 4px;
  max-width: 80%;
`;

const MessageWrapper: React.FC<React.PropsWithChildren> = React.memo(
  ({ children }) => <Root>{children}</Root>
);

export default MessageWrapper;
