import { createApi } from '@reduxjs/toolkit/query/react';

import {
  IUser,
  IUserDevice,
  SuccessResponse,
  WorkoutSchedule,
  UserDeviceNotificationSetting,
} from 'shared-interfaces';
import { createBaseQuery } from '../baseQuery';
import { NewUser, CreateMedicalProfileRequestBody } from './assessmentApi';
import { AuthLoginResponse } from './authApi';

export interface UpdateUserRequestBody extends CreateMedicalProfileRequestBody {
  email: string;
}

export interface UpdateNotificationParams {
  id: string;
  notificationSetting: UserDeviceNotificationSetting;
}

export interface TimezoneUpdateBody {
  to: string;
  offset: number;
}

export interface TimezoneUpdateResponse {
  user: IUser;
  schedule: WorkoutSchedule;
}

export type EnablePushBody = Required<Pick<IUserDevice, 'pushToken' | 'deviceId'>>;

export interface UserResponse {
  user: IUser;
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    userCreate: builder.mutation<SuccessResponse<AuthLoginResponse>, NewUser>({
      query: (body) => ({
        url: 'user',
        method: 'POST',
        body,
      }),
    }),
    userRegisterDevice: builder.mutation<
      SuccessResponse<UserResponse>,
      Omit<IUserDevice, 'userId' | 'notificationSetting'>
    >({
      query: (body) => ({
        url: 'user/device',
        method: 'PUT',
        body,
      }),
    }),
    userUnregisterDevice: builder.mutation<SuccessResponse<UserResponse>, string>({
      query: (id) => ({
        url: `user/device/${id}`,
        method: 'DELETE',
      }),
    }),
    userUpdateNotificationSetting: builder.mutation<
      SuccessResponse<UserResponse>,
      UpdateNotificationParams
    >({
      query: ({ id, notificationSetting }) => ({
        url: `user/device/${id}`,
        method: 'PATCH',
        body: { notificationSetting },
      }),
    }),
    userEnablePush: builder.mutation<SuccessResponse<UserResponse>, EnablePushBody>({
      query: (body) => ({
        url: 'user/enable-push',
        method: 'PATCH',
        body,
      }),
    }),
    userDisablePush: builder.mutation<SuccessResponse<UserResponse>, string>({
      query: (id) => ({
        url: `user/disable-push/${id}`,
        method: 'PATCH',
      }),
    }),
    userUpdate: builder.mutation<SuccessResponse<UserResponse>, UpdateUserRequestBody>({
      query: (body) => ({
        url: 'user',
        method: 'PUT',
        body,
      }),
    }),
    userModify: builder.mutation<SuccessResponse<UserResponse>, Partial<IUser>>({
      query: (body) => ({
        url: 'user',
        method: 'PATCH',
        body,
      }),
    }),
    userTimezoneUpdate: builder.mutation<
      SuccessResponse<TimezoneUpdateResponse>,
      TimezoneUpdateBody
    >({
      query: (body) => ({
        url: 'user/update-timezone',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useUserCreateMutation,
  useUserRegisterDeviceMutation,
  useUserUnregisterDeviceMutation,
  useUserUpdateMutation,
  useUserEnablePushMutation,
  useUserDisablePushMutation,
  useUserTimezoneUpdateMutation,
  useUserUpdateNotificationSettingMutation,
  useUserModifyMutation,
} = userApi;
