import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CheckinSummary, Progress } from 'shared-interfaces';
import { checkinApi, progressApi, workoutApi } from '../api';
import { RootState } from '../store';
import { logout } from './auth';

// State Interface
export interface ProgressPlanState {
  progress: Progress | null;
  checkins: CheckinSummary | null;
}

// Initial State
const initialState: ProgressPlanState = {
  progress: null,
  checkins: null,
};

// Slice
export const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    // logout: (state) => {
    //   state.token = null;
    // },
  },
  extraReducers: (builder) => {
    const { progressFetch } = progressApi.endpoints;
    const { workoutSubmit, workoutFeedbackSubmit } = workoutApi.endpoints;
    const { checkinSubmit } = checkinApi.endpoints;
    builder.addCase(logout, (state) => {
      state.progress = null;
    });
    builder.addMatcher(
      isAnyOf(
        progressFetch.matchFulfilled,
        checkinSubmit.matchFulfilled,
        workoutSubmit.matchFulfilled,
        workoutFeedbackSubmit.matchFulfilled
      ),
      (state, action) => {
        state.progress = action.payload.data.progress;
        state.checkins = action.payload.data.checkins;
      }
    );
  },
});

// Actions
// export const { logout } = planSlice.actions;

// Selectors
export const getProgress = (state: RootState): Progress | null => state.progress.progress;
export const getCheckinSummary = (state: RootState): CheckinSummary | null =>
  state.progress.checkins;

// Reducer
export default progressSlice.reducer;
