import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const MuteIcon: React.FC<IconProps> = ({ color = theme.palette.PRIMARY, size = theme.spacing(2.25) }) => (
  <Svg width={size} height={size} viewBox="0 0 18 18">
    <G fill="none" fillRule="evenodd">
      <Path d="M0 0h18v18H0z" />
      <Path stroke={color} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" d="M4.13 12.99h-3V5h3" />
      <Path stroke={color} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" d="M4 5l7-4v16l-7-4" />
      <Path
        d="M15.093 12.172C16.208 11.688 17 10.457 17 9.014c0-1.482-.837-2.74-2-3.195M13.201 10.5c.527-.228.9-.81.9-1.49 0-.7-.394-1.295-.944-1.51"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  </Svg>
);

export default MuteIcon;
