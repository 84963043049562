import React from "react";
import { useNavigate } from "react-router-dom";
import { LearnArticle } from "shared-interfaces";
import { t, theme } from "shared-utilities";
import styled, { css } from "styled-components/macro";
import Paper from "../patterns/Paper";

interface ArticleCellProps {
  article: LearnArticle;
  newest: boolean;
}
const learnCellContainer = css`
  border-radius: 5px;
  align-self: stretch;
  padding: 0;
  margin: 15px 0 5px;
  &:hover {
    opacity: 0.8;
  }
`;
const ExpandedCellOuterContainer = styled.div`
  overflow: hidden;
  border-radius: 5px;
`;
const ArticleFullImage = styled.img`
  width: 100%;
  border-radius: 5px 5px 0 0;
`;
const TitleText = styled.div`
  flex: 1;
  color: ${theme.palette.PRIMARY};
  margin-bottom: 5px;
`;
const ReadTimeText = styled.div`
  margin-top: 3px;
  color: ${theme.palette.PRIMARY};
`;
const SnippetText = styled.div`
  color: ${theme.palette.PRIMARY};
`;

export const ArticleCell: React.FC<ArticleCellProps> = ({
  article,
  newest,
}) => {
  // Navigation
  const navigate = useNavigate();
  // Store
  // const badgeLearnSeen = useSelector((state: any) => state.badgeLearnSeen);

  // Methods
  const handleShowArticle = () => {
    navigate(`/learn/article?id=${article.id}`);
  };

  // Renderers
  const renderExpandedCell = (article: LearnArticle): React.ReactElement => {
    const { title, snippet, fullImage, readTime } = article.article;

    return (
      <Paper style={learnCellContainer} onClick={handleShowArticle}>
        <ExpandedCellOuterContainer>
          <ArticleFullImage src={fullImage} alt="article cover" />
          <div style={{ padding: 15 }}>
            <div style={{ flex: 1, flexDirection: "row", display: "flex" }}>
              <TitleText
                css={
                  theme.typography[theme.typography.FONT_NAME]
                    .REGULAR_BOLD_HEADING
                }
              >
                {title}
              </TitleText>
              <ReadTimeText
                css={theme.typography[theme.typography.FONT_NAME].SMALL_TEXT}
              >
                {readTime} {t("learn_read_time")}
              </ReadTimeText>
            </div>
            <SnippetText
              css={theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT}
            >
              {snippet}
            </SnippetText>
          </div>
        </ExpandedCellOuterContainer>
      </Paper>
    );
  };
  return renderExpandedCell(article);
};
