import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { checkinReset, logout, useAppDispatch } from "shared-redux";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import { useLockedBody, useOnClickOutside } from "usehooks-ts";
import { Button } from "../Button";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background: rgba(0, 0, 0, 0.5);
  mix-blend-mode: normal;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalWrapper = styled.div`
  background: #fff;
  max-width: 320px;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalTitle = styled.h4`
  color: ${theme.palette.PRIMARY};
  text-align: center;
`;

const ModalSubtitle = styled.div`
  padding-bottom: ${theme.spacingPx(2)};
  color: ${theme.palette.PRIMARY};
  text-align: center;
`;

const ModalFooter = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: inherit;

  button {
    width: auto;
  }
`;

interface CancelModalProps {
  onClose: () => void;
}

export const CancelModal: React.FC<CancelModalProps> = ({ onClose }) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const dispatchCheckinReset = () => dispatch(checkinReset());

  const handleClose = () => {
    dispatchCheckinReset();
    navigate(-1);
  };

  useLockedBody(true, "root");
  useOnClickOutside(ref, () => onClose());

  return (
    <ModalContainer>
      <ModalWrapper ref={ref}>
        <div>
          <ModalTitle
            css={theme.typography[theme.typography.FONT_NAME].MEDIUM_HEADING}
          >
            {t("assessment_exit_modal_title")}
          </ModalTitle>
          <ModalSubtitle
            css={theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT}
          >
            {t("assessment_exit_modal_text")}
          </ModalSubtitle>
        </div>
        <ModalFooter>
          <Button isBoldText bgColor="#FF5A5A" handleClick={handleClose}>
            {t("assessment_exit_modal_yes")}
          </Button>
          <Button isBoldText bgColor="#145E7F" handleClick={onClose}>
            {t("assessment_exit_modal_cancel")}
          </Button>
        </ModalFooter>
      </ModalWrapper>
    </ModalContainer>
  );
};
