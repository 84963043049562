export type ValueGetter<T = unknown> = (item: T) => string | number;

type SortingOrder = "ascending" | "descending";

export type SortGroup<T = unknown> = {
  key: string | number;
  items: T[];
};

/**
 * Slice that wraps around if the slice exceeds the length of the array
 * @param array
 * @param start
 * @param count
 */
export const circularSlice = <T>(
  array: T[],
  start: number,
  count: number
): T[] => {
  const result = [];
  const end = start + count;
  for (let i = start; i < end; i += 1) {
    // @ts-ignore
    result.push(array[i % array.length]);
  }
  return result;
};

/**
 * Generic sorting predicate
 * @param key
 * @param order
 */
export function sortBy<T>(
  key: ValueGetter<T>,
  order: SortingOrder = "ascending"
) {
  if (order === "ascending") return (a: T, b: T) => (key(a) > key(b) ? 1 : -1);
  return (a: T, b: T) => (key(a) > key(b) ? -1 : 1);
}
