/*
    TODO...
    Remove these hard coded paths and replace with loadImages();
*/
const QUICK_WORKOUT_IMAGE = require("shared-assets/src/themes/wpaTheme/images/quick_workout_tile.jpg");
const QUICK_WORKOUT_COMPLETE_IMAGE = require("shared-assets/src/themes/wpaTheme/images/quick_workout_tile_complete.jpg");
const DAILY_WORKOUT_IMAGE = require("shared-assets/src/themes/wpaTheme/images/daily_workout_tile.jpg");
const DAILY_WORKOUT_COMPLETE_IMAGE = require('shared-assets/src/themes/wpaTheme/images/daily_workout_tile_complete.jpg');
const FIRST_WORKOUT_IMAGE = require('shared-assets/src/themes/wpaTheme/images/first_workout_tile.jpg');
const FIRST_WORKOUT_COMPLETE_IMAGE = require('shared-assets/src/themes/wpaTheme/images/first_workout_tile_complete.jpg');

export const images = {
    fullWorkoutTile: {
        DAILY_WORKOUT_IMAGE,
        DAILY_WORKOUT_COMPLETE_IMAGE,
        FIRST_WORKOUT_IMAGE,
        FIRST_WORKOUT_COMPLETE_IMAGE
    },
    quickWorkoutTile: {
        QUICK_WORKOUT_COMPLETE_IMAGE,
        QUICK_WORKOUT_IMAGE
    },
    workoutIntroScreen: {
        QUICK_WORKOUT_IMAGE,
        DAILY_WORKOUT_IMAGE
    }
}

// const loadImage = async (image) => {
//     const url = await import(
//         `shared-assets/src/themes/${process.env.REACT_APP_THEME}/images/${image}`
//     );
    
//     return url.default;
// }