import { createApi } from '@reduxjs/toolkit/query/react';

import {
  Answer,
  Assessment,
  LanguageKey,
  PainAreaKey,
  UserCountry,
  SuccessResponse,
  IUserMedicalInformation,
} from 'shared-interfaces';
import { createBaseQuery } from '../baseQuery';
import { AuthLoginBody } from './authApi';
import { UserResponse } from './userApi';

export interface NewUser {
  device: ReturnType<any> & { deviceId: string };
  language: LanguageKey;
  timezone: string;
  timezoneOffset: number;
  country: UserCountry;
  email?: string;
  firstName?: string;
  lastName?: string;
  medicalInformation: IUserMedicalInformation;
}

export interface CreateAssessmentRequestBody {
  painArea: PainAreaKey;
}

export interface CreateAssessmentResponse {
  userAssessment: {
    id: number;
    painArea: PainAreaKey;
    pathwayVariantId: number;
  };
  assessment: Assessment;
}

export interface SubmitAssessmentRequestBody {
  id: number;
  answers: Answer[];
}

export interface CreateMedicalProfileRequestBody {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

export const assessmentApi = createApi({
  reducerPath: 'assessmentApi',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    assessmentFetch: builder.query<Assessment, number>({
      query: (id) => ({ url: `/user-full-assessment/${id}` }),
    }),
    assessmentCreate: builder.mutation<
      SuccessResponse<CreateAssessmentResponse>,
      CreateAssessmentRequestBody
    >({
      query: ({ painArea }) => ({
        url: 'user-assessment',
        method: 'POST',
        body: { painArea },
      }),
    }),
    assessmentDestroy: builder.mutation<null, void>({
      query: () => ({
        url: 'user-assessment',
        method: 'DELETE',
      }),
    }),
    assessmentSubmit: builder.mutation<null, SubmitAssessmentRequestBody>({
      query: ({ id, answers }) => ({
        url: `user-assessment/${id}`,
        method: 'POST',
        body: answers,
      }),
    }),
    assessmentCreateMedicalProfile: builder.mutation<
      SuccessResponse<UserResponse>,
      CreateMedicalProfileRequestBody
    >({
      query: (body) => ({
        url: 'user/create-medical-profile',
        method: 'POST',
        body,
      }),
    }),
    assessmentCreateAccountDetails: builder.mutation<SuccessResponse<UserResponse>, AuthLoginBody>({
      query: (body) => ({
        url: 'user/create-login',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useAssessmentFetchQuery,
  useAssessmentCreateMutation,
  useAssessmentDestroyMutation,
  useAssessmentSubmitMutation,
  useAssessmentCreateMedicalProfileMutation,
  useAssessmentCreateAccountDetailsMutation,
} = assessmentApi;
