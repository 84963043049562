import { useNavigate } from "react-router-dom";
import Launch from "shared-assets/src/launch.jpg";
import { theme } from "shared-utilities";
import LogoImage from "src/assets/logo.png";
import { ScreenElement, Button } from "src/components";
import styled from "styled-components/macro";

const Logo = styled.div`
  position: absolute;
  height: 36px;
  width: 139px;
  left: 118px;
  top: 49px;
  background: url(${LogoImage}) center/contain no-repeat;
`;

const Tagline = styled.div`
  position: absolute;
  height: 72px;
  width: 280px;
  left: 47px;
  top: 108px;
  fontFamily: ${theme.typography.FONT_NAME},
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.5px;
  text-align: center;
  color: white;
`;
const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 39px;
  height: 125px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const LaunchScreen = () => {
  const navigate = useNavigate();
  const goToReferralScreen = () => {
    navigate("/referral");
  };

  return (
    <ScreenElement bgImage={Launch}>
      <Logo />
      <Tagline>Personalised exercises & physio support</Tagline>
      <ButtonsContainer>
        <Button handleClick={goToReferralScreen}>Start your recovery</Button>
        <Button isActive={false}>I have an account</Button>
      </ButtonsContainer>
    </ScreenElement>
  );
};
