import { scaledNumberPx } from "../../common";
import { css } from "styled-components";

export const fontStyles = {
    FONT_NAME: 'Inter',
    Inter: {
        HUGE_HEADING: css`
        font-family: "InterMedium", sans-serif;
        font-size: ${scaledNumberPx({ small: 264, normal: 300, large: 400 })};
        line-height: ${scaledNumberPx({ small: 264, normal: 300, large: 400 })};
        letter-spacing: -4px;
        `,
        BIGGEST_HEADING: css`
        font-family: "InterMedium", sans-serif;
        font-size: ${scaledNumberPx({ small: 100, normal: 116, large: 124 })};
        line-height: ${scaledNumberPx({ small: 100, normal: 116, large: 124 })};
        letter-spacing: -2px;
        `,
        BIGGER_HEADING: css`
        font-family: "InterMedium", sans-serif;
        font-size: ${scaledNumberPx({ small: 68, normal: 80, large: 88 })};
        line-height: ${scaledNumberPx({ small: 76, normal: 88, large: 96 })};
        letter-spacing: -2px;
        `,
        BIG_HEADING: css`
        font-family: "InterMedium", sans-serif;
        font-size: ${scaledNumberPx({ small: 44, normal: 52, large: 58 })};
        line-height: ${scaledNumberPx({ small: 52, normal: 60, large: 64 })};
        letter-spacing: -1.2px;
        `,
        LARGEST_HEADING: css`
        font-family: "InterMedium", sans-serif;
        font-size: ${scaledNumberPx({ small: 36, normal: 40, large: 44 })};
        line-height: ${scaledNumberPx({ small: 40, normal: 44, large: 48 })};
        letter-spacing: -0.8px;
        `,
        LARGER_HEADING: css`
        font-family: "InterMedium", sans-serif;
        font-size: ${scaledNumberPx({ small: 28, normal: 32, large: 36 })};
        line-height: ${scaledNumberPx({ small: 36, normal: 40, large: 44 })};
        letter-spacing: -0.4px;
        `,
        LARGE_HEADING: css`
        font-family: "InterMedium", sans-serif;
        font-size: ${scaledNumberPx({ small: 20, normal: 24, large: 28 })};
        line-height: ${scaledNumberPx({ small: 28, normal: 32, large: 36 })};
        letter-spacing: -0.2px;
        `,
        LARGE_BOLD_HEADING: css`
        font-family: "InterSemiBold", sans-serif;
        font-size: ${scaledNumberPx({ small: 20, normal: 24, large: 28 })};
        line-height: ${scaledNumberPx({ small: 28, normal: 32, large: 36 })};
        letter-spacing: -0.2px;
        `,
        MEDIUM_HEADING: css`
        font-family: "InterMedium", sans-serif;
        font-size: ${scaledNumberPx({ small: 16, normal: 20, large: 24 })};
        line-height: ${scaledNumberPx({ small: 20, normal: 26, large: 30 })};
        letter-spacing: -0.2px;
        `,
        MEDIUM_BOLD_HEADING: css`
        font-family: "InterSemiBold", sans-serif;
        font-size: ${scaledNumberPx({ small: 16, normal: 20, large: 24 })};
        line-height: ${scaledNumberPx({ small: 20, normal: 26, large: 30 })};
        letter-spacing: -0.2px;
        `,
        REGULAR_HEADING: css`
        font-family: "InterMedium", sans-serif;
        font-size: ${scaledNumberPx({ normal: 16, large: 18 })};
        line-height: ${scaledNumberPx({ normal: 20, large: 22 })};
        letter-spacing: -0.2px;
        `,
        REGULAR_BOLD_HEADING: css`
        font-family: "InterSemiBold", sans-serif;
        font-size: ${scaledNumberPx({ normal: 16, large: 18 })};
        line-height: ${scaledNumberPx({ normal: 20, large: 22 })};
        letter-spacing: -0.2px;
        `,
        TINY_HEADING: css`
        font-family: "InterMedium", sans-serif;
        font-size: ${scaledNumberPx({ normal: 12, large: 14 })};
        line-height: ${scaledNumberPx({ normal: 16, large: 18 })};
        letter-spacing: -0.1px;
        `,
        TINY_BOLD_HEADING: css`
        font-family: "InterSemiBold", sans-serif;
        font-size: ${scaledNumberPx({ normal: 12, large: 14 })};
        line-height: ${scaledNumberPx({ normal: 16, large: 18 })};
        letter-spacing: -0.2px;
        `,
        REGULAR_TEXT: css`
        font-family: "InterRegular", sans-serif;
        font-size: ${scaledNumberPx({ normal: 16, large: 18 })};
        line-height: ${scaledNumberPx({ normal: 20, large: 22 })};
        `,
        SMALL_TEXT: css`
        font-family: "InterRegular", sans-serif;
        font-size: ${scaledNumberPx({ normal: 14, large: 16 })};
        line-height: ${scaledNumberPx({ normal: 18, large: 20 })};
        `,
        SMALL_BOLD_HEADING: css`
        font-family: "InterRegular", sans-serif;
        font-size: ${scaledNumberPx({ normal: 14, large: 16 })};
        line-height: ${scaledNumberPx({ normal: 18, large: 20 })};
        `,
        TINY_TEXT: css`
        font-family: "InterRegular", sans-serif;
        font-size: ${scaledNumberPx({ normal: 12, large: 14 })};
        line-height: ${scaledNumberPx({ normal: 16, large: 18 })};
        `
    }
}

export const fontStylesObj = {
    FONT_NAME: "Inter",
    Inter: {
        HUGE_HEADING: {
            fontFamily: "InterMedium, sans-serif",
            fontSize: scaledNumberPx({ small: 264, normal: 300, large: 400 }),
            lineHeight: scaledNumberPx({ small: 264, normal: 300, large: 400 }),
            letterSpacing: -4,
        },
        BIGGEST_HEADING: {
            fontFamily: "InterMedium, sans-serif",
            fontSize: scaledNumberPx({ small: 100, normal: 116, large: 124 }),
            lineHeight: scaledNumberPx({ small: 100, normal: 116, large: 124 }),
            letterSpacing: -2,
        },
        BIGGER_HEADING: {
            fontFamily: "InterMedium, sans-serif",
            fontSize: scaledNumberPx({ small: 68, normal: 80, large: 88 }),
            lineHeight: scaledNumberPx({ small: 76, normal: 88, large: 96 }),
            letterSpacing: -2,
        },
        BIG_HEADING: {
            fontFamily: "InterMedium, sans-serif",
            fontSize: scaledNumberPx({ small: 44, normal: 52, large: 58 }),
            lineHeight: scaledNumberPx({ small: 52, normal: 60, large: 64 }),
            letterSpacing: -1.2,
        },
        LARGEST_HEADING: {
            fontFamily: "InterMedium, sans-serif",
            fontSize: scaledNumberPx({ small: 36, normal: 40, large: 44 }),
            lineHeight: scaledNumberPx({ small: 40, normal: 44, large: 48 }),
            letterSpacing: -0.8,
        },
        LARGER_HEADING: {
            fontFamily: "InterMedium, sans-serif",
            fontSize: scaledNumberPx({ small: 28, normal: 32, large: 36 }),
            lineHeight: scaledNumberPx({ small: 36, normal: 40, large: 44 }),
            letterSpacing: -0.4,
        },
        LARGE_HEADING: {
            fontFamily: "InterMedium, sans-serif",
            fontSize: scaledNumberPx({ small: 20, normal: 24, large: 28 }),
            lineHeight: scaledNumberPx({ small: 28, normal: 32, large: 36 }),
            letterSpacing: -0.2,
        },
        LARGE_BOLD_HEADING: {
            fontFamily: "InterSemiBold, sans-serif",
            fontSize: scaledNumberPx({ small: 20, normal: 24, large: 28 }),
            lineHeight: scaledNumberPx({ small: 28, normal: 32, large: 36 }),
            letterSpacing: -0.2,
        },
        MEDIUM_HEADING: {
            fontFamily: "InterMedium, sans-serif",
            fontSize: scaledNumberPx({ small: 16, normal: 20, large: 24 }),
            lineHeight: scaledNumberPx({ small: 20, normal: 26, large: 30 }),
            letterSpacing: -0.2,
        },
        MEDIUM_BOLD_HEADING: {
            fontFamily: "InterSemiBold, sans-serif",
            fontSize: scaledNumberPx({ small: 16, normal: 20, large: 24 }),
            lineHeight: scaledNumberPx({ small: 20, normal: 26, large: 30 }),
            letterSpacing: -0.2,
        },
        REGULAR_HEADING: {
            fontFamily: "InterMedium, sans-serif",
            fontSize: scaledNumberPx({ normal: 16, large: 18 }),
            lineHeight: scaledNumberPx({ normal: 20, large: 22 }),
            letterSpacing: -0.2,
        },
        REGULAR_BOLD_HEADING: {
            fontFamily: "InterSemiBold, sans-serif",
            fontSize: scaledNumberPx({ normal: 16, large: 18 }),
            lineHeight: scaledNumberPx({ normal: 20, large: 22 }),
            letterSpacing: -0.2,
        },
        TINY_HEADING: {
            fontFamily: "InterMedium, sans-serif",
            fontSize: scaledNumberPx({ normal: 12, large: 14 }),
            lineHeight: scaledNumberPx({ normal: 16, large: 18 }),
            letterSpacing: -0.1,
        },
        TINY_BOLD_HEADING: {
            fontFamily: "InterSemiBold, sans-serif",
            fontSize: scaledNumberPx({ normal: 12, large: 14 }),
            lineHeight: scaledNumberPx({ normal: 16, large: 18 }),
            letterSpacing: -0.1,
        },
        REGULAR_TEXT: {
            fontFamily: "InterRegular, sans-serif",
            fontSize: scaledNumberPx({ normal: 16, large: 18 }),
            lineHeight: scaledNumberPx({ normal: 20, large: 22 }),
        },
        SMALL_TEXT: {
            fontFamily: "InterRegular, sans-serif",
            fontSize: scaledNumberPx({ normal: 14, large: 16 }),
            lineHeight: scaledNumberPx({ normal: 18, large: 20 }),
        },
        TINY_TEXT: {
            fontFamily: "InterRegular, sans-serif",
            fontSize: scaledNumberPx({ normal: 12, large: 14 }),
            lineHeight: scaledNumberPx({ normal: 16, large: 18 }),
        }
    }
}