import React from "react";

import {
  WorkoutFeedbackEasyReason,
  WorkoutFeedbackHardReason,
  WorkoutFeedbackPainReason,
  WorkoutFeedbackReason,
} from "shared-interfaces";
import {
  t,
  removeValueFromArray,
  uniqueArray,
  typography,
} from "shared-utilities";
import styled from "styled-components";

import DetailedFeedbackEasyHardRow from "./DetailedFeedbackEasyHardRow";
import DetailedFeedbackPainRow from "./DetailedFeedbackPainRow";

interface DetailedFeedbackReasonsControlProps {
  feedbackIndex: number;
  onChange: (values: WorkoutFeedbackReason[]) => void;
  value: WorkoutFeedbackReason[];
}

const FEEDBACK_EASY_OPTIONS: WorkoutFeedbackEasyReason[] = [
  "could_do_way_more",
  "could_do_few_more",
  "would_like_another_exercise",
];
const FEEDBACK_HARD_OPTIONS: WorkoutFeedbackHardReason[] = [
  "barely_finished",
  "could_not_finish",
  "technique_difficult",
  "would_like_another_exercise",
];
const FEEDBACK_PAIN_OPTIONS: WorkoutFeedbackPainReason[] = [
  "mild",
  "moderate",
  "severe",
];

const EasyHardRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 315px;
  margin: 0 auto;
`;

const EasyHardRowWrapperTitle = styled.p`
  font-family: ${typography.FONT_NAME};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #145e7f;
`;

const DetailedFeedbackReasonsControl: React.FC<
  DetailedFeedbackReasonsControlProps
> = ({ feedbackIndex, onChange, value: values }) => {
  // Methods
  const handleSetPainValue = (value: WorkoutFeedbackPainReason) =>
    onChange([value]);
  const handleSetEasyHardValue = (value: WorkoutFeedbackReason) => {
    onChange(
      values.includes(value)
        ? removeValueFromArray(value, values)
        : uniqueArray([...values, value])
    );
  };

  // Renderers
  const renderPainRow = React.useCallback(
    (option: WorkoutFeedbackPainReason, index: number): React.ReactElement => (
      <DetailedFeedbackPainRow
        value={option}
        // index={index}
        key={option}
        onSelected={handleSetPainValue}
        selected={values.includes(option)}
      />
    ),
    [handleSetPainValue, values]
  );

  const renderEasyHardRow = React.useCallback(
    (
      option: WorkoutFeedbackEasyReason | WorkoutFeedbackHardReason
      // index: number
    ): React.ReactElement => (
      <DetailedFeedbackEasyHardRow
        value={option}
        // index={index}
        key={option}
        onSelected={handleSetEasyHardValue}
        selected={values.includes(option)}
      />
    ),
    [handleSetPainValue, values]
  );

  const renderRows = React.useCallback(() => {
    switch (feedbackIndex) {
      case 1:
        return (
          <EasyHardRowWrapper>
            {FEEDBACK_EASY_OPTIONS.map(renderEasyHardRow)}
          </EasyHardRowWrapper>
        );
      case 2:
        return (
          <EasyHardRowWrapper>
            {FEEDBACK_HARD_OPTIONS.map(renderEasyHardRow)}
          </EasyHardRowWrapper>
        );
      case 3:
        return (
          <EasyHardRowWrapper>
            <EasyHardRowWrapperTitle>
              {t("feedback_painful_subquestion")}
            </EasyHardRowWrapperTitle>
            <div style={{ flexDirection: "row", justifyContent: "center" }}>
              {FEEDBACK_PAIN_OPTIONS.map(renderPainRow)}
            </div>
          </EasyHardRowWrapper>
        );
      default:
        return null;
    }
  }, [renderEasyHardRow, renderPainRow, feedbackIndex]);

  return <div>{renderRows()}</div>;
};

export default DetailedFeedbackReasonsControl;
