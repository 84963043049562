import * as React from "react";
import { DuotoneIconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const PlayButtonIcon: React.FC<DuotoneIconProps> = ({
  size = theme.spacing(2.5),
  color = "#145E7F",
  colorTwo = theme.palette.WHITE,
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <circle cx={10} cy={10} r={10} fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 10l-6 4V6l6 4z"
        fill={colorTwo}
      />
    </svg>
  );
};

export default PlayButtonIcon;
