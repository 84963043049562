import React from "react";
import lottie from "lottie-web";
import { logo } from "./logo";

const Preloader = ({ height = 40 }) => {
  const aniRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const instance = lottie.loadAnimation({
      container: aniRef.current as Element,
      animationData: logo,
    });

    return () => instance.destroy();
  }, []);

  return <div ref={aniRef} style={{ width: 50, height: 50 }} />;
};

export default Preloader;
