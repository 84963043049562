import { theme } from "shared-utilities";
import styled from "styled-components/macro";

const getBackgroundColour = (disabled?: boolean, isInvalid?: boolean) => {
  if (disabled) return theme.palette.LIGHTEST_GREY;
  if (isInvalid) return theme.palette.LIGHT_RED;
  return theme.palette.BACKGROUND;
};

const getTextColour = (disabled?: boolean) => {
  if (disabled) return theme.palette.LIGHT_GREY;
  return theme.palette.PRIMARY;
};

type InputProps = {
  value: string;
  setValue?: (_: string) => void;
  type?: "text" | "password" | "email" | "date";
  isInvalid?: boolean;
  placeholder?: string;
  disabled?: boolean;
};

const InputElement = styled.input<InputProps>`
  //height: 22px;
  width: 279px;
  border-radius: 4px;
  font-size: 16px;
  background-color: ${({ disabled, isInvalid }) =>
    getBackgroundColour(disabled, isInvalid)};
  padding: 16px;
  border-width: 0;
  outline: none;
  color: ${({ disabled }) => getTextColour(disabled)};
  font-family: ${theme.typography.FONT_NAME};
  min-height: 1.2em;
  -webkit-appearance: none;

  &::placeholder {
    font-family: ${theme.typography.FONT_NAME};
    font-size: 16px;

    line-height: 22px;
    text-align: left;
    color: #bcc4c2;
  }
`;

export const Input = ({
  value,
  setValue,
  placeholder,
  type = "text",
  isInvalid = false,
  disabled = false,
}: InputProps) => {
  return (
    <InputElement
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue?.(e.target.value)}
      isInvalid={isInvalid}
      disabled={disabled}
    />
  );
};
