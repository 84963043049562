import React from "react";

import Meh from "shared-assets/src/svg/emojis/Meh";
import Sad from "shared-assets/src/svg/emojis/Sad";
import VerySad from "shared-assets/src/svg/emojis/VerySad";
import { WorkoutFeedbackPainReason } from "shared-interfaces";
import { theme, t } from "shared-utilities";
import styled from "styled-components";

interface DetailedFeedbackPainRowButtonProps {
  // index: number;
  value: WorkoutFeedbackPainReason;
  // selected: boolean;
  onSelected: (value: WorkoutFeedbackPainReason) => void;
  selected: boolean;
}

const PainRowText = styled.p`
  font-family: ${theme.typography.FONT_NAME};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #80918c;
`;

const DetailedFeedbackPainRow: React.FC<DetailedFeedbackPainRowButtonProps> = ({
  // index,
  value,
  // selected,
  onSelected,
  selected,
}) => {
  // Methods
  const handleClick = () => onSelected(value);

  // Computed Variables
  const emojiProps = { size: 48, selected };

  // Renderers
  const emojiReducer = () => {
    switch (value) {
      case "mild":
        return <Meh {...emojiProps} />;
      case "moderate":
        return <Sad {...emojiProps} />;
      case "severe":
        return <VerySad {...emojiProps} />;
      default:
        return null;
    }
  };

  return (
    <button type="button" onClick={handleClick}>
      <div>
        {emojiReducer()}

        <PainRowText>{t(`workout_feedback_${value}`)}</PainRowText>
      </div>
    </button>
  );
};

export default DetailedFeedbackPainRow;
