import React from "react";
import moment from "moment";
import { CheckinSummary, PainAreaKey } from "shared-interfaces";
import { splitArrayByUniqueProperty, t, getPain } from "shared-utilities";
import styled from "styled-components/macro";
import Graph from "./Graph";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface GraphContainerProps {
  checkinSummary: CheckinSummary;
}

interface GraphData {
  date: string;
  value: number;
}

interface PainfulActivityGraphData extends GraphData {
  type: "painfulActivity";
  painfulActivity: string;
}

interface PainAreaGraphData extends GraphData {
  type: "painArea";
  painArea: PainAreaKey;
}

type UnifiedGraphData = PainfulActivityGraphData | PainAreaGraphData;

const preparePainfulActivityData = (
  checkinSummary: CheckinSummary
): PainfulActivityGraphData[][] => {
  const array = splitArrayByUniqueProperty(
    checkinSummary.painInRelationToActivity,
    "painfulActivity"
  );
  return array.map((a) => a.map((v) => ({ ...v, type: "painfulActivity" })));
};

const preparePainAreaData = (
  checkinSummary: CheckinSummary
): PainAreaGraphData[][] => {
  const array = splitArrayByUniqueProperty(
    checkinSummary.painInRelationToArea,
    "painArea"
  );
  return array.map((a) => a.map((v) => ({ ...v, type: "painArea" })));
};

const isPainArea = (data: UnifiedGraphData): data is PainAreaGraphData =>
  data.type === "painArea";

const formatTick = (date?: string | number): string => {
  if (date) return moment(date).format("D/M");
  if (moment(date).isValid()) return moment(date).format("DD/MM");
  return "";
};

const formatSubtitle = (value?: UnifiedGraphData) => {
  if (!value) return "";
  if (!isPainArea(value)) return value.painfulActivity;
  return getPain(value.painArea);
};

const GraphContainer: React.FC<GraphContainerProps> = ({ checkinSummary }) => {
  const graphs = React.useMemo(
    () => [
      ...preparePainfulActivityData(checkinSummary),
      ...preparePainAreaData(checkinSummary),
    ],
    [checkinSummary]
  );

  const renderItem = (item: any) => {
    const [firstRecord] = item;
    const title = t(
      !isPainArea(firstRecord)
        ? "progress_graph_type_activity"
        : "progress_graph_type_area"
    );
    const subtitle = formatSubtitle(firstRecord);
    return (
      <Graph<UnifiedGraphData>
        // highlightBorder
        isMultiLayout
        value={item}
        domain={[0, 10]}
        title={title}
        subtitle={subtitle}
        tickGetter={(data) => data.date}
        dataFormatter={(data) => ({ x: data.date, y: 10 - data.value })}
        tickFormatter={formatTick}
      />
    );
  };

  return <Container>{graphs.map((graph) => renderItem(graph))}</Container>;
};

export default GraphContainer;
