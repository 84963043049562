import React from "react";
import { theme } from "shared-utilities";
import styled, { CSSProp, DefaultTheme } from "styled-components/macro";
import CloseButtonIcon from "../../assets/svg/buttons/CloseButton";

const Root = styled.div`
  padding: ${theme.spacingPx(2)};
`;

interface CloseButtonProps {
  onPress: () => void;
  color?: string;
  small?: boolean;
  style?: CSSProp<DefaultTheme>;
  trackingEvent?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({
  onPress,
  color,
  style,
  small,
  trackingEvent,
}) => {
  // Methods
  const handlePress = () => {
    // if (trackingEvent) Tracking.logClick(trackingEvent);
    onPress();
  };

  return (
    <Root css={style} onClick={handlePress}>
      <CloseButtonIcon
        color={color}
        size={small ? theme.spacing(1.5) : undefined}
      />
    </Root>
  );
};

export default CloseButton;
