import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CommitmentImg from "shared-assets/src/images/theme/commitment.jpg";
import { getUser, useAppSelector } from "shared-redux";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import { InstructionModal } from "../components";
import Heading from "../components/texts/Heading";
import Paragraph from "../components/texts/Paragraph";

type ButtonProps = {
  bgColor?: string;
  color?: string;
};

const Wrapper = styled.div`
  box-sizing: border-box;
  min-height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 35px;
  background-image: url(${CommitmentImg});
  background-size: cover;
`;

const Button = styled.button<ButtonProps>`
  background: #145e7f;
  border-radius: 27px;
  font-weight: 400;
  font-size: 16px;
  height: 54px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.2px;
  width: 100%;
  background: ${({ bgColor }) => bgColor ?? "#145E7F"};
  color: ${({ color }) => color ?? "#fff"};
`;

const Text = styled.div``;

const InnerContainer = styled.div`
  margin-top: ${theme.spacingPx(10)};
  padding-left: ${theme.spacingPx(5)};
  padding-right: ${theme.spacingPx(5)};
`;

export const AssessmentCommitmentScreen = () => {
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);

  // Store
  const user = useAppSelector(getUser);

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    window.history.pushState(null, window.location.pathname);
  };

  useEffect(() => {
    window.history.pushState(null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  // Computed Variables
  // const pathways = user?.pathways ?? [];
  const formattedPathways = "";

  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <Wrapper>
        <InnerContainer>
          <Heading
            size="larger"
            bold
            text={t("assessment_commitment_heading")}
            color={theme.palette.WHITE}
          />
          <Paragraph
            text={t("assessment_commitment_text", { pain: formattedPathways })}
            color={theme.palette.WHITE}
            marginBottom={theme.spacingPx(2)}
            style={{ whiteSpace: "pre-wrap" }}
          />
          <Heading
            size="regular"
            text={t("assessment_commitment_highlight_text")}
            highlightColor={theme.palette.BACKGROUND}
          />
        </InnerContainer>
        <div style={{ width: "80%" }}>
          <Button
            onClick={() => navigate("/first-workout")}
            css={{ marginBottom: theme.spacingPx(2) }}
          >
            <Text
              css={[
                theme.typography[theme.typography.FONT_NAME].REGULAR_HEADING,
              ]}
            >
              {t("assessment_commitment_ready_action")}
            </Text>
          </Button>
          <Button
            onClick={() => setModal(true)}
            type="button"
            bgColor="#E7EEF2"
            color="#145E7F"
          >
            <Text
              css={[
                theme.typography[theme.typography.FONT_NAME].REGULAR_HEADING,
              ]}
            >
              {t("assessment_commitment_skip_action")}
            </Text>
          </Button>

          {modal && <InstructionModal closeModal={() => setModal(!modal)} />}
        </div>
      </Wrapper>
    </div>
  );
};
