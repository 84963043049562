import React from "react";
// import { View, Image, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import { WorkoutSummaryRow } from "shared-interfaces/src/workout";
import { t, theme } from "shared-utilities";
import { renderInstructions } from "shared-utilities/src/workouts";
import PlayButton from "src/assets/svg/buttons/PlayButton";
import styled from "styled-components/macro";
import Heading from "../texts/Heading";
import Paragraph from "../texts/Paragraph";
import NewTag from "./NewTag";

const Root = styled.div`
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing(2)}px;
  display: flex;
`;

const Image = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 5px;
  object-fit: cover;
`;

const Content = styled.div`
  margin-left: 20px;
  justify-content: center;
  margin-right: 0px;
`;

const ContentInner = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const View = styled.div``;

interface UpNextRowProps {
  exercise: WorkoutSummaryRow;
  onDetailsClicked: () => void;
}

const UpNextRow: React.FC<UpNextRowProps> = ({
  exercise,
  onDetailsClicked,
}) => {
  return (
    // <TouchableWithoutFeedback onPress={onDetailsClicked}>
    <Root>
      <div style={{ position: "relative" }}>
        <Image src={exercise.exercise.thumbnailUrl} />
        <View style={{ position: "absolute", left: "35px", top: "35px" }}>
          <PlayButton size={22} />
        </View>
      </div>
      <Content>
        <ContentInner>
          <Heading
            size="regular"
            text={t((exercise.exercise as any).nameId)}
            leftAlign
            marginBottom="0px"
            style={{ marginRight: "5px" }}
            ellipsizeMode="tail"
            numberOfLines={1}
          />
          {exercise.isNew && <NewTag />}
        </ContentInner>
        <Paragraph
          size="small"
          text={renderInstructions(exercise)}
          leftAlign
          marginBottom="0px"
        />
      </Content>
    </Root>
    // </TouchableWithoutFeedback>
  );
};

export default UpNextRow;
