import { createApi } from '@reduxjs/toolkit/query/react';

import { IUser, SuccessResponse } from 'shared-interfaces';
import { createBaseQuery } from '../baseQuery';

// interface AuthGetMeResponse {
//   token: string;
//   user: User;
// }

export interface AuthLoginBody {
  email: string;
  password: string;
}

export interface AuthLoginResponse {
  token: string;
  refreshToken?: string;
  user: IUser;
}

export interface AuthForgotPasswordBody {
  email: string;
}

export interface AuthChangePasswordBody {
  password: string;
}

export interface AuthResetPasswordBody extends AuthChangePasswordBody {
  activationCode: string;
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    authGetMe: builder.query<SuccessResponse<IUser>, void>({
      query: () => ({ url: 'auth/me' }),
    }),
    authLogin: builder.mutation<SuccessResponse<AuthLoginResponse>, AuthLoginBody>({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
    }),
    authChangePassword: builder.mutation<null, AuthChangePasswordBody>({
      query: (body) => ({
        url: 'auth/change-password',
        method: 'POST',
        body,
      }),
    }),
    authForgotPassword: builder.mutation<null, AuthForgotPasswordBody>({
      query: (body) => ({
        url: 'auth/forgot-password',
        method: 'POST',
        body,
      }),
    }),
    authResetPassword: builder.mutation<null, AuthResetPasswordBody>({
      query: (body) => ({
        url: 'auth/reset-password',
        method: 'POST',
        body,
      }),
    }),
    authIsValidMagicLink: builder.query<null, string>({
      query: (activationCode) => ({
        url: `auth/magic-link/${activationCode}/valid`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useAuthGetMeQuery,
  useAuthIsValidMagicLinkQuery,
  useAuthForgotPasswordMutation,
  useAuthChangePasswordMutation,
  useAuthResetPasswordMutation,
  useAuthLoginMutation,
} = authApi;
