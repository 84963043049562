import * as React from 'react';
import Svg, { Path, Rect, Circle } from 'react-native-svg';
import { DuotoneIconProps } from '@interfaces/common';
import { theme } from 'shared-utilities/src/theme/theme';

const InfoIcon: React.FC<DuotoneIconProps> = ({
  color = theme.palette.PRIMARY,
  colorTwo = theme.palette.WHITE,
  size = theme.spacing(3),
}) => (
  <Svg width={size} height={size} viewBox="0 0 30 30" fill="none">
    <Path
      d="M15 30C6.716 30 0 23.284 0 15 0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15z"
      fill={color}
    />
    <Rect x={13.5} y={11} width={3} height={13} rx={1.5} fill={colorTwo} />
    <Circle cx={15} cy={6.5} r={1.5} fill={colorTwo} />
  </Svg>
);

export default InfoIcon;
