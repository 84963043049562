import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const BallIcon: React.FC<SvgProps & IconProps> = ({
  size = theme.spacing(2.875),
  color = theme.palette.PRIMARY,
  ...props
}) => (
  <Svg width={size * 0.9565217391304348} height={size} viewBox="0 0 22 23" {...props}>
    <G stroke={color} strokeWidth={1.7} fill="none" fillRule="evenodd">
      <Path d="M10.69 21.297a10.032 10.032 0 0 0 7.353-2.938 10.032 10.032 0 0 0 2.933-7.44 10.03 10.03 0 0 1-7.29-2.94 10.018 10.018 0 0 1-2.934-6.795 10.025 10.025 0 0 0-6.945 2.94 10.02 10.02 0 0 0-2.935 6.85 10.025 10.025 0 0 1 6.881 2.937 10.03 10.03 0 0 1 2.936 7.386zM10.752 1.184c2.634-.045 5.281.93 7.291 2.94a10.023 10.023 0 0 1 2.933 6.795" />
      <Path d="M10.69 21.297a10.028 10.028 0 0 1-6.883-2.938 10.025 10.025 0 0 1-2.935-7.386" />
    </G>
  </Svg>
);

export default BallIcon;
