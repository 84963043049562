const WINDOW_WIDTH = window.innerWidth;
const WINDOW_HEIGHT = window.innerHeight;

type DeviceSize = 'xsmall' | 'small' | 'normal' | 'large' | 'xlarge';

/**
 * Get DeviceSize for an iOS device
 * @param width
 * @param height
 */
const getIosDeviceSize = (width: number, height: number): DeviceSize => {
  // iphone 4 spec
  if ((width <= 320 && height <= 480) || (width <= 480 && height <= 320)) return 'xsmall';
  // iphone 5 spec
  if ((width <= 320 && height <= 568) || (width <= 568 && height <= 320)) return 'small';
  // iphone 6 spec
  if ((width <= 375 && height <= 667) || (width <= 667 && height <= 375)) return 'normal';
  // iphone 6 plus spec
  if ((width <= 414 && height <= 736) || (width <= 736 && height <= 414)) return 'large';
  // greater then inphone 6 plus, such as tablets
  return 'xlarge';
};

/**
 * Get DeviceSize for an Android device
 * @param width
 * @param height
 */
const getAndroidDeviceSize = (width: number, height: number): DeviceSize => {
  // xlarge screens are at least 960dp x 720dp
  if ((width >= 960 && height >= 720) || (width >= 720 && width >= 960)) return 'xlarge';
  // large screens are at least 640dp x 480dp
  if ((width >= 640 && height >= 480) || (width >= 480 && height >= 640)) return 'large';
  // normal screens are at least 470dp x 320dp
  if ((width >= 470 && height >= 320) || (width >= 320 && height >= 470)) return 'normal';
  // small screens are at least 426dp x 320dp
  if ((width >= 426 && height >= 320) || (width >= 320 && height >= 426)) return 'small';
  // default to extra small
  return 'xsmall';
};

/**
 * Get DeviceSize for device
 * (Device metrics https://material.io/devices/)
 * @param width
 * @param height
 * @param platform
 */
export const getDeviceSize = (width: number, height: number, platform: any): DeviceSize => {
  if (platform === 'ios') return getIosDeviceSize(width, height);
  if (platform === 'android') return getAndroidDeviceSize(width, height);
  return 'normal';
};

const DEVICE_SIZE = getDeviceSize(WINDOW_WIDTH, WINDOW_HEIGHT, "android");

export const isSmallest = (): boolean => DEVICE_SIZE === 'xsmall';
export const isSmall = (): boolean => DEVICE_SIZE === 'small';
export const isNormal = (): boolean => DEVICE_SIZE === 'normal';
export const isLarge = (): boolean => DEVICE_SIZE === 'xlarge';

export default DEVICE_SIZE;
