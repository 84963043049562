import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const ChairIcon: React.FC<SvgProps & IconProps> = ({
  size = theme.spacing(3.25),
  color = theme.palette.PRIMARY,
  ...props
}) => (
  <Svg width={size * 0.8461538461538461} height={size} viewBox="0 0 22 26" {...props}>
    <G stroke={color} strokeWidth={1.7} fill="none" fillRule="evenodd">
      <Path d="M15.234 10.523H6.27a2.768 2.768 0 0 1-2.768-2.768V3.619A2.768 2.768 0 0 1 6.27.85h8.964a2.768 2.768 0 0 1 2.768 2.768v4.136a2.768 2.768 0 0 1-2.768 2.768zM7.425 10.477v4M14.425 10.477v4M19.425 17.477h-17c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1h17c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1z" />
      <Path d="M4.425 17.477v7M17.425 17.477v7" strokeLinecap="round" />
    </G>
  </Svg>
);

export default ChairIcon;
