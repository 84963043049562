// import AsyncStorage from "@react-native-async-storage/async-storage";
import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Modules
import {
  assessmentApi,
  authApi,
  referralApi,
  learnApi,
  checkinApi,
  workoutApi,
  progressApi,
  planApi,
  userApi,
  chatApi,
} from './api';
// import { grokApi } from "../api/src/grokApi";
import assessmentReducer from './modules/assessment';
import authReducer from './modules/auth';
import chatReducer from './modules/chat';
import checkinReducer from './modules/checkin';
// import grokReducer from "./modules/grok";
import learnReducer from './modules/learn';
import miscellaneousReducer from './modules/miscellaneous';
// import notificationReducer from "./modules/notifications";
import planReducer from './modules/plan';
import progressReducer from './modules/progress';
import referralReducer from './modules/referral';
import userReducer from './modules/user';
import workoutReducer from './modules/workout';

const IGNORED_ACTIONS = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];
const API_REDUCERS = {
  [assessmentApi.reducerPath]: assessmentApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [checkinApi.reducerPath]: checkinApi.reducer,
  // [grokApi.reducerPath]: grokApi.reducer,
  [learnApi.reducerPath]: learnApi.reducer,
  [planApi.reducerPath]: planApi.reducer,
  [progressApi.reducerPath]: progressApi.reducer,
  [referralApi.reducerPath]: referralApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [workoutApi.reducerPath]: workoutApi.reducer,
};
const SLICE_REDUCERS = {
  assessment: assessmentReducer,
  auth: authReducer,
  chat: chatReducer,
  checkin: checkinReducer,
  learn: learnReducer,
  miscellaneous: miscellaneousReducer,
  // notifications: notificationReducer,
  progress: progressReducer,
  plan: planReducer,
  referral: referralReducer,
  user: userReducer,
  workout: workoutReducer,
  // grok: grokReducer,
};
const COMBINED_REDUCERS = combineReducers({
  ...API_REDUCERS,
  ...SLICE_REDUCERS,
});
const PERSIST_CONFIG = {
  key: 'root',
  version: 1,
  storage,
  // storage: AsyncStorage,
  blacklist: [...Object.keys(API_REDUCERS), 'user'],
};
const PERSISTED_REDUCER = persistReducer(PERSIST_CONFIG, COMBINED_REDUCERS);

// Middleware
const middlewares: Middleware[] = [
  assessmentApi.middleware,
  authApi.middleware,
  chatApi.middleware,
  checkinApi.middleware,
  // grokApi.middleware,
  learnApi.middleware,
  planApi.middleware,
  progressApi.middleware,
  referralApi.middleware,
  userApi.middleware,
  workoutApi.middleware,
];

// Flipper Integration
// if (__DEV__) {
//   const createDebugger = require("redux-flipper").default;
//   middlewares.push(createDebugger());
// }

// Store
export const store = configureStore({
  reducer: PERSISTED_REDUCER,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: IGNORED_ACTIONS,
        warnAfter: 256,
      },
      immutableCheck: {
        warnAfter: 256,
      },
    }),
    ...middlewares,
  ],
});

export const storePersistor = persistStore(store);

// Types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type StoreAlias = typeof store;
