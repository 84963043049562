import { Palette } from '../types';

export const palette: Palette = {
    // Brand Colours
    PRIMARY: '#0064B9',
    SECONDARY: '#95C6F0',
    DARK: '#0B2D7B',

    // Accents
    ACCENT_PRIMARY: '#95C6F0',
    ACCENT_SECONDARY: '',

    // Buttons
    BUTTON_PRIMARY: '#0064B9',
    BUTTON_SECONDARY: '#E7EEF2',

    // Top Nav
    TOP_BAR_BACKGROUND: '#0064B9',
    TOP_BAR_TEXT: '#FFFFFF',

    // Shadow
    PRIMARY_SHADOW: '#437E99',
  
    // Utility Colours
    BACKGROUND: '#E7EEF2',
    HIGHLIGHT: '#D0DEE5',
    SHADOW: '#3f4c52',
  
    // Pastel Colours
    SOFT_PINK: '#FFCCF6',
    PINK: '#0064B9',
    ORANGE: '#FF8900',
    VIBRANT_ORANGE: '#FF6B3F',
    DARK_RED: '#C83333',
    RED: '#FF5A5A',
    LIGHT_RED: '#FFEFEF',
    LIGHT_GREEN: '#edf6e7',
  
    // Black & White
    BLACK: '#000000',
    WHITE: '#FFFFFF',
  
    // Greys
    DARK_GREY: '#4F615C',
    GREY: '#80918C',
    LIGHT_GREY: '#BCC4C2',
    LIGHT_GREY_MONO: '#C2C2C2',
    LIGHTER_GREY: '#DCE2E0',
    LIGHTEST_GREY: '#EDEFEF',
    BASICALLY_WHITE: '#F9FAFB',
  
    // Social
    FACEBOOK: '#3C5A99',
  
    // NEW STYLES
    NOVA_TEXT_PRIMARY: '#111827',
    NOVA_TEXT_SECONDARY: '#6B7280',
}