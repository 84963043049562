import { nanoid } from "nanoid";

interface IAbortListener {
  id: string;
  callback: () => void;
}

type IAbortListenerCancel = () => void;

export class AbortController {
  // Shared instance
  static shared: AbortController = new AbortController();

  constructor() {
    // eslint-disable-next-line no-constructor-return
    return AbortController.shared;
  }

  private listeners: IAbortListener[] = [];

  public addEventListener = (
    callback: IAbortListener["callback"]
  ): IAbortListenerCancel => {
    const id = nanoid();
    this.listeners.push({ id, callback });
    return () => {
      this.listeners = this.listeners.filter((listener) => listener.id !== id);
    };
  };

  public clearAllListener = () => {
    this.listeners = [];
  };

  public abortAll = () => {
    this.listeners.forEach((listener) => {
      listener.callback();
    });
    this.listeners = [];
  };
}
