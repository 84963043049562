import React from "react";
import { IconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const CheckIcon: React.FC<any & IconProps> = ({
  color,
  size = theme.spacing(2),
  ...props
}) => (
  <svg width={size} height={size} viewBox="0 0 16 16" {...props}>
    <path
      d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zM5.8 8.774a1.032 1.032 0 0 0-1.278 1.622l2.247 1.77c.521.41 1.29.23 1.572-.372l2.916-6.193a1.032 1.032 0 1 0-1.868-.88l-2.363 5.02L5.8 8.774z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export default CheckIcon;
