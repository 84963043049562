import { createSlice } from '@reduxjs/toolkit';
import { Plan, Workouts } from 'shared-interfaces';
import { createWorkout } from 'shared-utilities';
import { planApi } from '../api';
import { RootState } from '../store';
import { logout } from './auth';
import { getProgress } from './progress';

// State Interface
export interface PlanState {
  plan: Plan | null;
}

// Initial State
const initialState: PlanState = {
  plan: null,
};

// Slice
export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    // logout: (state) => {
    //   state.token = null;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.plan = null;
    });
    builder.addMatcher(planApi.endpoints.planFetch.matchFulfilled, (state, action) => {
      state.plan = action.payload.data.plan;
    });
  },
});

// Actions
// export const { logout } = planSlice.actions;

// Selectors
export const getPlan = (state: RootState): Plan | null => state.plan.plan;
export const getWorkouts = (state: RootState): Workouts => {
  const plan = getPlan(state);
  const progress = getProgress(state);

  if (!plan || !progress) return { full: null, quick: null };

  const full = createWorkout(plan, 'Full', progress);
  const quick = createWorkout(plan, 'Quick', progress);

  return { full, quick };
};

// Reducer
export default planSlice.reducer;
