import { RotatingLines } from "react-loader-spinner";
import { theme } from "shared-utilities";
import styled from "styled-components/macro";

type ButtonProps = {
  bgColor?: string;
  handleClick?: () => void;
  isActive?: boolean;
  isBoldText?: boolean;
  isFullWidth?: boolean;
  isRoundedCorners?: boolean;
  loading?: boolean;
};

type ContainerProps = Pick<
  ButtonProps,
  "isFullWidth" | "isRoundedCorners" | "isBoldText" | "isActive" | "bgColor"
>;

export const ButtonContainer = styled.button<ContainerProps>`
  height: 54px;
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "311px")};
  border-radius: ${({ isRoundedCorners }) => (isRoundedCorners ? "27px" : "0")};
  line-height: 54px;
  font-family: ${theme.typography.FONT_NAME};
  font-weight: ${({ isBoldText }) => (isBoldText ? 700 : 400)};
  color: ${({ isActive }) => (isActive ? "white" : "#145E7F")};
  background-color: ${({ isActive, bgColor }) =>
    isActive ? bgColor : "#E7EEF2"};
  cursor: pointer;
  pointer-events: ${({ isActive }) => (isActive ? "all" : "none")};
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  bgColor = theme.palette.BUTTON_PRIMARY,
  handleClick,
  loading,
  isActive = true,
  isBoldText = false,
  isFullWidth = false,
  isRoundedCorners = true,
}) => {
  return (
    <ButtonContainer
      isActive={isActive}
      onClick={handleClick}
      isBoldText={isBoldText}
      isFullWidth={isFullWidth}
      isRoundedCorners={isRoundedCorners}
      bgColor={loading ? theme.palette.BUTTON_SECONDARY : bgColor}
    >
      {loading ? (
        <LoaderContainer>
          <RotatingLines
            visible
            width="25"
            strokeWidth="5"
            animationDuration="0.75"
            strokeColor={theme.palette.WHITE}
          />
        </LoaderContainer>
      ) : (
        children
      )}
    </ButtonContainer>
  );
};
