import React, { CSSProperties } from "react";
import moment, { Moment } from "moment";

const Text = "div";

interface TimerProps {
  paused: boolean;
  style?: CSSProperties;
  updateSeconds: (state: number) => void;
}

const Timer: React.FC<TimerProps> = ({ paused, style, updateSeconds }) => {
  // Refs
  const intervalRef = React.useRef<NodeJS.Timeout | undefined>();

  // State
  const [isRunning, setIsRunning] = React.useState<boolean>(false);
  const [timer, setTimer] = React.useState<number | undefined>();
  const [timerStart, setTimerStart] = React.useState<Moment | undefined>();
  const [savedTime, setSavedTime] = React.useState<number>(0);

  // Methods
  const resetTimer = () => {
    if (!intervalRef.current) return;
    clearInterval(intervalRef.current);
    setIsRunning(false);
  };

  const updateTimer = () => {
    if (paused && isRunning) {
      setIsRunning(false);
      setSavedTime((v) => v + moment().diff(timerStart, "seconds"));
      return;
    }

    if (!paused && !isRunning) {
      setTimerStart(moment());
      setIsRunning(true);
    }

    if (!isRunning) return;

    setTimer(savedTime + moment().diff(timerStart, "seconds"));
  };

  const startTimer = () => {
    setIsRunning(true);
    setTimerStart(moment());
    setTimer(0);
    intervalRef.current = setInterval(updateTimer, 1000);
  };

  // Effects
  React.useEffect(() => {
    startTimer();
    return resetTimer;
  }, []);

  React.useEffect(() => {
    if (paused) startTimer();
    return resetTimer;
  }, [paused]);

  React.useEffect(() => {
    if (!timer) return;
    updateSeconds(timer);
  }, [timer]);

  if (!style) return null;
  return (
    <Text style={style}>{moment.utc((timer ?? 0) * 1000).format("mm:ss")}</Text>
  );
};

// Timer.propTypes = {
//   paused: PropTypes.bool.isRequired,
//   style: PropTypes.instanceOf(Object),
//   updateSeconds: PropTypes.func.isRequired,
// };
//
// Timer.defaultProps = {
//   style: undefined,
// };

export default Timer;
