import React from "react";

import { ReactComponent as CheckIcon } from "shared-assets/src/icons/Shape.svg";
import {
  WorkoutFeedbackEasyReason,
  WorkoutFeedbackHardReason,
} from "shared-interfaces";
import { t, theme } from "shared-utilities";
import styled from "styled-components";

type ButtonProps = {
  selected: boolean;
};

const ButtonWrapper = styled.button<ButtonProps>`
  background: #eceeec;
  border-radius: 5px;
  position: relative;
  height: 40px;
  width: 100%;
  font-family: ${theme.FONT_NAME}
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #145e7f;
  background: ${({ selected }) => (selected ? "#E8EFF2" : "#ECEEEC")};
  color: ${({ selected }) => (selected ? "#FF6B3F" : "#145E7F")};

  svg {
    position: absolute;
    right: 13px;
    top: 13px;
  }
`;

type CombinedReason = WorkoutFeedbackEasyReason | WorkoutFeedbackHardReason;

interface DetailedFeedbackPainRowButtonProps {
  value: CombinedReason;
  selected: boolean;
  onSelected: (value: CombinedReason) => void;
}

const DetailedFeedbackEasyHardRow: React.FC<
  DetailedFeedbackPainRowButtonProps
> = ({ value, selected, onSelected }) => {
  // Methods
  const handleClick = () => onSelected(value);

  return (
    <ButtonWrapper selected={selected} type="button" onClick={handleClick}>
      <div>
        <p>{t(`workout_feedback_${value}`)}</p>
        {selected && <CheckIcon />}
      </div>
    </ButtonWrapper>
  );
};

export default DetailedFeedbackEasyHardRow;
