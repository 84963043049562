import React, { ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  useAuthLoginMutation,
  authSetToken,
  useAppDispatch,
} from "shared-redux";
import { theme } from "shared-utilities";

export const AuthLoginScreen: React.FC<React.PropsWithChildren> = () => {
  const dispatch = useAppDispatch();
  // Refs
  const emailRef = React.useRef<string>("");
  const passwordRef = React.useRef<string>("");

  // Navigation
  const navigate = useNavigate();

  // Store
  const [dispatchLogin, { isLoading, error, isSuccess, data: response }] =
    useAuthLoginMutation();

  // State
  const [displayErrors, setDisplayErrors] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  // Form
  // const emailError = useValidator([EMAIL_VALIDATOR], email.trim());
  // const passwordError = useValidator([PASSWORD_VALIDATOR], password.trim());

  // Methods
  const handleLogin = async () => {
    setDisplayErrors(true);
    // if (emailError || passwordError) return;
    await dispatchLogin({
      email: email.trim(),
      password: password.trim(),
    });
  };

  const handlePasswordForgot = async () => {
    // navigation.navigate('ResetPassword', { email });
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setDisplayErrors(false);
  };

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setDisplayErrors(false);
  };

  // Computed Variables
  const isFormValid = Boolean(email && password);

  // Effects
  // React.useEffect(() => {
  // if (!error) return;
  // if ("data" in error) {
  // const typedError = createErrorResponse(error);
  // showAlert('Error logging in', getErrorString(typedError.data.message));
  // }
  // }, [error]);

  React.useEffect(() => {
    if (!isSuccess || !response) return;
    dispatch(authSetToken(response.data.token));
    setDisplayErrors(false);
    navigate("/");
    // setTimeout(() => (navigation as any).reset({ index: 0, routes: [{ name: 'Launch' }] }), 1000);
  }, [isSuccess]);

  return (
    <div>
      <input
        style={{ marginBottom: theme.spacing(2) }}
        value={email}
        autoCapitalize="none"
        type="text"
        onChange={handleChangeEmail}
      />
      <input
        style={{ marginBottom: theme.spacing(2) }}
        value={password}
        autoCapitalize="none"
        type="password"
        onChange={handleChangePassword}
      />
      {/* <LinkButton */}
      {/*  onPress={handlePasswordForgot} */}
      {/*  style={styles.button} */}
      {/*  text={t('login_forgot_pw')} */}
      {/*  // trackingEvent="Forgot Password" */}
      {/* /> */}
      <button onClick={handleLogin} type="button">
        Login
      </button>
    </div>
  );
};
