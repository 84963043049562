/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import Slider from "react-input-slider";

import { ReactComponent as SlideIcon } from "shared-assets/src/icons/Slide.svg";
import { theme } from "shared-utilities";
import styled from "styled-components";
import EmphasizedText from "../../texts/EmphasizedText";
import Heading from "../../texts/Heading";
import Subtitle from "../../texts/Subtitle";

type TSliderIconWrappernProps = {
  isActive: any;
};

type TTooltipProps = {
  value: number;
};

const PainSliderWrapper = styled.div`
  position: relative;
`;

const SliderIconWrapper = styled.div<TSliderIconWrappernProps>`
  opacity: ${({ isActive }) => `${isActive}`};
  transition: all 0.1s ease-in-out;
  text-align: center;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
`;

const SliderWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 30px;
  margin: 60px auto 10px;
`;

const Tooltip = styled.div<TTooltipProps>`
  width: 33px;
  background-color: ${theme.palette.PRIMARY};
  color: ${theme.palette.WHITE};
  font-family: ${theme.typography.FONT_NAME};
  text-align: center;
  border-radius: 6px;
  padding: 9px 0;
  position: absolute;
  z-index: 1;
  margin-left: -20px;
  bottom: 148%;
  left: ${({ value }) => `${value}%`};
  opacity: 1;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${theme.palette.PRIMARY} transparent transparent transparent;
  }
`;

interface PainSliderProps {
  item: any;
  answers: any;
  onChange: (id: number, type: string, value: number) => void;
}

const PainSliderQuestionView: React.FC<PainSliderProps> = ({
  item,
  answers,
  onChange,
}) => {
  const selectedQuestionAnswers = answers.find(
    (answer: any) => answer?.question === item.id
  );

  const [isActive, setIsActive] = useState(false);
  const [actionCommited, setActionCommited] = useState(
    !!selectedQuestionAnswers
  );
  const [value, setValue] = useState(
    selectedQuestionAnswers ? selectedQuestionAnswers?.input?.value : 5.5
  );

  const renderToolTip = () => {
    if (actionCommited) {
      return (
        <Tooltip value={(value / item.steps) * 100 - (item.steps - value - 1)}>
          {value}
        </Tooltip>
      );
    }
  };

  const handleChange = (newValues: number) => {
    setValue(newValues);
    setActionCommited(true);
    onChange(item.id, item.type, newValues);
  };

  const onDragStart = () => {
    setIsActive(true);
  };

  const onDragEnd = () => {
    setIsActive(false);
  };

  return (
    <PainSliderWrapper>
      <Heading text={item.title} size="medium" />
      <EmphasizedText text={item.emphasised} />
      <Subtitle text={item.subtitle} />
      <SliderWrapper>
        {renderToolTip()}
        <Slider
          axis="x"
          xmax={item.steps}
          xmin={1}
          x={value}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onChange={({ x }) => handleChange(x)}
          styles={{
            track: {
              height: 6,
              background:
                "linear-gradient(87.53deg, #13C48E 1.89%, #B9DC67 24.86%, #FFC44E 49.98%, #FF974C 72.8%, #FF5A5A 97.93%)",
            },
            active: {
              height: 6,
              background:
                "linear-gradient(87.53deg, #13C48E 1.89%, #B9DC67 24.86%, #FFC44E 49.98%, #FF974C 72.8%, #FF5A5A 97.93%)",
            },
            thumb: {
              width: 30,
              height: 30,
              borderRadius: "50%",
              background: "#ffffff",
              border: "3px solid #145e7f",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            },
            disabled: {
              opacity: 0.5,
            },
          }}
        />
      </SliderWrapper>
      <SliderIconWrapper isActive={Number(!isActive)}>
        <SlideIcon />
      </SliderIconWrapper>
    </PainSliderWrapper>
  );
};

export default PainSliderQuestionView;
