import React from "react";
/* import { Audio, ResizeMode, Video } from "expo-av";
import { AVPlaybackStatus } from "expo-av/build/AV"; */
// import * as Animatable from 'react-native-animatable';
/* import PauseButton from "@assets/svg/buttons/PauseButton";
import PlayButton from "@assets/svg/buttons/PlayButton";
import Content from "@components/patterns/Content";
import Heading from "@components/texts/Heading";
import Paragraph from "@components/texts/Paragraph";
import { AUDIO_SETTINGS } from "shared-utilities"; */
import ReactPlayer from "react-player";
import { Outlet, useNavigate } from "react-router-dom";
import { theme, t } from "shared-utilities/src";
import styled from "styled-components/macro";
import CloseButtonWithCircle from "../../assets/svg/buttons/CloseButtonWithCircle";
import PauseButton from "../../assets/svg/buttons/PauseButton";
import PlayButton from "../../assets/svg/buttons/PlayButton";
import Heading from "../texts/Heading";
import Paragraph from "../texts/Paragraph";

interface PhaseProps {
  // onRef: (ref: typeof Phase) => void;
  goal: string;
  videoUrl: string;
  phase: number;
}

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  justify-content: flex-end;
  align-items: center;
  padding-top: 56.25%;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.palette.LIGHTEST_GREY};
`;

const VideoButton = styled.div`
  height: 52px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translate(-50%, 0);
`;

const SafeArea = styled.div`
  position: absolute;
  top: 0;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;
const CloseButtonContainer = styled.div`
  padding: 10px;
  float: right;
`;

const Phase: React.FC<React.PropsWithChildren<PhaseProps>> = ({
  goal,
  videoUrl,
  phase,
  children,
}) => {
  // Refs
  const playerRef = React.useRef<ReactPlayer | null>(null);

  // Navigation
  const navigate = useNavigate();

  // State
  const [paused, setPaused] = React.useState<boolean>(false);
  const [ended, setEnded] = React.useState<boolean>(false);

  // Methods
  const handlePlayPause = () => {
    if (ended) {
      setPaused(false);
      setEnded(false);
      return;
    }
    setPaused((v) => !v);
  };

  const stopVideo = async () => {
    if (!playerRef.current) return;
    // await playerRef.current.pauseAsync();
    playerRef.current = null;
  };

  const handleClose = async () => {
    await stopVideo();
    navigate(-1);
  };

  // Renderer
  const renderVideoOverlays = () => (
    <Overlay>
      <SafeArea>
        <CloseButtonContainer onClick={handleClose}>
          <CloseButtonWithCircle />
        </CloseButtonContainer>
      </SafeArea>
      <VideoButton onClick={handlePlayPause}>
        {paused ? <PlayButton size={52} /> : <PauseButton size={52} />}
      </VideoButton>
    </Overlay>
  );

  return (
    <div style={{ flex: 1, position: "relative" }}>
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="100%"
        playing={!paused}
        onEnded={() => setPaused(true)}
        playsinline
      />
      <div style={{ flex: 1 }}>
        <Heading
          size="largest"
          leftAlign
          text={t("progress_phase", { phase })}
          style={{ padding: 15 }}
          marginBottom="0px"
        />
        <Separator />
        <div
          style={{
            borderBottomColor: theme.palette.LIGHTER_GREY,
            borderBottomWidth: 1,
          }}
        />
        <div style={{ padding: 15 }}>
          <div style={{ marginBottom: theme.spacing(2) }}>
            <Paragraph
              style={{ whiteSpace: "pre-wrap" }}
              leftAlign
              bold
              text={t("progress_phase_expect", { phase })}
            />
            {children}
          </div>
          <div style={{ marginBottom: theme.spacing(2) }}>
            <Paragraph
              style={{ whiteSpace: "pre-wrap" }}
              leftAlign
              bold
              text={t("progress_phase_goal", { phase })}
            />
            <Paragraph
              leftAlign
              style={{ whiteSpace: "pre-wrap" }}
              text={goal}
            />
          </div>
          <div style={{ marginBottom: theme.spacing(2) }}>
            <Paragraph
              style={{ whiteSpace: "pre-wrap" }}
              leftAlign
              bold
              text={t("progress_phase_length", { phase })}
            />
            <Paragraph leftAlign text={t("progress_phase_length_short")} />
          </div>
        </div>
      </div>
      {renderVideoOverlays()}
    </div>
  );
};

export default Phase;
