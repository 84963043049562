import { createApi } from '@reduxjs/toolkit/query/react';

import { GetCheckinQuestionsResponse, SuccessResponse } from 'shared-interfaces';
import { createBaseQuery } from '../baseQuery';

import { FetchProgressResponse } from './progressApi';

export interface CheckinBody {
  painInRelationToArea: number;
  painInRelationToActivity: number;
  satisfactionWithTreatment: number;
  comments: string;
}

export const checkinApi = createApi({
  reducerPath: 'checkinApi',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    checkinFetch: builder.query<SuccessResponse<GetCheckinQuestionsResponse>, void>({
      query: () => ({ url: 'user-checkin' }),
    }),
    checkinSubmit: builder.mutation<SuccessResponse<FetchProgressResponse>, CheckinBody>({
      query: (body) => ({
        url: 'user-checkin',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useCheckinFetchQuery, useCheckinSubmitMutation } = checkinApi;
