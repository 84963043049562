import React from "react";
import { t, theme } from "shared-utilities";
import { Button } from "src/components/Button";
import styled from "styled-components/macro";
import Heading from "../../texts/Heading";
import Paragraph from "../../texts/Paragraph";
import { Illustration } from "../Illustration";

const Text = styled.div``;

interface RedFlagWarningProps {
  onChange: () => void;
}
const RedFlagWarningQuestionView: React.FC<RedFlagWarningProps> = ({
  onChange,
}) => {
  return (
    <>
      <Illustration name="illustration_check" />
      <Heading text="Important information" size="regular" bold />
      <Paragraph marginBottom={theme.spacingPx(3)}>
        Based on some of your answers, we advise that you chat to one of our
        physiotherapists before completing any exercises through the app.
      </Paragraph>
      <Paragraph marginBottom={theme.spacingPx(3)}>
        Please continue with your assessment and once you have registered we’ll
        be in touch to discuss your exercise plan.
      </Paragraph>
      <Button handleClick={onChange}>
        <Text
          css={[
            theme.typography[theme.typography.FONT_NAME].REGULAR_BOLD_HEADING,
            { color: theme.palette.WHITE },
          ]}
        >
          {t("btn_next")}
        </Text>
      </Button>
    </>
  );
};

export default RedFlagWarningQuestionView;
