import React from "react";
import { theme } from "shared-utilities";
import styled from "styled-components";
import Heading from "../texts/Heading";

const Root = styled.div({
  display: "flex",
  backgroundColor: "#fff",
  flex: 1,
});
const FormContainer = styled.div({
  display: "flex",
  flex: 1,
  justifyContent: "center",
});
const ScrollContentContainer = styled.div({
  display: "flex",
  flexGrow: 1,
  backgroundColor: "#fff",
  // backgroundColor: 'yellow',
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(4),
});
const ScrollInner = styled.div({
  width: "100%",
});
const ScrollView = styled.div({
  flex: 1,
  display: "flex",
});

interface FormProps {
  title?: string;
}

const Form: React.FC<React.PropsWithChildren<FormProps>> = ({
  title,
  children,
}) => {
  // State

  // Renderers
  const renderTitle = React.useCallback(
    () => (
      <div
        style={{
          paddingTop: theme.spacing(2.5),
        }}
      >
        <Heading size="regular" text={title} />
      </div>
    ),
    [title]
  );

  return (
    <Root>
      <FormContainer>
        <ScrollView>
          <ScrollContentContainer>
            <ScrollInner>
              {renderTitle()}
              {children}
            </ScrollInner>
          </ScrollContentContainer>
        </ScrollView>
      </FormContainer>
    </Root>
  );
};

export default Form;
