import { createApi } from '@reduxjs/toolkit/query/react';

import { IUser, Account, ChatMessage, SuccessResponse } from 'shared-interfaces';
import { environmentVariables } from 'shared-utilities';
import { createBaseQuery } from '../baseQuery';

export interface ConversationResponse {
  userId: string;
  messages: ChatMessage[];
}

export interface ChatMessageRequest {
  message: string;
  tempId: string;
  createdAt: Date;
}

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: createBaseQuery(environmentVariables.CHAT_API),
  endpoints: (builder) => ({
    sendMessage: builder.mutation<ChatMessage, ChatMessageRequest>({
      query: (body) => ({
        url: 'send-message-physios',
        method: 'POST',
        body,
      }),
      transformResponse: (response: SuccessResponse<ChatMessage>) => response.data,
    }),
    chatResetConnection: builder.mutation<SuccessResponse<string>, void>({
      query: () => ({ url: 'reset-connection', method: 'GET' }),
    }),
    chatGetPhysios: builder.query<Account[], void>({
      query: () => ({ url: 'get-physios', method: 'GET' }),
      transformResponse: (response: SuccessResponse<Account[]>) => response.data,
    }),
    chatGetUsers: builder.query<IUser[], void>({
      query: () => ({ url: 'get-users', method: 'GET' }),
      transformResponse: (response: SuccessResponse<IUser[]>) => response.data,
    }),
    chatGetConversation: builder.query<ConversationResponse, void>({
      query: () => ({
        url: 'get-conversation',
        method: 'GET',
      }),
      transformResponse: (response: SuccessResponse<ConversationResponse>) => response.data,
    }),
    chatReadMessages: builder.mutation<void, void>({
      query: () => ({
        url: 'seen-messages',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useChatGetPhysiosQuery,
  useChatGetConversationQuery,
  useChatResetConnectionMutation,
  useChatReadMessagesMutation,
  useSendMessageMutation,
} = chatApi;
