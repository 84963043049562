import { theme } from "shared-utilities";
import styled from "styled-components";

const Icon = styled.svg<{ focused?: boolean }>`
  transition: 0.3s ease;
  transform: ${(props) => (props.focused ? "scale(1.2)" : "scale(1)")};

  .accent {
    fill: ${(props) => (props.focused ? theme.palette.ACCENT_PRIMARY : "#fff")};
  }
`;

const LearnSelectedIcon: React.FC<any> = ({
  size = theme.spacing(3.125),
  color = theme.palette.PRIMARY,
  ...props
}) => (
  <Icon width={size} height={size} viewBox="0 0 37 25" {...props}>
    <g
      transform="translate(-20 -10) translate(20 11)"
      stroke={color}
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path strokeLinecap="round" d="M33 8.68938275L33 21.3220507" />
      <circle className="accent" cx={33.1} cy={20.5} r={2.5} />
      <path d="M28 16c0 2.761-4.629 5-10.098 5C12.434 21 8 18.761 8 16V7.412h20V16z" />
      <path
        className="accent"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.0097352 14.5824L0.170185915 7.41225915 18.0097352 0.24211831 35.8492845 7.41225915z"
      />
    </g>
  </Icon>
);

export default LearnSelectedIcon;
