import { createApi } from '@reduxjs/toolkit/query/react';

import { SuccessResponse, Learn } from 'shared-interfaces';
import { createBaseQuery } from '../baseQuery';

export interface LearnResponse {
  learn: Learn;
}

export const learnApi = createApi({
  reducerPath: 'learnApi',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    learnFetch: builder.query<SuccessResponse<LearnResponse>, void>({
      query: () => ({ url: 'user-learn' }),
    }),
    learnMarkAsSeen: builder.mutation<SuccessResponse<LearnResponse>, void>({
      query: () => ({
        url: 'user-learn',
        method: 'PUT',
      }),
    }),
    learnMarkAsRead: builder.mutation<SuccessResponse<LearnResponse>, number>({
      query: (articleId) => ({
        url: `user-learn/${articleId}`,
        method: 'PUT',
      }),
    }),
  }),
});

export const { useLearnFetchQuery, useLearnMarkAsSeenMutation, useLearnMarkAsReadMutation } =
  learnApi;
