import React from "react";
import { IconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const SendIcon: React.FC<IconProps> = ({
  size = theme.spacing(3),
  color = theme.palette.PRIMARY,
}) => (
  <svg width={size} height={size} viewBox="0 0 24 24">
    <path
      d="M2.348 13.727l7.09-1.14a1 1 0 0 0 0-1.974L2.198 9.449.832 2.015A1 1 0 0 1 2.262.94l20.331 10.165a1 1 0 0 1 0 1.79L2.263 23.06a1 1 0 0 1-1.431-1.075l1.516-8.258z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export default SendIcon;
