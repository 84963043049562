import React from 'react';
import moment from 'moment/moment';
import { t } from "./localise";

export type Validator<T> = (value: T) => string | null;

export interface ValidatorConfig<T> {
  handler: (value: T) => boolean;
  errorMessage: string;
}

export const NAME_REGEX = /^[a-zA-Z\u00c0-\u017e]+(([’'´,. -][a-zA-Z\u00c0-\u017e])?[a-zA-Z\u00c0-\u017e]*)*$/;
export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const PASSWORD_REGEX = /^[a-zA-Z0-9#$^\-_+=!*()@%&]{6,20}$/;
export const OTP_REGEX = /^[a-zA-Z0-9_]{6}$/;

/**
 * Creates a validator for a given type of value
 * @param validators an array of ValidatorConfig
 */
export const validate =
  <T>(validators: ValidatorConfig<T>[]): Validator<T> =>
    (value) =>
      validators.find((v) => v.handler(value))?.errorMessage ?? null;

/**
 * Hook for validation
 * @param validators
 * @param value
 */
export const useValidator = <T>(validators: ValidatorConfig<T>[], value: T): string | null => {
  const validator = React.useMemo<Validator<T>>(() => validate(validators), [validators]);
  return validator(value);
};

// VALIDATORS
export const FIRST_NAME_VALIDATOR: ValidatorConfig<string> = {
  errorMessage: t('error_validation_first_name'),
  handler: (value) => !NAME_REGEX.test(value) || value.length < 2,
};
export const LAST_NAME_VALIDATOR: ValidatorConfig<string> = {
  ...FIRST_NAME_VALIDATOR,
  errorMessage: t('error_validation_last_name'),
};
export const PASSWORD_VALIDATOR: ValidatorConfig<string> = {
  errorMessage: t('error_validation_password'),
  handler: (value) => !PASSWORD_REGEX.test(value),
};
export const CONFIRM_PASSWORD_VALIDATOR: ValidatorConfig<[string, string]> = {
  errorMessage: t('error_validation_password_confirm'),
  handler: ([original, confirm]) => original !== confirm,
};
export const EMAIL_VALIDATOR: ValidatorConfig<string> = {
  errorMessage: t('error_validation_email'),
  handler: (value) => !EMAIL_REGEX.test(value),
};
export const OTP_VALIDATOR: ValidatorConfig<string> = {
  errorMessage: t('error_validation_otp'),
  handler: (value) => !OTP_REGEX.test(value),
};
export const DATE_OF_BIRTH_VALIDATOR: ValidatorConfig<string> = {
  errorMessage: t('error_validation_age_limit'),
  handler: (value) => moment().diff(value, 'years') < 16,
};
