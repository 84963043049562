import React from 'react';
import { nanoid } from 'nanoid/non-secure';
import {
  getChatConnected,
  getToken,
  useAppDispatch,
  useAppSelector,
  setConnected as setConnectedAction,
  useChatResetConnectionMutation
} from "shared-redux";
import { usePageVisibility } from 'react-page-visibility';
import { environmentVariables } from "shared-utilities";

const CHAT_SOCKET = environmentVariables.CHAT_SOCKET;

/**
 * Enumerable describing WebSocket readyStates
 */
export enum WebSocketState {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

const { OPEN, CONNECTING } = WebSocketState;

export type WebSocketMessageCallback = (event: MessageEvent) => void;

export type WebSocketEventCallback = (event: Event) => void;

export interface SocketCallback {
  id: string;
  callback: WebSocketMessageCallback;
}

export interface SocketMessage<Body extends Record<string, any> = Record<string, any>> {
  action: string;
  body: Body;
  userId?: number;
}

/**
 * Singleton class to manage interaction with chat service
 */
class ChatManager {
  /**
   * WebSocket connection
   */
  websocket?: WebSocket | null;
  /**
   * Authentication token
   */
  token: string | null = null;
  /**
   * Queued actions to send once connected
   */
  queuedSends: SocketMessage[] = [];
  /**
   * Callbacks to be called on opening WebSocket
   */
  onOpenCallback?: (event: Event) => void;
  /**
   * Callbacks to be called on closing WebSocket
   */
  onCloseCallback?: (event: Event) => void;
  /**
   * Callbacks to be called on receiving a message
   */
  callbacks: SocketCallback[] = [];
  /**
   * Interval ping task
   */
  pingInterval: NodeJS.Timer | null = null;

  /**
   * Getter for prepared socket URL
   */
  get tokenisedUrl(): string {
    return `${CHAT_SOCKET}?token=${this.token}`;
  }

  /**
   * Setter for authentication token
   * @param token
   */
  setToken(token?: string) {
    this.token = token ?? null;
    if (this.token) this.connect();
  }

  /**
   * Set callbacks for open and close connections
   * @param open
   * @param close
   */
  setLifeCycleCallbacks({ open, close }: Record<'open' | 'close', WebSocketEventCallback>): () => void {
    this.onOpenCallback = open;
    this.onCloseCallback = close;
    return () => {
      this.onOpenCallback = undefined;
      this.onCloseCallback = undefined;
    };
  }

  /**
   * Subscribe to message events from WebSocket
   * @param callback
   */
  subscribe(callback: WebSocketMessageCallback) {
    const id = nanoid();
    this.callbacks.push({ id, callback });
    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb.id !== id);
    };
  }

  /**
   * Send message through the WebSocket connection
   * @param message
   */
  send<Body extends Record<string, unknown>>(message: SocketMessage<Body>) {
    try {
      if (this.websocket?.readyState !== OPEN) {
        this.queuedSends.push(message);
        return;
      }
      this.websocket.send(JSON.stringify(message));
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Send ping message to keep WebSocket connection alive
   */
  ping = () => {
    if (this.websocket?.readyState !== OPEN) {
      if (this.pingInterval) clearInterval(this.pingInterval);
      this.pingInterval = null;
      return;
    }
    this.send({ action: 'ping', body: {} });
  };

  /**
   * Method to be called on WebSocket open
   */
  onWebsocketOpen = (event: Event) => {
    this.onOpenCallback?.(event);
    while (this.queuedSends.length) {
      const message = this.queuedSends.shift();
      if (message) this.send(message);
    }
    this.pingInterval = setInterval(this.ping, 1000 * 60 * 5);
  };

  /**
   * Method to be called on WebSocket message received
   * @param event
   */
  onWebsocketMessage = (event: MessageEvent) => {
    this.callbacks.forEach(({ callback }) => callback(event));
  };

  /**
   * Method to be called on WebSocket closed
   */
  onWebsocketClose = (event: Event) => {
    this.onCloseCallback?.(event);
    this.websocket = null;
    if (this.pingInterval) clearInterval(this.pingInterval);
    this.pingInterval = null;
  };

  /**
   * Connect to Chat Service
   */
  connect() {
    if (!this.token) return;
    if ([CONNECTING || OPEN].includes(this.websocket?.readyState as WebSocketState)) return;

    this.disconnect();
    this.websocket = new WebSocket(this.tokenisedUrl);
    this.websocket.addEventListener('open', this.onWebsocketOpen);
    this.websocket.addEventListener('message', this.onWebsocketMessage);
    this.websocket.addEventListener('close', this.onWebsocketClose);
    this.websocket.addEventListener('error', console.error);
  }

  /**
   * Disconnect from Chat Service
   */
  disconnect() {
    this.websocket?.close();
    this.websocket = null;
  }
}

/**
 * Singleton instance
 */
const chatManager = new ChatManager();

/**
 * React Hook to automatically manage ChatManager subscriptions
 * @param callback
 */
export const useChatSubscription = (callback: WebSocketMessageCallback): void => {
  React.useEffect(() => chatManager.subscribe(callback), [callback]);
};

/**
 * React Hook to get boolean reflecting connected state
 */
export const useChatConnected = (): boolean => useAppSelector(getChatConnected);

/**
 * Container that automatically manages authentication, reconnects on
 * page focus and provides connected state via context.
 */
export const useChat = () => {
  const isAppFocused = usePageVisibility();

  // Store
  const dispatch = useAppDispatch();
  const token = useAppSelector(getToken);
  const [resetConnection] = useChatResetConnectionMutation();

  // Methods
  const reconnect = async () => {
    await resetConnection();
    chatManager.connect();
  };
  const setConnected = (connected: boolean) => dispatch(setConnectedAction(connected));

  // Effects
  React.useEffect(() => {
    chatManager.token = token ?? null;
  }, [token]);


  React.useEffect(
    () => {
      return chatManager.setLifeCycleCallbacks({
        open: () => setConnected(true),
        close: () => setConnected(false),
      });
    },
    [],
  );

  React.useEffect(() => {
    if (isAppFocused && token) {
      reconnect();
      return;
    }
    chatManager.disconnect();
  }, [isAppFocused, token]);
};

export default chatManager;
