import React from "react";
import { useNavigate } from "react-router-dom";
import { CheckinSummary } from "shared-interfaces";
import {
  useCheckinFetchQuery,
  getCheckinSummary,
  getProgress,
  useAppSelector,
  useProgressFetchQuery,
} from "shared-redux";
import { t, theme } from "shared-utilities";
import styled, { css } from "styled-components/macro";
import { ScreenElement } from "../components";
import IntroScreen from "../components/patterns/IntroScreen";
import Paper from "../components/patterns/Paper";
import CheckinCountdown from "../components/progress/CheckinCountdown";
import CheckinPrompt from "../components/progress/CheckinPrompt";
import GraphContainer from "../components/progress/GraphContainer";
import Journey from "../components/progress/Journey";
import Heading from "../components/texts/Heading";

const ScrollInner = styled.div`
  flex: 1;
  background-color: ${theme.palette.WHITE};
  padding-top: ${theme.spacingPx(2)};
  padding-bottom: ${theme.spacingPx(2)};
  align-self: stretch;
`;

const SidePadding = styled.div`
  padding-left: ${theme.spacingPx(2)};
  padding-right: ${theme.spacingPx(2)};
`;
const journeyPaperStyle = css`
  background-color: ${theme.palette.WHITE};
  margin-bottom: ${theme.spacingPx(2)};
  padding: 2px;
  border-radius: 5px;
`;

export const DashboardProgressScreen: React.FC<
  React.PropsWithChildren
> = () => {
  // Navigation
  const navigate = useNavigate();

  const {
    isLoading: isLoadingProgress,
    error: errorProgress,
    refetch: resetProgress,
  } = useProgressFetchQuery();

  // Store
  const progress = useAppSelector(getProgress);
  useCheckinFetchQuery();
  const checkinSummary = useAppSelector(getCheckinSummary);

  // State
  const [shouldDisplayPrompt, setShouldDisplayPrompt] =
    React.useState<boolean>(false);

  // Methods
  const handleShowPhase = () => navigate("ProgressPhaseDescription");

  const handleStartCheckin = () => navigate("ProgressCheckin");

  // Effects
  React.useEffect(() => {
    const timeout = setTimeout(() => setShouldDisplayPrompt(true), 1000);
    return () => clearTimeout(timeout);
  }, []);

  // Renderers
  const renderGraph = (checkin: CheckinSummary) => {
    const nextCheckin = checkin?.nextCheckinCountdown;
    if (!checkin) return null;
    return (
      <ScreenElement>
        <ScrollInner>
          {shouldDisplayPrompt && nextCheckin === 0 && (
            <CheckinPrompt onStartCheckin={handleStartCheckin} />
          )}
          <GraphContainer checkinSummary={checkin} />
          <SidePadding>
            <Paper style={journeyPaperStyle}>
              <Heading
                size="large"
                style={{ textAlign: "center", marginBottom: 0 }}
              >
                {t("progress_journey_title")}
              </Heading>
              <Journey onShowPhase={handleShowPhase} />
            </Paper>
          </SidePadding>
          {!!nextCheckin && <CheckinCountdown checkin={checkin} />}
        </ScrollInner>
      </ScreenElement>
    );
  };

  const renderState = (key: string) => (
    <IntroScreen
      illustration="man-at-whiteboard"
      title={t("progress_onboarding_header")}
      text={t(key)}
    />
  );

  const renderTab = () => {
    if (!checkinSummary) return null;
    if (!((progress?.workoutTracker?.length ?? 0) > 0))
      return renderState("progress_onboarding_after_assessment");

    return renderGraph(checkinSummary);
  };

  return renderTab();
};
