import React from "react";
import { DuotoneIconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const CloseButtonWithCircleIcon: React.FC<DuotoneIconProps> = ({
  size = theme.spacing(4.75),
  color = theme.palette.PRIMARY,
  colorTwo = theme.palette.WHITE,
}) => (
  <svg width={size} height={size}>
    <g fill="none" fillRule="evenodd">
      <circle fill={color} cx={19} cy={19} r={19} />
      <g stroke={colorTwo} strokeLinecap="round" strokeWidth={2}>
        <path d="M14.16 14.217l9.689 9.689M23.849 14.217l-9.689 9.689" />
      </g>
    </g>
  </svg>
);

export default CloseButtonWithCircleIcon;
