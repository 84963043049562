import React, { useRef, useEffect, useState } from "react";
import lottie, { AnimationItem } from "lottie-web";
import { useNavigate, useParams } from "react-router-dom";
import animationData from "shared-assets/src/animations/trophy.json";
import {
  getProgress,
  getWorkouts,
  useAppDispatch,
  useAppSelector,
} from "shared-redux";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import { Button, ButtonContainer } from "../components";
import RoundButton from "../components/buttons/RoundButton";
import Heading from "../components/texts/Heading";
import Paragraph from "../components/texts/Paragraph";
import Journey from "../components/workout/Journey";

const FullScreen = styled.div`
  position: absolute;
  display: flex;
  flex: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  margin-bottom: ${theme.spacing(2)}px;
  padding: ${theme.spacing(4)}px;
  justify-content: flex-start;
  height: 100%;
  box-sizing: border-box;

  ${ButtonContainer} {
    margin-top: auto;
  }
`;

const AnimationContainer = styled.div`
  height: 175px;
  align-self: center;
`;

export const WorkoutTrophyPageScreen = () => {
  const navigate = useNavigate();
  const params = useParams();
  const animContainerRef = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);
  const [hasClickedOnContinueOnce, setHasClickedOnContinueOnce] =
    useState(false);

  const dispatch = useAppDispatch();
  const progress = useAppSelector(getProgress);
  const workouts = useAppSelector(getWorkouts);
  const completedWorkouts = progress?.workoutTracker?.length ?? 0;

  useEffect(() => {
    if (animContainerRef.current && animContainerRef.current.innerHTML === "") {
      const anim = lottie.loadAnimation({
        animationData,
        container: animContainerRef.current,
        loop: false,
        autoplay: false,
        renderer: "svg",
      });
      anim.setSpeed(0.5);
      anim.addEventListener("DOMLoaded", () => anim.play());
      setAnimation(anim);
    }
  }, [animContainerRef]);

  const getText = () => {
    if (completedWorkouts <= 3) return t("trophy_page_txt");
    switch (progress?.phase?.phase || 1) {
      case 1:
        return t("trophy_page_txt_phase_one");
      case 2:
        return t("trophy_page_txt_phase_two");
      case 3:
        return t("trophy_page_txt_phase_three");
      case 4:
        return t("trophy_page_txt_phase_four");
      default:
        return t("trophy_page_txt_phase_one");
    }
  };

  return (
    <FullScreen>
      <Root>
        <AnimationContainer
          onClick={() => animation?.goToAndPlay(0)}
          ref={animContainerRef}
        />

        <Heading
          size="largest"
          text={t("trophy_page_title")}
          style={{
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4),
          }}
        />

        <Paragraph
          text={getText()}
          style={{
            marginBottom: theme.spacing(4),
          }}
        />
        {completedWorkouts <= 3 && (
          <Heading
            size="regular"
            text={t("trophy_page_first_three_workouts", {
              completedWorkouts,
            })}
            highlightColor={theme.palette.BACKGROUND}
          />
        )}
        {completedWorkouts > 3 && <Journey onShowPhase={() => null} />}

        <div style={{ marginTop: "auto" }}>
          <RoundButton
            text={t("btn_continue")}
            onPress={() => {
              if (!hasClickedOnContinueOnce) {
                // animation?.goToAndPlay(0);
                setHasClickedOnContinueOnce(true);
              }
              // set timeout to see animation
              // setTimeout(() => {
              navigate(
                `/workout/${params.workoutType}/feedback`,
                {
                  replace: true,
                  // });
                }
                // 500
              );
            }}
          />
        </div>
      </Root>
    </FullScreen>
  );
};
