import React from "react";
import {
  useLearnFetchQuery,
  useLearnMarkAsSeenMutation,
  getArticleBadgeCount,
  getArticles,
  getNextArticleCountdown,
  getRemainingArticles,
  useAppSelector,
} from "shared-redux";
import styled from "styled-components/macro";
import { LearnCountdown, ScreenElement } from "../components";
import { ArticleCell } from "../components/learn/ArticleCell";

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export const DashboardLearnScreen = () => {
  // Store
  const articles = useAppSelector(getArticles);

  const learnBadge = useAppSelector(getArticleBadgeCount);
  const remainingArticles = useAppSelector(getRemainingArticles);
  const nextArticleCountdown = useAppSelector(getNextArticleCountdown);
  const { isLoading: isLoadingLearn, refetch } = useLearnFetchQuery();
  const [dispatchLearnSeen, { isLoading, isSuccess, reset }] =
    useLearnMarkAsSeenMutation();

  // Effects
  // TODO handle focus or something
  React.useEffect(() => {
    // if (!isFocused) return;
    refetch();
  }, []);

  React.useEffect(() => {
    if (!learnBadge) return;
    dispatchLearnSeen();
  }, [dispatchLearnSeen, learnBadge]);

  React.useEffect(() => {
    if (!isSuccess) return;
    refetch();
    reset();
  }, [isSuccess, refetch, reset]);

  return (
    <Root>
      {articles.map((article, index) => (
        <ArticleCell
          key={article.id}
          article={article}
          newest={Boolean(index)}
        />
      ))}
      <LearnCountdown
        remainingArticles={remainingArticles}
        nextArticleCountdown={nextArticleCountdown}
      />
    </Root>
  );
};
