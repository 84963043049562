import React from "react";
import { Outlet } from "react-router-dom";
import { getToken, useAppSelector } from "shared-redux";
import InvalidTokenScreen from "../../screens/InvalidTokenScreen";

const AuthCheck: React.FC = () => {
  const token = useAppSelector(getToken);
  if (!token) return <InvalidTokenScreen />;
  return <Outlet />;
};

export default AuthCheck;
