import { theme } from "shared-utilities";
import styled from "styled-components";

const Modal = styled.label`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background: rgba(0, 0, 0, 0.5);
  mix-blend-mode: normal;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalWrapper = styled.div`
  background: #fff;
  max-width: 320px;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    background: ${theme.palette.BUTTON_PRIMARY};
    border-radius: 27px;
    border: none;
    width: 100%;
    height: 54px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #ffffff;
  }
`;

const ModalText = styled.p`
  font-family: ${theme.typography.FONT_NAME};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${theme.palette.PRIMARY};
  max-width: 100%;

  span {
    color: ${theme.palette.ACCENT_PRIMARY};
  }
`;

export type IInstructionModal = {
  closeModal: () => void;
};

export const InstructionModal: React.FC<IInstructionModal> = ({
  closeModal,
}) => (
  <Modal>
    <ModalWrapper>
      <ModalText>
        Our team are on hand to answer your queries about the Ascenti Reach app,
        including how we process your data and information about other treatment
        options available to you. Email us at <span>reach@ascenti.co.uk</span>{" "}
        to get in touch.
      </ModalText>
      <button type="button" onClick={closeModal}>
        Go back
      </button>
    </ModalWrapper>
  </Modal>
);
