import React from "react";
import { ChatMessage } from "shared-interfaces";
import { theme } from "shared-utilities";
import { css } from "styled-components";
import styled from "styled-components/macro";
import CheckIcon from "../../assets/svg/icons/Check";

const ChatBubble = styled.div({
  padding: "10px 16px",
  backgroundColor: theme.palette.BACKGROUND,
  borderRadius: 4,
});
const topBubbleStyle = css({
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
});
const bottomBubbleStyle = css({
  borderBottomLeftRadius: 16,
  borderBottomRightRadius: 16,
});
const otherBubbleStyle = css({
  borderTopLeftRadius: 16,
  borderBottomLeftRadius: 16,
});
const ownBubbleStyle = css({
  backgroundColor: theme.palette.PINK,
  borderTopRightRadius: 16,
  borderBottomRightRadius: 16,
});
const IndicatorBlock = styled.div({
  // display: "flex",
  width: 16,
  flexShrink: 0,
  justifyContent: "flex-end",
});
const Text = styled.div({
  overflow: "hidden",
  textOverflow: "ellipsis",
});

interface MessageProps {
  message: ChatMessage;
  isOwnMessage: boolean;
  isFirstMessage: boolean;
  isLastMessage: boolean;
  showIndicator?: boolean;
}

const Message: React.FC<MessageProps> = ({
  message,
  isFirstMessage,
  isLastMessage,
  isOwnMessage,
  showIndicator,
}) => {
  // Computed Variables
  const paperStyle = React.useMemo(() => {
    if (isOwnMessage) return { justifySelf: "flex-start" };
    return { marginLeft: "auto" };
  }, [isOwnMessage]);

  function replacer(matched: string) {
    return `!!${matched.replace("http://", "").replace("https://", "")}!!`;
  }

  const parseURLs = (message: string) => {
    const linkRegex = /(https?:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
    const matches = message.replace(linkRegex, replacer);

    return matches.split("!!").map((el: string) => {
      if (linkRegex.test(el)) {
        return (
          <a
            href={`http://${el}`}
            target="_blank"
            rel="noreferrer"
            style={{
              color: isOwnMessage ? "#fff" : theme.palette.PINK,
              textDecoration: "none",
            }}
          >
            {el}
          </a>
        );
      }
      return el;
    });
  };

  // Renderer
  const renderIndicator = React.useMemo(() => {
    if (!showIndicator) return null;
    return (
      <CheckIcon
        size={12}
        color={
          theme.palette[isLastMessage && !message.id ? "LIGHTER_GREY" : "GREY"]
        }
        style={{ height: 16, width: 16, alignSelf: "flex-end", marginLeft: 8 }}
      />
    );
  }, [showIndicator, isLastMessage, message]);

  return (
    <div style={{ flexDirection: "row" }}>
      <ChatBubble
        css={[
          isFirstMessage && topBubbleStyle,
          isLastMessage && bottomBubbleStyle,
          paperStyle,
          isOwnMessage ? ownBubbleStyle : otherBubbleStyle,
        ]}
      >
        <Text
          css={[
            theme.typography[theme.typography.FONT_NAME].SMALL_TEXT,
            {
              color: theme.palette[isOwnMessage ? "WHITE" : "BLACK"],
            },
          ]}
        >
          {parseURLs(message.message)}
        </Text>
      </ChatBubble>
    </div>
  );
};

export default Message;
