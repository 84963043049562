import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const BookIcon: React.FC<SvgProps & IconProps> = ({
  size = theme.spacing(3),
  color = theme.palette.PRIMARY,
  ...props
}) => (
  <Svg width={size * 1.2} height={size} viewBox="0 0 20 24" {...props}>
    <G fill={color} fillRule="nonzero" stroke="none" strokeWidth={1}>
      <Path
        d="M18.925-.118a.75.75 0 01.743.648l.007.101v15.892a.75.75 0 01-.648.743l-.102.007H3.38a.75.75 0 01-.102-1.494l.102-.006 14.794-.001V.632a.75.75 0 01.649-.744l.102-.006zM18.925 20.682a.75.75 0 01.102 1.493l-.102.007H3.38a.75.75 0 01-.102-1.493l.102-.007h15.545z"
        transform="translate(-6 -4) translate(6 5)"
      />
      <Path
        d="M18.925-.219a.75.75 0 01.102 1.493l-.102.007H3.38a.75.75 0 01-.102-1.493l.102-.007h15.545zM3.38 15.773a.75.75 0 110 1.5 1.705 1.705 0 100 3.409.75.75 0 110 1.5 3.205 3.205 0 010-6.41z"
        transform="translate(-6 -4) translate(6 5)"
      />
      <Path
        d="M3.38-.227a.75.75 0 010 1.5c-.892 0-1.624.684-1.699 1.557l-.006.147v16.455a.75.75 0 01-1.493.101l-.007-.101V2.977A3.204 3.204 0 013.38-.227zM16.925 16.682a.75.75 0 01.743.648l.007.102v4a.75.75 0 01-1.493.101l-.007-.101v-4a.75.75 0 01.75-.75z"
        transform="translate(-6 -4) translate(6 5)"
      />
    </G>
  </Svg>
);

export default BookIcon;
