import React from "react";
import { ReactComponent as FemaleIcon } from "shared-assets/src/icons/female.svg";
import { ReactComponent as MaleIcon } from "shared-assets/src/icons/male.svg";
import { theme } from "shared-utilities";
import styled from "styled-components";
import Heading from "../../texts/Heading";

type TGenderCardProps = {
  isActive: boolean;
};

type TGenderQuestionViewProps = {
  isPregnant: boolean;
  activeGender: number | null;
  setIsPregnant: (isPregnant: boolean) => void;
  updateActiveGender: (activeGender: number | null) => void;
};

const GenderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GenderCard = styled.div<TGenderCardProps>`
  padding: 32px 36px 25px;
  background: #e7eef2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  background: ${({ isActive }) => (isActive ? "#D0DEE5" : "#e7eef2")};
`;

const GenderCardWrapper = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GenderButton = styled.div<TGenderCardProps>`
  width: 100%;
  border-radius: 4px;
  display: flex;
  height: 54px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: "-0.2";
  color: "#145e7f";
  background: ${({ isActive }) => (isActive ? "#D0DEE5" : "#e7eef2")};
`;

const GenderQuestionView: React.FC<TGenderQuestionViewProps> = ({
  activeGender,
  updateActiveGender,
  isPregnant,
  setIsPregnant,
}) => {
  const isMale = activeGender === 1;
  const isFemale = activeGender === 2;

  return (
    <>
      <GenderContainer>
        <Heading
          size="medium"
          text="Are you male or female"
          marginBottom="40px"
          style={{ lineHeight: theme.spacingPx(2), marginTop: 60 }}
        />
        <GenderCardWrapper>
          <GenderCard isActive={isMale} onClick={() => updateActiveGender(1)}>
            <MaleIcon />
            <Heading
              text="Male"
              size="regular"
              marginBottom="0px"
              style={{ marginTop: 16 }}
            />
          </GenderCard>
          <GenderCard isActive={isFemale} onClick={() => updateActiveGender(2)}>
            <FemaleIcon />
            <Heading
              text="Female"
              size="regular"
              marginBottom="0px"
              style={{ marginTop: 16 }}
            />
          </GenderCard>
        </GenderCardWrapper>
      </GenderContainer>
      {isFemale && (
        <div>
          <Heading
            text="Are you currently pregnant or within three months of having given
            birth?"
            size="medium"
            style={{ paddingTop: 20, paddingBottom: 20 }}
          />
          <GenderCardWrapper>
            <GenderButton
              isActive={!isPregnant}
              onClick={() => setIsPregnant(false)}
            >
              <Heading text="No" size="regular" marginBottom="0px" />
            </GenderButton>
            <GenderButton
              isActive={isPregnant}
              onClick={() => setIsPregnant(true)}
            >
              <Heading text="Yes" size="regular" marginBottom="0px" />
            </GenderButton>
          </GenderCardWrapper>
        </div>
      )}
    </>
  );
};

export default GenderQuestionView;
