import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const FloorSpaceIcon: React.FC<SvgProps & IconProps> = ({
  size = theme.spacing(1.625),
  color = theme.palette.PRIMARY,
  ...props
}) => (
  <Svg width={size * 2.3076923076923075} height={size} viewBox="0 0 30 13" {...props}>
    <G fill={color} fillRule="nonzero" stroke="none" strokeWidth={1}>
      <Path
        d="M26.809 9.305a.85.85 0 011.111.116l.073.09.702 1a.85.85 0 01-.589 1.333L28 11.85h-2a.85.85 0 01-.107-1.693L26 10.15h.462l-.012-.08a.85.85 0 01.269-.693l.09-.072zM23 10.15h-2a.85.85 0 000 1.7h2a.85.85 0 000-1.7zm-7 0h-2a.85.85 0 000 1.7h2a.85.85 0 000-1.7zm-7 0H7a.85.85 0 000 1.7h2a.85.85 0 000-1.7zM.007 9.511a.85.85 0 011.543.56l-.013.079H2a.85.85 0 01.843.743L2.85 11a.85.85 0 01-.743.843L2 11.85H0a.85.85 0 01-.751-1.248l.056-.09.702-1zM2.76 7.38l1.273-2.545a.85.85 0 10-1.52-.76L1.24 6.62a.85.85 0 001.52.76zM6-.85A.85.85 0 016.107.843L6 .85h-.965l-.28.542a.85.85 0 01-1.049.406l-.098-.044A.85.85 0 013.202.706l.044-.098.519-1a.85.85 0 01.637-.45l.117-.008H6zM8 .85h3.18a.85.85 0 000-1.7H8a.85.85 0 100 1.7zm6.36 0h3.18a.85.85 0 000-1.7h-3.18a.85.85 0 000 1.7zM23.48-.85a.85.85 0 01.694.358l.061.1.52 1a.85.85 0 01-1.454.875l-.055-.091-.282-.542H22a.85.85 0 01-.843-.743L21.15 0a.85.85 0 01.743-.843L22-.85h1.48zM24.24 4.38l1.272 2.545a.85.85 0 101.52-.76L25.76 3.62a.85.85 0 10-1.52.76z"
        transform="translate(-1 -10) translate(2 11)"
      />
    </G>
  </Svg>
);

export default FloorSpaceIcon;
