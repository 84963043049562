import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const UnmuteIcon: React.FC<IconProps> = ({ size = theme.spacing(2.25), color = '#919191' }) => (
  <Svg width={size} height={size} viewBox="0 0 18 18">
    <G fill="none" fillRule="evenodd">
      <Path d="M0 0h18v18H0z" />
      <Path stroke={color} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" d="M4.13 12.99h-3V5h3" />
      <Path stroke={color} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" d="M4 5l7-4v16l-7-4" />
      <G stroke={color} strokeLinecap="round" strokeWidth={1.5}>
        <Path d="M14 8l3 3M17 8l-3 3" />
      </G>
    </G>
  </Svg>
);

export default UnmuteIcon;
