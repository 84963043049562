import React from "react";
import { IconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const AppleIcon: React.FC<IconProps> = ({
  size = theme.spacing(2.25),
  color = theme.palette.WHITE,
  ...props
}) => (
  <svg
    width={size * 0.8333333333333334}
    height={size}
    viewBox="0 0 15 18"
    {...props}
  >
    <g
      d="M94.54 27.584l.001-.072c-.008-1.475.654-2.588 1.993-3.408-.75-1.08-1.881-1.676-3.376-1.792-1.414-.113-2.96.832-3.526.832-.598 0-1.97-.792-3.045-.792C84.363 22.388 82 24.14 82 27.705c0 1.053.191 2.14.574 3.263.51 1.475 2.351 5.092 4.272 5.031 1.004-.024 1.713-.72 3.02-.72 1.268 0 1.925.72 3.045.72 1.937-.028 3.603-3.315 4.089-4.794-2.369-1.125-2.462-3.205-2.46-3.621zm-2.254-6.67c-.781.92-1.73 1.458-2.77 1.378-.088-1.11.323-2.094 1.02-2.89.634-.743 1.746-1.346 2.706-1.402l.014.215c.023.536-.03 1.574-.97 2.698z"
      fill={color}
      fillRule="nonzero"
      transform="translate(-82 -18)"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);

export default AppleIcon;
