import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

declare global {
  // State Interface
  interface MiscellaneousState {
    mutedState: boolean;
  }
}

// Initial State
const initialState: MiscellaneousState = {
  mutedState: false,
};

// Slice
export const miscellaneousSlice = createSlice({
  name: 'miscellaneous',
  initialState,
  reducers: {
    setMuted: (state, action: PayloadAction<boolean>) => {
      state.mutedState = action.payload;
    },
  },
});

// Actions
export const { setMuted } = miscellaneousSlice.actions;

// Selectors
export const getMuted = (state: RootState): boolean => state.miscellaneous.mutedState;

// Reducer
export default miscellaneousSlice.reducer;
