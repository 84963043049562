import { scaledNumber } from "./common";

export interface ThemeUtilities {
  SCALE: number;
  BORDER_RADIUS: number;
  BORDER_RADIUS_MD: number;
  BORDER_RADIUS_LG: number;
}

export const utilities: ThemeUtilities = {
  // SCALE: 1,
  // SCALE: scaledNumber({ small: 0.7, normal: 1, large: 1.3 }),
  SCALE: scaledNumber({ small: 1.3, normal: 1.6, large: 1.9 }),
  BORDER_RADIUS: 5,
  BORDER_RADIUS_MD: 10,
  BORDER_RADIUS_LG: 20,
};
