import React, { CSSProperties } from "react";
import Mute from "shared-assets/src/svg/icons/Mute";
import Unmute from "shared-assets/src/svg/icons/Unmute";
// import ProgressBar from "@components/assessment/elements/ProgressBar";
import { WorkoutSummaryRow, WorkoutWithInfo } from "shared-interfaces";
import { t, theme } from "shared-utilities";
import SmallButton from "src/components/buttons/SmallButton";
import Heading from "src/components/texts/Heading";
import styled from "styled-components";
import CloseButton from "../../assets/svg/buttons/CloseButton";
import RoundButton from "../buttons/RoundButton";
import { TopInfoBar } from "../layout/navigation/TopInfoBar";
import Timer from "./Timer";
import UpNext from "./UpNext";

// TODO: replace with components
const View = "div";
const TouchableOpacity = styled.div<{ activeOpacity?: number }>`
  :active {
    opacity: ${(props) => props.activeOpacity || 1};
  }
`;

const styles: Record<string, CSSProperties> = {
  topContainer: {
    display: "flex",
    alignSelf: "stretch",
    paddingLeft: "10px",
    paddingRight: "0px",
    paddingTop: "10px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  muteButton: {
    display: "flex",
    height: "35px",
    width: "35px",
    alignItems: "center",
    justifyContent: "center",
  },
  progressContainer: {
    marginTop: "10px",
    backgroundColor: theme.palette.LIGHTEST_GREY,
    alignSelf: "stretch",
    height: 2,
    flexDirection: "row",
    marginLeft: "15px",
    marginRight: "15px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
};

interface RestTimerProps {
  onDone: (value: number) => void;
  onMute: () => void;
  onShowCancelModal: () => void;
  onShowDetails: (script: WorkoutSummaryRow) => void;
  seconds: number;
  workout: WorkoutWithInfo;
  index: number;
  muted: boolean;
  paused: boolean;
}

const RestTimer: React.FC<RestTimerProps> = ({
  onDone,
  onMute,
  onShowCancelModal,
  onShowDetails,
  seconds,
  workout,
  index,
  muted,
  paused,
}) => {
  // Methods
  const handleTimerDone = () => onDone(seconds);

  return (
    <View style={{ flex: 1, marginBottom: 15 }}>
      <TopInfoBar
        title={t("insession_rest_title")}
        leftButton={
          <TouchableOpacity
            activeOpacity={0.8}
            onClick={onMute}
            // style={styles.muteButton}
          >
            {muted ? <Unmute /> : <Mute />}
          </TouchableOpacity>
        }
        rightButton={<CloseButton />}
        rightButtonOnClick={onShowCancelModal}
      />
      <View style={{ padding: "56px 15px 15px" }}>
        {/* <View style={styles.progressContainer}>
          <ProgressBar
            progress={index ? index / (workout.workoutSummary?.length ?? 1) : 0}
            // background={theme.palette.WHITE}
            // height={8}
            initial={Math.max(
              0,
              index ? (index - 1) / (workout.workoutSummary?.length ?? 1) : 0
            )}
          />
        </View> */}
      </View>
      <View style={styles.content}>
        <Heading size="larger" text={t("insession_rest_txt")} />
        <Timer
          seconds={seconds}
          onFinish={handleTimerDone}
          fontSize="biggest"
          paused={paused}
        />
        <View style={{ width: 151, marginTop: 15 }}>
          <RoundButton
            text={t("btn_skip")}
            backgroundColor={theme.palette.BACKGROUND}
            textColor={theme.palette.PRIMARY}
            onPress={handleTimerDone}
          />
        </View>
      </View>
      {workout?.workoutSummary?.length && (
        <UpNext
          headerText={t("insession_up_next")}
          exercise={workout.workoutSummary[index]}
          workoutSummary={workout.workoutSummary}
          onDetailsClicked={() =>
            onShowDetails((workout.workoutSummary ?? [])[index])
          }
        />
      )}
    </View>
  );
};

export default RestTimer;
