import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getUser, useAppSelector } from "shared-redux";
import { t } from "shared-utilities";
import CoachSelectedIcon from "src/assets/svg/navbar/Coach/Selected";
import LearnSelectedIcon from "src/assets/svg/navbar/Learn/Selected";
import ProfileSelectedIcon from "src/assets/svg/navbar/Profile/Selected";
import ProgressSelectedIcon from "src/assets/svg/navbar/Progress/Selected";
import TodaySelectedIcon from "src/assets/svg/navbar/Today/Selected";
import useCurrentPath from "../../utilities/useCurrentPath";
import { BottomNavigation } from "./navigation/BottomNavigation";
import { BottomNavigationAction } from "./navigation/BottomNavigationAction";
import { TopInfoBar } from "./navigation/TopInfoBar";

export type WithChildren = {
  children?: React.ReactNode;
};

interface TabBarIcon {
  focused?: boolean;
  color?: string;
  size?: number;
}

export const renderDashboardWorkout = ({ focused, color }: TabBarIcon) => {
  return <TodaySelectedIcon color={color} size={45} focused={focused} />;
};
export const renderDashboardLearn = ({ focused, color }: TabBarIcon) => {
  return <LearnSelectedIcon color={color} size={40} focused={focused} />;
};
export const renderDashboardChat = ({ focused, color }: TabBarIcon) => {
  return <CoachSelectedIcon color={color} size={25} focused={focused} />;
};
export const renderDashboardProgress = ({ focused, color }: TabBarIcon) => {
  return <ProgressSelectedIcon color={color} size={28} focused={focused} />;
};
export const renderDashboardSettings = ({ focused, color }: TabBarIcon) => {
  return <ProfileSelectedIcon color={color} size={28} focused={focused} />;
};

const LINKS = (dashboardTitle?: string) => {
  return [
    {
      label: "Workout",
      icon: renderDashboardWorkout,
      href: "/",
      title: dashboardTitle ?? "Workout",
    },
    {
      label: "Learn",
      icon: renderDashboardLearn,
      href: "/learn",
      title: "Learn",
    },
    {
      label: "Chat",
      icon: renderDashboardChat,
      href: "/chat",
      title: "Chat",
    },
    {
      label: "Progress",
      icon: renderDashboardProgress,
      href: "/progress",
      title: "Progress",
    },
    {
      label: "Profile",
      icon: renderDashboardSettings,
      href: "/profile",
      title: "Profile",
    },
  ];
};

const BaseLayout: React.FC = () => {
  const navigate = useNavigate();

  const currentPath = useCurrentPath(
    LINKS().map((link) => ({
      path: link.href,
    }))
  );

  // TODO fix nested routes
  const currentRouteIndexValue = React.useMemo(() => {
    return LINKS().findIndex((link) => link.href === currentPath);
  }, [currentPath]);

  const [value, setValue] = React.useState<number>(currentRouteIndexValue);
  const ref = React.useRef<HTMLDivElement>(null);

  // Store
  const user = useAppSelector(getUser);
  // const isSubscribed = useAppSelector(getIsPaidUp);
  // const checkinSummary = useAppSelector(getCheckinSummary);
  // const learnBadgeCount = useAppSelector(getArticleBadgeCount);
  // const chatBadgeCount = useAppSelector(getChatBadgeCount);

  // Computed Variables
  const dashboardWorkoutOptions = React.useMemo(() => {
    const { firstName: name } = user ?? {};
    if (!user) return t("tab_workout");
    if (name) return t("common_hello", { name });
    return t("common_hello_alternative");
  }, [user]);

  // methods
  const renderList = () => {
    return LINKS().map((link, index) => (
      <BottomNavigationAction
        key={link.label}
        label={link.label}
        icon={link.icon({ focused: value === index })}
      />
    ));
  };

  return (
    <div ref={ref}>
      <TopInfoBar title={LINKS(dashboardWorkoutOptions)[value]?.title} />
      <div style={{ paddingTop: 50 }} />
      {/* {children} */}
      <Outlet />
      <div style={{ paddingTop: 50 }} />
      <BottomNavigation
        showLabels
        value={value}
        onChange={(_: any, newValue: number) => {
          navigate(LINKS()[newValue].href);
          setValue(newValue);
        }}
      >
        {renderList()}
      </BottomNavigation>
    </div>
  );
};

export default BaseLayout;
