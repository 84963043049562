import { theme } from "shared-utilities";
import styled from "styled-components";

const Icon = styled.svg<{ focused?: boolean }>`
  transition: 0.3s ease;
  position: relative;
  top: 3px;
  transform: ${(props) => (props.focused ? "scale(1.2)" : "scale(1)")};

  .accent {
    stroke: ${(props) =>
      props.focused ? theme.palette.ACCENT_PRIMARY : theme.palette.PRIMARY};
  }
`;

const CoachSelectedIcon: React.FC<any> = ({
  size = theme.spacing(3),
  color = theme.palette.PRIMARY,
  focused = false,
  ...props
}) => (
  <Icon
    transform="scale(1.2)"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    focused={focused}
    {...props}
  >
    <g
      transform="translate(-26 -13) translate(27 14)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M20 0a2 2 0 012 2v15a2 2 0 01-2 2h-7l-2 4-2-4H2a2 2 0 01-2-2V2a2 2 0 012-2h18z"
        stroke={color}
      />
      <path className="accent" d="M4.5 5.5L15.8219866 5.5" />
      <path className="accent" d="M4.5 9.5L10.1609933 9.5" />
    </g>
  </Icon>
);

export default CoachSelectedIcon;
