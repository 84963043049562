import React from "react";
import lottie, { AnimationItem } from "lottie-web";
import animationData from "shared-assets/src/animations/checking_spinner.json";
import styled from "styled-components";

const AnimationContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const AnimationLoader = () => {
  const animContainerRef = React.useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = React.useState<AnimationItem | null>(null);

  React.useEffect(() => {
    if (animContainerRef.current && animContainerRef.current.innerHTML === "") {
      const anim = lottie.loadAnimation({
        animationData,
        container: animContainerRef.current,
        loop: false,
        autoplay: false,
        renderer: "svg",
      });
      anim.setSpeed(0.5);
      anim.addEventListener("DOMLoaded", () => anim.play());
      setAnimation(anim);
    }
  }, [animContainerRef]);

  return (
    <AnimationContainer>
      <AnimationContainer
        onClick={() => animation?.goToAndPlay(0)}
        ref={animContainerRef}
      />
    </AnimationContainer>
  );
};
