import { useNavigate } from "react-router-dom";
import { theme } from "shared-utilities";
import HandWithPhone from "src/assets/hand-with-phone.svg";
import { Button, ScreenElement } from "src/components";
import styled from "styled-components/macro";

const Image = styled.div`
  position: absolute;
  width: 271px;
  height: 250px;
  left: 50px;
  top: 40px;
  background: url(${HandWithPhone}) center/contain no-repeat;
`;

const Text = styled.div`
  width: 308px;
  height: 168px;
  margin-top: 315px;
  margin-bottom: 94px;
  fontFamily: ${theme.typography.FONT_NAME},
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #145e7f;

  span {
    color: #ff6b3f;
    font-weight: 700;
    cursor: pointer;
  }
`;

export const AssessmentAgreementScreen = () => {
  const navigate = useNavigate();
  const goToSymptomsScreen = () => navigate("/instructions");

  return (
    <ScreenElement>
      <Image />
      <Text>
        Before continuing, please confirm: that you have read and agree
        to&nbsp;our <span>instructions for use</span>.<br />
        <br />
        These are designed to help you safely use the app and avoid making your
        injury or condition worse.
      </Text>
      <Button handleClick={goToSymptomsScreen} bgColor="#145E7F" isBoldText>
        Confirm and continue
      </Button>
    </ScreenElement>
  );
};
