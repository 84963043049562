import { createSlice } from '@reduxjs/toolkit';
import { ChatMessage } from 'shared-interfaces';
import { isPhysioMessage } from 'shared-utilities';
import { chatApi } from '../api';
import { RootState } from '../store';
import { logout } from './auth';
// import * as Notifications from 'expo-notifications';

// State Interface
export interface ChatState {
  conversation: ChatMessage[];
  typingIndicators: string[];
  onlineStatus: Record<string, boolean>;
  connected: boolean;
  latestSeenMessage: number | null;
}

// Initial State
export const initialConversationState: ChatState = {
  conversation: [],
  typingIndicators: [],
  onlineStatus: {},
  connected: false,
  latestSeenMessage: null,
};

// Slice
export const chatSlice = createSlice({
  name: 'chat',
  initialState: initialConversationState,
  reducers: {
    addTemporaryMessage(state, action) {
      const { message } = action.payload;
      state.conversation = [...state.conversation, message];
    },
    addMessage(state, action) {
      const message = action.payload;
      if (state.conversation.some((m) => m.tempId === message.tempId)) {
        state.conversation = state.conversation.map((m) => {
          if (m.tempId === message.tempId) return message;
          return m;
        });
        return;
      }
      state.conversation = [...state.conversation, message];
    },
    setTypingIndicator(state, action) {
      const { userId, isTyping } = action.payload;
      if (!isTyping) {
        state.typingIndicators = state.typingIndicators.filter((id) => id !== userId);
        return;
      }
      state.typingIndicators = [...state.typingIndicators, userId];
    },
    setOnline(state, action) {
      const { userId, online } = action.payload;
      state.onlineStatus[userId] = online;
    },
    setConnected(state, action) {
      state.connected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.conversation = [];
      state.typingIndicators = [];
      state.onlineStatus = {};
    });
    builder.addMatcher(chatApi.endpoints.chatGetConversation.matchFulfilled, (state, action) => {
      const { messages } = action.payload;
      state.conversation = messages;
    });
    builder.addMatcher(chatApi.endpoints.chatReadMessages.matchFulfilled, (state, action) => {
      state.latestSeenMessage =
        [...state.conversation].reverse().find((message) => isPhysioMessage(message))?.id ?? null;
      // Notifications.setBadgeCountAsync(0); // TODO
    });
    builder.addMatcher(chatApi.endpoints.sendMessage.matchFulfilled, (state, action) => {
      const message = action.payload;
      if (state.conversation.some((m) => m.tempId === message.tempId)) {
        state.conversation = state.conversation.map((m) => {
          if (m.tempId === message.tempId) return message;
          return m;
        });
        return;
      }
      state.conversation = [...state.conversation, message];
    });
  },
});

// Actions
export const { addMessage, addTemporaryMessage, setTypingIndicator, setOnline, setConnected } =
  chatSlice.actions;

// Selectors
export const getChatConnected = (state: RootState) => state.chat.connected;
export const getConversation = (state: RootState): ChatMessage[] => state.chat.conversation;
export const getTypingState = (state: RootState): string[] => state.chat.typingIndicators ?? [];
export const getChatBadgeCount = (state: RootState): number | null => {
  const { user } = state.user;
  const { latestSeenMessage, conversation } = state.chat;
  if (!user) return null;
  const physioMessages = [...conversation].reverse().filter(isPhysioMessage);
  if (latestSeenMessage)
    return Math.max(
      physioMessages.findIndex((message) => message.id === latestSeenMessage),
      0
    );
  return physioMessages.filter((message) => !message.readAt).length;
};

// Reducer
export default chatSlice.reducer;
