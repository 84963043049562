import { nanoid } from "nanoid/non-secure";

export const DEVICE_IDENTIFIER_KEY = "deviceIdentifier";

/**
 * Singleton class to manage device ID
 */
class DeviceManager {
  // private loading = false;
  private identifier: string | null = null;
  // private callbacks: Array<(value: string) => void> = [];

  constructor() {
    this.identifier = localStorage.getItem(DEVICE_IDENTIFIER_KEY);
  }

  /**
   * Get key either from local storage, or generate one,
   * forcing all calls to wait for the first to finish
   */
  public getKey(): string {
    if (this.identifier) return this.identifier;
    // if (this.loading)
    //   return new Promise((resolve) => {
    //     this.callbacks.push(resolve);
    //   });
    const existingDeviceId = localStorage.getItem(DEVICE_IDENTIFIER_KEY);
    const deviceId = existingDeviceId ?? nanoid(12);
    if (!existingDeviceId)
      localStorage.setItem(DEVICE_IDENTIFIER_KEY, deviceId);
    this.identifier = deviceId;
    // if (this.callbacks.length) {
    //   this.callbacks.forEach((cb) => cb(deviceId));
    //   this.callbacks = [];
    // }
    return deviceId;
  }
}

export default new DeviceManager();
