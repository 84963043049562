import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const BandIcon: React.FC<SvgProps & IconProps> = ({
  size = theme.spacing(3),
  color = theme.palette.PRIMARY,
  ...props
}) => (
  <Svg width={size * 1.0833333333333333} height={size} viewBox="0 0 26 24" {...props}>
    <Path
      d="M16.414 1l6.794 9.422a5.455 5.455 0 0 1 1.251 4.018 5.457 5.457 0 0 1-1.957 3.727 5.456 5.456 0 0 1-4.019 1.252 5.465 5.465 0 0 1-3.727-1.958c0-.001 0-.002-.002-.003L7.717 9.01c-.01-.013-.017-.028-.028-.041-.01-.013-.025-.023-.037-.036a1.489 1.489 0 0 0-1.016-.534 1.502 1.502 0 0 0-1.289 2.454c.011.013.018.028.028.042.012.012.025.022.036.035l5.898 7.94-2.177 4.041-6.794-9.421c-.01-.013-.018-.029-.028-.042-.012-.013-.025-.022-.036-.035a5.463 5.463 0 0 1-1.25-4.019A5.462 5.462 0 0 1 2.98 5.667 5.467 5.467 0 0 1 7 4.415a5.467 5.467 0 0 1 3.726 1.958c.011.014.018.029.03.043.01.012.023.021.034.034l7.038 8.449.003.003c.256.307.616.497 1.015.534a1.501 1.501 0 0 0 1.289-2.455L14.31 4.87 16.414 1z"
      stroke={color}
      strokeWidth={1.7}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default BandIcon;
