import { scaledNumber } from "./common";

export function spacing(value: number): number;
export function spacing(topBottom: number, leftRight: number): [number, number];
export function spacing(
  top: number,
  leftRight: number,
  bottom: number
): [number, number, number];
export function spacing(
  top: number,
  left: number,
  bottom: number,
  right: number
): [number, number, number, number];

export function spacing(...values: number[]) {
  const [first, ...rest] = values;
  const size = scaledNumber({ small: 6, normal: 8, large: 10 });
  if (!rest.length) return first * size;
  return values.map((v) => v * size);
}

// spacing wrapper to return px values
export function spacingPx(value: number): string;
export function spacingPx(
  topBottom: number,
  leftRight: number
): [string, string];
export function spacingPx(
  top: number,
  leftRight: number,
  bottom: number
): [string, string, string];
export function spacingPx(
  top: number,
  left: number,
  bottom: number,
  right: number
): [string, string, string, string];

export function spacingPx(...values: number[]) {
  const [first, ...rest] = values;
  const size = scaledNumber({ small: 6, normal: 8, large: 10 });
  if (!rest.length) return `${first * size}px`;
  return values.map((v) => `${v * size}px`);
}
