import moment from "moment";
import { Progress, WorkoutTracker } from "shared-interfaces";
import { getProgress, useAppSelector } from "shared-redux";
import { t, completedWorkouts } from "shared-utilities";
import styled from "styled-components";
import Target from "./Target";

const WEEKDAYS = [
  t("common_weekday_shortened_mon"),
  t("common_weekday_shortened_tue"),
  t("common_weekday_shortened_wed"),
  t("common_weekday_shortened_thur"),
  t("common_weekday_shortened_fri"),
  t("common_weekday_shortened_sat"),
  t("common_weekday_shortened_sun"),
];

const Root = styled.div`
  // maxWidth: Theme.MAX_WIDTH,
  text-align: center;
  display: flex;
  width: 100vw;
  padding-top: 15px;
  justify-content: space-between;
  align-self: center;
`;

const workoutPredicate =
  (completed: WorkoutTracker[]) => (_: any, index: number) =>
    completed.some((workout) => moment(workout.ended).weekday() === index);

const WeeklyProgress: React.FC<React.PropsWithChildren> = () => {
  const progress = useAppSelector(getProgress);
  // Renderers
  const renderTargets = (progress: Progress) => {
    const workoutProgress = new Array(7).fill(false);
    const startWeek = moment().weekday(0).startOf("day");
    const endWeek = moment().weekday(6).endOf("day");
    const completed = completedWorkouts(progress, startWeek, endWeek);

    return workoutProgress
      .map(workoutPredicate(completed))
      .map((completed, index) => (
        <Target
          reached={completed}
          key={index}
          dayKey={index}
          day={WEEKDAYS[index]}
        />
      ));
  };

  if (!progress) return null;

  return <Root>{renderTargets(progress)}</Root>;
};

export default WeeklyProgress;
