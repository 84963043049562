import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  LanguageKey,
  WorkoutDurationKey,
  WorkoutSchedule,
} from "shared-interfaces";
import {
  getPlan,
  getProgress,
  getUser,
  getWorkouts,
  useAppSelector,
} from "shared-redux";
import { completedWorkouts } from "shared-utilities";
import { FullWorkoutTile } from "./FullWorkoutTile";
import QuickWorkoutTile from "./QuickWorkoutTile";

interface WorkoutTileProps {
  editSchedule?: () => void;
  language: LanguageKey;
  isSubscribed: boolean;
  schedule: WorkoutSchedule | null;
}

export const WorkoutTile: React.FC<WorkoutTileProps> = ({
  editSchedule,
  language,
  isSubscribed,
  schedule,
}) => {
  // navigation
  const navigate = useNavigate();
  // Store
  const user = useAppSelector(getUser);
  const plan = useAppSelector(getPlan);
  const progress = useAppSelector(getProgress);
  const { quick, full } = useAppSelector(getWorkouts);

  // Methods
  const handleShowWorkout = async (workoutType: WorkoutDurationKey) => {
    console.log("Press workout");
    if (!plan) return;
    // if (!isSubscribed) return navigation.navigate('AuthenticatedPayments');
    try {
      // await cacheAssets(plan);
      // navigation.navigate('WorkoutNavigator', { screen: 'WorkoutIntro', params: { workout } });
      navigate(`/workout/${workoutType}/intro`);
    } catch (error) {
      // Tracking.captureError('today.onShowWorkout', error);
    }
  };

  // Computed Variables
  const start = moment().startOf("day");
  const end = moment().endOf("day");
  const completedWorkoutsToday = React.useMemo(() => {
    if (!progress) return;
    return completedWorkouts(progress, start, end);
  }, [progress]);

  if (!user || !quick || !full || !completedWorkoutsToday) return null;

  return (
    <div style={{ flex: 1 }}>
      {full && (
        <FullWorkoutTile
          key="daily-workout"
          workout={full}
          showWorkout={() => handleShowWorkout("full")}
          // editSchedule={editSchedule}
          schedule={schedule}
          user={user}
          language={language}
          completedWorkouts={progress?.workoutTracker?.length || 0}
          completed={completedWorkoutsToday?.some(
            (workout) => workout.duration === "full"
          )}
          isSubscribed={isSubscribed}
        />
      )}
      {quick && (
        <QuickWorkoutTile
          key="quick-workout"
          workout={quick}
          isSubscribed={isSubscribed}
          showWorkout={() => handleShowWorkout("quick")}
          completed={completedWorkoutsToday?.some(
            (workout) => workout.duration === "quick"
          )}
        />
      )}
    </div>
  );
};
