import React from "react";
import { nanoid } from "nanoid";
import { usePageVisibility } from "react-page-visibility";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useChatConnected, useTypingIndicator } from "shared-chat";
import {
  useChatGetConversationQuery,
  useChatGetPhysiosQuery,
  useChatReadMessagesMutation,
  useSendMessageMutation,
  addTemporaryMessage,
  getConversation,
  getUser,
  useAppSelector,
} from "shared-redux";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import BackButton from "../assets/svg/buttons/BackButton";
import ChatInput from "../components/chat/ChatInput";
import MessageDisplay from "../components/chat/MessageDisplay";
import { TopInfoBar } from "../components/layout/navigation/TopInfoBar";
import Loader from "../components/miscellaneous/Loader";
import Heading from "../components/texts/Heading";
import Paragraph from "../components/texts/Paragraph";

const IllustrationContainer = styled.div`
  flex: 1;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding-left: ${theme.spacingPx(4)};
  padding-right: ${theme.spacingPx(4)};
  // padding-top: 100px;
  // padding-bottom: ${theme.spacingPx(8)};
`;
const Root = styled.div`
  background-color: ${theme.palette.WHITE};
  flex: 1;
  width: 100%;
  padding: 0;
  display: flex;
  //overflow: hidden;
  // height: 100%;
`;

const ChatEmpty: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  // State
  const [height, setHeight] = React.useState<number>(0);

  // Computed Variables
  // const { bottom } = useSafeAreaInsets();
  // const offset = useElementRelativeKeyboardHeight(height);
  // const animatedStyle = useAnimatedStyle(
  //   () => ({ transform: [{ translateY: -(offset.value / 2) }] }),
  //   [offset, height]
  // );

  // Methods
  // const handleLayout = (event: LayoutChangeEvent) =>
  //   setHeight(event.nativeEvent.layout.height);
  const handleBackButton = () => navigate("/");

  return (
    // <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
    <div style={{ flex: 1 }}>
      <TopInfoBar
        title="Chat"
        leftButton={<BackButton />}
        leftButtonOnClick={handleBackButton}
      />
      <IllustrationContainer>
        {/* <Animated.View layout={springify(Layout)}> */}
        {/* <Animated.View style={animatedStyle}> */}
        <Heading size="larger" text={t("chat_onboarding_title")} />
        <Paragraph text={t("chat_onboarding_text")} />
        {/* </Animated.View> */}
      </IllustrationContainer>
      {children}
    </div>
    // </TouchableWithoutFeedback>
  );
};

export const DashboardChatScreen: React.FC<React.PropsWithChildren> = () => {
  // Refs
  const inputRef = React.useRef<any>(null);

  // Navigation
  const navigate = useNavigate();
  const isFocused = usePageVisibility();

  // Store
  const dispatch = useDispatch();
  const user = useAppSelector(getUser);
  const messages = useAppSelector(getConversation);
  const connected = useChatConnected();
  const { refetch, isLoading, isFetching } = useChatGetConversationQuery();
  const [dispatchReadMessages] = useChatReadMessagesMutation();
  const { refetch: fetchPhysios } = useChatGetPhysiosQuery();
  const [dispatchSendMessage] = useSendMessageMutation();

  // State
  const [displayMessages, setDisplayMessages] = React.useState<boolean>(true);
  const [message, setMessage] = React.useState<string>("");
  // const [inputAccessoryHeight, setInputAccessoryHeight] = React.useState<number>(0);
  // const [chatHeight, setChatHeight] = React.useState<number>(0);

  // Animated
  // const messageOverlayStyle = useAnimatedStyle(
  //   () => ({
  //     height: "100%",
  //     opacity: withTiming(displayMessages ? 0 : 1),
  //     pointerEvents: displayMessages ? "none" : "auto",
  //   }),
  //   [displayMessages]
  // );

  // Computed Variables
  useTypingIndicator(message);
  // const appIsInactive = useAppIsInactive();
  // const { bottom } = useSafeAreaInsets();

  // Methods
  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) =>
    setMessage(event.target.value);

  const handleSubmitMessage = () => {
    console.log("xx ===================================== ");
    if (!connected || !user) return;
    const m = { message, tempId: nanoid(), createdAt: new Date() };

    dispatch(
      addTemporaryMessage({
        conversationId: user.id,
        message: {
          message: m.message,
          tempId: m.tempId,
          createdAt: m.createdAt.toISOString(),
          userId: user.id,
          type: "User",
        },
      })
    );
    dispatchSendMessage(m);
    setMessage("");
  };

  const handleBackButton = () => navigate("/");

  // const handleInputLayout = (event: LayoutChangeEvent) => setInputAccessoryHeight(event.nativeEvent.layout.height);
  // const handleChatLayout = (event: LayoutChangeEvent) => setChatHeight(event.nativeEvent.layout.height);
  const handleInputLayout = console.log;
  const handleChatLayout = console.log;

  // Effects
  React.useEffect(() => {
    fetchPhysios();
    // if (Platform.OS !== 'ios') return;
    // KeyboardManager.setEnable(false);
    // return () => {
    //   KeyboardManager.setEnable(true);
    // };
  }, []);

  React.useEffect(() => {
    if (!isFocused) return;
    dispatchReadMessages();
    // setBadgeCountAsync(0);
  }, [messages, isFocused]);

  // React.useEffect(() => {
  //   if (appIsInactive) return;
  //   refetch();
  // }, [appIsInactive]);

  React.useEffect(() => {
    refetch();
  }, []);

  // React.useEffect(() => {
  //   if (displayMessages) return;
  //   const unsubscribe = navigation.addListener("transitionEnd", () => {
  //     unsubscribe();
  //     setDisplayMessages(true);
  //   });
  // }, [displayMessages]);

  // Renderers
  const renderInput = React.useMemo(
    () => (
      // <div>ChatInput</div>
      <ChatInput
        ref={inputRef}
        disabled={!connected}
        value={message}
        onChange={handleChangeMessage}
        onLayout={handleInputLayout}
        onSubmit={handleSubmitMessage}
      />
    ),
    [inputRef, connected, message]
  );
  // const renderAccessory = React.useMemo(() => {
  //   if (Platform.OS === 'android') return renderInput;
  //   return <InputAccessoryView backgroundColor={theme.palette.WHITE}>{renderInput}</InputAccessoryView>;
  // }, [renderInput]);
  const renderEmpty = React.useMemo(
    () => <ChatEmpty>{renderInput}</ChatEmpty>,
    [renderInput]
  );
  const renderMessages = React.useMemo(() => {
    if (!user || !displayMessages) return null;
    return (
      <MessageDisplay
        user={user}
        messages={messages}
        loading={isLoading && isFetching}
      />
    );
  }, [user, displayMessages, messages, isLoading, isFetching]);
  const renderChat = React.useMemo(() => {
    if (!user) return null;
    return (
      <>
        <TopInfoBar
          title="Chat"
          leftButton={<BackButton />}
          leftButtonOnClick={handleBackButton}
        />
        <div style={{ flex: 1, display: "flex", height: "100%" }}>
          {renderMessages}
          {renderInput}
        </div>
      </>
    );
  }, [user, renderMessages, renderInput]);
  const renderRoot = React.useMemo(() => {
    if (!messages?.length || !user) return renderEmpty;
    return renderChat;
  }, [messages?.length, user, renderEmpty, renderChat]);

  return <Root>{renderRoot}</Root>;
};
