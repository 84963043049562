/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

import styled from "styled-components";
import EmphasizedText from "../../texts/EmphasizedText";
import Heading from "../../texts/Heading";
import Subtitle from "../../texts/Subtitle";

type ButtonProps = {
  isActive: boolean;
};

const YesNoWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
`;

const Button = styled.button<ButtonProps>`
  flex: 1;
  max-width: 163px;
  width: 100%;
  background: #e7eef2;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #145e7f;
  height: 54px;
  background: ${({ isActive }) => (isActive ? "#D0DEE5" : "#e7eef2")};
`;

interface MultipleChoiceProps {
  item: any;
  answers: any;
  onChange: (id: number, type: string, value: boolean) => void;
}

const YesNoQuestionsView: React.FC<MultipleChoiceProps> = ({
  item,
  answers,
  onChange,
}) => {
  const selectedQuestionAnswers = answers.find(
    (answer: any) => answer?.question === item.id
  );
  const [isActive, setIsActive] = useState<boolean | null>(
    selectedQuestionAnswers ? selectedQuestionAnswers?.input?.value : null
  );

  const changeValueHandler = (value: boolean) => {
    onChange(item.id, item.type, value);
    setIsActive(value);
  };

  return (
    <>
      <Heading text={item.title} size="medium" />
      <EmphasizedText text={item.emphasised} />
      <Subtitle text={item.subtitle ?? ""} />
      <YesNoWrapper>
        <Button
          type="button"
          onClick={() => changeValueHandler(true)}
          isActive={Boolean(isActive)}
        >
          <Heading text="Yes" size="regular" marginBottom="0px" />
        </Button>

        <Button
          type="button"
          onClick={() => changeValueHandler(false)}
          isActive={isActive === false}
        >
          <Heading text="No" size="regular" marginBottom="0px" />
        </Button>
      </YesNoWrapper>
    </>
  );
};

export default YesNoQuestionsView;
