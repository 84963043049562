// @TODO: Fix any types
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ChoiceQuestion } from "shared-interfaces";

import { Selector } from "src/components/Selector";
import EmphasizedText from "../../texts/EmphasizedText";
import Heading from "../../texts/Heading";
import Subtitle from "../../texts/Subtitle";

interface ChoiceProps {
  item: ChoiceQuestion;
  answers?: any;
  activeAnswerOption?: any;
  onChange: (id: number, type: string, value: string) => void;
  onChangeSubAnswer: (id: number, type: string, value: string) => void;
}

const ChoiceQuestionView: React.FC<ChoiceProps> = ({
  item,
  answers,
  onChange,
  onChangeSubAnswer,
  activeAnswerOption,
}) => {
  const selectedQuestionAnswers = answers.find(
    (answer: any) => answer?.question === item.id
  );
  const activeSubQuestionSelectedAnswer = answers.find(
    (answer: any) => answer?.question === activeAnswerOption?.subQuestion?.id
  );

  // const questionsList = item.questionChoices.map((item) => item.label);
  const changeValueHandler = (value: string) => {
    onChange(item.id, item.type, value);
  };

  const changeSubValueHandler = (item: any, value: string) => {
    onChangeSubAnswer(item.subQuestion.id, item.subQuestion.type, value);
  };

  return (
    <>
      <Heading text={item.title} size="medium" />
      <EmphasizedText text={item.emphasised} />
      <Subtitle text={item.subtitle} />
      <Selector
        isSubmit={false}
        items={item.questionChoices}
        onChange={changeValueHandler}
        onChangeSubAnswer={changeSubValueHandler}
        value={selectedQuestionAnswers?.input?.value}
        subQuestionValue={activeSubQuestionSelectedAnswer?.input?.value}
      />
    </>
  );
};

export default ChoiceQuestionView;
