import React from "react";
import { theme } from "shared-utilities";
import styled, { CSSProp, DefaultTheme } from "styled-components/macro";

interface ContentProps {
  style?: CSSProp<DefaultTheme>;
}

const Root = styled.div`
  flex: 1;
  padding: ${theme.spacingPx(4)};
`;
// width: ${(props) => `${props.width}px` || "100vw"};

const Content: React.FC<React.PropsWithChildren<ContentProps>> = ({
  style,
  children,
}) => {
  return <Root css={style}>{children}</Root>;
};

export default Content;
