import moment, { Moment } from 'moment';
import {
  ExerciseTemplate, Plan,
  Progress,
  UserExercise, Workout,
  WorkoutDuration,
  WorkoutExercise, WorkoutSummaryRound, WorkoutSummaryRow,
  WorkoutTracker, WorkoutWithInfo
} from "shared-interfaces";
import {circularSlice} from "./array";
import {uniqueArray} from "./common";
import { t } from "./localise";


const DEFAULT_EXERCISE_TIME = 50;
const DEFAULT_EXERCISE_REST = 10;

/**
 * Get completed Workouts
 * @param progress
 * @param start
 * @param end
 */
export const completedWorkouts = (progress: Progress, start: Moment, end: Moment): WorkoutTracker[] => {
  if (!progress?.workoutTracker?.length) return [];
  return progress.workoutTracker.filter(
    (workout) => moment(workout.ended).toDate() >= start.toDate() && moment(workout.ended).toDate() < end.toDate(),
  );
};

/**
 * Calculates time for exercise based on Physio defined
 * values and the original exercise template
 * @param exercise
 * @param originalRepetitions
 */
export const calculateTimeForExercise = ({ exercise, repetitions: originalRepetitions }: UserExercise): number => {
  const { time, repetitions, sets, rest } = exercise;
  if (time > 0) {
    const templateRepetitions = repetitions > 0 ? repetitions : 1;
    const exerciseRepetitions = originalRepetitions > 0 ? originalRepetitions : 1;
    const totalRest = rest * (sets - 1);
    const lengthOfRepetition = Math.round((time - totalRest) / sets / templateRepetitions);
    return sets * exerciseRepetitions * lengthOfRepetition + (sets - 1) * rest;
  }
  return 0;
};

/**
 * Check if a particular exercise is new to the user
 * @param progress
 * @param id
 */
const haveCompletedExerciseBefore = (id: number, progress?: Progress): boolean =>
  !progress?.workoutTracker?.find((workout) => workout.exerciseIds.includes(id));

/**
 * Determine how long an exercise should be done for
 * @param hold
 * @param massage
 */
const getWorkoutSummaryRowTime = ({ hold, massage }: ExerciseTemplate): number => (hold > 0 ? hold : massage);

/**
 * Creates an array of objects that summarise workouts for presentational logic
 * @param exercises
 * @param duration
 */
const createWorkoutSummary = (exercises: WorkoutExercise[], duration: WorkoutDuration): WorkoutSummaryRow[] => {
  if (!exercises?.length) return [];
  const isFull = duration === 'Full';
  const totalSumOfSets = exercises.reduce(function (acc, obj) { return acc + obj.sets; }, 0);

  const inflatedExercises = Array.from({ length: totalSumOfSets }).reduce<WorkoutExercise[]>((acc, _, i) => {
    exercises.forEach((exercise) => {
      if (i >= exercise.sets) return;
      acc.push(exercise);
    });
    return acc;
  }, []);

  const roundNumberTracker: Record<string, number> = {};
  return inflatedExercises.map((exercise, i) => {
    const { id, hold, rest } = exercise.exercise;
    const existingEntry = roundNumberTracker[id];
    roundNumberTracker[id] = existingEntry ? existingEntry + 1 : 1;

    const getRestLength = (): number => {
      if (i === inflatedExercises.length - 1) return 0;
      return isFull ? rest : DEFAULT_EXERCISE_REST;
    };

    return {
      exercise: exercise.exercise,
      isNew: Boolean(exercise.isNew),
      repetitions: !isFull || hold > 0 ? 0 : exercise.repetitions,
      time: isFull ? getWorkoutSummaryRowTime(exercise.exercise) : DEFAULT_EXERCISE_TIME,
      rest: getRestLength(),
      round: roundNumberTracker[id],
    };
  });
};

const transformExercises = (
  exercises: UserExercise[],
  duration: WorkoutDuration,
  progress?: Progress,
): WorkoutExercise[] => {
  if (!exercises?.length) return [];

  let result = [...exercises];
  if (duration === 'Quick') {
    result = circularSlice(result, moment().dayOfYear() % exercises.length, 3);
  }

  return result.map((exercise: UserExercise) => ({
    exercise: exercise.exercise,
    isNew: haveCompletedExerciseBefore(exercise.exercise.id, progress),
    sets: exercise.sets,
    repetitions: exercise.repetitions,
    time: calculateTimeForExercise(exercise),
    // rest: exercise.rest TODO: What about rest, is it needed on this object?
  }));
};

/**
 * Transform a workout for use within the application
 * @param plan
 * @param duration
 * @param progress
 */
export const transformWorkout = (plan: Plan, duration: WorkoutDuration, progress?: Progress): Workout => {
  const exercises = transformExercises(plan.exercises, duration, progress);
  return {
    duration,
    exercises,
    totalTime: plan.totalTime,
    date: new Date().toISOString(),
    workoutSummary: createWorkoutSummary(exercises, duration),
  };
};

export const chunkSummaryToRounds = (array: WorkoutSummaryRow[], chunkSize: number, groupKey: string): WorkoutSummaryRound[] => {
  const rounds = array.reduce((acc: {[key: string]: any}, obj: {[key: string]: any}) => {
    const key = obj[groupKey];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  const result:WorkoutSummaryRound[] = [];
  for (const key in rounds) {
    for (let i = 0; i < rounds[key].length; i += chunkSize) {
      const set = rounds[key].slice(i, i + chunkSize);
      result.push({title: String(set[0].round), data: set});
    }
  }

  return result;
}

export const splitWorkoutIntoRounds = (workoutSummary: WorkoutSummaryRow[]): WorkoutSummaryRound[] => {
  const totalSumOfSets = workoutSummary.reduce(function (acc, obj) { return acc + obj.exercise.sets; }, 0);
  const rounds: WorkoutSummaryRound[] = chunkSummaryToRounds(workoutSummary, totalSumOfSets, 'round');
  return rounds;
};

export const getRound = ({ round }: WorkoutSummaryRow, script: WorkoutSummaryRow[]): string => {
  const rounds = splitWorkoutIntoRounds(script);
  return t('insession_script_round_progress', { round, total: rounds.length });
};

export const renderInstructions = (script: WorkoutSummaryRow): string => {
  const { time, repetitions, exercise } = script;
  if (exercise.massage !== 0) return t("insession_instructions_massage", { time });
  if (script.exercise.hold !== 0) return t("insession_instructions_hold", { time });
  if (script.repetitions < 2 && script.time > 0) return t("insession_instructions_repeat", { time });
  if (script.repetitions > 1) return t("insession_instructions_reps", { reps: repetitions });
  return '';
};

/**
 * Get unique array of all equipmentIds for Workout
 * @param exercises
 */
export const getArrayOfEquipment = (exercises: WorkoutExercise[]): string[] =>
  exercises.reduce<string[]>((acc, { exercise }) => {
    if (!exercise.equipmentIds?.length) return acc;
    return uniqueArray([...acc, ...exercise.equipmentIds]);
  }, []);

export const createWorkout = (plan: Plan, duration: WorkoutDuration, progress?: Progress): WorkoutWithInfo => {
  const workout = transformWorkout(plan, duration, progress);
  return {
    ...workout,
    title: `workout_${duration.toLowerCase()}_title`,
    type: 'Plan',
    subtitle: `workout_${duration.toLowerCase()}_subtitle`,
    description: `workout_${duration.toLowerCase()}_description`,
    time: duration === 'Quick' ? 360 : workout.totalTime ?? 360,
  };
};

/**
 * Cache all media assets for Exercises
 * @param plan
 */
// export const cacheAssets = async (plan: Plan): Promise<void> => {
//   plan.exercises.forEach(({ exercise }) => {
//     Image.prefetch(exercise.thumbnailUrl);
//     Image.prefetch(exercise.posterFrameUrl);
//     cacheFile(exercise.loopVideoUrl, 'loopVideo');
//   });
// };
