import React from "react";

import { ReactComponent as IllustrationAbout } from "shared-assets/src/icons/IllustrationAbout.svg";
import { ReactComponent as IllustrationActivity } from "shared-assets/src/icons/IllustrationActivity.svg";
import { ReactComponent as IllustrationCheck } from "shared-assets/src/icons/IllustrationCheck.svg";
import { ReactComponent as IllustrationLife } from "shared-assets/src/icons/IllustrationLife.svg";
import { ReactComponent as IllustrationPain } from "shared-assets/src/icons/IllustrationPain.svg";

export type IllustrationName =
  | "hand-with-phone"
  | "running-man"
  | "man-at-whiteboard"
  | "woman-asking-question"
  | "bike"
  | "illustration_pain"
  | "illustration_about"
  | "illustration_activity"
  | "illustration_life"
  | "illustration_move"
  | "illustration_check"
  | "illustration_account"
  | "illustration_assessment_done"
  | "illustration_assessment_complete"
  | "illustration_assessment_continue"
  | "illustration_assessment_inreview";

interface IllustrationProps {
  name?: IllustrationName;
}

export const Illustration: React.FC<IllustrationProps> = ({ name }) => {
  const renderIllustration = () => {
    switch (name) {
      //   case "hand-with-phone":
      //     return <HandWithPhone />;
      //   case "running-man":
      //     return <ManRunning />;
      //   case "man-at-whiteboard":
      //     return <ManAtWhiteboard />;
      //   case "woman-asking-question":
      //     return <WomanAskingQuestion />;
      //   case "bike":
      //     return <Bike />;
      case "illustration_pain":
        return <IllustrationPain />;
      case "illustration_about":
        return <IllustrationAbout />;
      case "illustration_activity":
        return <IllustrationActivity />;
      case "illustration_life":
        return <IllustrationLife />;
      //   case "illustration_move":
      //     return <IllustrationMove />;
      case "illustration_check":
        return <IllustrationCheck />;
      //   case "illustration_account":
      //     return <IllustrationAccount />;
      //   case "illustration_assessment_done":
      //     return <AssessmentDone />;
      //   case "illustration_assessment_complete":
      //     return <AssessmentComplete />;
      //   case "illustration_assessment_continue":
      //     return <AssessmentContinue />;
      //   case "illustration_assessment_inreview":
      //     return <AssessmentInReview />;
      default:
        return <IllustrationPain />;
    }
  };

  return <div>{renderIllustration()}</div>;
};
