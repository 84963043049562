import { theme } from "shared-utilities";
import styled from "styled-components";

const Icon = styled.svg<{ focused?: boolean }>`
  transition: 0.3s ease;
  transform: ${(props) => (props.focused ? "scale(1.2)" : "scale(1)")};

  .accent {
    stroke: ${(props) =>
      props.focused ? theme.palette.ACCENT_PRIMARY : theme.palette.PRIMARY};
  }
`;

const ProfileSelectedIcon: React.FC<any> = ({
  size = theme.spacing(3.25),
  color = theme.palette.PRIMARY,
  focused = false,
  ...props
}) => (
  <Icon
    transform="scale(1.2)"
    width={size}
    height={size}
    viewBox="0 0 27 26"
    focused={focused}
    {...props}
  >
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <path
        d="M10.758 22.968a1.414 1.414 0 01-1.762.882l-.696-.226a1.408 1.408 0 01-.907-1.748l.082-.267c.187-.609-.077-1.404-.594-1.78l-.14-.102c-.515-.375-1.354-.382-1.875-.016l-.229.16a1.414 1.414 0 01-1.943-.322l-.43-.592a1.411 1.411 0 01.292-1.947l.223-.167c.509-.383.763-1.181.565-1.789l-.053-.166c-.197-.605-.872-1.104-1.509-1.115l-.279-.004a1.413 1.413 0 01-1.382-1.403v-.732c0-.762.625-1.39 1.382-1.403l.28-.004c.636-.01 1.31-.508 1.508-1.115l.053-.166c.197-.605-.056-1.406-.565-1.789l-.223-.167a1.412 1.412 0 01-.293-1.947l.43-.592a1.414 1.414 0 011.944-.322l.229.16c.52.366 1.358.36 1.875-.016l.14-.102c.515-.374.78-1.171.594-1.78l-.082-.267A1.413 1.413 0 018.3.376L8.996.15a1.408 1.408 0 011.762.882l.09.264c.207.602.888 1.09 1.526 1.09h.175c.636 0 1.32-.488 1.526-1.09l.09-.264A1.414 1.414 0 0115.927.15l.696.226a1.408 1.408 0 01.907 1.748l-.082.267c-.187.609.077 1.404.594 1.78l.14.102c.515.375 1.355.382 1.876.016l.228-.16a1.414 1.414 0 011.943.322l.431.592a1.411 1.411 0 01-.293 1.947l-.223.167c-.509.383-.763 1.181-.565 1.789l.054.166c.196.605.871 1.104 1.508 1.115l.279.004a1.413 1.413 0 011.382 1.403v.732c0 .762-.625 1.39-1.382 1.403l-.28.004c-.636.01-1.31.508-1.507 1.115l-.054.166c-.197.605.056 1.406.565 1.789l.223.167c.61.46.738 1.336.293 1.947l-.43.592a1.414 1.414 0 01-1.944.322l-.228-.16c-.521-.366-1.36-.36-1.876.016l-.14.102c-.515.374-.78 1.171-.594 1.78l.082.267a1.413 1.413 0 01-.907 1.748l-.696.226a1.408 1.408 0 01-1.762-.882l-.09-.264c-.207-.602-.888-1.09-1.526-1.09h-.175c-.636 0-1.32.488-1.526 1.09l-.09.264z"
        stroke={color}
        transform="translate(-24 -12) translate(25 13)"
      />
      <path
        d="M12.462 18a6 6 0 100-12 6 6 0 000 12z"
        className="accent"
        transform="translate(-24 -12) translate(25 13)"
      />
    </g>
  </Icon>
);

export default ProfileSelectedIcon;
