type PossibleErrorCode =
  | 'EXPIRED_REF_CODE'
  | 'INVALID_REF_CODE'
  | 'AUTH_INVALID_COMBINATION'
  | 'ACTIVATION_CODE_DOES_NOT_MATCH'
  | string;

const EXPIRED_REF_CODE_STRING =
  'You’ve entered a referral code that is no longer valid. If you’re still stuck our team are on hand to help. Email us at reach@ascenti.co.uk to get in touch.';

const INVALID_REF_CODE_STRING =
  'You’ve entered a referral code that isn’t valid. If you’re still stuck our team are on hand to help. Email us at reach@ascenti.co.uk to get in touch.';

const AUTH_INVALID_COMBINATION_STRING = `The email and password details you’ve entered aren’t correct. If you think you’ve forgotten your password then please use the 'Forgot your password?' feature.`;

const ACTIVATION_CODE_DOES_NOT_MATCH_STRING =
  'We’re experiencing a problem with your reset request. Please try again later and if you’re still stuck our team are on hand to help. Email us at reach@ascenti.co.uk to get in touch.';

const GENERIC_STRING =
  'We’re experiencing a problem right now. Please try again later and if you’re still stuck our team are on hand to help. Email us at reach@ascenti.co.uk to get in touch. [ERRORCODE]';

const errorStringReducer = (code: PossibleErrorCode): string => {
  switch (code) {
    case 'EXPIRED_REF_CODE':
      return EXPIRED_REF_CODE_STRING;
    case 'INVALID_REF_CODE':
      return INVALID_REF_CODE_STRING;
    case 'AUTH_INVALID_COMBINATION':
      return AUTH_INVALID_COMBINATION_STRING;
    case 'ACTIVATION_CODE_DOES_NOT_MATCH':
      return ACTIVATION_CODE_DOES_NOT_MATCH_STRING;
    default:
      return GENERIC_STRING;
  }
};

const transformString = (value: string, code: PossibleErrorCode) => value.replace(/\[ERRORCODE]/g, code);

export const getErrorString = (code: PossibleErrorCode) => transformString(errorStringReducer(code), code);
