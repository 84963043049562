import moment from 'moment';
import 'moment/locale/da';
import { t } from './localise';
import { LanguageKey } from "shared-interfaces";

/**
 * Configure Moment locale based on Language ISO string
 * @param language
 */
export const setMomentLocale = (language?: LanguageKey): void => {
  const locale = language?.toLowerCase() ?? 'en';

  // Check if the locale is included in the array returned by `locales()` which (in this case) tells us which locales moment will support
  if (moment.locales().includes(locale)) {
    moment.locale(locale);
    return;
  }
  if (moment.locales().includes(locale.substring(0, 2))) {
    // Check if the first two letters of the locale are included in the array returned by `locales()` which
    // (in this case) tells us which locales moment will support.
    // Will fixes crashes caused by "en-US" not being found, as we'll tell moment to load "en" instead
    moment.locale(locale.substring(0, 2));
  }
};

export const birthdateFromServer = (date: Date | string): string => moment(date).format('DD/MM/YYYY');

export const birthdateToServer = (date: Date | string): string => moment(date).format('YYYYMMDD');

export const prettyTimeSince = (date: Date | string, language?: LanguageKey): string => {
  setMomentLocale(language);
  return moment(date).fromNow();
};

export const prettyDate = (date: Date | string, language: LanguageKey, time = false): string => {
  setMomentLocale(language);
  const timeFormat = time ? ' HH:mm' : '';

  return moment(date).calendar(null, {
    sameDay: `[${t('common_time_today')}]${timeFormat}`,
    nextDay: `[${t('common_time_tomorrow')}]${timeFormat}`,
    nextWeek: `dddd${timeFormat}`,
    lastDay: `[${t('common_time_yesterday')}]${timeFormat}`,
    lastWeek: `DD/MM/YYYY${timeFormat}`,
    sameElse: `DD/MM/YYYY${timeFormat}`,
  });
};
