import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const WallSpaceIcon: React.FC<SvgProps & IconProps> = ({
  size = theme.spacing(2.375),
  color = theme.palette.PRIMARY,
  ...props
}) => (
  <Svg width={size * 1.263157894736842} height={size} viewBox="0 0 24 19" {...props}>
    <Path
      d="M11.427 17.337v-3.906H1.077a.796.796 0 1 1 0-1.593h3.98V7.931h-3.98a.796.796 0 0 1 0-1.592h10.35V2.432H1.077a.796.796 0 1 1 0-1.592h21.847a.796.796 0 1 1 0 1.592h-9.905V6.34h9.905a.796.796 0 1 1 0 1.592h-3.535v3.907h3.535a.796.796 0 1 1 0 1.593h-9.905v3.906h9.905a.796.796 0 1 1 0 1.593H1.076a.796.796 0 1 1 0-1.593h10.35zm6.37-5.499V7.931H6.65v3.907h11.146z"
      fill={color}
      fillRule="nonzero"
    />
  </Svg>
);

export default WallSpaceIcon;
