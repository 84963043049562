import React from "react";
import { theme } from "shared-utilities";
import { css } from "styled-components";
import styled, { CSSProp, DefaultTheme } from "styled-components/macro";

export type HeadingSize =
  | "huge"
  | "biggest"
  | "bigger"
  | "big"
  | "largest"
  | "larger"
  | "large"
  | "medium"
  | "regular"
  | "tiny";

interface HeadingProps {
  size: HeadingSize;
  text?: string;
  color?: string;
  font?: string;
  highlightColor?: string;
  marginBottom?: string;
  leftAlign?: boolean;
  bold?: boolean;
  style?: CSSProp<DefaultTheme>;
  ellipsizeMode?: any; // TextProps["ellipsizeMode"];
  numberOfLines?: number;
}

const Text = styled.div``;

const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  style,
  size,
  text,
  children,
  color = theme.palette.PRIMARY,
  font,
  highlightColor,
  marginBottom,
  leftAlign,
  bold,
  ellipsizeMode,
  numberOfLines,
}) => {
  // Methods
  const getFont = () => {
    switch (size) {
      case "huge":
        return theme.typography[theme.typography.FONT_NAME].HUGE_HEADING;
      case "biggest":
        return theme.typography[theme.typography.FONT_NAME].BIGGEST_HEADING;
      case "bigger":
        return theme.typography[theme.typography.FONT_NAME].BIGGER_HEADING;
      case "big":
        return theme.typography[theme.typography.FONT_NAME].BIG_HEADING;
      case "largest":
        return theme.typography[theme.typography.FONT_NAME].LARGEST_HEADING;
      case "larger":
        return theme.typography[theme.typography.FONT_NAME].LARGER_HEADING;
      case "large":
        return theme.typography[theme.typography.FONT_NAME].LARGE_HEADING;
      case "medium":
        if (bold)
          return theme.typography[theme.typography.FONT_NAME]
            .MEDIUM_BOLD_HEADING;
        return theme.typography[theme.typography.FONT_NAME].MEDIUM_HEADING;
      case "regular":
        if (bold)
          return theme.typography[theme.typography.FONT_NAME]
            .REGULAR_BOLD_HEADING;
        return theme.typography[theme.typography.FONT_NAME].REGULAR_HEADING;
      case "tiny":
        if (bold)
          return theme.typography[theme.typography.FONT_NAME].TINY_BOLD_HEADING;
        return theme.typography[theme.typography.FONT_NAME].TINY_HEADING;
      default:
        return {};
    }
  };

  const getMarginBottom = () => {
    if (marginBottom !== undefined) return marginBottom;
    switch (size) {
      case "huge":
      case "biggest":
      case "bigger":
      case "largest":
      case "larger":
      case "large":
        return theme.spacingPx(3);
      default:
        return theme.spacingPx(2);
    }
  };

  // Computed Variables
  const alignment = !leftAlign
    ? css`
        text-align: center;
      `
    : null;
  const highlighted = highlightColor
    ? css`
        align-self: center;
        padding: 4px 8px;
        border-radius: 4px;
        overflow: hidden;
        background-color: ${highlightColor};
      `
    : null;
  return (
    // TODO figure out what to do with ellipsizeMode and numberOfLines
    <Text
      css={[
        getFont(),
        alignment,
        css`
          margin-bottom: ${getMarginBottom()};
          color: ${color};
        `,
        highlighted,
        style,
      ]}
      // ellipsizeMode={ellipsizeMode}
      // numberOfLines={numberOfLines}
    >
      {children ?? text}
    </Text>
  );
};

export default Heading;
