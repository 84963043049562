import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const StabilityBall: React.FC<SvgProps & IconProps> = ({ color, ...props }) => (
  <Svg width={27} height={27} {...props} viewBox="0 0 27 27">
    <G stroke={color || theme.palette.PRIMARY} strokeWidth={1.7} fill="none" fillRule="evenodd">
      <Path d="M23.31 19.832a12.193 12.193 0 0 0 1.82-8.991 12.192 12.192 0 0 0-4.859-7.653 12.187 12.187 0 0 1-7.676 4.89A12.199 12.199 0 0 1 3.164 5.95a12.205 12.205 0 0 0 3.35 17.455M23.31 19.832a12.197 12.197 0 0 1-7.96 5.298 12.186 12.186 0 0 1-8.836-1.725M3.164 5.95a12.197 12.197 0 0 1 7.676-4.89 12.196 12.196 0 0 1 9.43 2.128" />
      <Path d="M22.679 6.018c-2.382 3.312-4.96 4.844-9.285 5.654-4.617.865-8.19-.1-11.715-2.654" />
      <Path d="M24.679 9.018c-2.438 3.501-5.57 5.402-10.08 6.32-4.815.978-9.56-.17-13.288-2.784" />
    </G>
  </Svg>
);

export default StabilityBall;
