import React, { useState } from "react";
import { theme } from "shared-utilities";
import styled from "styled-components";

import CloseButton from "../../assets/svg/buttons/CloseButton";
import { Icon, IconKind } from "../Icon";
import Heading from "../texts/Heading";
import { CancelModal } from "./CancelModal";

type TBackIconProps = {
  isActive: any;
};

type TProgressBarWrapperProps = {
  count: number;
};

type TProgressItemProps = {
  activeStep: number;
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.header`
  position: fixed;
  width: 100%;
  min-height: 76px;
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  top: 0;
  background: #fff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BackIcon = styled.button<TBackIconProps>`
  //width: 40px;
  padding: 0;
  //position: fixed;
  //left: 0;
  //top: 0;
  // img {
  //   display: ${({ isActive }) => (isActive ? "block" : "none")};
  // }
`;

const CloseIcon = styled.button`
  //position: fixed;
  //top: 0;
  //right: 0;
  padding: 0;
`;

const ProgressBarWrapper = styled.div<TProgressBarWrapperProps>`
  display: grid;
  align-items: center;
  gap: 8px;
  position: absolute;
  width: calc(100% - 30px);
  bottom: 12px;
  grid-template-columns: ${({ count }) => `repeat(${count}, 1fr)`};
`;

const ProgressItem = styled.div<TProgressItemProps>`
  max-width: 100%;
  height: 2px;
  background: #dce2e0;
  border-radius: 1px;
  position: relative;

  :before {
    content: "";
    width: ${({ activeStep }) => `${activeStep}%`};
    background: #ff7ea2;
    position: absolute;
    left: 0;
    border-radius: 1px;
    height: 2px;
  }
`;

interface AssessmentHeaderProps {
  onBack?: () => void;
  questions: any[];
  activeStep: number;
}

export const CheckinHeader: React.FC<AssessmentHeaderProps> = ({
  onBack,
  questions,
  activeStep,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModalToggler = () => {
    setIsOpen(!isOpen);
  };

  const calculateProgress = (questionLength: number) => {
    if (questionLength > activeStep && activeStep > 0) {
      return (activeStep / questionLength) * 100;
    }

    if (questionLength === activeStep) {
      return 100;
    }
    return 0;
  };

  const renderProgressBarItems = () => (
    <ProgressItem activeStep={calculateProgress(questions.length)} />
  );

  const renderBackArrow = () => {
    if (activeStep > 0) {
      return (
        <BackIcon
          isActive={activeStep}
          type="button"
          onClick={onBack}
          style={{ marginTop: 10 }}
        >
          <Icon kind={IconKind.BACK} />
        </BackIcon>
      );
    }
    return <div> </div>;
  };

  return (
    <Header>
      <HeaderWrapper>
        {renderBackArrow()}
        <Heading
          size="regular"
          bold
          text="Checkin"
          marginBottom="0px"
          style={{ lineHeight: theme.spacingPx(2) }}
        />
        <CloseIcon onClick={openModalToggler}>
          {
            /* <Icon kind={IconKind.CROSS} /> */
            <CloseButton
              color={theme.palette.PRIMARY}
              size={theme.spacing(1.75)}
            />
          }
        </CloseIcon>
      </HeaderWrapper>
      <ProgressBarWrapper count={1}>
        {renderProgressBarItems()}
      </ProgressBarWrapper>
      {isOpen && <CancelModal onClose={openModalToggler} />}
    </Header>
  );
};
