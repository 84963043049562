import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { WorkoutDurationKey, WorkoutWithInfo } from "shared-interfaces";
import {
  getProgress,
  getWorkouts,
  useAppDispatch,
  useAppSelector,
  workoutReset,
} from "shared-redux";
import { t, splitWorkoutIntoRounds, theme } from "shared-utilities";
import RoundButton from "src/components/buttons/RoundButton";
import styled from "styled-components/macro";
import CloseButton from "../components/buttons/CloseButton";
import Heading from "../components/texts/Heading";
import Paragraph from "../components/texts/Paragraph";
import WorkoutSummaryRowView from "../components/workout/WorkoutSummaryRowView";

const getPicture = (workout: WorkoutWithInfo): any => {
  if (workout.duration === "Quick")
    return theme.images.workoutIntroScreen.QUICK_WORKOUT_IMAGE;
  return theme.images.workoutIntroScreen.DAILY_WORKOUT_IMAGE;
};

const Root = styled.div`
  display: flex;
  flex: 1;
`;
const SafeArea = styled.div`
  position: fixed;
  top: 0;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
`;
const ImageContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 50vh;
  margin-bottom: ${theme.spacingPx(1)};
  background-color: ${theme.palette.LIGHTER_GREY};
  background-image: url(${(props: { image: any }) => props.image});
  background-size: cover;
  background-position: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  flex: 1;
  padding: ${theme.spacingPx(4)};
  justify-content: flex-end;
`;
const Separator = styled.div`
  width: 100vw;
  height: 1px;
  background-color: ${theme.palette.LIGHTEST_GREY};
`;

export const WorkoutIntroScreen: React.FC<React.PropsWithChildren> = () => {
  // Navigation
  const navigate = useNavigate();
  const params = useParams();

  // Store
  const dispatch = useAppDispatch();
  const progress = useAppSelector(getProgress);
  const workouts = useAppSelector(getWorkouts);
  const dispatchResetExercise = () => dispatch(workoutReset());

  // Methods
  const handleClose = async () => {
    // await handleSetExtras({ shouldPromptForNotificationTime: true });
    navigate("/");
  };

  const handleStartSession = () => {
    // Tracking.logEvent('Workout Started', {
    //   Type: workout?.duration ?? 'Unkown duration',
    // });

    navigate(`/workout/${params.workoutType}/countdown`);
  };

  // const handleSetExtras = (config: any) => dispatch(setExtras(config));

  // Computed Variables
  const [searchParams] = useSearchParams();
  const type = (searchParams.get("type") ?? "full") as WorkoutDurationKey;
  // const { bottom } = useSafeAreaInsets();
  const completedWorkouts = progress?.workoutTracker?.length ?? 0;
  const workout = workouts[type];
  const title = !completedWorkouts
    ? t("workout_first_title")
    : t(workout?.title ?? "");

  const data = React.useMemo(() => {
    if (!workout) return;
    return splitWorkoutIntoRounds(workout.workoutSummary);
  }, [workout]);

  // Effects
  React.useEffect(() => {
    dispatchResetExercise();
  }, []);

  // Renderers
  const renderHeader = React.useCallback(() => {
    if (!workout) return;
    return (
      <Root>
        <SafeArea>
          <CloseButton
            onPress={handleClose}
            color={theme.palette.WHITE}
            style={{ float: "right" }}
          />
        </SafeArea>
        <Content>
          <div style={{ marginBottom: theme.spacing(3) }}>
            <Heading
              size="largest"
              color={theme.palette.WHITE}
              style={{ width: 185 }}
              text={title}
              leftAlign
              marginBottom={theme.spacingPx(1)}
            />
            <Paragraph
              color={theme.palette.WHITE}
              text={t("workout_estimate", {
                time:
                  Math.floor(workout.time / 60) <= 0
                    ? (workout.time / 60).toFixed(1)
                    : Math.floor(workout.time / 60),
              })}
              marginBottom="0px"
              leftAlign
            />
          </div>
          <RoundButton
            text={t("btn_start_workout")}
            backgroundColor={theme.palette.ACCENT_PRIMARY}
            onPress={handleStartSession}
          />
        </Content>
      </Root>
    );
  }, [workout, handleStartSession]);

  const renderListHeader = React.useCallback(
    () => (
      <>
        <Heading
          size="tiny"
          bold
          marginBottom={theme.spacingPx(1)}
          text={t("workout_rounds", { rounds: data?.length ?? 0 })}
        />
        <Separator />
      </>
    ),
    [data]
  );

  if (!data || !workout) return null;
  return (
    <>
      <ImageContainer image={getPicture(workout)}>
        {renderHeader()}
      </ImageContainer>
      <div style={{ flex: 1 }}>
        {renderListHeader()}
        {data[0].data.map((item, index) => (
          <React.Fragment key={index}>
            <WorkoutSummaryRowView workoutType={type} item={item} />
            {index !== data[0].data.length - 1 && <Separator />}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
