const environmentReducer = () => {
  switch (process.env.APP_VARIANT) {
    case "production":
      return {
        APPLE_REVENUE_CAT_KEY: "appl_bbTQGdEuYMmmVRoklJWflZjcRTx",
        GOOGLE_REVENUE_CAT_KEY: "goog_dwlwGNiZOjkeUMWOHHeawlnCpKW",
        REFERRAL_CODE_API:
          "https://greencliff-reach-integration-api.ascenti.co.uk",
        REFERRAL_CODE_API_KEY: "YVZNKJhDl2fAbtxN1z27OKX5gSLEg4Ee",
        SERVERLESS_API:
          "https://sls-uks-prod-ascenti-reach-api-v2-1.azurewebsites.net/api",
        CHAT_API: "https://tfrtrlkid1.execute-api.us-east-1.amazonaws.com/prod",
        CHAT_SOCKET:
          "wss://bvdqldood2.execute-api.us-east-1.amazonaws.com/prod",
      };
    case "staging":
      return {
        APPLE_REVENUE_CAT_KEY: "appl_bbTQGdEuYMmmVRoklJWflZjcRTx",
        GOOGLE_REVENUE_CAT_KEY: "goog_dwlwGNiZOjkeUMWOHHeawlnCpKW",
        REFERRAL_CODE_API:
          "https://greencliff-reach-integration-api.ascenti.co.uk",
        REFERRAL_CODE_API_KEY: "YVZNKJhDl2fAbtxN1z27OKX5gSLEg4Ee",
        SERVERLESS_API:
          "https://sls-uks-sta-ascenti-reach-api-v2-1.azurewebsites.net/api",
        CHAT_API:
          "https://p8ow0ow115.execute-api.us-east-1.amazonaws.com/stage",
        CHAT_SOCKET:
          "wss://8p9ajuzrqe.execute-api.us-east-1.amazonaws.com/stage",
      };
    case "development":
      return {
        APPLE_REVENUE_CAT_KEY: "appl_WTkulXyjOFBhpiemByPkybqGPuj",
        GOOGLE_REVENUE_CAT_KEY: "goog_nFudZLivmUfeHJYutqZRaXJsOBM",
        REFERRAL_CODE_API: "https://reachfilesapi-stage.ascenti.co.uk",
        REFERRAL_CODE_API_KEY: "YVZNKJhDl2fAbtxN1z27OKX5gSLEg4Ee",
        // SERVERLESS_API: "http://localhost:3100/proxy/api",
        // SERVERLESS_API:
        //   "https://sls-uks-dev-ascenti-reach-api-v2-1.azurewebsites.net/api",
        SERVERLESS_API: "https://dev-reach-api.ascenti.co.uk/api",
        CHAT_API: "https://qv60m5f3wk.execute-api.us-east-1.amazonaws.com/dev",
        CHAT_SOCKET: "wss://8m4xuyuuya.execute-api.us-east-1.amazonaws.com/dev",
      };
    default:
      return {
        REFERRAL_CODE_API: process.env.REACT_APP_REFERRAL_CODE_API,
        REFERRAL_CODE_API_KEY: process.env.REACT_APP_REFERRAL_CODE_API_KEY,
        SERVERLESS_API: process.env.REACT_APP_SERVERLESS_API,
        CHAT_API: process.env.REACT_APP_CHAT_API,
        CHAT_SOCKET: process.env.REACT_APP_CHAT_SOCKET,
      }
  }
};

export const environmentVariables = environmentReducer();
