import React from "react";
import { VariableSizeList as List } from "react-window";
import { MessageGroupType, reduceMessages } from "shared-chat";
import { Account, ChatMessage, IUser } from "shared-interfaces";
import styled from "styled-components/macro";
import { useWindowResize } from "../../utilities/useWindowResize";
import MessageGroup from "./MessageGroup";

const MessageGroupContainer = styled.div`
  flex: 1;
  display: block;
  flex-direction: column-reverse;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 0 0 15px;
`;

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export interface MessageDisplayProps {
  user: IUser | Account;
  messages: ChatMessage[];
  loading?: boolean;
}

interface LoadingType {
  loading: boolean;
}

const isLoadingType = (
  value: MessageGroupType<ChatMessage> | LoadingType
): value is LoadingType => {
  return Boolean("loading" in value);
};

interface IRow {
  data: any;
  index: number;
  setSize: (index: number, size: number) => void;
  windowWidth: number;
  renderMessageGroup: any;
}

const Row = ({
  data,
  index,
  setSize,
  windowWidth,
  renderMessageGroup,
}: IRow) => {
  const rowRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!rowRef || !rowRef.current) return;
    setSize(index, rowRef.current.getBoundingClientRect().height);
  }, [setSize, index, windowWidth]);
  return <div ref={rowRef}>{renderMessageGroup(data[index])}</div>;
};

const MessageDisplay: React.FC<MessageDisplayProps> = ({
  messages,
  user,
  loading,
}) => {
  const listRef = React.useRef<any | null>();

  // Computed Variables
  const messageGroups = React.useMemo(
    () => [...messages].reduce(reduceMessages(user), []),
    [messages]
  );
  const lastOwnMessage = React.useMemo(
    () => messages.find((m) => m.userId === user?.id),
    [messages]
  );

  // Renderers
  const renderMessageGroup: any = (
    item: LoadingType | MessageGroupType<ChatMessage>,
    style?: any
  ) => {
    if (!item) return null;
    if (isLoadingType(item)) return null;

    return (
      <MessageGroup
        style={style}
        lastMessage={lastOwnMessage?.id}
        messageGroup={item}
        key={`${item.messages[0].id}${item.userId}`}
      />
    );
  };

  const footerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    footerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  interface SizeMap {
    [key: number]: number | undefined;
  }
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const sizeMap = React.useRef<SizeMap>({});
  const setSize = React.useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);
  const getSize = (index: number) => sizeMap.current[index] || 50;
  const [windowWidth] = useWindowResize();

  React.useEffect(() => {
    // temporary hack... need to figure out how to calculate total scroll height
    // of elements that are not loaded into the view;
    setTimeout(() => {
      // listRef?.current.scrollTo(800000); // this magic number forces max scroll. Needs to be calculated somehow.
    }, 1000);
  }, [messageGroups]);

  return (
    <Root>
      <MessageGroupContainer ref={containerRef}>
        <List
          ref={listRef}
          height={containerRef.current?.clientHeight || 0}
          itemCount={messageGroups.length}
          itemData={messageGroups}
          itemSize={getSize}
          width="100%"
        >
          {({ data, index, style }) => (
            <div style={style}>
              <Row
                data={data}
                index={index}
                setSize={setSize}
                windowWidth={windowWidth}
                renderMessageGroup={renderMessageGroup}
              />
              {index === messageGroups.length - 1 ? (
                <div ref={footerRef} />
              ) : null}
            </div>
          )}
        </List>
      </MessageGroupContainer>
    </Root>
  );
};

export default MessageDisplay;
