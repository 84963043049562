import I18n, { TranslateOptions } from "i18n-js";
// import { getLocales } from "react-native-localize";
import da from "shared-assets/src/localisation/da/strings.json";
import en from "shared-assets/src/localisation/en/strings.json";
import { LanguageKey, PainAreaKey } from "shared-interfaces";

// const locales = getLocales();
const locales = navigator.languages?.map((l) => l.split("-")[0]);

if (Array.isArray(locales)) {
  [I18n.locale] = locales;
}
I18n.fallbacks = true;
I18n.translations = { en, da };

export const t = (key: string, param?: TranslateOptions): string =>
  I18n.t(key, param);
// export const getTimezone = (): string => getTimeZone();
export const getLanguage = (): LanguageKey => {
  const [primaryLanguage] = locales;
  return primaryLanguage ? (primaryLanguage as LanguageKey) : "en";
};
export const painReducer = (pain: PainAreaKey): string => {
  switch (pain) {
    case "neck":
      return t("painarea_neck");
    case "knee":
      return t("painarea_knee");
    case "lowerBack":
      return t("painarea_lowerback");
    case "midUpperBack":
      return t("painarea_upperback");
    case "groinHip":
      return t("painarea_groinhip");
    case "lowerLeg":
      return t("painarea_lowerleg");
    case "upperArm":
      return t("painarea_upperarm");
    case "thigh":
      return t("painarea_thigh");
    case "footAnkle":
      return t("painarea_footankle");
    case "shoulder":
      return t("painarea_shoulder");
    case "elbow":
      return t("painarea_elbow");
    case "forearm":
      return t("painarea_forearm");
    case "wristHand":
      return t("painarea_wristhand");
    default:
      return "";
  }
};
export const getPain = (painArea: PainAreaKey, lowercase = false): string => {
  const pa = painReducer(painArea);
  return lowercase ? pa.toLowerCase() : pa;
};

// export { getTimeZone } from "react-native-localize";
