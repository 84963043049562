import React from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  ExerciseTemplate,
  WorkoutDurationKey,
  WorkoutSummaryRow,
} from "shared-interfaces";
import { getWorkouts, useAppSelector } from "shared-redux";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import { useWindowSize } from "usehooks-ts";
import CloseButtonWithCircle from "../assets/svg/buttons/CloseButtonWithCircle";
import PauseButton from "../assets/svg/buttons/PauseButton";
import PlayButton from "../assets/svg/buttons/PlayButton";
import Heading from "../components/texts/Heading";
import Paragraph from "../components/texts/Paragraph";
import EquipmentItem from "../components/workout/EquipmentItem";

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 56.25%;
  justify-content: flex-end;
  align-items: center;
`;

const Button = styled.div`
  height: 52px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translate(-50%, 0);
`;
const SafeArea = styled.div`
  position: absolute;
  top: 0;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;
const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.palette.LIGHTEST_GREY};
`;
const CloseButtonContainer = styled.div`
  padding: 10px;
  float: right;
`;
const EquipmentList = styled.div`
  display: flex;
  flex-direction: row;
  list-style: none;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
`;

interface WorkoutDetailedExerciseScreenProps {
  children?: React.ReactNode;
  exerciseId?: number;
}

export const WorkoutDetailedExerciseScreen: React.FC<
  WorkoutDetailedExerciseScreenProps
> = () => {
  // Refs
  // const playerRef = React.useRef<Video | null>(null);
  const playerRef = React.useRef<ReactPlayer | null>(null);
  const params = useParams();
  const { width } = useWindowSize();

  // Navigation
  const navigate = useNavigate();
  // const route = useWorkoutRoute<"DetailedExercise">();

  // Store
  const workouts = useAppSelector(getWorkouts);

  // State
  const [paused, setPaused] = React.useState<boolean>(false);
  const [ended, setEnded] = React.useState<boolean>(false);

  // Methods
  const handlePlayPause = () => {
    if (ended) {
      setPaused(false);
      setEnded(false);
      return;
    }
    setPaused((v) => !v);
  };

  const stopVideo = async () => {
    if (!playerRef.current) return;
    // await playerRef.current.pauseAsync();
    playerRef.current = null;
  };

  // const handleStatusUpdate = (status: ExpandedAVPlaybackStatus) => {
  //   if (!status.didJustFinish) return;
  //   setPaused(true);
  //   setEnded(true);
  // };

  const handleClose = async () => {
    await stopVideo();
    navigate(-1);
  };

  const handleBackPress = () => {
    handleClose();
    return true;
  };

  // Computed Variables
  const type = params.workoutType as WorkoutDurationKey;
  const exerciseId = Number(params.exerciseId);
  const workout = workouts[type]; // workouts[route.params.workout];
  const { exercise }: { exercise?: ExerciseTemplate } =
    workout?.workoutSummary?.find(
      (e: WorkoutSummaryRow) => e.exercise.id === exerciseId
    ) ?? {};

  // Effect
  React.useEffect(() => {
    // BackHandler.addEventListener("hardwareBackPress", handleBackPress);
    // Audio.setAudioModeAsync(AUDIO_SETTINGS);
    // return () => {
    //   stopVideo();
    //   BackHandler.removeEventListener("hardwareBackPress", handleBackPress);
    // };
  }, []);

  // Renderers
  const renderVideoOverlays = () => (
    <Overlay>
      <SafeArea>
        <CloseButtonContainer onClick={handleClose}>
          <CloseButtonWithCircle />
        </CloseButtonContainer>
      </SafeArea>
      <Button onClick={handlePlayPause}>
        {paused ? <PlayButton size={52} /> : <PauseButton size={52} />}
      </Button>
    </Overlay>
  );

  const renderEquipment = () => {
    if (!exercise?.equipmentIds?.length) return null;
    return (
      <>
        <div
          style={{
            borderBottomColor: theme.palette.LIGHTER_GREY,
            borderBottomWidth: 1,
          }}
        />
        <div
          style={{
            padding: 15,
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
          }}
        >
          <EquipmentList>
            <Paragraph
              text={t("up_next_you_will_need")}
              style={{ marginRight: 10 }}
              marginBottom="0px"
            />
            {exercise.equipmentIds.map((item) => (
              <EquipmentItem name={item} horizontal />
            ))}
          </EquipmentList>
        </div>
      </>
    );
  };

  if (!exercise) return null;
  return (
    <div
      style={{ flex: 1, backgroundColor: theme.palette.WHITE, maxWidth: 950 }}
    >
      <div style={{ flex: 1, position: "relative" }}>
        {/* TODO handle max width */}
        <div
          style={{
            height: width / 1.78082191781,
            backgroundColor: theme.palette.LIGHTEST_GREY,
          }}
        >
          <ReactPlayer
            url={exercise.videoUrl}
            width="100%"
            height="100%"
            playing={!paused}
            onEnded={() => setPaused(true)}
            playsinline
          />
        </div>
        <div style={{ flex: 1 }}>
          <Heading
            size="largest"
            leftAlign
            text={exercise.name}
            style={{ padding: 15 }}
            marginBottom="0px"
          />
          <Separator />
          {renderEquipment()}
          <div
            style={{
              borderBottomColor: theme.palette.LIGHTER_GREY,
              borderBottomWidth: 1,
            }}
          />
          <div style={{ padding: 15 }}>
            <Paragraph
              style={{ whiteSpace: "pre-wrap" }}
              leftAlign
              text={exercise.steps}
            />
          </div>
        </div>
        {renderVideoOverlays()}
      </div>
    </div>
  );
};
