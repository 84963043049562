import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { CompletedExercise, WorkoutSchedule } from 'shared-interfaces';
import { userApi, workoutApi } from '../api';
import { RootState } from '../store';
import { logout } from './auth';

interface FailureResponse {
  data: {
    message: string;
    success: false;
  };
}

// State Interface
export interface WorkoutState {
  exercises: CompletedExercise[];
  schedule: WorkoutSchedule | null;
}

// Initial State
const initialState: WorkoutState = {
  exercises: [],
  schedule: null,
};

// Slice
export const workoutSlice = createSlice({
  name: 'workout',
  initialState,
  reducers: {
    workoutAddExercise: (state, action: PayloadAction<CompletedExercise>) => {
      state.exercises.push(action.payload);
    },
    workoutReset: (state) => {
      state.exercises = [];
    },
  },
  extraReducers: (builder) => {
    const { workoutScheduleFetch, workoutScheduleUpdate } = workoutApi.endpoints;
    const { userTimezoneUpdate } = userApi.endpoints;
    builder.addCase(logout, (state) => {
      state.exercises = [];
      state.schedule = null;
    });
    builder.addMatcher(
      isAnyOf(workoutScheduleFetch.matchFulfilled, workoutScheduleUpdate.matchFulfilled),
      (state, action) => {
        state.schedule = action.payload.data;
      }
    );
    builder.addMatcher(isAnyOf(userTimezoneUpdate.matchFulfilled), (state, action) => {
      state.schedule = action.payload.data.schedule;
    });
    builder.addMatcher(
      workoutScheduleFetch.matchRejected,
      (state, action: PayloadAction<unknown>) => {
        // console.log(action?.payload);
        if ((action?.payload as FailureResponse)?.data?.message !== 'WORKOUT_SCHEDULE_NOT_FOUND')
          return;
        state.schedule = null;
      }
    );
  },
});

// Actions
export const { workoutAddExercise, workoutReset } = workoutSlice.actions;

// Selectors
export const getExercises = (state: RootState): CompletedExercise[] => state.workout.exercises;
export const getWorkoutSchedule = (state: RootState): WorkoutSchedule | null =>
  state.workout.schedule;

// Reducer
export default workoutSlice.reducer;
