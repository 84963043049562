import React from "react";
import { WorkoutWithInfo } from "shared-interfaces";
import { t, theme } from "shared-utilities";
import CompletedWithCircle from "../../assets/svg/icons/CompletedWithCircle";
import SmallButton from "../buttons/SmallButton";
import Tile from "../patterns/Tile";
import Heading from "../texts/Heading";
import Paragraph from "../texts/Paragraph";

interface QuickWorkoutTileProps {
  workout: WorkoutWithInfo;
  showWorkout: (workout: WorkoutWithInfo) => void;
  completed?: boolean;
  isSubscribed: boolean;
}

const QuickWorkoutTile: React.FC<QuickWorkoutTileProps> = ({
  workout,
  showWorkout,
  completed,
  isSubscribed,
}) => {
  // Methods
  const handleShowWorkout = () => {
    if (completed) return;
    showWorkout(workout);
  };

  // Computed Variables
  const title = completed
    ? t("workout_quick_title_completed")
    : t(workout.title);
  const image = completed
    ? theme.images.quickWorkoutTile.QUICK_WORKOUT_COMPLETE_IMAGE
    : theme.images.quickWorkoutTile.QUICK_WORKOUT_IMAGE;

  return (
    <Tile
      backgroundColor={theme.palette.DARK_GREY}
      backgroundImage={image}
      onPress={handleShowWorkout}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }} // TODO fix height
      tileStyle={{ aspectRatio: 2 }}
    >
      {completed && (
        <CompletedWithCircle
          scale={theme.utilities.SCALE}
          style={{ marginBottom: theme.spacing(2) }}
        />
      )}
      <Heading
        size="larger"
        text={title}
        color={theme.palette.WHITE}
        marginBottom={theme.spacingPx(1)}
      />
      {!completed && (
        <Paragraph
          text={t("workout_estimate", {
            time:
              Math.floor(workout.time / 60) <= 0
                ? (workout.time / 60).toFixed(1)
                : Math.floor(workout.time / 60),
          })}
          color={theme.palette.WHITE}
          marginBottom={theme.spacingPx(2)}
        />
      )}
      {!completed && (
        <SmallButton
          onPress={handleShowWorkout}
          text={t("workout_action")}
          disabled={!isSubscribed}
        />
      )}
    </Tile>
  );
};

export default QuickWorkoutTile;
