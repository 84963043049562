import React from "react";
import { theme } from "shared-utilities";

const ManAtWhiteboard: React.FC<any> = ({ scale = 1, ...props }) => (
  <svg
    width={147 * scale}
    height={180 * scale}
    viewBox="0 0 147 180"
    {...props}
  >
    <g
      transform="translate(-114 -191)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path fill="#FFF" d="M0 0H375V667H0z" />
      <path
        d="M138.135 26.79c13.773 21.252 9.375 53.109-.287 79.108-9.62 25.965-24.463 46.053-43.91 54.681-19.406 8.626-43.376 5.778-61.712-4.663C13.847 145.472 1.1 127.445.074 110.272-.996 93.152 9.695 76.927 21.7 56.088c12.006-20.84 25.408-46.302 48.064-53.73 22.693-7.427 54.597 3.225 68.37 24.431z"
        fill={theme.palette.BACKGROUND}
        fillRule="nonzero"
        transform="translate(114 191)"
      />
      <g transform="translate(114 191) translate(24.328 27.761)">
        <path
          d="M37.575 26.202L47.87 15.904M53.677 21.38l11.052-11.05M70.483 15.657l2.402-2.403M82.276 22.503L96.632 8.147M47.905 15.733l5.516 5.519M64.745 10.437l5.469 5.469M73.027 13.129l9.258 9.258M96.998 7.792l3.571 3.57"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.317 15.915a.617.617 0 111.234 0 .617.617 0 01-1.234 0zM52.961 21.166a.617.617 0 111.234 0 .617.617 0 01-1.234 0zM64.065 10.625a.617.617 0 111.234 0 .617.617 0 01-1.234 0zM69.722 15.765a.617.617 0 111.234 0 .617.617 0 01-1.234 0zM72.396 13.171a.617.617 0 111.234 0 .617.617 0 01-1.234 0zM81.71 22.239a.617.617 0 111.234 0 .617.617 0 01-1.234 0zM96.372 7.97a.617.617 0 111.234 0 .617.617 0 01-1.234 0z"
          fill="#000"
          fillRule="nonzero"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M7.14732402 147.860002L1.60203556 151.128402 1.60203556 151.933725 12.4323967 151.933725 12.4323967 147.860002z"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M35.5781436 148.084326L40.8946216 148.084326 40.8946216 151.74305 35.5781436 151.74305z"
        />
        <path
          fill="#B99A78"
          fillRule="nonzero"
          d="M10.4538593 72.6553923L6.8915947 148.216677 12.894504 148.266029 25.1964304 98.8182965 24.902566 74.2166871z"
        />
        <path
          d="M41.595 72.507c-.097 2.15-.344 75.864-.344 75.864l-5.855.043L17.19 74.362s24.505-4.001 24.405-1.855z"
          fill="#C9AA89"
          fillRule="nonzero"
        />
        <path
          d="M5.066 51.762C3.85 53.812.938 69.282.965 72.9.027 76.14.788 85.888.812 87.18c.025 1.292-.224 6.127.052 7.316-.736 1.875 2.986 5.774 4.125 5.875 1.14.1 2.481-1.898 2.481-2.863 0-2.454-1.6-4.529-1.57-5.543.153-5.063 2.355-17.975 2.634-18.843.278-.868 1.215-2.557 2-3.241.786-.685 1.165-15.746 1.165-15.746s-5.985-3.466-6.633-2.373zM21.002 25.67c0 .638.39 6.484-.355 7.796-.745 1.312 7.353.868 7.353-.126 0-.993-.266-5.608.389-7.279.655-1.671-7.387-1.03-7.387-.39zM48.56 42.986l1.184.864s10.176-19.7 10.299-20.414c.123-.713-.673-2.467-.552-3.304.121-.837 4.215-4.895 5.498-4.957 1.283-.063 1.384.572 1.07 1.198-.314.625-2.77 3.12-2.77 3.12s1.7-.469 2.295 0c.594.469 2.849 3.475 2.003 4.79-.846 1.314-2.468 1.971-2.786 2.754-.319.783-9.987 27.97-11.174 28.507-2.003.897-8.481-2.243-9.453-3.787-.971-1.543 4.386-8.77 4.386-8.77z"
          fill="#E49D75"
          fillRule="nonzero"
        />
        <path
          d="M17.116 34.498c6.651 2.402 2.322 20.081.211 21.31-1.671.97-13.886-2.88-14.303-4.726-.619-2.734 8.311-18.67 14.092-16.584z"
          fill="#FFC48E"
          fillRule="nonzero"
        />
        <path
          d="M25.481 31.308c17.078-.056 18.738 38.891 15.871 41.556-5.16 4.803-29.98 2.652-31.062.103-1.774-4.177-.94-41.607 15.191-41.66z"
          fill="#FFD98E"
          fillRule="nonzero"
        />
        <path
          d="M27.971 38.2c2.946-6.428 20.205-.642 21.246 1.57.828 1.747-4.038 13.598-5.902 13.86-2.77.382-17.905-9.845-15.344-15.43z"
          fill="#FFE3AD"
          fillRule="nonzero"
        />
        <path
          d="M18.04 17.357c0-5.58 3.453-10.106 7.713-10.106s7.712 4.525 7.712 10.106c0 5.581-3.452 10.108-7.712 10.108s-7.713-4.527-7.713-10.108z"
          fill="#E49D75"
          fillRule="nonzero"
        />
        <path
          d="M15.501 16.056c-.282-4.217 2.156-8.136 3.024-10.6.75-2.368 2.243-4.127 4.172-4.219 1.93-.092 2.468.397 3.753.35 1.286-.047 3.518-1.5 4.971-1.5 1.454 0 2.322 1.038 3.22 1.689.897.65 2.75 1.875 2.743 2.954-.038 6.488-5.967 5.873-7.692 7.737-1.171.985-1.243 3.284-1.828 3.659-.586.374-1.853.152-1.876 1.994-.013 1.083.28 1.97 1.055 2.04.774.069 1.047.351 1.032.914-.164 5.433-4 6.454-5.564 6.297-2.191-.206-2.813-2.882-3.353-3.798-2.073-2.312-3.482-4.865-3.657-7.517z"
          fill="#7E5933"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default ManAtWhiteboard;
