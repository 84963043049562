import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WorkoutDurationKey, WorkoutSummaryRow } from "shared-interfaces";
import {
  useWorkoutSubmitMutation,
  getMuted,
  setMuted,
  getWorkouts,
  useAppDispatch,
  useAppSelector,
  workoutReset,
  getExercises,
} from "shared-redux";
import RestTimer from "src/components/workout/RestTimer";
import CancelSessionModal from "../components/modal/EndEarlyModal";

// TODO: replace with components
const View = "div";
const SafeAreaView = "div";

export const WorkoutRestScreen: React.FC<React.PropsWithChildren> = () => {
  const params = useParams();
  const navigate = useNavigate();

  // const isFocused = useIsFocused();
  const isFocused = true;

  // Refs
  const accumulatedTimeRef = React.useRef<number>(0);

  // Store
  const dispatch = useAppDispatch();
  const workouts = useAppSelector(getWorkouts);
  const muted = useAppSelector(getMuted);
  const exercises = useAppSelector(getExercises);
  const dispatchSetMuted = (muted: boolean) => dispatch(setMuted(muted));
  const dispatchResetExercise = () => dispatch(workoutReset());
  const [dispatchSubmitWorkout, { isLoading, error, isSuccess, reset }] =
    useWorkoutSubmitMutation();

  // State
  const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
  const [paused, setPaused] = React.useState<boolean>(false);

  // useAppStatus((appState, nextAppState) => {
  //   if (appState.match(/inactive|background/) && nextAppState === "active")
  //     return setPaused(false);
  //   if (nextAppState === "background") setPaused(true);
  // });

  // Computed variables
  const workoutKey = params.workoutType as WorkoutDurationKey;
  const exerciseIndex = Number(params.stepIndex) - 1;
  const workout = workouts[workoutKey];

  // Audio
  const audio = new Audio("/audio/in_session_new_exercise.mp3");
  audio.load();

  // Methods
  const playCompletionSound = async () => {
    if (muted) return;

    try {
      await audio.play();
    } catch (error) {
      // TODO: log error
    }
  };

  const addTime = (seconds: number) => {
    accumulatedTimeRef.current += seconds;
  };

  const showNext = (playSound: boolean) => {
    if (!isFocused) return;
    if (playSound) playCompletionSound();

    navigate(
      `/workout/${workoutKey}/session/${Number(params.stepIndex) + 1}/exercise`,
      { replace: true }
    );
  };

  const showTrophyPage = () => {
    // navigation.navigate("TrophyPage", {
    //   accumulatedTime: accumulatedTimeRef.current,
    //   workout: route.params.workout,
    // });
  };

  const handleWillFocus = () => setPaused(false);

  const handleCompletedRest = (seconds: number) => {
    addTime(seconds);
    showNext(true);
  };

  const handleMute = async () => {
    // await setMuteState(!muted);
    dispatchSetMuted(!muted);
  };

  const handleSubmitWorkout = () => {
    dispatchSubmitWorkout({ duration: workoutKey, exercises });
  };

  const handleShowCancelModal = () => {
    setPaused(!showCancelModal);
    setShowCancelModal(!showCancelModal);
  };

  const handleCompleteSession = async () => {
    await handleSubmitWorkout();
    handleShowCancelModal();
  };
  const handleCancelSession = () => {
    handleShowCancelModal();
    dispatchResetExercise();
    navigate(`/workout/${params.workoutType}/intro`);
  };

  const handleShowDetails = ({ exercise }: WorkoutSummaryRow) => {
    setPaused(true);
  };

  // Effect
  // React.useEffect(() => {
  // if (!isFocused) return;
  // const unsubscribeFocus = navigation.addListener("focus", handleWillFocus);
  // BackHandler.addEventListener("hardwareBackPress", handleBackPress);
  // activateKeepAwake();
  // return () => {
  //   BackHandler.removeEventListener("hardwareBackPress", handleBackPress);
  //   deactivateKeepAwake();
  //   unsubscribeFocus();
  // };
  // }, [workoutKey, isFocused]);

  React.useEffect(() => {
    if (!isSuccess) return;
    reset();
    navigate(`/workout/${params.workoutType}/trophy`, {
      replace: true,
    });
  }, [isSuccess]);

  React.useEffect(() => {
    setPaused(!isFocused);
  }, [isFocused]);

  React.useEffect(() => {
    if (!isFocused) return;
    return () => {
      // console.log("UNLOADING");
      // completedSound.unloadAsync().then(() => console.log("unloaded"));
    };
  }, [isFocused]);

  if (!workout) return null;

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "100%",
      }}
    >
      <SafeAreaView
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
      >
        <RestTimer
          onDone={handleCompletedRest}
          onShowCancelModal={handleShowCancelModal}
          onShowDetails={handleShowDetails}
          onMute={handleMute}
          seconds={workout.workoutSummary[exerciseIndex].rest}
          workout={workout}
          index={exerciseIndex + 1}
          paused={paused}
          muted={muted}
        />
        <CancelSessionModal
          isVisible={showCancelModal}
          exercise={workout.workoutSummary[exerciseIndex]}
          onContinueSession={handleShowCancelModal}
          onCompleteSession={handleCompleteSession}
          onCancelSession={handleCancelSession}
        />
      </SafeAreaView>
    </View>
  );
};
