/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import styled from "styled-components";
import { SelectorCheckboxItem } from "./SelectorCheckboxItem";

export const MultiSelectorWrapper = styled.div`
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 343px;
  gap: 8px;
`;

export const MultiSelector = ({
  items,
  value,
  onChange = () => null,
}: {
  items: any[];
  value?: number[];
  onChange?: (item: any) => void;
}) => {
  const [checkedItems, setCheckedItems] = useState<number[]>(value || []);

  const onSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e?.target as any;
    const numberId = Number(id);
    const filteredList = checkedItems.filter((item) => item !== numberId);

    if (checked) {
      setCheckedItems([...filteredList, numberId]);
      onChange([...filteredList, numberId]);
    } else {
      setCheckedItems(filteredList);
      onChange(filteredList);
    }
  };

  return (
    <MultiSelectorWrapper>
      {items.map((item) => (
        <SelectorCheckboxItem
          item={item}
          key={item.id}
          onChange={onSelectHandler}
          selectedList={checkedItems}
        />
      ))}
    </MultiSelectorWrapper>
  );
};
