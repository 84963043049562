import React from "react";
import { ScaleIconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const CompletedWithCircle: React.FC<ScaleIconProps & { style: any }> = ({
  color = theme.palette.WHITE,
  size = theme.spacing(4),
  scale = 1,
  ...props
}) => (
  <svg
    width={size * scale}
    height={size * scale}
    viewBox="0 0 32 32"
    {...props}
  >
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <circle stroke={color} strokeWidth="2" cx="16" cy="16" r="15" />
      <path
        d="M14.745 21.235l8.05-8.05a.7.7 0 000-.99l-.99-.99a.7.7 0 00-.99 0L14.25 17.77l-3.065-3.065a.7.7 0 00-.99 0l-.99.99a.7.7 0 000 .99l4.55 4.55a.7.7 0 00.99 0z"
        fill={color}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default CompletedWithCircle;
