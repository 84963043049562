import React from "react";
import { t } from "shared-utilities";
import styled from "styled-components";

import Heading from "../texts/Heading";

interface FeedbackCommentProps {
  setComment: (text: string, index: number) => void;
  feedbackIndex: number;
  comment?: string;
}

const AddCommitButton = styled.button`
  margin-top: 12px;
`;

const InputWrapper = styled.input`
  width: 299px !important;
  height: 40px;
  color: #145e7f;
  background: #e8eff2;
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 16px;
`;

const FeedbackComment: React.FC<FeedbackCommentProps> = ({
  setComment,
  feedbackIndex,
  comment = "",
}) => {
  // State
  const [expanded, setExpanded] = React.useState<boolean>(!!comment?.length);

  // Methods
  const handleExpand = () => setExpanded(true);

  // Renderer
  if (expanded) {
    return (
      <div style={{ padding: 10 }}>
        <InputWrapper
          value={comment}
          autoCapitalize="sentences"
          style={{ marginBottom: 0 }}
          placeholder={t("feedback_comment_form")}
          onChange={({ target: { value: text } }) => {
            setComment(text, feedbackIndex);
          }}
        />
      </div>
    );
  }

  return (
    <AddCommitButton type="button" onClick={handleExpand}>
      <div>
        <Heading size="tiny" bold text={t("feedback_comment_btn")} />
      </div>
    </AddCommitButton>
  );
};

export default FeedbackComment;
