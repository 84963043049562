import React from "react";
import {
  browserName,
  browserVersion,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from "react-device-detect";
import { IUserDevice } from "shared-interfaces";
import packageInfo from "../../package.json";
import deviceManager from "./DeviceManager";

export const useDeviceIdentifier = (): string | null => {
  const [deviceId, setDeviceId] = React.useState<string | null>(null);
  React.useEffect(() => {
    setDeviceId(deviceManager.getKey());
  }, []);
  return deviceId;
};

/**
 * Get metadata for device & application
 */
export const gatherMetadata = (): Omit<
  IUserDevice,
  "deviceId" | "notificationSetting" | "userId"
> => ({
  platform: osName.toLowerCase(),
  platformVersion: osVersion,
  browserName: browserName.toLowerCase(),
  browserVersion,
  device: `${mobileVendor.toLowerCase()} ${mobileModel.toLowerCase()}`,
  userAgent: navigator.userAgent,
  version: packageInfo.version,
  isWeb: true,
  screenHeight: window.innerHeight,
});
