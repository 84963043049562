import { createSlice } from '@reduxjs/toolkit';
import { AbortController } from 'shared-utilities';
import { RootState } from '../store';

declare global {
  // State Interface
  interface AuthState {
    token: string | null;
    loading: boolean;
  }
}

// Initial State
const initialState: AuthState = {
  token: null,
  loading: false,
};

// Slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      AbortController.shared.abortAll();
    },
    authSetToken: (state, action) => {
      state.token = action.payload;
    },
  },
  // TODO check create user works after replacing
  // extraReducers: (builder) => {
  // const { authLogin } = authApi.endpoints;
  // const { userCreate } = userApi.endpoints;
  // builder.addCase(resetAssessment, (state) => {
  //   state.token = null;
  // });
  // builder.addMatcher(
  //   isAnyOf(authLogin.matchFulfilled, userCreate.matchFulfilled),
  //   (state, action) => {
  //     state.token = action.payload?.data?.token;
  //   }
  // );
  // },
});

// Actions
export const { logout, authSetToken } = authSlice.actions;

// Selectors
export const getToken = (state: RootState): string | null => state.auth.token;
export const getAuthLoading = (state: RootState): boolean => state.auth.loading;

// Reducer
export default authSlice.reducer;
