import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { theme } from "shared-utilities";
import styled, { css } from "styled-components/macro";
import Send from "../../assets/svg/icons/Send";

const SendButton = styled.button({
  paddingLeft: 17,
  paddingRight: 7,
});
const ChatTextContainer = styled.div({
  flex: 1,
  borderRadius: 24,
  borderWidth: 1,
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 18,
  paddingRight: 18,
  backgroundColor: theme.palette.BACKGROUND,
});
const ChatTextInput = styled(TextareaAutosize)`
  color: ${theme.palette.PRIMARY};
  //text-align-vertical: center;
  vertical-align: middle;
  resize: none;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
`;
const disabledStyle = css({
  opacity: 0.5,
});

interface ChatInputProps {
  disabled?: boolean;
  isActive?: boolean;
  value?: string;
  onChange?: (event: any) => void;
  onLayout?: (event: any) => void;
  onSubmit?: () => void;
}

const ChatInput = React.forwardRef<any, ChatInputProps>(
  ({ disabled, isActive, onLayout, value, onChange, onSubmit }, ref) => {
    // Computed Variables
    const sendDisabled = disabled || !value?.trim().length;
    // const { bottom } = useSafeAreaInsets();
    // const keyboard = useReanimatedKeyboardAnimation();
    // const animatedStyle = useAnimatedStyle(() => ({
    //   transform: [{ translateY: -Math.max(Math.abs(keyboard?.height?.value) ?? 0, bottom) }],
    // }));
    const getDisabledStyle = () => {
      if (sendDisabled) {
        return disabledStyle;
      }
      return {};
    };

    const handleFocus = () => {
      // console.log("open");
    };

    const handleBlur = () => {
      // console.log("closed");
    };

    return (
      <div
        style={{
          borderTop: "solid",
          borderTopWidth: 1,
          borderTopColor: theme.palette.LIGHTER_GREY,
          position: "fixed",
          // alignSelf: "flex-end",
          left: 0,
          bottom: 64,
          width: "100%",
          backgroundColor: theme.palette.WHITE,
        }}
        // onLayout={onLayout}
      >
        <div
          style={{
            flex: 1,
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.LIGHTER_GREY,
          }}
        />
        <div style={{ padding: 15 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <ChatTextContainer
              css={[
                {
                  borderColor:
                    theme.palette[isActive ? "PRIMARY" : "LIGHTER_GREY"],
                },
              ]}
            >
              <ChatTextInput
                placeholder="Message your physio..."
                ref={ref}
                css={theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT}
                onChange={onChange}
                value={value}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              {/* <AutoGrowingTextInput */}
              {/*  ref={ref} */}
              {/*  style={[styles.chatTextInput]} */}
              {/*  onChangeText={onChange} */}
              {/*  placeholder={t("chat_input_placeholder")} */}
              {/*  placeholderTextColor={theme.palette.GREY} */}
              {/*  value={value} */}
              {/*  maxHeight={150} */}
              {/*  enableScrollToCaret */}
              {/*  underlineColorAndroid="transparent" */}
              {/*  allowFontScaling={false} */}
              {/* /> */}
            </ChatTextContainer>
            <SendButton
              type="button"
              css={getDisabledStyle()}
              onClick={onSubmit}
              disabled={sendDisabled}
            >
              <Send />
            </SendButton>
          </div>
        </div>
      </div>
    );
  }
);

export default ChatInput;
