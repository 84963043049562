import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export interface ErrorWithMessage {
  message: string;
  success: false;
}

interface ErrorResponse<T = ErrorWithMessage> {
  status: number;
  data: T;
}

export const createErrorResponse = <T = ErrorWithMessage>(error: any): ErrorResponse<T> => {
  if (!('data' in error)) throw Error('Incorrectly formed error');
  return error as ErrorResponse<T>;
};
