import React from "react";

import { ReactComponent as InfoIcon } from "shared-assets/src/icons/info.svg";
import { NonInteractiveQuestion } from "shared-interfaces";
import styled from "styled-components";
import Subtitle from "../../texts/Subtitle";

const InfoIconWrapper = styled.div`
  svg {
    width: 60px;
    height: 60px;

    path {
      fill: #e7eef2;
    }

    rect {
      fill: #fff;
    }

    circle {
      fill: #fff;
    }
  }
`;

const TextContainer = styled.div`
  max-width: 80%;
  padding-top: 26px;
`;

interface DescriptionProps {
  item: NonInteractiveQuestion;
}

const DescriptionQuestionView: React.FC<DescriptionProps> = ({ item }) => {
  return (
    <>
      <InfoIconWrapper>
        <InfoIcon />
      </InfoIconWrapper>
      <TextContainer>
        <Subtitle text={item.subtitle ?? ""} />
      </TextContainer>
    </>
  );
};

export default DescriptionQuestionView;
