import React from "react";
import { theme } from "shared-utilities";
import styled from "styled-components";
import { css, CSSProp, DefaultTheme } from "styled-components/macro";
import Apple from "../../assets/svg/icons/Apple";
import Facebook from "../../assets/svg/icons/Facebook";

const baseStyle = css({
  flexDirection: "row",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  paddingTop: 16,
  paddingBottom: 16,
  alignSelf: "stretch",
});
const LoadingContainer = styled.div({
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});
const Text = styled.div({});
const ButtonContainer = styled.div({});
const ButtonInner = styled.div({});

interface RoundButtonProps {
  text: string;
  subtitle?: string;
  backgroundColor?: string;
  textColor?: string;
  onPress: (() => Promise<void>) | (() => void);
  onCompleted?: () => void;
  completed?: boolean;
  completedText?: string;
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  style?: CSSProp<DefaultTheme>;
  appleIcon?: boolean;
  facebookIcon?: boolean;
  containerStyle?: CSSProp<DefaultTheme>;
}

const RoundButton: React.FC<RoundButtonProps> = ({
  text,
  subtitle,
  backgroundColor = theme.palette.BUTTON_PRIMARY,
  textColor = theme.palette.WHITE,
  onPress,
  onCompleted,
  completed,
  completedText,
  loading,
  loadingText,
  disabled,
  style = {},
  appleIcon,
  facebookIcon,
  containerStyle = {},
}) => {
  // State
  const [isPressedIn, setIsPressedIn] = React.useState<boolean>(false);

  // Methods
  const handlePress = React.useCallback(async () => {
    if (disabled) return;
    return onPress();
  }, [disabled, onPress]);
  const handlePressIn = React.useCallback(() => {
    // if (disabled) return;
    // return withHaptic(() => setIsPressedIn(true));
  }, [disabled]);
  const handlePressOut = React.useCallback(() => {
    if (disabled) return;
    setIsPressedIn(true);
  }, [disabled]);

  // Computed Variables
  const bg = React.useMemo((): string => {
    if (disabled || loading) return theme.palette.LIGHT_GREY;
    if (!isPressedIn) return backgroundColor ?? theme.palette.PRIMARY_SHADOW;
    switch (backgroundColor) {
      case theme.palette.PRIMARY:
        return theme.palette.PRIMARY_SHADOW;
      case theme.palette.WHITE:
        return theme.palette.LIGHTEST_GREY;
      case theme.palette.VIBRANT_ORANGE:
        return theme.palette.ORANGE;
      case theme.palette.LIGHTEST_GREY:
      case theme.palette.BACKGROUND:
        return theme.palette.LIGHTER_GREY;
      case theme.palette.LIGHT_GREY:
        return theme.palette.GREY;
      case theme.palette.RED:
        return theme.palette.DARK_RED;
      case theme.palette.BLACK:
        return theme.palette.DARK_GREY;
      default:
        return backgroundColor ?? theme.palette.PRIMARY_SHADOW;
    }
  }, [backgroundColor, disabled, isPressedIn, loading]);

  const buttonStyle = React.useMemo(() => ({ backgroundColor: bg }), [bg]);

  const baseStyles = [
    style,
    baseStyle,
    buttonStyle,
    { borderRadius: subtitle ? 37 : 27 },
  ] as CSSProp<DefaultTheme>[];
  // const loadingStyle = useAnimatedStyle(
  //   () => ({
  //     opacity: withTiming(loading ? 1 : 0),
  //   }),
  //   [loading]
  // );
  // const contentStyle = useAnimatedStyle(
  //   () => ({
  //     opacity: withTiming(loading ? 0 : 1),
  //   }),
  //   [loading]
  // );

  // Renderers
  const renderIcon = React.useMemo((): React.ReactElement | null => {
    if (!appleIcon && !facebookIcon) return null;

    const iconReducer = () => {
      if (appleIcon) return <Apple />;
      if (facebookIcon) return <Facebook />;
    };

    return <div style={{ marginRight: theme.spacing(1) }}>{iconReducer()}</div>;
  }, [appleIcon, facebookIcon]);
  const renderSubtitleElement = React.useMemo(() => {
    if (!subtitle) return null;
    return (
      <Text
        css={[
          theme.typography[theme.typography.FONT_NAME].SMALL_TEXT,
          { textAlign: "center", color: textColor, marginTop: 0 },
        ]}
      >
        {subtitle}
      </Text>
    );
  }, [subtitle]);
  const renderLoading = React.useMemo(() => {
    if (!loading) return null;
    return (
      <LoadingContainer
      // style={[styles.loadingContainer, loadingStyle]}
      // exiting={springify(FadeOut)}
      // entering={springify(FadeIn)}
      >
        {/* Loading */}
        {/* <ActivityIndicator size="small" color={textColor} /> */}
      </LoadingContainer>
    );
  }, [loading]);
  const renderInner = React.useMemo(
    (): React.ReactElement => (
      <>
        {renderLoading}
        <div>
          {renderIcon}
          <div>
            <Text
              css={[
                theme.typography[theme.typography.FONT_NAME]
                  .REGULAR_BOLD_HEADING,
                { textAlign: "center", color: textColor },
              ]}
            >
              {text}
            </Text>
            {renderSubtitleElement}
          </div>
        </div>
      </>
    ),
    [renderLoading, renderSubtitleElement, renderIcon, text]
  );

  return (
    <ButtonContainer
      onClick={handlePress}
      // onPressIn={handlePressIn}
      // onPressOut={handlePressOut}
      css={[{ width: "100%" }, containerStyle]}
    >
      <ButtonInner css={baseStyles}>{renderInner}</ButtonInner>
    </ButtonContainer>
  );
};

export default RoundButton;
