import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getWorkouts, useAppSelector } from "shared-redux";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import { Button } from "../components";
import Heading from "../components/texts/Heading";
import UpNext from "../components/workout/UpNext";

const Root = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const Gradient = styled.div`
  width: 100vw;
  flex: 1;
  background-image: linear-gradient(
    ${theme.palette.PRIMARY},
    ${theme.palette.WHITE},
    ${theme.palette.WHITE}
  );
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

export const WorkoutCountdownScreen: React.FC<React.PropsWithChildren> = () => {
  // Navigation
  const navigate = useNavigate();
  const params = useParams();

  // Refs
  const timerRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

  // Store
  const workouts = useAppSelector(getWorkouts);

  // State
  const [seconds, setSeconds] = React.useState<number>(3);
  const [audioLoading, setAudioLoading] = React.useState<boolean>(true);
  const [audioInitialStartError, setAudioInitialStartError] =
    React.useState<boolean>(false);

  // Audio
  const audio = new Audio("/audio/in_session_countdown.mp3");
  audio.load();

  // Computed Variables
  const workout =
    params.workoutType && workouts[params.workoutType as keyof typeof workouts];

  // Methods

  const handleDetailsClicked = () => {
    if (!workout) return;

    navigate(
      `/workout/${params.workoutType}/exercise/${workout?.workoutSummary[0].exercise.id}`
    );
  };

  const handleCountdown = () => {
    setSeconds((s) => s - 1);
  };

  const handleStartTimer = () => {
    audio.oncanplaythrough = async () => {
      try {
        if (!timerRef.current) {
          clearInterval(timerRef.current);
          timerRef.current = setInterval(handleCountdown, 1000);
        }

        if (timerRef.current) {
          await audio.play();
          setAudioLoading(false);
          return;
        }
      } catch (error) {
        // Silently fail
        console.log("Error playing audio", error);
        if ((error as Error).name === "NotAllowedError") {
          setAudioInitialStartError(true); // TODO - find out why this is happening
          setAudioLoading(false);
        }
      }

      clearInterval(timerRef.current);
      timerRef.current = setInterval(handleCountdown, 1000);
    };
  };

  // Effect
  React.useEffect(() => {
    handleStartTimer();
  }, []);

  React.useEffect(() => {
    if (seconds !== -1) return;
    if (timerRef.current) clearInterval(timerRef.current);
    navigate(`/workout/${params.workoutType}/session/1/exercise`, {
      replace: true,
    });
  }, [seconds, workout]);

  if (!workout) return null;

  return (
    <Gradient>
      <div style={{ flex: 1 }}>
        {!audioLoading && (
          <Root>
            {audioInitialStartError ? (
              <ButtonWrapper>
                <Button
                  handleClick={() => {
                    setAudioInitialStartError(false);
                    handleStartTimer();
                  }}
                >
                  Start workout
                </Button>
              </ButtonWrapper>
            ) : (
              <Heading
                size={seconds < 1 ? "bigger" : "huge"}
                color={theme.palette.WHITE}
                style={seconds < 1 ? { marginTop: "80px" } : {}}
                text={
                  seconds < 1 ? t("insession_countdown_ready") : `${seconds}`
                }
              />
            )}
          </Root>
        )}
        <UpNext
          headerText={t("insession_up_first")}
          exercise={workout.workoutSummary[0]}
          workoutSummary={workout.workoutSummary}
          onDetailsClicked={handleDetailsClicked}
        />
      </div>
    </Gradient>
  );
};
