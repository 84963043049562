import React from "react";
// import { View, Text, FlatList, StyleSheet } from "react-native";
import { WorkoutSummaryRow } from "shared-interfaces";
import { t, theme, getRound } from "shared-utilities";
import styled from "styled-components/macro";
import Paper from "../patterns/Paper";
import EquipmentItem from "./EquipmentItem";
// import EquipmentItem from "./EquipmentItem";
import UpNextRow from "./UpNextRow";

const Root = styled.div`
  position: absolute;
  bottom: 15px;
  width: calc(100% - 30px);
  align-self: stretch;
  padding-left: 15px;
  padding-right: 15px;
`;

const Inner = styled.div`
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
`;

const PaperInner = styled.div`
  background-color: ${theme.palette.WHITE};
  border-radius: 5px;
  border-color: ${theme.palette.LIGHTEST_GREY};
  border-width: 1px;
`;

const EquipmentContainer = styled.div`
  flex-direction: row;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
`;

const Text = styled.div``;

const View = styled.div``;

const EquipmentList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

interface UpNextProps {
  headerText: string;
  exercise: WorkoutSummaryRow;
  workoutSummary: WorkoutSummaryRow[];
  onDetailsClicked: () => void;
}

const UpNext: React.FC<UpNextProps> = ({
  headerText,
  exercise,
  workoutSummary,
  onDetailsClicked,
}) => {
  const round = React.useMemo(
    () => getRound(exercise, workoutSummary),
    [exercise, workoutSummary]
  );

  return (
    <Root>
      <Inner>
        <Text
          css={
            theme.typography[theme.typography.FONT_NAME].REGULAR_BOLD_HEADING
          }
          style={{ color: theme.palette.PRIMARY }}
        >
          {headerText}
        </Text>
        <Text
          css={theme.typography[theme.typography.FONT_NAME].SMALL_TEXT}
          style={{
            ...{ color: theme.palette.PRIMARY },
          }}
        >
          {round}
        </Text>
      </Inner>
      <Paper
        style={{
          padding: "0px",
          borderRadius: theme.utilities.BORDER_RADIUS,
          boxShadow: "0px 0px 2px #3f4c52",
        }}
      >
        <PaperInner>
          <UpNextRow exercise={exercise} onDetailsClicked={onDetailsClicked} />
          {exercise.exercise.equipmentIds && (
            <View>
              <View
                style={{
                  borderBottomColor: theme.palette.LIGHTER_GREY,
                  borderBottomWidth: 1,
                }}
              />
              {exercise.exercise.equipmentIds.length ? (
                <EquipmentContainer>
                  <Text
                    css={
                      theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT
                    }
                    style={{
                      marginRight: "10px",
                      color: theme.palette.PRIMARY,
                    }}
                  >
                    {t("up_next_you_will_need")}
                  </Text>
                  <EquipmentList
                  // horizontal
                  // data={exercise.exercise.equipmentIds}
                  // keyExtractor={(item) => item}
                  >
                    {exercise.exercise.equipmentIds.map((item) => (
                      <EquipmentItem name={item} horizontal />
                    ))}
                    {/* <EquipmentItem name="equipment_tag_small_ball" horizontal />
                  <EquipmentItem name="equipment_tag_small_ball" horizontal />
                  <EquipmentItem name="equipment_tag_small_ball" horizontal />
                  <EquipmentItem name="equipment_tag_small_ball" horizontal /> */}
                  </EquipmentList>
                </EquipmentContainer>
              ) : (
                ""
              )}
            </View>
          )}
        </PaperInner>
      </Paper>
    </Root>
  );
};

export default UpNext;
