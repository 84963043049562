import { theme } from "shared-utilities";
import styled, { keyframes } from "styled-components";

type TCardWrapperProps = {
  isActive: boolean;
};

const CardWrapper = styled.label<TCardWrapperProps>`
  padding: 19px 18px 15px 15px;
  border-radius: 4px;
  position: relative;
  display: flex;
  max-width: 340px;
  width: 100%;
  box-sizing: border-box;
  background: ${({ isActive }) => (isActive ? "#D0DEE5" : "#e7eef2")};
`;

const CardWrapperText = styled.div`
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${theme.palette.PRIMARY};
  max-width: 236px;
  padding-right: 24px;
  a {
    color: ${theme.palette.SECONDARY};
    text-decoration: none;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Indicator = styled.div`
  width: 20px;
  height: 20px;
  background: #fff;
  position: absolute;
  border-radius: 3px;
  transform: translateY(-50%);
  top: 50%;
  right: 24px;
  border: none;

  ${Input}:not(:disabled):checked & {
    background: #d1d1d1;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 3px;
    left: 7px;
    width: 21%;
    height: 49%;
    border: solid #145e7f;
    border-width: 0 0.1em 0.1em 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;

export type TInstructionCardProps = {
  id: string;
  name: string;
  label: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const InstructionCard: React.FC<TInstructionCardProps> = ({
  id,
  name,
  label,
  value,
  onChange,
}) => (
  <CardWrapper isActive={value} htmlFor={id}>
    <CardWrapperText dangerouslySetInnerHTML={{ __html: label }} />
    <CheckboxWrapper>
      <Input id={id} name={name} type="checkbox" onChange={onChange} />
      <Indicator />
    </CheckboxWrapper>
  </CardWrapper>
);
