import React from "react";
import { theme } from "shared-utilities";
import { css } from "styled-components";
import styled, { CSSProp, DefaultTheme } from "styled-components/macro";
import { WithChildren } from "../layout/BaseLayout";
import Heading from "../texts/Heading";
import Paper from "./Paper";

const Root = styled.div`
  flex: 1;
  margin-bottom: 12px;
`;
const Content = styled.div`
  flex: 1;
  align-items: center;
  padding: ${theme.spacingPx(4)};
`;
const TileContainer = styled.div<{ backgroundColor: string }>`
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: ${theme.utilities.BORDER_RADIUS}px;
  background-color: ${(props: { backgroundColor: string }) =>
    props.backgroundColor};
`;
const BackgroundImage = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: url(${(props: { src: string }) => props.src});
  background-size: cover;
`;
const BottomText = styled.div`
  background-color: ${theme.palette.WHITE};
  border-radius: 4px;
  padding: ${theme.spacingPx(1)};
  margin: ${theme.spacingPx(1)};
  align-self: stretch;
`;
const paper = css`
  flex: 1;
  padding: 0;
`;

interface TileProps {
  onPress?: () => void;
  style?: CSSProp<DefaultTheme>;
  tileStyle?: CSSProp<DefaultTheme>;
  backgroundColor?: string;
  backgroundImage?: any;
  trackingEvent?: string;
  bottomText?: string;
}

const Tile: React.FC<TileProps & WithChildren> = ({
  onPress,
  style,
  tileStyle,
  backgroundImage,
  backgroundColor = theme.palette.WHITE,
  trackingEvent,
  bottomText,
  children,
}) => {
  // Methods
  const handlePress = () => {
    // if (trackingEvent) Tracking.logClick(`${trackingEvent} Tile`);
    if (onPress) onPress();
  };

  // Renderers
  const renderContent = React.useCallback(
    () => <Content css={style}>{children}</Content>,
    [children]
  );
  const renderBackground = React.useCallback(
    () => (
      <BackgroundImage src={backgroundImage!}>
        {renderContent()}
        {bottomText && (
          <BottomText>
            {bottomText}
            <Heading
              size="tiny"
              text={bottomText}
              style={css`
                margin-bottom: 0;
              `}
            />
          </BottomText>
        )}
      </BackgroundImage>
    ),
    [backgroundImage, bottomText, renderContent]
  );
  const renderMain = React.useCallback(() => {
    if (!backgroundImage) return renderContent();
    return renderBackground();
  }, [backgroundImage, renderContent, renderBackground]);
  const renderTile = React.useCallback(
    () => (
      <Paper style={paper}>
        <TileContainer backgroundColor={backgroundColor} css={tileStyle}>
          {renderMain()}
        </TileContainer>
      </Paper>
    ),
    [backgroundColor, tileStyle, renderMain]
  );

  return <Root onClick={handlePress}>{renderTile()}</Root>;
};

export default Tile;
