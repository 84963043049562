import React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';
import { theme } from 'shared-utilities/src/theme/theme';
import { EmojiIconProps } from "shared-interfaces";

const VerySadIcon: React.FC<EmojiIconProps> = ({ size = theme.spacing(8), selected = true }) => (
  <Svg width={size} height={size} viewBox="0 0 64 64">
    <G fill="none" fillRule="evenodd">
      <Circle fill={selected ? theme.scales.RED : theme.palette.LIGHTEST_GREY} cx={32} cy={32} r={32} />
      <Path
        d="M47.849 21.684a1.965 1.965 0 0 1-1.058 2.57 3.42 3.42 0 0 1-.585.176c-1.093.236-2.444.134-3.907-.454a8.617 8.617 0 0 1-1.644-.79c-1.013-.633-1.64-1.33-1.957-1.908a1.965 1.965 0 0 1 3.446-1.89c.008.015.181.208.593.465.251.157.548.3.96.45.789.317 1.34.359 1.678.286.978-.35 2.07.125 2.474 1.095zM20.367 20.28c.348-.126.645-.27.896-.427.412-.257.585-.45.593-.464a1.965 1.965 0 1 1 3.446 1.889c-.317.579-.944 1.275-1.957 1.908a8.363 8.363 0 0 1-1.58.765c-1.527.613-2.878.715-3.971.479a3.42 3.42 0 0 1-.585-.177 1.965 1.965 0 0 1 1.42-3.663c.334.072.885.03 1.738-.31zM48.28 28.474a1.965 1.965 0 0 1 0 3.93h-7.859c-1.708 0-2.603-2.03-1.45-3.291l3.079-3.369a1.965 1.965 0 0 1 2.9 2.652l-.07.078h3.4zM20.172 28.396a1.965 1.965 0 1 1 2.901-2.652l3.08 3.369c1.152 1.26.257 3.29-1.451 3.29h-7.86a1.965 1.965 0 1 1 0-3.93h3.402l-.072-.077z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.5}
      />
      <G fill="#000" fillRule="nonzero" opacity={0.5}>
        <Path d="M46.307 52.883c.539-.639.846-1.597.616-2.554C46 42.746 39.695 37 32.468 37s-13.454 5.746-14.454 13.329c-.077.957.154 1.835.77 2.554C19.397 53.6 20.166 54 21.09 54H44c.847 0 1.692-.4 2.307-1.117zM32.545 50.3c-1.845 0-3.536-.478-5.074-1.277.769-2.234 2.768-3.75 5.228-3.75 2.307.08 4.306 1.596 4.997 3.75-1.614.878-3.306 1.277-5.15 1.277z" />
      </G>
    </G>
  </Svg>
);

export default VerySadIcon;
