import * as React from "react";
import { theme } from "shared-utilities";
import styled from "styled-components/macro";

const BottomNavItem = styled("div")({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: "80%",
  transition: "all 0.3s ease",
  marginTop: "8px",
});

// TODO fix font and color when selected
const BottomNavItemTitle = styled("div")(() => ({
  marginTop: "5px",
  marginBottom: "0px",
}));

export interface BottomNavigationActionProps {
  key: string;
  label?: string;
  icon: React.ReactNode;
  selected?: boolean;
  onChange?: any;
  value?: any;
  // onClick?: any;
}

export const BottomNavigationAction: React.FC<BottomNavigationActionProps> = ({
  key,
  label = "",
  icon,
  selected = false,
  onChange,
  value,
  // onClick,
}) => {
  const handleChange = (event: any) => {
    if (onChange) {
      onChange(event, value);
    }

    // if (onClick) {
    //   onClick(event);
    // }
  };

  return (
    <BottomNavItem onClick={handleChange}>
      {icon}
      <BottomNavItemTitle
        css={theme.typography[theme.typography.FONT_NAME].SMALL_TEXT}
      >
        {label}
      </BottomNavItemTitle>
    </BottomNavItem>
  );
};
