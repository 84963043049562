import React from "react";
import { DuotoneIconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const TargetDoneIcon: React.FC<DuotoneIconProps> = ({
  size = theme.spacing(2.75),
  color = theme.palette.PINK,
  colorTwo = theme.palette.WHITE,
}) => (
  <svg width={size} height={size} viewBox="0 0 22 22">
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <circle
        fill={color}
        stroke={color}
        strokeWidth={1.8}
        cx={10}
        cy={10}
        r={10}
      />
      <path
        d="M7.25 10.968a1.29 1.29 0 1 0-1.597 2.027l2.808 2.212a1.29 1.29 0 0 0 1.966-.464l3.644-7.742a1.29 1.29 0 0 0-2.335-1.099l-2.953 6.274-1.533-1.208z"
        fill={colorTwo}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default TargetDoneIcon;
