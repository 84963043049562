import { useNavigate } from "react-router-dom";
import { WorkoutDurationKey, WorkoutSummaryRow } from "shared-interfaces";
import { t, renderInstructions, theme } from "shared-utilities";
import styled from "styled-components/macro";
import PlayButton from "../../assets/svg/buttons/PlayButton";
import Heading from "../texts/Heading";
import Paragraph from "../texts/Paragraph";
import NewTag from "./NewTag";

// const styles = StyleSheet.create({
const Root = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacingPx(2)};
`;
const Inner = styled.div`
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;
const Image = styled.img`
  object-fit: cover;
  object-position: center;
  height: 50px;
  width: 50px;
  border-radius: 5px;
`;

interface ScriptRowProps {
  item: WorkoutSummaryRow;
  workoutType: WorkoutDurationKey;
}

const WIDTH = window.innerWidth;

const WorkoutSummaryRowView: React.FC<ScriptRowProps> = ({
  item,
  workoutType,
}) => {
  // Navigation
  // const navigation = useWorkoutNavigation();
  const navigate = useNavigate();

  // Methods
  const handleShowExercise = () => {
    navigate(`/workout/${workoutType}/exercise/${item.exercise.id}`);
  };

  // Computed Variables
  const equipment = item.exercise.equipmentIds ?? [];
  const textWidth = WIDTH - equipment.length * 50 - 2 * 24 - 50 - 20;

  return (
    <Root onClick={handleShowExercise}>
      <Inner>
        <Image
          // resizeMode="cover"
          src={item.exercise.thumbnailUrl}
        />
        <div style={{ marginLeft: 20, maxWidth: textWidth }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Heading
              size="regular"
              text={item.exercise.name}
              leftAlign
              marginBottom="0px"
              style={{ marginRight: 5 }}
              ellipsizeMode="tail"
              numberOfLines={2}
            />
            {item.isNew && <NewTag />}
          </div>
          <Paragraph
            size="small"
            leftAlign
            marginBottom="0px"
            text={renderInstructions(item)}
          />
        </div>
        <div style={{ position: "absolute", left: 34, top: 34 }}>
          <PlayButton />
        </div>
      </Inner>
      {/* <View> */}
      {/*  {Boolean(equipment.length) && ( */}
      {/*    <FlatList */}
      {/*      horizontal */}
      {/*      data={equipment} */}
      {/*      renderItem={({ item }) => <EquipmentItem name={item.trim()} />} */}
      {/*      ItemSeparatorComponent={RowSeparator} */}
      {/*      keyExtractor={(item) => item} */}
      {/*    /> */}
      {/*  )} */}
      {/* </View> */}
    </Root>
  );
};

export default WorkoutSummaryRowView;
