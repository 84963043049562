import { createContext, useContext } from "react";

export type StoreType = {
  profile: {
    email: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
  };
};

export const ContextStore = createContext<StoreType | null>(null);

export const useStore = () =>
  useContext(ContextStore as React.Context<StoreType>);
