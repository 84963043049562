import React from "react";
import { PainAreaKey } from "shared-interfaces";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import Lock from "../../assets/icons/Lock";
import Paragraph from "../texts/Paragraph";

const WorkoutCountDownContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 15px;
  align-items: center;
  padding-bottom: 20px;
  display: inline-block;
`;
const WorkoutCountDownInnerContainer = styled.div`
  height: 40px;
  border-radius: 20px;
  align-self: center;
  margin-top: 8px;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  border-style: solid;
  border-color: ${theme.palette.LIGHTEST_GREY};
  border-width: 1px;
`;
const LockContainer = styled.div`
  margin-top: 6px;
  align-items: center;
  flex-direction: row;
`;
const UnlockText = styled.span`
  padding-left: 15px;
  color: ${theme.palette.PRIMARY};
`;

interface CheckinSummary {
  comments: { date: string; value: string }[];
  nextCheckinCountdown: number;
  painInRelationToActivity: {
    date: string;
    painfulActivity: PainAreaKey;
    value: number;
  }[];
  painInRelationToArea: {
    date: string;
    painArea: PainAreaKey;
    value: number;
  }[];
  painfulActivity: string;
  satisfactionWithTreatment: {
    date: string;
    value: number;
  };
}

interface CheckinCountdownProps {
  checkin?: CheckinSummary;
}

const CheckinCountdown: React.FC<CheckinCountdownProps> = ({ checkin }) => {
  // Computed Variables
  const { nextCheckinCountdown } = checkin ?? {};
  if (nextCheckinCountdown === null) return null;

  return (
    <WorkoutCountDownContainer>
      <Paragraph
        css={[
          theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT,
          { color: theme.palette.PRIMARY },
        ]}
      >
        {t("progress_checkin_countdown_title")}
      </Paragraph>
      <WorkoutCountDownInnerContainer>
        <LockContainer>
          <Lock />
          <UnlockText
            css={
              theme.typography[theme.typography.FONT_NAME].SMALL_BOLD_HEADING
            }
          >
            {t("progress_checkin_countdown_txt", {
              nextCheckin: nextCheckinCountdown,
            })}
          </UnlockText>
        </LockContainer>
      </WorkoutCountDownInnerContainer>
    </WorkoutCountDownContainer>
  );
};

export default CheckinCountdown;
