import React, { FunctionComponent, PropsWithChildren } from "react";
import { theme } from "shared-utilities";
import styled, { CSSProp, DefaultTheme } from "styled-components/macro";
import type {} from "styled-components/cssprop";
import useWindowSize from "../../utilities/useWindowSize";

export const ScreenContainer = styled.div<{
  src?: string;
  maxWidth: number;
}>`
  position: relative;
  max-width: ${(props) => props.maxWidth}px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: ${theme.typography.FONT_NAME};
  font-weight: 400;
  background: ${({ src }) => `url(${src}) no-repeat center center fixed`};
  background-size: cover;
`;

const Background = styled.div<{ src: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ src }) => `url(${src}) no-repeat center center fixed`};
  background-size: cover;
`;

export const ScreenElement: FunctionComponent<
  {
    bgImage?: string;
    extraStyles?: CSSProp<DefaultTheme>;
  } & PropsWithChildren
> = ({ bgImage, extraStyles, children }) => {
  const [size, setSize] = React.useState((95 / 100) * window.outerWidth);
  const { height, width } = useWindowSize();

  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize((95 / 100) * window.outerWidth);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      {/* {!!bgImage && <Background src={bgImage} />} */}
      <ScreenContainer css={extraStyles} maxWidth={size} src={bgImage}>
        {children}
      </ScreenContainer>
    </>
  );
};
