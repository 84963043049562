import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment';

import { IUser } from 'shared-interfaces';
import { assessmentApi, authApi, userApi } from '../api';

import { RootState } from '../store';

import { resetAssessment } from './assessment';
import { logout } from './auth';
import { getRequiresPayment } from './referral';

declare global {
  // State Interface
  interface UserState {
    user: IUser | null;
  }
}

// Initial State
const initialState: UserState = {
  user: null,
};

// Slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userOneTimeLogin: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    const { assessmentCreateAccountDetails, assessmentCreateMedicalProfile } =
      assessmentApi.endpoints;
    const { authLogin, authGetMe } = authApi.endpoints;
    const {
      userCreate,
      userUpdate,
      userRegisterDevice,
      userUnregisterDevice,
      userEnablePush,
      userDisablePush,
      userUpdateNotificationSetting,
      userTimezoneUpdate,
    } = userApi.endpoints;
    builder.addMatcher(isAnyOf(logout, resetAssessment), (state) => {
      state.user = null;
    });
    builder.addMatcher(
      isAnyOf(
        authLogin.matchFulfilled,
        assessmentCreateAccountDetails.matchFulfilled,
        assessmentCreateMedicalProfile.matchFulfilled,
        userCreate.matchFulfilled,
        userUpdate.matchFulfilled,
        userRegisterDevice.matchFulfilled,
        userUnregisterDevice.matchFulfilled,
        userEnablePush.matchFulfilled,
        userDisablePush.matchFulfilled,
        userUpdateNotificationSetting.matchFulfilled,
        userTimezoneUpdate.matchFulfilled
      ),
      (state, action) => {
        if (!action.payload?.data?.user) return;
        if (!state.user) {
          state.user = action.payload.data.user;
          return;
        }
        state.user = { ...state.user, ...action.payload.data.user };
      }
    );
    builder.addMatcher(authGetMe.matchFulfilled, (state, action) => {
      state.user = action.payload.data;
    });
  },
});

// Actions
// export const { setLanguage } = miscellaneousSlice.actions;
export const { userOneTimeLogin } = userSlice.actions;

// Selectors
export const getUser = (state: RootState): IUser | null => state.user.user;
export const getIsAuthed = (state: RootState): boolean => !!state.user.user;
export const getIsPaidUp = (state: RootState): boolean => {
  if (!getRequiresPayment(state)) return true;
  const { expiresAt } = state.user.user?.subscriptionDetails ?? {};
  if (!expiresAt) return false;
  return moment(expiresAt).isAfter(new Date());
};

// Reducer
export default userSlice.reducer;
