import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  assessmentApi,
  authApi,
  chatApi,
  checkinApi,
  learnApi,
  planApi,
  progressApi,
  referralApi,
  useUserRegisterDeviceMutation,
  userApi,
  authSetToken,
  logout,
  setMedicalProfileDateOfBirth,
  setMedicalProfileFirstName,
  setMedicalProfileLastName,
  setReferralDetails,
  useAppDispatch,
  userOneTimeLogin,
} from "shared-redux";
import { Heading } from "shared-utilities/src/ui";
import styled from "styled-components/macro";
import { gatherMetadata } from "../utilities/common";
import { getDeviceIdentifier } from "../utilities/storage";

const Container = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  margin: 1rem 0;
  width: 80px;
`;

export const AuthHandover = () => {
  const [logo, fetchLogo] = React.useState<JSX.Element | null>(null);
  const [dispatchRegisterDevice] = useUserRegisterDeviceMutation();

  // Navigation
  const navigate = useNavigate();

  // Store
  const dispatch = useAppDispatch();
  const handleResetState = async () => {
    await Promise.all(
      [
        logout(),
        assessmentApi.util.resetApiState(),
        authApi.util.resetApiState(),
        chatApi.util.resetApiState(),
        checkinApi.util.resetApiState(),
        learnApi.util.resetApiState(),
        planApi.util.resetApiState(),
        progressApi.util.resetApiState(),
        referralApi.util.resetApiState(),
        userApi.util.resetApiState(),
      ].map(dispatch)
    );
  };
  useEffect(() => {
    import("shared-assets/src/themes/defaultTheme/icons/logo.svg").then(
      (res) => {
        fetchLogo(<Logo src={res.default} />);
      }
    );
    handleResetState();
  }, []);

  // Computed variables
  const [searchParams] = useSearchParams();
  const singleUseToken = searchParams.get("token");
  const storageToken = localStorage.getItem("token");

  // Methods
  const handleLogin = () => {
    if (storageToken) navigate("/launch");

    fetch(`${process.env.REACT_APP_SERVERLESS_API}/auth/get-token-wpa`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${singleUseToken}`,
      },
    })
      .then((response) => response.json())
      .catch((err) => {
        console.log("err", err);
        navigate("/");
      })
      .then((data) => {
        const { token, user, shouldCompleteAssessment } = data.data;
        const { referralCode: code, organisation } = user;
        dispatch(authSetToken(token));
        dispatch(setReferralDetails({ code, organisation }));
        dispatch(userOneTimeLogin(user));

        const device = {
          deviceId: getDeviceIdentifier(),
          ...gatherMetadata(),
        };

        dispatchRegisterDevice(device);

        if (shouldCompleteAssessment) {
          dispatch(setMedicalProfileFirstName(user.firstName));
          dispatch(setMedicalProfileLastName(user.lastName));
          dispatch(
            setMedicalProfileDateOfBirth(user.medicalInformation.gender)
          );
          return navigate("/instructions");
        }
        // check for assessment
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    // if (storageToken) navigate("/launch");
    handleLogin();
  }, []);

  return (
    <Container>
      <Heading align="center" size="lg">
        Loading
      </Heading>
      {logo}
    </Container>
  );
};
