import React from 'react';
import { Account, ChatMessage, IUser, PhysioChatMessage } from "shared-interfaces";
import { getChatConnected, getTypingState, getUser, useAppSelector } from "shared-redux";
import chatManager from "./chat";

export interface MessageGroupType<T> {
  userId: number;
  isPhysio: boolean;
  messages: T[];
}

/**
 * Checks if message is from logged-in user
 * @param message
 */
export const isOwnMessage = (message: ChatMessage): boolean => {
  return !message.staffId;
};

/**
 * Type guard to determine if object is Account
 * @param user
 */
export const isPhysioAccount = (user: Account | IUser): user is Account => {
  return !(user as IUser)?.medicalInformation;
};

/**
 * Returns the initials of x many names passed in
 * as separate arguments
 * @param value
 */
export const getInitials = (...value: (string | undefined)[]): string =>
  value
    .filter(Boolean)
    .map((v) => {
      if (!v?.length) return '';
      return v[0];
    })
    .join('')
    .toUpperCase();

/**
 * Get personal details from either an Account or
 * User object
 * @param user
 */
export const getPersonalDetails = (user: Account | IUser | null): Pick<IUser, 'firstName' | 'lastName'> => {
  if (!user) return { firstName: '', lastName: '' };
  const { firstName, lastName } = user ?? {};
  return { firstName, lastName };
};

/**
 * Function to reduce the messages into a grouped
 * structure for display in app
 * @param user
 */
export const reduceMessages =
  (user: Account | IUser) =>
    (acc: MessageGroupType<ChatMessage>[], message: ChatMessage): MessageGroupType<ChatMessage>[] => {
      // TODO: FIX LOGIC PERTAINING TO WHO SENT MESSAGE
      const isPhysioMessage = !isOwnMessage(message);
      const { userId, staffId } = message;
      const uid = staffId ?? userId;
      if (!acc.length) return [...acc, { userId: uid, isPhysio: isPhysioMessage, messages: [message] }];
      const lastMessageGroup = acc[acc.length - 1];
      if (lastMessageGroup.userId === uid) {
        lastMessageGroup.messages = [message, ...lastMessageGroup.messages];
        return acc;
      }
      return [...acc, { userId: uid, isPhysio: isPhysioMessage, messages: [message] }];
    };

/**
 * Hook to update typing status
 * @param message
 */
export const useTypingIndicator = (message: string): string[] => {
  const isTyping = Boolean(message.length);
  const user = useAppSelector(getUser);
  const typingState = useAppSelector(getTypingState);
  const connected = useAppSelector(getChatConnected);

  React.useEffect(() => {
    if (!connected || !user) return;
    chatManager.send({
      action: 'setTypingIndicator',
      userId: user.id,
      body: {
        conversationId: user.id,
        userId: user.id,
        isTyping,
      },
    });
  }, [isTyping, connected, user?.id]);

  return typingState;
};

/**
 * Resolves user from an array of Users and Accounts
 * @param group
 * @param users
 */
export const resolveUser = (
  group: MessageGroupType<ChatMessage>,
  users: (IUser | Account | null)[],
): IUser | Account | null => users.filter(Boolean).find((user) => user!.id === group.userId) ?? null;
