import { theme } from "shared-utilities";
import styled from "styled-components";

const Icon = styled.svg<{ focused?: boolean }>`
  transition: 0.3s ease;
  transform: ${(props) => (props.focused ? "scale(1.2)" : "scale(1)")};

  .accent {
    stroke: ${(props) =>
      props.focused ? theme.palette.ACCENT_PRIMARY : theme.palette.PRIMARY};
  }
`;

const ProgressSelectedIcon: React.FC<any> = ({
  color = theme.palette.PRIMARY,
  size = theme.spacing(3.375),
  focused = false,
  ...props
}) => (
  <Icon
    focused={focused}
    transform="scale(1.2)"
    width={size}
    height={size}
    viewBox="0 0 23 27"
    {...props}
  >
    <g
      transform="translate(-26 -12) translate(26 12)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M22.348 14.969c0 5.964-4.835 10.799-10.8 10.799-5.963 0-10.798-4.835-10.798-10.8C.75 9.006 5.585 4.17 11.549 4.17c5.964 0 10.799 4.835 10.799 10.799z"
        stroke={color}
      />
      <path stroke={color} d="M22.953 6.1084L20.131 3.5634" />
      <path stroke={color} d="M19.7304 6.8437L21.4544 4.9327" />
      <path stroke={color} d="M11.5487 4.1699L11.5487 1.5469" />
      <path stroke={color} d="M7.2245 0.75L15.6235 0.75" />
      <path
        className="accent"
        d="M8.413 15.8056L10.435 18.0906 15.624 11.5956"
      />
    </g>
  </Icon>
);

export default ProgressSelectedIcon;
