import React from "react";
// import { Dimensions } from 'react-native';
// import { StackCardStyleInterpolator } from '@react-navigation/stack';
import {
  Answer,
  AnswerValue,
  ChoiceAnswer,
  InteractiveAnswerValue,
  MultipleChoiceAnswer,
  TextAnswer,
  MainQuestionCondition,
  ChoiceQuestion,
  NonInteractiveQuestion,
  Question,
  QuestionChoice,
  TextQuestion,
} from "shared-interfaces";

// const { width: SCREEN_WIDTH } = Dimensions.get('window');
export const NON_INTERACTIVE_TYPES = [
  "section_intro",
  "red_flag_warning",
  "description",
  "instructions_of_use",
];
export const TEXT_TYPES = ["short_text", "long_text"];
export const DATE_FORMAT = "DD/MM/YYYY";
export const INSTRUCTIONS_OF_USE_LINK =
  "https://www.ascenti.co.uk/reach/instructions-for-use";
export const TERMS_AND_CONDITIONS_LINK =
  "https://www.ascenti.co.uk/terms-conditions";
export const PRIVACY_POLICY_LINK = "https://www.ascenti.co.uk/reach/privacy";

/**
 * Function to safely type questions
 * @param answer
 */
export function typeAnswer<T extends AnswerValue>(
  answer?: Answer
): Answer<T> | undefined {
  if (!answer) return undefined;
  return answer as Answer<T>;
}

/**
 * Assessment Context
 */
interface IAssessmentContext {
  buttonHeight: number;
  currentId: number;
  next: () => void;
}

export const AssessmentContext = React.createContext<IAssessmentContext>({
  buttonHeight: 0,
  next: console.log,
  currentId: -1,
});

/**
 * Decides whether to proceed on an answer
 * @param answer
 */
export const shouldProceedOnValue = (answer?: Answer): boolean => {
  switch (answer?.input.type) {
    case "choice":
    case "yes_no":
      return true;
    default:
      return false;
  }
};

/**
 * Decides whether to proceed on a question
 * @param question
 */
export const shouldProceedOnQuestion = ({ type }: Question): boolean => {
  switch (type) {
    case "choice":
    case "pain_scale":
    case "yes_no":
      return true;
    default:
      return false;
  }
};

export const getAnswerChoice = (
  question: ChoiceQuestion,
  answer: Answer<ChoiceAnswer> | undefined
): QuestionChoice | undefined => {
  return question.questionChoices.find(
    (choice) => choice.id === answer?.input.value
  );
};

/**
 * Hook for getting assessment context
 */
export const useAssessmentContext = (): IAssessmentContext =>
  React.useContext(AssessmentContext);

/**
 * Hook for auto progression in assessment
 * @param question
 * @param value
 */
export const useAutoProgress = (question: Question, value?: Answer) => {
  const { next, currentId } = useAssessmentContext();
  React.useEffect(() => {
    if (value === undefined || currentId !== question.id) return;
    setTimeout(next, 100);
  }, [value]);
};

export const findAnswerPredicate = (question: Question) => (answer: Answer) =>
  answer.question === question.id;

export const isNonInteractive = (
  question: Question
): question is NonInteractiveQuestion => {
  return NON_INTERACTIVE_TYPES.includes(question.type);
};

export const isTextQuestion = (
  question: Question
): question is TextQuestion => {
  return TEXT_TYPES.includes(question.type);
};

// export const hasTypeNone = (question: Question): boolean => {
//   return (
//     question.type === "multiple_choice" &&
//     question.questionChoices.some((c) => c.ofType === "None")
//   );
// };

export const findAnswer = <T extends AnswerValue>(
  question: Question,
  answers: Answer[]
): Answer<T> | undefined => {
  const value = answers.find(findAnswerPredicate(question));
  if (value) return value as Answer<T>;
};

export const canProceedReducer = (
  acc: boolean,
  [question, answer]: [Question, Answer | undefined]
): boolean => {
  const reduce = (): boolean => {
    if (isNonInteractive(question)) return true;
    switch (question.type) {
      case "short_text":
      case "long_text":
        return (
          question.validations?.some((v) => v.required === false) ||
          Boolean(typeAnswer<TextAnswer>(answer)?.input.value.length)
        );
      case "multiple_choice":
        return (
          !question.required ||
          Boolean(typeAnswer<MultipleChoiceAnswer>(answer)?.input.value.length)
        );
      case "yes_no":
      case "pain_scale":
      case "pain_slider":
        return (
          typeAnswer<InteractiveAnswerValue>(answer)?.input.value !== undefined
        );
      default:
        return Boolean(typeAnswer<InteractiveAnswerValue>(answer)?.input.value);
    }
  };
  return acc && reduce();
};

/**
 * Group questions into screens
 * @param answerMap
 */
export const questionGroupingPredicate =
  (answerMap: Map<number, Answer>) =>
  (question: Question): [Question, Answer | undefined][] => {
    const currentAnswer = answerMap.get(question.id!) as
      | Answer<InteractiveAnswerValue>
      | undefined;
    const questionArray: [Question, Answer | undefined][] = [
      [question, currentAnswer],
    ];
    if (question.type === "choice") {
      const choice = getAnswerChoice(
        question,
        typeAnswer<ChoiceAnswer>(currentAnswer)
      );
      if (choice?.subQuestion)
        questionArray.push([
          choice.subQuestion,
          typeAnswer<InteractiveAnswerValue>(
            answerMap.get(choice.subQuestion.id!)
          ),
        ]);
    }
    if (!question.subQuestions?.length) return questionArray;
    return question.subQuestions.reduce<[Question, Answer | undefined][]>(
      (acc, { question, showIf }) => {
        if (showIf.always)
          return [...acc, [question, answerMap.get(question.id!)]];
        if (currentAnswer === undefined) return acc;
        if (showIf.equals === currentAnswer?.input?.value)
          return [...acc, [question, answerMap.get(question.id!)]];
        return acc;
      },
      questionArray
    );
  };

/**
 * Top level condition predicate
 * @param conditions
 * @param answers
 */
export const questionConditionPredicate = (
  conditions: [number, MainQuestionCondition[]][],
  answers: Answer[]
): ((question: Question) => boolean) => {
  const conditionsMap = new Map(conditions);
  const answerMap = new Map(answers.map((a) => [a.question, a]));

  return (question: Question) => {
    const conditions = conditionsMap.get(question.id!);
    if (!conditions?.length) return true;
    return conditions.some(({ questionId, value, operator }) => {
      const answer = answerMap.get(questionId);
      if (!answer || !("value" in answer.input)) return false;
      const isArray = answer.input.value instanceof Array;
      const arrayAnswer = (isArray ? answer.input.value : [answer.input.value]) as (string | number | boolean)[];
      return arrayAnswer.some((v) => {

      switch (operator) {
        case "=":
          return v === value;
        case "!=":
          return v !== value;
        case ">":
          return v > value;
        case "<":
          return v < value;
        case ">=":
          return v >= value;
        case "<=":
          return v <= value;
      }
      return true;
      });
    });
  };
};

// /**
//  * Screen interpolator that matches original functionality
//  * @param current
//  * @param next
//  */
// export const ASSESSMENT_NAVIGATOR_CARD_INTERPOLATOR: StackCardStyleInterpolator =
//   ({ current, next }) => {
//     const bottomTransform = next && {
//       translateX: next.progress.interpolate({
//         inputRange: [0, 1],
//         outputRange: [0, -SCREEN_WIDTH],
//       }),
//     };
//     const topTransform = {
//       translateX: current.progress.interpolate({
//         inputRange: [0, 1],
//         outputRange: [SCREEN_WIDTH, 0],
//       }),
//     };
//     const opacity = next
//       ? next.progress.interpolate({
//           inputRange: [0, 1],
//           outputRange: [1, 0],
//         })
//       : current.progress;

//     return {
//       cardStyle: {
//         opacity,
//         transform: [bottomTransform, topTransform].filter(Boolean),
//       },
//     };
//   };
