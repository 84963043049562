import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Meh } from "shared-assets/src/icons/Meh.svg";
import { ReactComponent as Sad } from "shared-assets/src/icons/Sad.svg";
import { ReactComponent as VeryHappy } from "shared-assets/src/icons/VeryHappy.svg";
import { ReactComponent as VerySad } from "shared-assets/src/icons/VerySad.svg";
import { typography } from "shared-utilities";
import { ScreenElement } from "src/components";
import { AnimationLoader } from "src/components/feedback/AnimationLoader";
import styled from "styled-components";

const Title = styled.h1`
  font-family: ${typography.FONT_NAME};
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #145e7f;
  margin: 60px 0 0;
`;

const SubTitle = styled.p`
  margin: 18px 0 30px;
  font-family: ${typography.FONT_NAME};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #145e7f;
  max-width: 320px;
`;

const FeedbackWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 16px;
  justify-content: center;
`;

type FeedbackRowProps = {
  isActive: boolean;
  activeBg: string;
  activeColor: string;
};

const FeedbackRow = styled.button<FeedbackRowProps>`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 190px;
  padding: 0;
  color: ${({ isActive, activeColor }) => (isActive ? activeColor : "#4F615C")};
  background-color: ${({ isActive, activeBg }) =>
    isActive ? activeBg : "#edefef"};
  font-family: ${typography.FONT_NAME};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.2px;

  svg {
    width: 64px;
    height: 64px;
  }

  p {
    margin: 30px 0 0;
  }
`;

export const WorkoutFeedbackScreen = () => {
  // Refs
  // const timerRef = React.useRef<NodeJS.Timeout | undefined>();

  // Navigation
  const params = useParams();
  const navigate = useNavigate();

  // State
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(
    params?.index ? Number(params?.index) : undefined
  );
  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  // Methods
  const handleSubmitFeedback = (index: number) => {
    if (index !== selectedIndex) {
      setSelectedIndex(index);
    }

    if (!index) {
      setShowLoader(true);
      setTimeout(() => {
        navigate(`/`);
      }, 3500);
    } else {
      navigate(`/workout/${params?.workoutType}/feedback-detailed/${index}`);
    }
  };

  return (
    <ScreenElement>
      {showLoader && <AnimationLoader />}
      <Title>How was the session?</Title>
      <SubTitle>
        Our physios will use your feedback to improve your next workout session
      </SubTitle>
      <FeedbackWrapper>
        <FeedbackRow
          isActive={selectedIndex === 0}
          activeBg="#DEF8F0"
          activeColor="#13C48E"
          onClick={() => handleSubmitFeedback(0)}
        >
          <VeryHappy />
          <p>All good</p>
        </FeedbackRow>
        <FeedbackRow
          isActive={selectedIndex === 1}
          activeBg="#FFF9ED"
          activeColor="#FFC44E"
          onClick={() => handleSubmitFeedback(1)}
        >
          <Meh />
          <p>Too easy</p>
        </FeedbackRow>
        <FeedbackRow
          isActive={selectedIndex === 2}
          activeBg="#FFF2E9"
          activeColor="#FF974C"
          onClick={() => handleSubmitFeedback(2)}
        >
          <Sad />
          <p>Too hard</p>
        </FeedbackRow>
        <FeedbackRow
          isActive={selectedIndex === 3}
          activeBg="#FFEFEF"
          activeColor="#FF5A5A"
          onClick={() => handleSubmitFeedback(3)}
        >
          <VerySad />
          <p>Painful</p>
        </FeedbackRow>
      </FeedbackWrapper>
    </ScreenElement>
  );
};
