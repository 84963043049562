import React from "react";
// import { StyleSheet, View } from "react-native";

import Ball from "shared-assets/src/svg/equipment/Ball";
import Band from "shared-assets/src/svg/equipment/Band";
import Bed from "shared-assets/src/svg/equipment/Bed";
import Book from "shared-assets/src/svg/equipment/Book";
import Chair from "shared-assets/src/svg/equipment/Chair";
import Dumbbell from "shared-assets/src/svg/equipment/Dumbbell";
import FloorSpace from "shared-assets/src/svg/equipment/FloorSpace";
import Sofa from "shared-assets/src/svg/equipment/Sofa";
import StabilityBall from "shared-assets/src/svg/equipment/StabilityBall";
import Towel from "shared-assets/src/svg/equipment/Towel";
import WallSpace from "shared-assets/src/svg/equipment/WallSpace";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import Heading from "../texts/Heading";
import Paragraph from "../texts/Paragraph";

const HorizontalEquipmentItem = styled.div`
  padding-top: 5px;
  display: flex;
  padding-bottom: 5px;
  padding-right: 10px;
  flex-direction: row;
  align-items: center;
`;

const VerticalEquipmentItem = styled.div`
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
`;

interface EquipmentItemProps {
  name: string;
  horizontal?: boolean;
  color?: string;
}

const EquipmentItem: React.FC<EquipmentItemProps> = ({
  name,
  horizontal,
  color,
}) => {
  const renderIcon = (name: string): React.ReactElement | null => {
    switch (name.toLowerCase()) {
      case "equipment_tag_small_ball":
        return <Ball color={color} />;
      case "equipment_tag_small_towel":
        return <Towel color={color} />;
      case "equipment_tag_floor_space":
        return <FloorSpace color={color} />;
      case "equipment_tag_bed":
        return <Bed color={color} />;
      case "equipment_tag_chair":
        return <Chair color={color} />;
      case "equipment_tag_light_resistance_band":
        return <Band color={color} />;
      case "equipment_wall_space":
        return <WallSpace color={color} />;
      case "equipment_tag_small_book":
        return <Book color={color} />;
      case "equipment_tag_sofa":
        return <Sofa color={color} />;
      case "equipment_tag_dumbbell":
        return <Dumbbell color={color} />;
      case "equipment_tag_stability_ball":
        return <StabilityBall color={color} />;
      default:
        return null;
    }
  };

  if (horizontal) {
    return (
      <HorizontalEquipmentItem>
        <div style={{ marginRight: "5px", justifyContent: "flex-end" }}>
          {renderIcon(name)}
        </div>
        <Paragraph
          size="tiny"
          text={t(name)}
          leftAlign
          marginBottom="0px"
          color={color ?? theme.palette.PRIMARY}
        />
      </HorizontalEquipmentItem>
    );
  }

  return (
    <div style={{ alignItems: "center", justifyContent: "flex-end" }}>
      <VerticalEquipmentItem>{renderIcon(name)}</VerticalEquipmentItem>
      <Heading
        size="tiny"
        bold
        text={t(name)}
        leftAlign
        marginBottom="0px"
        color={color || theme.palette.PRIMARY}
      />
    </div>
  );
};

export default EquipmentItem;
