import * as React from "react";
import { DuotoneIconProps } from "shared-interfaces";
import { theme } from "shared-utilities";

const PauseButtonIcon: React.FC<any & DuotoneIconProps> = ({
  size = theme.spacing(6.5),
  color = "#D0DFE6",
  colorTwo = theme.palette.WHITE,
  ...props
}) => (
  <svg width={size} height={size} viewBox="0 0 64 64" fill="none" {...props}>
    <circle cx={32} cy={32} r={32} fill={color} />
    <path fill={colorTwo} d="M22 20.1796H30.75V42.1796H22z" />
    <path fill={colorTwo} d="M33.25 20.1796H42V42.1796H33.25z" />
  </svg>
);

export default PauseButtonIcon;
