// @TODO: Fix any types
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactComponent as Happy } from "shared-assets/src/icons/Happy.svg";
import { ReactComponent as Meh } from "shared-assets/src/icons/Meh.svg";
import { ReactComponent as Sad } from "shared-assets/src/icons/Sad.svg";
import { ReactComponent as VeryHappy } from "shared-assets/src/icons/VeryHappy.svg";
import { ReactComponent as VerySad } from "shared-assets/src/icons/VerySad.svg";
import { theme } from "shared-utilities";

import styled from "styled-components";

type SelectorItemProps = {
  item?: any;
  subQuestionValue?: any;
  index: number;
  select: () => void;
  isEmoji: boolean;
  isSelected: boolean;
  onChangeSubAnswer: (item: any, value: string) => void;
};

type ContainerProps = {
  isSelected: boolean;
  isSubQuestion?: boolean;
};

const Container = styled.div<ContainerProps>`
  gap: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isSubQuestion }) =>
    isSubQuestion ? "center" : "center"};
  align-items: center;
  height: ${({ isSelected, isSubQuestion }) =>
    isSelected && isSubQuestion ? "auto" : "62px"};
  width: 100%;
  background: #e7eef2;
  border-radius: 4px;
  padding: ${({ isSelected, isSubQuestion }) =>
    isSelected && isSubQuestion ? "16px" : "0 16px"};
  cursor: ${({ isSelected }) => (isSelected ? "default" : "pointer")};
  box-sizing: border-box;
  background-color: ${({ isSelected }) => (isSelected ? "#D0DEE5" : "#e7eef2")};

  font-family: ${theme.typography.FONT_NAME};
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: ${theme.palette.PRIMARY};
  text-align: left;
  padding-right: 50px;
`;

const ContainerWrapper = styled.div<ContainerProps>`
  gap: 16px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  ::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    right: -32px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 6px solid white;
    box-sizing: border-box;
    background-color: ${({ isSelected }) =>
      isSelected ? theme.palette.PRIMARY : "white"};
  }
`;

const TextArea = styled.textarea`
  height: 82px;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin-left: 0;
  width: 100%;
  color: #145e7f;
  border-radius: 4px;
  background: #fff;
  border: none;
  box-sizing: border-box;
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none;
  font-family: ${theme.typography.FONT_NAME};

  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    font-family: ${theme.typography.FONT_NAME};
    color: ${theme.palette.LIGHT_GREY};
  }
`;

export const SelectorItem = ({
  item,
  index,
  select,
  subQuestionValue,
  isEmoji = false,
  isSelected,
  onChangeSubAnswer,
}: SelectorItemProps) => {
  const renderEmoji = () => {
    switch (index + 1) {
      case 1:
        return <VeryHappy />;
      case 2:
        return <Happy />;
      case 3:
        return <Meh />;
      case 4:
        return <Sad />;
      case 5:
        return <VerySad />;
      default:
        return null;
    }
  };

  const changeValueHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChangeSubAnswer(item, e.target.value);
  };

  return (
    <Container
      onClick={select}
      isSelected={isSelected}
      isSubQuestion={item.subQuestion}
    >
      <ContainerWrapper isSelected={isSelected}>
        {isEmoji && renderEmoji()}
        {item.label}
      </ContainerWrapper>
      {item.subQuestion && isSelected && (
        <TextArea
          defaultValue={subQuestionValue}
          onChange={changeValueHandler}
          placeholder={item.subQuestion.title}
        />
      )}
    </Container>
  );
};
