import * as React from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ErrorResponse } from 'shared-interfaces';
import {
  useAuthGetMeQuery,
  useChatGetConversationQuery,
  useLearnFetchQuery,
  usePlanFetchQuery,
  useProgressFetchQuery,
  useWorkoutScheduleFetchQuery,
} from '../api';

type QueryKey = 'user' | 'progress' | 'learn' | 'plan' | 'chat' | 'schedule';

/**
 * Extract error code from response
 * @param error
 */
export const useQueryError = (error?: FetchBaseQueryError | SerializedError): string | null =>
  React.useMemo(() => {
    if (!error) return null;
    if ('data' in error) {
      const { data } = error as FetchBaseQueryError;
      return (data as ErrorResponse).message;
    }
    return 'NON_HTTP_ERROR';
  }, [error]);

/**
 * Keep last positive value
 * @param value
 */
export const usePositiveValue = <T>(value: T | null | undefined): T | null | undefined => {
  // State
  const [positiveValue, setPositiveValue] = React.useState<T | null | undefined>(value);

  // Effects
  React.useEffect(() => {
    if (!value) return;
    setPositiveValue(value);
  }, [value]);

  return positiveValue;
};

/**
 * Refresh all data required for the dashboard
 * @param shouldNotFetchQueries - if true, queries will not be fetched
 */
export const useDashboardRefresh = (shouldNotFetchQueries: boolean) => {
  const props = { skip: shouldNotFetchQueries };
  // Store
  const { refetch: refetchUser, isLoading: userLoading } = useAuthGetMeQuery(undefined, props);
  const { refetch: refetchProgress, isLoading: progressLoading } = useProgressFetchQuery(
    undefined,
    props
  );
  const { refetch: refetchLearn, isLoading: learnLoading } = useLearnFetchQuery(undefined, props);
  const { refetch: refetchPlan, isLoading: planLoading } = usePlanFetchQuery(undefined, props);
  const { refetch: refetchChat, isLoading: chatLoading } = useChatGetConversationQuery(
    undefined,
    props
  );
  const { refetch: refetchSchedule, isLoading: scheduleLoading } = useWorkoutScheduleFetchQuery(
    undefined,
    props
  );

  // Methods
  const handleRefresh = React.useCallback(() => {
    refetchUser();
    refetchProgress();
    refetchLearn();
    refetchPlan();
    refetchChat();
    refetchSchedule();
  }, [refetchUser, refetchProgress, refetchLearn, refetchPlan, refetchChat]);
  const handleGetLoading = React.useCallback(
    (queries?: QueryKey[]): boolean => {
      if (!queries)
        return (
          userLoading ||
          progressLoading ||
          learnLoading ||
          planLoading ||
          chatLoading ||
          scheduleLoading
        );
      return queries.reduce<boolean>((acc, cv) => {
        switch (cv) {
          case 'user':
            return acc || userLoading;
          case 'progress':
            return acc || progressLoading;
          case 'learn':
            return acc || learnLoading;
          case 'plan':
            return acc || planLoading;
          case 'chat':
            return acc || chatLoading;
          case 'schedule':
            return acc || scheduleLoading;
          default:
            return acc;
        }
      }, false);
    },
    [userLoading, progressLoading, learnLoading, planLoading, chatLoading, scheduleLoading]
  );

  return {
    refreshDashboard: handleRefresh,
    dashboardLoading: handleGetLoading,
  };
};
