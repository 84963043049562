import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const ProgressShoeIcon: React.FC<Pick<IconProps, 'size'>> = ({ size = theme.spacing(2.5) }) => (
  <Svg width={size * 1.95} height={size} viewBox="0 0 39 20">
    <G fill="none" fillRule="evenodd">
      <Path d="M4.433 4.844s-2.978 1.891-2.329 8.165c0 0-.409 1.476-.8 2.261-.393.787-.864 2.632 1.366 3.056 2.23.424 23.396.555 23.396.555s4.109-.115 5.983-.441c1.874-.327 3.48-.555 4.508-1.167s1.614-.598 1.597-2.136c-.016-1.538-.648-1.872-1.757-2.323-1.11-.45-6.08-2.217-6.08-2.217l-2.485-.85L17.258 1.49s-.744-.124-1.344.445c-.6.57-1.087.876-.262 2.01l-2.335 1.8S11.14 7.548 9.654 7.248c-1.485-.3-2.915-.759-5.221-2.404" />
      <Path
        d="M4.433 4.844s-2.978 1.891-2.329 8.165c0 0-.409 1.476-.8 2.261-.393.787-.864 2.632 1.366 3.056 2.23.424 23.396.555 23.396.555s4.109-.115 5.983-.441c1.874-.327 3.48-.555 4.508-1.167s1.614-.598 1.597-2.136c-.016-1.538-.648-1.872-1.757-2.323-1.11-.45-6.08-2.217-6.08-2.217l-2.485-.85L17.258 1.49s-.744-.124-1.344.445c-.6.57-1.087.876-.262 2.01l-2.335 1.8S11.14 7.548 9.654 7.248c-1.485-.3-2.915-.759-5.221-2.404z"
        stroke={theme.palette.DARK_GREY}
      />
      <Path d="M15.675 14.71c-4.674-.959-13.57-1.7-13.57-1.7s-.409 1.475-.802 2.262c-.392.785-.863 2.631 1.368 3.055 2.232.424 23.419.555 23.419.555s4.112-.115 5.989-.442c1.876-.326 3.482-.554 4.511-1.165 1.029-.612 1.616-.599 1.6-2.137 0 0-4.897 1.635-9.057 1.483-4.16-.152-8.784-.951-13.458-1.91" />
      <Path
        d="M15.675 14.71c-4.674-.959-13.57-1.7-13.57-1.7s-.409 1.475-.802 2.262c-.392.785-.863 2.631 1.368 3.055 2.232.424 23.419.555 23.419.555s4.112-.115 5.989-.442c1.876-.326 3.482-.554 4.511-1.165 1.029-.612 1.616-.599 1.6-2.137 0 0-4.897 1.635-9.057 1.483-4.16-.152-8.784-.951-13.458-1.91z"
        stroke={theme.palette.DARK_GREY}
        fill={theme.palette.PRIMARY}
        strokeLinejoin="round"
      />
      <Path d="M17.258 1.489s-.744-.124-1.344.445c-.6.569-1.087.876-.26 2.01l-2.337 1.8S11.14 7.55 9.654 7.248c-1.485-.3-2.914-.76-5.222-2.405 0 0-.7.448-1.347 1.623 0 0 .425 1.273 2.576 2.756 2.15 1.482 5.11 1.34 7.991-.34 2.883-1.683 6.918-4.807 6.918-4.807L17.258 1.49z" />
      <Path
        d="M17.258 1.489s-.744-.124-1.344.445c-.6.569-1.087.876-.26 2.01l-2.337 1.8S11.14 7.55 9.654 7.248c-1.485-.3-2.914-.76-5.222-2.405 0 0-.7.448-1.347 1.623 0 0 .425 1.273 2.576 2.756 2.15 1.482 5.11 1.34 7.991-.34 2.883-1.683 6.918-4.807 6.918-4.807L17.258 1.49z"
        stroke={theme.palette.DARK_GREY}
        fill={theme.palette.PRIMARY}
      />
      <Path
        d="M24.938 16.284c-1.129-2.595-4.512-3.333-12.822-4.664-8.309-1.33-9.032-5.154-9.032-5.154"
        stroke={theme.palette.DARK_GREY}
      />
      <Path d="M10.347 16.973l-4.419-.105a.91.91 0 0 1-.89-.928.91.91 0 0 1 .934-.885l4.418.104a.91.91 0 0 1 .89.929.91.91 0 0 1-.933.885" />
      <Path d="M19.446 12.976s1.268-3.24 8.385-3.229" stroke={theme.palette.DARK_GREY} />
      <Path d="M33.617 16.255c.684-.136 4.572-1.117 4.572-1.117s-.059-.413-.252-1.184c-.195-.772-2.127-1.367-2.127-1.367s-3.455.827-8.068.9c-4.614.072-4.935-2.914-4.935-2.914s-1.889-.006-3.243 2.437c0 0 4.59.865 5.135 3.245 0 0 4.895.802 8.918 0" />
      <Path
        d="M33.617 16.255c.684-.136 4.572-1.117 4.572-1.117s-.059-.413-.252-1.184c-.195-.772-2.127-1.367-2.127-1.367s-3.455.827-8.068.9c-4.614.072-4.935-2.914-4.935-2.914s-1.889-.006-3.243 2.437c0 0 4.59.865 5.135 3.245 0 0 4.895.802 8.918 0z"
        stroke={theme.palette.DARK_GREY}
        fill={theme.palette.DARK_GREY}
      />
      <Path d="M20.968 6.295l.398.327M22.728 7.69l.398.327" stroke={theme.palette.DARK_GREY} strokeLinecap="round" />
      <Path d="M5.795 15.941h4.419" stroke={theme.palette.DARK_GREY} strokeLinecap="round" strokeLinejoin="round" />
    </G>
  </Svg>
);

export default ProgressShoeIcon;
