import React from "react";
import { useNavigate } from "react-router-dom";
import { theme, t } from "shared-utilities";
import CheckingLoader from "../components/miscellaneous/CheckingLoader";

export const CheckinCompleteScreen: React.FC<React.PropsWithChildren> = () => {
  // Navigation
  const navigation = useNavigate();

  // Methods
  const handleComplete = () => navigation("/progress");

  return (
    <div style={{ flex: 1, backgroundColor: theme.palette.WHITE }}>
      <CheckingLoader
        onCompleted={handleComplete}
        text={t("checkin_submitting")}
        submittedText={t("checkin_submitted")}
      />
    </div>
  );
};
