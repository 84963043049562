import React from "react";

// import AssessmentComplete from "@assets/svg/illustrations/AssessmentComplete";
// import AssessmentContinue from "@assets/svg/illustrations/AssessmentContinue";
// import AssessmentDone from "@assets/svg/illustrations/AssessmentDone";
// import AssessmentInReview from "@assets/svg/illustrations/AssessmentInReview";
// import Bike from "@assets/svg/illustrations/Bike";
// import HandWithPhone from "@assets/svg/illustrations/HandWithPhone";
// import IllustrationAbout from "@assets/svg/illustrations/IllustrationAbout";
// import IllustrationAccount from "@assets/svg/illustrations/IllustrationAccount";
// import IllustrationActivity from "@assets/svg/illustrations/IllustrationActivity";
// import IllustrationCheck from "@assets/svg/illustrations/IllustrationCheck";
// import IllustrationLife from "@assets/svg/illustrations/IllustrationLife";
// import IllustrationMove from "@assets/svg/illustrations/IllustrationMove";
// import IllustrationPain from "@assets/svg/illustrations/IllustrationPain";
// import ManAtWhiteboard from "@assets/svg/illustrations/ManAtWhiteboard";
// import ManRunning from "@assets/svg/illustrations/ManRunning";
// import WomanAskingQuestion from "@assets/svg/illustrations/WomanAskingQuestion";
import { theme } from "shared-utilities";
import ManAtWhiteboard from "src/assets/svg/illustrations/ManAtWhiteboard";

export type IllustrationName =
  | "hand-with-phone"
  | "running-man"
  | "man-at-whiteboard"
  | "woman-asking-question"
  | "bike"
  | "illustration_pain"
  | "illustration_about"
  | "illustration_activity"
  | "illustration_life"
  | "illustration_move"
  | "illustration_check"
  | "illustration_account"
  | "illustration_assessment_done"
  | "illustration_assessment_complete"
  | "illustration_assessment_continue"
  | "illustration_assessment_inreview";

interface IllustrationProps {
  name: IllustrationName;
  marginBottom?: number;
}

const Illustration: React.FC<IllustrationProps> = ({
  name,
  marginBottom = theme.spacing(3),
}) => {
  const renderIllustration = () => {
    switch (name) {
      case "hand-with-phone":
        return;
      // return <HandWithPhone scale={theme.utilities.SCALE} />;
      case "running-man":
        return;
      // return <ManRunning scale={theme.utilities.SCALE} />;
      case "man-at-whiteboard":
        return <ManAtWhiteboard scale={theme.utilities.SCALE} />;
      case "woman-asking-question":
        return;
      // return <WomanAskingQuestion scale={theme.utilities.SCALE} />;
      case "bike":
        return;
      // return <Bike scale={theme.utilities.SCALE} />;
      case "illustration_pain":
        return;
      // return <IllustrationPain scale={theme.utilities.SCALE} />;
      case "illustration_about":
        return;
      // return <IllustrationAbout scale={theme.utilities.SCALE} />;
      case "illustration_activity":
        return;
      // return <IllustrationActivity scale={theme.utilities.SCALE} />;
      case "illustration_life":
        return;
      // return <IllustrationLife scale={theme.utilities.SCALE} />;
      case "illustration_move":
        return;
      // return <IllustrationMove scale={theme.utilities.SCALE} />;
      case "illustration_check":
        return;
      // return <IllustrationCheck scale={theme.utilities.SCALE} />;
      case "illustration_account":
        return;
      // return <IllustrationAccount scale={theme.utilities.SCALE} />;
      case "illustration_assessment_done":
        return;
      // return <AssessmentDone scale={theme.utilities.SCALE} />;
      case "illustration_assessment_complete":
        return;
      // return <AssessmentComplete scale={theme.utilities.SCALE} />;
      case "illustration_assessment_continue":
        return;
      // return <AssessmentContinue scale={theme.utilities.SCALE} />;
      case "illustration_assessment_inreview":
        return;
      // return <AssessmentInReview scale={theme.utilities.SCALE} />;
      default:
        return null;
    }
  };

  return <div style={{ marginBottom }}>{renderIllustration()}</div>;
};

export default Illustration;
