import React from "react";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import EmphasizedText from "../../texts/EmphasizedText";
import Heading from "../../texts/Heading";
import Subtitle from "../../texts/Subtitle";
import { Illustration } from "../Illustration";

const Text = styled.div``;

const SectionIntroButton = styled.button`
  background: ${theme.palette.BUTTON_PRIMARY};
  border-radius: 27px;
  width: 100%;
  height: 54px;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
`;

interface SectionIntroProps {
  item: any;
  onChange: () => void;
}

const SectionIntroQuestionView: React.FC<SectionIntroProps> = ({
  item,
  onChange,
}) => {
  return (
    <>
      <Heading text={item.title} size="medium" />
      <EmphasizedText text={item.emphasised} />
      <Subtitle text={item.subtitle} />
      <Illustration name={item.theme.illustration} />
      <SectionIntroButton type="button" onClick={onChange}>
        <Text
          css={[
            theme.typography[theme.typography.FONT_NAME].REGULAR_BOLD_HEADING,
            { color: theme.palette.WHITE },
          ]}
        >
          {t("btn_continue")}
        </Text>
      </SectionIntroButton>
    </>
  );
};

export default SectionIntroQuestionView;
