// @TODO: Fix any types
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MultipleChoiceAnswer, Question } from "shared-interfaces";
import {
  useCheckinSubmitMutation,
  getAssessmentId,
  getCheckinQuestions,
  getUser,
  useAppSelector,
} from "shared-redux";
import {
  t,
  createErrorResponse,
  getErrorString,
  theme,
} from "shared-utilities";
import { Button } from "src/components";
import {
  YesNoQuestionView,
  ChoiceQuestionView,
  LongTextQuestionView,
  PainSliderQuestionView,
  DescriptionQuestionView,
  SectionIntroQuestionView,
  RedFlagWarningQuestionView,
  MultipleChoiceQuestionView,
  PainScaleQuestionView,
} from "src/components/assessment/questionTypes";
import styled from "styled-components/macro";
import { CheckinHeader } from "../components/checkin/checkinHeader";

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const MultiQuestionsWrapper = styled.div`
  max-width: 80%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  padding-bottom: 60px;
  padding-top: 60px;
`;

const Text = styled.div``;

interface MultiQuestionsSectionViewProps {
  questions: Question[];
}

export const MultiQuestionsView: React.FC<MultiQuestionsSectionViewProps> = ({
  questions,
}) => {
  const navigate = useNavigate();
  const user = useAppSelector(getUser);
  const [
    dispatchSubmit,
    { isLoading: isLoadingCheckin, error, isSuccess: isSuccessCheckin },
  ] = useCheckinSubmitMutation();
  const assessmentError = error as any;

  const [answers, setAnswers] = useState<any[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [activeAnswer, setActiveAnswer] = useState<any>(null);
  const [activeSubAnswer, setActiveSubAnswer] = useState<any>(null);
  const [nextButtonText, setNextButtonText] = useState<string>("Next");
  const activeQuestion = questions[activeStep] as any;

  const isFinalCheck = activeStep === questions.length - 1;

  const activeQuestionSelectedAnswer = answers.find(
    (answer: any) => answer?.question === activeQuestion?.id
  );
  const activeAnswerOption = activeQuestion?.questionChoices?.find(
    (questionChoice: any) =>
      questionChoice.id === activeQuestionSelectedAnswer?.input?.value
  );

  const isAnswerWithSubFilled =
    activeAnswerOption && activeAnswerOption?.subQuestion
      ? !!activeSubAnswer
      : false;

  const showNextButton =
    activeQuestion.type === "section_intro" ||
    activeQuestion.type === "red_flag_warning";

  useEffect(() => {
    if (activeQuestion.button) setNextButtonText(activeQuestion.button);
    else if (
      activeQuestion.type === "multiple_choice" &&
      !activeQuestion.required
    ) {
      if (
        !activeAnswer ||
        !(activeAnswer.input as MultipleChoiceAnswer).value.length
      )
        setNextButtonText(t("btn_none_apply"));
      else setNextButtonText(t("btn_next"));
    } else setNextButtonText(t("btn_next"));
  }, [activeAnswer, activeQuestion]);

  useEffect(() => {
    const activeSubQuestionSelectedAnswer = answers.find(
      (answer: any) => answer?.question === activeAnswerOption?.subQuestion?.id
    );

    if (!activeAnswer && activeQuestionSelectedAnswer) {
      setActiveAnswer(activeQuestionSelectedAnswer);
    }

    if (!activeSubAnswer && activeSubQuestionSelectedAnswer) {
      setActiveSubAnswer(activeSubQuestionSelectedAnswer);
    }
  }, [activeStep, answers, activeQuestion]);

  useEffect(() => {
    if (!isSuccessCheckin) return;
    // Decide based on state what screen to navigate to
    return navigate("/progress/CheckinCompleted");
  }, [isSuccessCheckin]);

  useEffect(() => {
    if (!assessmentError) return;

    if ("data" in assessmentError) {
      const typedError = createErrorResponse(assessmentError);

      toast.error(
        `Error submitting assessment: ${getErrorString(
          typedError.data.message
        )}`
      );
    }
  }, [assessmentError]);

  const updateActiveStepHandler = () => {
    setActiveStep(activeStep + 1);
    setActiveAnswer(null);
    setActiveSubAnswer(null);
  };

  const selectQuestionsHandler = (id: number, type: string, value: any) => {
    if (type === "multiple_choice") {
      setActiveAnswer({
        question: id,
        input: { type, value: value ?? [] },
      });
      return;
    }
    setActiveAnswer({
      question: id,
      input: { type, value: value ?? null },
    });
  };

  const selectSubQuestionsHandler = (
    id: number,
    type: string,
    value: string
  ) => {
    setActiveSubAnswer({
      question: id,
      input: { type, value: value ?? null },
    });
  };
  const submitAnswersHandler = async () => {
    const { id, type } = activeQuestion;

    if (type === "description") {
      updateActiveStepHandler();
    }
    if (
      activeAnswer ||
      (type === "multiple_choice" && !activeQuestion.required)
    ) {
      const filteredAnswersList = answers?.filter(
        (item) => item?.question !== id
      );
      const updatedAnswers = [...filteredAnswersList, activeAnswer];

      if (activeSubAnswer) {
        updatedAnswers.push(activeSubAnswer);
      }

      if (isFinalCheck) {
        await dispatchSubmit({
          painInRelationToActivity: updatedAnswers[0].input.value,
          painInRelationToArea: updatedAnswers[1].input.value,
          satisfactionWithTreatment: updatedAnswers[2].input.value,
          comments: updatedAnswers[3].input.value,
        });
      } else {
        setAnswers(updatedAnswers);
        updateActiveStepHandler();
      }
    }
  };

  const backNavigationHandler = () => {
    if (!activeStep) {
      setActiveStep(questions.length - 1);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const renderQuestionsList = questions
    .slice(activeStep, activeStep + 1)
    .map((item) => {
      switch (item?.type) {
        case "choice": {
          return (
            <ChoiceQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
              activeAnswerOption={activeAnswerOption}
              onChangeSubAnswer={selectSubQuestionsHandler}
            />
          );
        }

        case "description": {
          return <DescriptionQuestionView key={item.id} item={item} />;
        }

        case "multiple_choice": {
          return (
            <MultipleChoiceQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
            />
          );
        }

        case "short_text":
        case "long_text": {
          return (
            <LongTextQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
            />
          );
        }

        case "section_intro": {
          return (
            <SectionIntroQuestionView
              key={item.id}
              item={item}
              onChange={updateActiveStepHandler}
            />
          );
        }

        case "yes_no": {
          return (
            <YesNoQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
            />
          );
        }

        case "red_flag_warning": {
          return (
            <RedFlagWarningQuestionView
              key={item.id}
              onChange={updateActiveStepHandler}
            />
          );
        }

        case "pain_scale": {
          return (
            <PainScaleQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
            />
          );
        }

        case "pain_slider": {
          return (
            <PainSliderQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
            />
          );
        }

        default: {
          return null;
        }
      }
    });

  return (
    <>
      <CheckinHeader
        questions={questions}
        activeStep={activeStep}
        onBack={backNavigationHandler}
      />
      <MultiQuestionsWrapper>{renderQuestionsList}</MultiQuestionsWrapper>
      {!showNextButton && (
        <Footer>
          <Button
            bgColor="#145E7F"
            isFullWidth
            isRoundedCorners={false}
            loading={isLoadingCheckin}
            handleClick={submitAnswersHandler}
            isActive={
              !!activeAnswer ||
              isAnswerWithSubFilled ||
              activeQuestion.type === "description" ||
              activeQuestion.type === "multiple_choice"
            }
          >
            <Text
              css={[
                theme.typography[theme.typography.FONT_NAME]
                  .REGULAR_BOLD_HEADING,
                { color: theme.palette.WHITE },
              ]}
            >
              {nextButtonText}
            </Text>
          </Button>
        </Footer>
      )}
    </>
  );
};

export const ProgressCheckinScreen = () => {
  const questions = useAppSelector(getCheckinQuestions);
  const questionList = [
    questions?.painInRelationToActivity as Question,
    questions?.painInRelationToArea as Question,
    questions?.satisfactionWithTreatment as Question,
    questions?.comments as Question,
  ];

  return (
    // <ScreenElement extraStyles={extraStyles}>
    <MultiQuestionsView questions={questionList} />
    // </ScreenElement>
  );
};
