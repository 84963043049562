import { isLarge, isSmall } from "../deviceSize";

export interface ScaledNumberConfig {
  small?: number;
  normal: number;
  large?: number;
}

/**
 * Get scaled numbers for properties like fontSize
 * @param small
 * @param large
 * @param normal
 */
export const scaledNumber = ({ small, large, normal }: ScaledNumberConfig): number => {
  if (isSmall() && small !== undefined) return small;
  if (isLarge() && large !== undefined) return large;
  return normal;
};

export const scaledNumberPx = (config: ScaledNumberConfig): string => `${scaledNumber(config)}px`;