import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const BedIcon: React.FC<SvgProps & IconProps> = ({
  size = theme.spacing(2.375),
  color = theme.palette.PRIMARY,
  ...props
}) => (
  <Svg width={size * 1.5789473684210527} height={size} viewBox="0 0 30 19" {...props}>
    <G fill={color} fillRule="nonzero">
      <Path d="M28.421 13.385v-2.696c0-.588-.475-1.074-1.05-1.074H2.628c-.575 0-1.05.486-1.05 1.074v2.696h26.843zm.79 1.615H.789A.799.799 0 0 1 0 14.192V10.69C0 9.209 1.181 8 2.628 8h24.743C28.818 8 30 9.209 30 10.689v3.503a.799.799 0 0 1-.79.808z" />
      <Path d="M25.4 1.588H4.6v5.824h20.8V1.588zM25.616 9H4.386A1.382 1.382 0 0 1 3 7.625v-6.25C3 .616 3.622 0 4.385 0h21.23C26.38 0 27 .617 27 1.374v6.25C27 8.384 26.38 9 25.616 9z" />
      <Path d="M7.588 7.25c0 .414-.355.75-.794.75C6.356 8 6 7.664 6 7.25v-1C6 5.01 7.07 4 8.382 4h4.236C13.93 4 15 5.01 15 6.25v1c0 .414-.356.75-.794.75-.439 0-.794-.336-.794-.75v-1c0-.41-.36-.75-.794-.75H8.382c-.435 0-.794.34-.794.75v1zM16.588 7.25c0 .414-.355.75-.794.75-.438 0-.794-.336-.794-.75v-1C15 5.01 16.07 4 17.382 4h4.235C22.929 4 24 5.01 24 6.25v1c0 .414-.356.75-.794.75-.439 0-.794-.336-.794-.75v-1c0-.41-.36-.75-.795-.75h-4.235c-.435 0-.794.34-.794.75v1zM3 14.833c0-.46.448-.833 1-.833s1 .373 1 .833v3.334c0 .46-.448.833-1 .833s-1-.373-1-.833v-3.334zM26 14.833c0-.46.448-.833 1-.833s1 .373 1 .833v3.334c0 .46-.448.833-1 .833s-1-.373-1-.833v-3.334z" />
    </G>
  </Svg>
);

export default BedIcon;
