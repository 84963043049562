// @TODO: Fix any types
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { theme } from "shared-utilities";
import styled from "styled-components/macro";
import { Button } from "../Button";
import { SelectorItem } from "./SelectorItem";

const SelectorContainer = styled.div`
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 343px;
  gap: 8px;
`;

const Label = styled.div`
  text-align: center;
  margin-top: 12px;
  margin-bottom: 20px;
  color: ${theme.palette.PRIMARY};
`;

export const Selector = ({
  items,
  value = "",
  subQuestionValue,
  isEmoji = false,
  isSubmit = true,
  onChange = () => null,
  callback = () => null,
  onChangeSubAnswer = () => null,
}: {
  items: any[];
  isSubmit?: boolean;
  isEmoji?: boolean;
  // eslint-disable-next-line
  onChange?: (item: any) => void;
  callback?: (_?: string) => void;
  onChangeSubAnswer?: (item: number, value: string) => void;
  value?: any;
  subQuestionValue?: any;
}) => {
  const [selectedItem, setSelectedItem] = useState<string | undefined>(value);

  const onSelectHandler = (item?: string) => {
    setSelectedItem(item);
    onChange(item);
  };

  return (
    <>
      <SelectorContainer>
        {items.map((item, index) => {
          const selectedValue = isEmoji ? item.value : item.id;
          if (item.type === "label")
            return (
              <Label css={theme.typography.Omnes.REGULAR_TEXT} key={item.id}>
                {item.label}
              </Label>
            );

          return (
            <SelectorItem
              isEmoji={isEmoji}
              item={item}
              index={index}
              key={item.id}
              subQuestionValue={subQuestionValue}
              select={() => onSelectHandler(selectedValue)}
              isSelected={selectedValue === selectedItem}
              onChangeSubAnswer={onChangeSubAnswer}
            />
          );
        })}
      </SelectorContainer>
      {isSubmit && (
        <Button
          bgColor={theme.palette.PRIMARY}
          handleClick={() => callback(selectedItem)}
          isActive={!!selectedItem}
          isFullWidth
          isRoundedCorners={false}
        >
          Next
        </Button>
      )}
    </>
  );
};
