import React from "react";
import moment from "moment";
import { theme } from "shared-utilities";
import styled from "styled-components/macro";
import TargetDone from "../../assets/svg/icons/TargetDone";
import TargetTodo from "../../assets/svg/icons/TargetTodo";
import TargetTodoToday from "../../assets/svg/icons/TargetTodoToday";

interface TargetProps {
  reached: boolean;
  dayKey: number;
  day: string;
}

const Text = styled.div``;

const Target: React.FC<TargetProps> = ({ reached, dayKey, day }) => {
  // Computed Variables
  const today = moment().weekday() === dayKey;

  // Effects
  React.useEffect(() => {
    moment.updateLocale("en", { week: { dow: 1 } });
  }, []);

  // Renderers
  const renderState = () => {
    if (reached) return <TargetDone />;
    return today ? <TargetTodoToday /> : <TargetTodo />;
  };

  return (
    <div
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        paddingTop: 15,
      }}
    >
      {renderState()}
      <Text
        css={[
          theme.typography[theme.typography.FONT_NAME][
            today ? "SMALL_BOLD_HEADING" : "SMALL_TEXT"
          ],
          {
            paddingTop: 9,
            color: theme.palette[today ? "PINK" : "DARK_GREY"],
          },
        ]}
      >
        {day}
      </Text>
    </div>
  );
};

export default Target;
