import React from "react";
import moment from "moment";
import Heading, { HeadingSize } from "../texts/Heading";

interface TimerProps {
  onFinish: () => void;
  seconds: number;
  fontSize: HeadingSize;
  paused?: boolean;
}

const Timer: React.FC<TimerProps> = ({
  onFinish,
  seconds,
  fontSize,
  paused,
}) => {
  // Refs
  const timerRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

  // State
  const [secondsState, setSecondsState] = React.useState<number>(seconds);

  // Methods
  const countdown = () => {
    if (paused) return;
    setSecondsState((s) => s - 1);
  };

  // Effects
  React.useEffect(() => {
    if (timerRef.current || paused) return;
    timerRef.current = setInterval(countdown, 1000);
    return () => {
      if (!timerRef.current) return;
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    };
  }, [paused]);

  React.useEffect(() => {
    if (secondsState !== 0 || !timerRef.current) return;
    clearInterval(timerRef.current);
    onFinish();
  }, [secondsState]);

  return (
    <Heading
      size={fontSize}
      text={moment.utc(secondsState * 1000).format("mm:ss")}
    />
  );
};

export default Timer;
