import React from "react";
import { theme } from "shared-utilities";
import styled from "styled-components/macro";

const Root = styled.div`
  text-align: center;
  color: ${theme.palette.PRIMARY};
`;

interface EmphasizedTextProps {
  text?: string;
  marginBottom?: number;
}

const EmphasizedText: React.FC<EmphasizedTextProps> = ({
  text,
  marginBottom = theme.spacing(2.5),
}) => {
  if (!text) return null;
  return (
    <Root
      css={[
        theme.typography[theme.typography.FONT_NAME].MEDIUM_BOLD_HEADING,
        { marginBottom },
      ]}
    >
      {text}
    </Root>
  );
};

export default EmphasizedText;
