import React from "react";
import {
  useAuthGetMeQuery,
  usePlanFetchQuery,
  useProgressFetchQuery,
  getPlan,
  getWorkoutSchedule,
  useAppSelector,
} from "shared-redux";
import { getLanguage, theme } from "shared-utilities";
import styled from "styled-components";
import WeeklyProgress from "../components/dashboard/WeeklyProgress";
import { WorkoutTile } from "../components/dashboard/WorkoutTile";

const ScrollView = styled.div`
  flex: 1;
  background-color: ${theme.palette.WHITE};
`;
const InnerContainer = styled.div`
  flex-grow: 1;
`;

const TilesContainer = styled.div`
  flex: 1;
`;

const LoadingContainer = styled.div`
  //...StyleSheet.absoluteFillObject,
  background-color: ${theme.palette.WHITE};
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Tiles = styled.div`
  flex: 1;
  padding: 15px;
`;

export const DashboardWorkoutScreen: React.FC<React.PropsWithChildren> = () => {
  // Store
  const language = useAppSelector(getLanguage);
  const isSubscribed = true;
  const plan = useAppSelector(getPlan);
  const schedule = useAppSelector(getWorkoutSchedule);
  // const isSubscribed = useAppSelector(getIsPaidUp);
  const { isLoading: isLoadingUser, refetch: refetchUser } =
    useAuthGetMeQuery();
  const {
    isLoading: isLoadingPlan,
    error: errorPlan,
    refetch: resetPlan,
  } = usePlanFetchQuery();
  const {
    isLoading: isLoadingProgress,
    error: errorProgress,
    refetch: resetProgress,
  } = useProgressFetchQuery();

  // State
  const [firstRefresh, setFirstRefresh] = React.useState<boolean>(true);

  // Methods
  const refreshData = () => {
    setFirstRefresh(false);
    resetPlan();
    resetProgress();
    refetchUser();
  };

  // Computed Variables
  const loading: boolean = isLoadingPlan || isLoadingProgress || isLoadingUser;

  // Effects
  React.useEffect(() => {
    refreshData();
  }, []);

  // Renderers
  const renderTiles = React.useCallback(() => {
    if (plan)
      return (
        <WorkoutTile
          // editSchedule={openWorkoutScheduleModal}
          language={language}
          isSubscribed={isSubscribed}
          schedule={schedule}
        />
      );
    // return <InReviewTile />;
  }, [plan, language, schedule]);

  return (
    <ScrollView>
      <InnerContainer>
        <WeeklyProgress />
        <TilesContainer>
          {loading && (
            <LoadingContainer>
              {/* <ActivityIndicator size="large" color={theme.palette.PRIMARY} /> */}
            </LoadingContainer>
          )}
          {!loading && <Tiles>{renderTiles()}</Tiles>}
        </TilesContainer>
      </InnerContainer>
    </ScrollView>
  );
};
