import React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';
import { theme } from 'shared-utilities/src/theme/theme';
import { EmojiIconProps } from "shared-interfaces";

const SadIcon: React.FC<EmojiIconProps> = ({ size = theme.spacing(8), selected = true }) => (
  <Svg width={size} height={size} viewBox="0 0 64 64">
    <G fill="none" fillRule="evenodd">
      <Circle fill={selected ? theme.scales.ORANGE : theme.palette.LIGHTEST_GREY} cx={32} cy={32} r={32} />
      <Path
        d="M19.59 47.566c-.74.736-.79 1.986-.114 2.792.677.805 1.825.86 2.565.124 2.353-2.343 5.554-3.53 9.694-3.53 3.909 0 6.981 1.06 9.29 3.146.77.697 1.915.582 2.556-.258.64-.84.535-2.085-.237-2.782C40.341 44.342 36.447 43 31.735 43c-5 0-9.078 1.512-12.145 4.566z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.5}
      />
      <Circle fill="#000" opacity={0.5} cx={17} cy={29} r={3} />
      <Circle fill="#000" opacity={0.5} cx={47} cy={29} r={3} />
    </G>
  </Svg>
);

export default SadIcon;
