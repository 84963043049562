import React, { useEffect, useRef, useState } from "react";
import lottie, { AnimationItem } from "lottie-web";
import animationData from "shared-assets/src/animations/checking_spinner.json";
import styled from "styled-components/macro";
import Heading from "../texts/Heading";

const Root = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const AnimationContainer = styled.div`
  height: 175px;
  align-self: center;
`;

interface CheckingLoaderProps {
  onCompleted: () => void;
  text: string;
  submittedText: string;
}

const CheckingLoader: React.FC<CheckingLoaderProps> = ({
  onCompleted,
  submittedText,
  text,
}) => {
  // Refs
  const timerRef = React.useRef<NodeJS.Timeout | undefined>();
  const animContainerRef = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);

  // State
  const [completed, setCompleted] = React.useState<boolean>(false);

  // Methods
  const handleCompleted = () => {
    console.log("hello");
    setCompleted(true);
    timerRef.current = setTimeout(onCompleted, 500);
  };

  // Effect
  useEffect(() => {
    if (animContainerRef.current && animContainerRef.current.innerHTML === "") {
      const anim = lottie.loadAnimation({
        animationData,
        container: animContainerRef.current,
        loop: false,
        autoplay: false,
        renderer: "svg",
      });
      anim.setSpeed(0.5);
      anim.addEventListener("DOMLoaded", () => anim.play());
      anim.addEventListener("complete", () => {
        handleCompleted();
      });
      setAnimation(anim);
    }
  }, [animContainerRef]);

  return (
    <Root>
      <AnimationContainer
        onClick={() => animation?.goToAndPlay(0)}
        ref={animContainerRef}
      />
      <Heading size="regular" text={completed ? submittedText : text} />
    </Root>
  );
};

export default CheckingLoader;
