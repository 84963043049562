import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  assessmentApi,
  authApi,
  chatApi,
  checkinApi,
  learnApi,
  planApi,
  progressApi,
  referralApi,
  useAssessmentDestroyMutation,
  userApi,
  logout,
  storePersistor,
  useAppDispatch,
} from "shared-redux";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import { useLockedBody, useOnClickOutside } from "usehooks-ts";
import RoundButton from "../buttons/RoundButton";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background: rgba(0, 0, 0, 0.5);
  mix-blend-mode: normal;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalWrapper = styled.div`
  background: #fff;
  max-width: 320px;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalTitle = styled.h4`
  color: ${theme.palette.PRIMARY};
  text-align: center;
`;

const ModalSubtitle = styled.div`
  padding-bottom: ${theme.spacingPx(2)};
  color: ${theme.palette.PRIMARY};
  text-align: center;
`;

const ModalFooter = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: inherit;

  button {
    width: auto;
  }
`;

interface DeleteAnswersModalProps {
  onClose: () => void;
}

export const DeleteAnswersModal: React.FC<DeleteAnswersModalProps> = ({
  onClose,
}) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [
    dispatchDestroyAssessment,
    { isLoading: isLoadingDestroy, isError, isSuccess: isSuccessDestroy },
  ] = useAssessmentDestroyMutation();

  useLockedBody(true, "root");
  useOnClickOutside(ref, () => onClose());

  const deleteAnswersHandler = async () => {
    dispatchDestroyAssessment();
  };

  React.useEffect(() => {
    const removeState = async () => {
      await Promise.all(
        [
          logout(),
          assessmentApi.util.resetApiState(),
          authApi.util.resetApiState(),
          chatApi.util.resetApiState(),
          checkinApi.util.resetApiState(),
          learnApi.util.resetApiState(),
          planApi.util.resetApiState(),
          progressApi.util.resetApiState(),
          referralApi.util.resetApiState(),
          userApi.util.resetApiState(),
        ].map(dispatch)
      );
      storePersistor.pause();
      storePersistor.flush().then(() => {
        storePersistor.purge();
        return navigate("/");
      });
    };
    if (isSuccessDestroy || isError) {
      removeState();
    }
  }, [isSuccessDestroy, isError]);

  return (
    <ModalContainer>
      <ModalWrapper ref={ref}>
        <div>
          <ModalTitle
            css={theme.typography[theme.typography.FONT_NAME].MEDIUM_HEADING}
          >
            {t("assessment_exit_modal_title")}
          </ModalTitle>
          <ModalSubtitle
            css={theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT}
          >
            {t("assessment_exit_modal_text")}
          </ModalSubtitle>
        </div>
        <ModalFooter>
          <RoundButton
            text={t("assessment_exit_modal_yes")}
            loading={isLoadingDestroy}
            backgroundColor={theme.palette.RED}
            onPress={deleteAnswersHandler}
          />
          <RoundButton
            text={t("assessment_exit_modal_cancel")}
            onPress={onClose}
          />
        </ModalFooter>
      </ModalWrapper>
    </ModalContainer>
  );
};
