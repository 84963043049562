import React from "react";
import styled from "styled-components/macro";
import SmallButton from "../buttons/SmallButton";
import Illustration, { IllustrationName } from "../miscellaneous/Illustration";
import Heading from "../texts/Heading";
import Paragraph from "../texts/Paragraph";
import Content from "./Content";

interface IntroScreenProps {
  onPress?: () => void;
  illustration?: string;
  title: string;
  text: string;
  buttonText?: string;
  loading?: boolean;
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const IntroScreen: React.FC<IntroScreenProps> = ({
  onPress,
  illustration,
  text,
  title,
  buttonText,
  loading,
}) => (
  <div
    style={{
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Content>
      <ContentContainer>
        <Illustration name={illustration as IllustrationName} />
        <Heading size="larger" text={title} />
        <Paragraph text={text} />
      </ContentContainer>
      {onPress && (
        <SmallButton
          text={buttonText ?? ""}
          onPress={onPress}
          loading={loading}
        />
      )}
    </Content>
  </div>
);

export default IntroScreen;
