import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AndreasImage from "shared-assets/src/images/theme/assessment_intro.png";
import {
  useReferralCodeMutation,
  usePositiveValue,
  useQueryError,
} from "shared-redux";
import { t, theme } from "shared-utilities";
import { Input } from "src/components";
import styled from "styled-components/macro";
import { useWindowSize } from "usehooks-ts";
import BackButton from "../assets/svg/buttons/BackButton";
import RoundButton from "../components/buttons/RoundButton";
import TextInputField from "../components/inputs/TextInputField";
import { TopInfoBar } from "../components/layout/navigation/TopInfoBar";
import Heading from "../components/texts/Heading";
import Paragraph from "../components/texts/Paragraph";

const INVALID_REFERRAL_CODE = "INVALID_REF_CODE";
const EXPIRED_REF_CODE = "EXPIRED_REF_CODE";
const INVALID_DATE_FORMAT = "INVALID_DATE_FORMAT";
const DOB_VALIDATION_ERROR = "DOB_VALIDATION_ERROR";

const getReferralError = (error: string | null): string | false => {
  switch (error) {
    case EXPIRED_REF_CODE:
      return "This referral code has expired";
    case INVALID_REFERRAL_CODE:
      return "Please enter a valid referral code";
    default:
      return false;
  }
};

const TextUnderInput = styled.div`
  height: 22px;
  font-family: ${theme.typography.FONT_NAME};
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  margin-top: 12px;
`;

const InvalidCode = styled.div`
  display: inline-block;
  color: #eb5757;
  margin-right: 15px;
`;

const DateInputWrapper = styled.div`
  margin-top: 16px;
`;

const Container = styled.div`
  width: 80%;
  max-width: 640px;
`;

const ImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const Image = styled.img`
  width: 100vw;
  height: 100%;
  max-height: 50vh;
  max-width: 480px;
  object-fit: contain;
`;

export const AssessmentReferralCodeScreen = () => {
  // Navigation
  const navigate = useNavigate();

  // State
  const [referralCode, setReferralCode] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");

  // Store
  const [
    dispatchFetchReferral,
    { data: referral, isLoading, error, reset, isSuccess },
  ] = useReferralCodeMutation();
  const ref = usePositiveValue(referral);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorCode = useQueryError(error as any);

  // Computed Variables
  const showDoBInput = Boolean(ref && ref.codeType === "Unique");
  const isDateValid =
    dateOfBirth &&
    new Date(dateOfBirth).setHours(0, 0, 0, 0) <
      new Date().setHours(0, 0, 0, 0);

  const { height } = useWindowSize();

  // Methods
  const handleContinue = () =>
    dispatchFetchReferral({
      referralCode,
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : undefined,
    });

  const handleBackButton = () => navigate("/launch");

  const handleChangeReferral = (event: React.ChangeEvent<HTMLInputElement>) =>
    setReferralCode(event.target.value);

  // Effects
  useEffect(() => {
    if (!referral || !isSuccess) return;
    // if (referral.codeType === "Physitrack") {
    //   navigate("PhysiTrack");
    //   return reset();
    // }
    if (referral.codeType === "Unique" && !referral.patient) return;
    navigate("/instructions");
    reset();
  }, [referral]);

  // renderers
  const renderAndreas = () => {
    // only show image if enough space
    if (height > 600) {
      return (
        <ImageContainer>
          <Image alt="Andreas" src={AndreasImage} />
        </ImageContainer>
      );
    }
    return null;
  };

  return (
    <Container>
      <TopInfoBar
        title=""
        leftButton={<BackButton />}
        leftButtonOnClick={handleBackButton}
      />
      <div style={{ paddingTop: "70px" }} />
      <Heading
        size="larger"
        text={t("referral_code_heading")}
        marginBottom={theme.spacingPx(2)}
      />
      <Paragraph
        text={t("referral_code_description")}
        marginBottom={theme.spacingPx(2)}
      />
      <TextInputField
        placeholderKey="form_label_referral_code"
        value={referralCode}
        error={getReferralError(errorCode)}
        keyboardType="default"
        autoCapitalize="characters"
        returnKeyType="done"
        onChangeText={handleChangeReferral}
      />
      {showDoBInput && (
        <DateInputWrapper>
          <Input
            type="date"
            value={dateOfBirth}
            setValue={(value) =>
              setDateOfBirth(new Date(value).toISOString().slice(0, 10))
            }
            isInvalid={!isDateValid}
            placeholder="Date of birth"
          />
        </DateInputWrapper>
      )}
      {errorCode && (
        <TextUnderInput>
          <InvalidCode>
            {errorCode &&
            [DOB_VALIDATION_ERROR, INVALID_DATE_FORMAT].includes(errorCode)
              ? "Invalid date of birth"
              : getReferralError(errorCode)}
          </InvalidCode>
        </TextUnderInput>
      )}
      <div style={{ marginTop: "24px" }} />
      <RoundButton
        text={t("referral_code_btn")}
        onPress={handleContinue}
        loading={isLoading}
      />
      {renderAndreas()}
    </Container>
  );
};
