import * as React from "react";
import { useNavigate } from "react-router-dom";
import { theme as style } from "shared-utilities";
import {
  View,
  Panel,
  Button,
  Heading,
  Paragraph,
} from "shared-utilities/src/ui";
import styled from "styled-components";

const Logo = styled.img`
  margin: 0;
  width: 300px;
`;

const Container = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const HandoverPrivacyScreen = () => {
  // Navigation
  const navigate = useNavigate();

  const [logo, fetchLogo] = React.useState<JSX.Element | null>(null);

  React.useEffect(() => {
    import("shared-assets/src/themes/defaultTheme/icons/logo.svg").then(
      (res) => {
        fetchLogo(<Logo src={res.default} />);
      }
    );
  }, []);

  return (
    <Container>
      <View>
        <Panel height="full">
          {logo}

          <Heading align="center" size="lg">
            Terms &amp; Conditions
          </Heading>

          <Paragraph align="center" maxWidth="750px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            faucibus orci et volutpat interdum. Phasellus ut venenatis mi, ut
            posuere quam. Phasellus iaculis, ante eu egestas tincidunt, nunc
            nulla dapibus neque, at tempor augue turpis in nibh.
          </Paragraph>

          <Paragraph align="center" maxWidth="750px">
            <a href="/">Read Our Privacy Notice</a>
            <br />
            <a href="/">Read Our Terms &amp; Conditions</a>
          </Paragraph>
        </Panel>

        <Panel>
          <Button
            background={style.palette.BUTTON_SECONDARY}
            color={style.palette.PRIMARY}
            rounded
            onClick={() => navigate("/instructions")}
          >
            <span>Agree &amp; Continue</span>
          </Button>
        </Panel>
      </View>
    </Container>
  );
};
