import React from "react";
import { WorkoutSummaryRow, ModalAction } from "shared-interfaces";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import RoundButton from "../buttons/RoundButton";

const ModalWrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  height: 100vh;
  width: 100%;
`;

const ModalContent = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 24px 16px;
  max-width: 311px;
`;

const Title = styled.div`
  text-align: center;
  color: ${theme.palette.PRIMARY};
`;

const SubTitle = styled.div`
  padding-top: ${theme.spacingPx(2)};
  padding-bottom: ${theme.spacingPx(2)};
  color: ${theme.palette.PRIMARY};
  text-align: center;
`;

type ModalProps = {
  title: string;
  subtitle: string;
  actions: ModalAction[];
};

export const EndEarlyModal: React.FC<ModalProps> = ({
  title,
  subtitle,
  actions,
}) => {
  return (
    <ModalWrapper>
      <ModalContent>
        <Title css={theme.typography.Omnes.MEDIUM_HEADING}>{title}</Title>
        <SubTitle css={theme.typography.Omnes.REGULAR_TEXT}>
          {subtitle}
        </SubTitle>
        {actions.map((action) => (
          <RoundButton
            key={action.text}
            text={action.text}
            backgroundColor={action.backgroundColor}
            onPress={action.onPress}
            // loading={loading}
            style={{ marginTop: theme.spacing(1.25) }}
          />
        ))}
      </ModalContent>
    </ModalWrapper>
  );
};

type FirstRoundModalProps = {
  onCancelSession: () => void;
  onContinueSession: () => void;
};
const FirstRoundModal: React.FC<FirstRoundModalProps> = ({
  onCancelSession,
  onContinueSession,
}) => (
  <EndEarlyModal
    title={t("insession_modal_bad_title")}
    subtitle={t("insession_modal_bad_txt")}
    actions={[
      {
        text: t("insession_modal_bad_btn_end"),
        backgroundColor: theme.palette.RED,
        onPress: onCancelSession,
      },
      { text: t("insession_modal_btn_continue"), onPress: onContinueSession },
    ]}
  />
);

type OtherRoundsModalProps = {
  onCompleteSession: () => void;
  onContinueSession: () => void;
};
const OtherRoundsModal: React.FC<OtherRoundsModalProps> = ({
  onCompleteSession,
  onContinueSession,
}) => (
  <EndEarlyModal
    title={t("insession_modal_bad_title")}
    subtitle={t("insession_modal_bad_txt")}
    actions={[
      {
        text: t("insession_modal_bad_btn_end"),
        backgroundColor: theme.palette.RED,
        onPress: onCompleteSession,
      },
      { text: t("insession_modal_btn_continue"), onPress: onContinueSession },
    ]}
  />
);

export type CancelSessionProps = {
  exercise: WorkoutSummaryRow;
  onContinueSession: () => void;
  onCancelSession: () => void;
  onCompleteSession: () => void;
  isVisible?: boolean;
};
const CancelSessionModal: React.FC<CancelSessionProps> = ({
  isVisible,
  exercise,
  ...props
}) => {
  if (!isVisible) return null;
  if (exercise.round === 1) return <FirstRoundModal {...props} />;
  return <OtherRoundsModal {...props} />;
};

export default React.memo(CancelSessionModal);
