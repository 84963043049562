import * as React from "react";
import { nanoid } from "nanoid";
import { useChatConnected } from "shared-chat";
import {
  useChatGetConversationQuery,
  useChatReadMessagesMutation,
  useSendMessageMutation,
  useAppSelector,
  getUser,
  getConversation,
  useAuthGetMeQuery,
} from "shared-redux";
import { t, theme } from "shared-utilities";
import styled from "styled-components";
import ChatInput from "../components/chat/ChatInput";
import MessageDisplay from "../components/chat/MessageDisplay";
import Preloader from "../components/preloader/Preloader";
import Heading from "../components/texts/Heading";
import Paragraph from "../components/texts/Paragraph";

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items center;
`;

const ChatView = styled.div`
  background: white;
  position: fixed;
  top: 58px;
  left: 0;
  right: 0;
  bottom: 135px;
`;

const InputContainer = styled.div`
  position: absolute;
  z-index: 99;
`;

const IllustrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding-left: ${theme.spacingPx(4)};
  padding-right: ${theme.spacingPx(4)};
  height: 100%;
`;

export const DashboardChatWidgetScreen: React.FC<
  React.PropsWithChildren
> = () => {
  const { isLoading: isLoadingUser, refetch: refetchUser } =
    useAuthGetMeQuery();
  const user = useAppSelector(getUser);
  const messages = useAppSelector(getConversation);
  const [message, setMessage] = React.useState<string>("");
  const [dispatchSendMessage] = useSendMessageMutation();
  const [dispatchReadMessages] = useChatReadMessagesMutation();
  const connected = useChatConnected();
  const { data, isLoading, isFetching } = useChatGetConversationQuery();

  const [messagesToDisplay, setMessagesToDisplay] = React.useState<any[]>([]);

  const renderWelcomeMessage = () => {
    return (
      <IllustrationContainer>
        <Heading size="larger" text={t("chat_onboarding_title")} />
        <Paragraph text={t("chat_onboarding_text")} />
      </IllustrationContainer>
    );
  };

  const renderMessages = () => {
    if (!data || !user)
      return (
        <LoadingContainer>
          <Preloader />
        </LoadingContainer>
      );

    if (!messagesToDisplay.length) return renderWelcomeMessage();

    return (
      <MessageDisplay
        user={user}
        messages={messagesToDisplay}
        loading={isLoading && isFetching}
      />
    );
  };

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) =>
    setMessage(event.target.value);

  const handleSubmitMessage = () => {
    if (!connected || !user || !data) return;
    const m = {
      userId: user.id,
      type: "user",
      message,
      tempId: nanoid(),
      createdAt: new Date(),
    };

    setMessagesToDisplay([...messagesToDisplay, m]);
    dispatchSendMessage(m);
    setMessage("");
  };

  React.useEffect(() => {
    if (!data) return;
    setMessagesToDisplay([...data.messages]);
  }, [data]);

  React.useEffect(() => {
    setMessagesToDisplay([...messages]);
  }, [messages]);

  React.useEffect(() => {
    refetchUser();
    dispatchReadMessages();
  }, []);

  React.useEffect(() => {
    dispatchReadMessages();
  }, [messagesToDisplay]);

  return (
    <ChatView>
      <InputContainer>
        <ChatInput
          value={message}
          onChange={handleChangeMessage}
          onSubmit={handleSubmitMessage}
        />
      </InputContainer>
      {isLoading ? (
        <LoadingContainer>
          <Preloader />
        </LoadingContainer>
      ) : (
        renderMessages()
      )}
    </ChatView>
  );
};
