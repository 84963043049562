import {
  IUser,
  LanguageKey,
  WorkoutSchedule,
  WorkoutWithInfo,
} from "shared-interfaces";
import { t, theme } from "shared-utilities";
import CompletedWithCircle from "../../assets/svg/icons/CompletedWithCircle";
import SmallButton from "../buttons/SmallButton";
import Tile from "../patterns/Tile";
import Heading from "../texts/Heading";
import Paragraph from "../texts/Paragraph";

interface FullWorkoutTileProps {
  workout: WorkoutWithInfo;
  showWorkout: (props: FullWorkoutTileProps) => void;
  editSchedule?: () => void;
  user: IUser;
  schedule: WorkoutSchedule | null;
  language: LanguageKey;
  completedWorkouts: number;
  completed?: boolean;
  isSubscribed?: boolean;
}

const WINDOW_HEIGHT = window.innerHeight;

export const FullWorkoutTile: React.FC<FullWorkoutTileProps> = ({
  workout,
  showWorkout,
  editSchedule,
  user,
  schedule,
  language,
  completed,
  completedWorkouts,
  isSubscribed,
}) => {
  // Computed variables
  // const windowSize = useWindowSize();

  // Methods
  const handleShowWorkout = () => {
    if (completed) return;
    showWorkout({
      workout,
      showWorkout,
      editSchedule,
      user,
      language,
      completedWorkouts,
      schedule,
      completed,
      isSubscribed,
    });
  };

  const handleGetTitle = (): string => {
    if (completed)
      return t(
        `workout_${completedWorkouts === 1 ? "first" : "full"}_title_completed`
      );
    if (completedWorkouts === 0) return t("workout_first_title");
    return t(workout.title);
  };

  const handleGetImage = (): any => {
    if (completed)
      return completedWorkouts
        ? theme.images.fullWorkoutTile.FIRST_WORKOUT_COMPLETE_IMAGE
        : theme.images.fullWorkoutTile.DAILY_WORKOUT_COMPLETE_IMAGE;
    return !completedWorkouts
      ? theme.images.fullWorkoutTile.FIRST_WORKOUT_IMAGE
      : theme.images.fullWorkoutTile.DAILY_WORKOUT_IMAGE;
  };

  const handleGetBottomText = (): string | undefined => {
    if (completedWorkouts === 0) return;
    if (!(completedWorkouts < 3 && !completed)) return;
    if (!(completedWorkouts <= 3 && completed)) return;
    return "next_workout_first_three_workouts";
  };

  // Computed Variables
  const title = handleGetTitle();
  const image = handleGetImage();
  const bottomText = handleGetBottomText();

  return (
    <Tile
      backgroundColor={theme.palette.PRIMARY}
      backgroundImage={image}
      onPress={completed ? editSchedule : handleShowWorkout}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: WINDOW_HEIGHT * 0.35,
      }} // TODO replace with screen height
      bottomText={bottomText}
    >
      {completed && (
        <CompletedWithCircle
          scale={theme.utilities.SCALE}
          style={{ marginBottom: theme.spacing(2) }}
        />
      )}
      <Heading
        size="largest"
        text={title}
        color={theme.palette.WHITE}
        marginBottom={theme.spacingPx(1)}
      />
      {!completed && (
        <>
          <Paragraph
            text={t("workout_estimate", {
              time:
                Math.floor(workout.time / 60) <= 0
                  ? (workout.time / 60).toFixed(1)
                  : Math.floor(workout.time / 60),
            })}
            color={theme.palette.WHITE}
            marginBottom={theme.spacingPx(2)}
          />
          <SmallButton
            onPress={handleShowWorkout}
            text={t("workout_action")}
            backgroundColor={theme.palette.ACCENT_PRIMARY}
            textColor={theme.palette.WHITE}
            style={{ marginBottom: theme.spacing(1) }}
            disabled={!isSubscribed}
          />
        </>
      )}
      {/* {completed && ( */}
      {/*  <NextWorkout */}
      {/*    user={user} */}
      {/*    language={language} */}
      {/*    editSchedule={editSchedule} */}
      {/*    isSubscribed={isSubscribed} */}
      {/*    schedule={schedule} */}
      {/*  /> */}
      {/* )} */}
    </Tile>
  );
};
