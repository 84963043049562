import React from "react";
import { t, theme } from "shared-utilities";
import styled, { css } from "styled-components/macro";
import { Button } from "../Button";
import Paper from "../patterns/Paper";

const Text = styled.div``;

interface CheckinPromptProps {
  onStartCheckin: () => void;
}

const CheckinPrompt: React.FC<CheckinPromptProps> = ({ onStartCheckin }) => (
  <Paper style={{ backgroundColor: theme.palette.PRIMARY, margin: 14 }}>
    <Text
      css={[
        theme.typography[theme.typography.FONT_NAME].LARGER_HEADING,
        { textAlign: "center", color: theme.palette.WHITE },
      ]}
    >
      {t("progress_checkin_prompt_title")}
    </Text>
    <Text
      css={[
        theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT,
        {
          color: theme.palette.WHITE,
          marginTop: 10,
          marginBottom: 30,
          textAlign: "center",
        },
      ]}
    >
      {t("progress_checkin_prompt_txt")}
    </Text>
    <Button
      loading={false}
      isBoldText
      bgColor={theme.palette.BUTTON_SECONDARY}
      handleClick={onStartCheckin}
    >
      <Text
        css={[
          theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT,
          {
            color: theme.palette.PRIMARY,
            textAlign: "center",
          },
        ]}
      >
        {t("progress_checkin_prompt_btn")}
      </Text>
    </Button>
  </Paper>
);

export default CheckinPrompt;
