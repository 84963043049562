import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const SofaIcon: React.FC<SvgProps & IconProps> = ({
  size = theme.spacing(2),
  color = theme.palette.PRIMARY,
  ...props
}) => (
  <Svg width={size * 1.875} height={size} viewBox="0 0 30 16" {...props}>
    <G stroke={color} strokeWidth={1.7} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <Path d="M6 7.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM29 7.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM13.579 11H7.42C6.64 11 6 10.325 6 9.5S6.64 8 7.421 8h6.158C14.36 8 15 8.675 15 9.5s-.64 1.5-1.421 1.5zM22.579 11H16.42C15.64 11 15 10.325 15 9.5S15.64 8 16.421 8h6.158C23.36 8 24 8.675 24 9.5s-.64 1.5-1.421 1.5zM13.579 8H7.42C6.64 8 6 7.325 6 6.5v-4C6 1.675 6.64 1 7.421 1h6.158C14.36 1 15 1.675 15 2.5v4c0 .825-.64 1.5-1.421 1.5zM22.579 8H16.42C15.64 8 15 7.325 15 6.5v-4c0-.825.64-1.5 1.421-1.5h6.158C23.36 1 24 1.675 24 2.5v4c0 .825-.64 1.5-1.421 1.5z" />
      <Path d="M26 10v5H3v-5" />
    </G>
  </Svg>
);

export default SofaIcon;
