import { createApi } from '@reduxjs/toolkit/query/react';
import moment from 'moment';

import { SuccessResponse } from 'shared-interfaces';
import { createBaseQuery } from '../baseQuery';

export interface ReferralCodeResponse {
  code: string;
  codeType: 'Unique' | 'Organisation' | 'Physitrack';
  startDate?: Date;
  expiryDate?: Date;
  paymentRequired: boolean;
  patient: {
    dateOfBirth?: string;
    emailAddress?: string;
    firstName?: string;
    surname?: string;
    reference?: string;
    fileCode?: number;
  } | null;
  organisation?: string;
}

export interface ReferralRequestBody {
  referralCode: string;
  dateOfBirth?: Date;
}

export const referralApi = createApi({
  reducerPath: 'referralApi',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    referralCode: builder.mutation<ReferralCodeResponse, ReferralRequestBody>({
      query: ({ referralCode, dateOfBirth }) => {
        const base = 'assessment/referral-code';
        if (!dateOfBirth) return { url: `${base}?referralCode=${referralCode}` };
        const url = `${base}?referralCode=${referralCode}&dob=${moment(dateOfBirth).format(
          'DD-MM-yyyy'
        )}`;
        return { url };
      },
      transformResponse: (response: SuccessResponse<ReferralCodeResponse>) => response.data,
    }),
    myReferral: builder.query<ReferralCodeResponse, null>({
      query: () => ({ url: '/user/referral-info' }),
      transformResponse: (response: SuccessResponse<ReferralCodeResponse>) => response.data,
    }),
  }),
});

export const { useReferralCodeMutation, useMyReferralQuery } = referralApi;
