import { Theme } from '../';
import { palette } from './palette';
import { scales } from './scales';
import { fontStyles, fontStylesObj } from './typography';
import { spacing, spacingPx } from "../../spacing";
import { utilities } from "../../utilities";
import { images } from './images';

export const defaultTheme: Theme = {
  palette,
  typography: fontStyles,
  typographyObj: fontStylesObj,
  spacing,
  spacingPx,
  utilities,
  scales,
  images
};