import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PainAreaKey } from "shared-interfaces";
import {
  ReferralCodeResponse,
  useAssessmentCreateMutation,
  useUserCreateMutation,
  useUserModifyMutation,
  authSetToken,
  getReferralDetails,
  getToken,
  getUser,
  useAppDispatch,
  useAppSelector,
} from "shared-redux";

import { t, theme } from "shared-utilities";
import {
  Icon,
  IconKind,
  Button,
  GenderQuestionView,
  LocationQuestionView,
  PainAreaQuestionView,
} from "src/components";
import styled from "styled-components/macro";
import Heading from "../components/texts/Heading";
import { gatherMetadata } from "../utilities/common";
import { getDeviceIdentifier } from "../utilities/storage";
import useDisableBack from "../utilities/useDisableBack";

const Header = styled.div`
  width: 100%;
  min-height: 56px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  position: fixed;
  top: 0;
  background: ${theme.palette.TOP_BAR_BACKGROUND};
  z-index: 10;

  span {
    height: 22px;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: ${theme.palette.TOP_BAR_TEXT};
  }
`;

const Text = styled.div``;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 40px;
  padding: 0 16px 60px;
`;

export const AssessmentStaticQuestionsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useDisableBack();

  const [activeStep, setActiveStep] = useState<number>(1);
  const [activePain, setActivePain] = useState<PainAreaKey | null>(null);
  const [activeGender, setActiveGender] = useState<number | null>(null);
  const [isPregnant, setIsPregnant] = useState<boolean>(false);
  const [activeLocation, setActiveLocation] = useState<string | null>(null);
  const btnRef = React.useRef(null);

  // State
  const [loading, setLoading] = useState<boolean>(false);

  // Store
  const referralDetails = useAppSelector(
    getReferralDetails
  ) as ReferralCodeResponse;
  const token = useAppSelector(getToken);
  const user = useAppSelector(getUser);

  const [
    dispatchCreateAssessment,
    { isLoading: isCreatingAssessment, isSuccess, isError },
  ] = useAssessmentCreateMutation();
  const [dispatchModifyUser, { isLoading: isModifyingUser }] =
    useUserModifyMutation();
  const [
    dispatchCreateUser,
    { isLoading: isCreatingUser, data: response, isSuccess: createUserSuccess },
  ] = useUserCreateMutation();

  const isDisabled =
    (activeStep === 1 && activeGender) ||
    (activeStep === 2 && activeLocation) ||
    (activeStep === 3 && activePain) ||
    (activeStep === 4 && activePain);

  // Methods
  const handleCreateUser = async () => {
    const { organisation, code, patient } = referralDetails;

    const timezone = // allow overriding of timezone for testing purposes
      process.env.REACT_APP_TZ ||
      new Intl.DateTimeFormat().resolvedOptions().timeZone;
    let language = // allow overriding of language for testing purposes
      process.env.REACT_APP_LANG ||
      navigator.language ||
      window.navigator.language;
    // ensure language is in the format "en" and not "en-US" etc
    if (language.includes("-")) [language] = language.split("-");
    // ensure language is either "en" or "da" - others are not supported by the API
    if (!["en", "da"].includes(language)) language = "en";

    const timezoneOffset = // allow overriding of timezone offset for testing purposes
      parseInt(process.env.REACT_APP_TZ_OFFSET as string, 10) ||
      new Date().getTimezoneOffset();

    const newUser = {
      device: {
        deviceId: getDeviceIdentifier(),
        ...gatherMetadata(),
      },
      organisation: organisation!,
      referralCode: code,
      timezone,
      timezoneOffset,
      language,
      country: activeLocation,
      medicalInformation: {
        gender:
          // eslint-disable-next-line no-nested-ternary
          activeGender === null
            ? "unknown"
            : activeGender === 1
            ? "male"
            : "female",
        isPregnant,
      },
    };

    // If ref code doesn't provide patient details then create without
    if (
      (referralDetails && referralDetails.codeType !== "Unique") ||
      !patient
    ) {
      // eslint-disable-next-line
      return dispatchCreateUser(newUser as any);
    }

    return dispatchCreateUser({
      ...newUser,
      firstName: patient.firstName,
      lastName: patient.surname,
      email: patient.emailAddress!,
      medicalInformation: {
        ...newUser.medicalInformation,
        dateOfBirth: patient.dateOfBirth,
      },
      // eslint-disable-next-line
    } as any);
  };

  useEffect(() => {
    if (!createUserSuccess) return;
    if (response) {
      dispatch(authSetToken(response.data.token));
    }
  }, [createUserSuccess]);

  useEffect(() => {
    if (isCreatingAssessment || isCreatingUser) return setLoading(true);
    if (!isCreatingAssessment && !isError && !isSuccess)
      return setLoading(false);
    if ((!isCreatingAssessment || !isModifyingUser) && (isError || isSuccess))
      return setLoading(true);
  }, [isCreatingAssessment, isCreatingUser, loading, isError, isSuccess]);

  const createAssessment = async () => {
    if (token && !isCreatingAssessment && activePain) {
      await dispatchCreateAssessment({ painArea: activePain });
      navigate("/symptoms");
    }
  };
  const getGender = () => {
    switch (activeGender) {
      case 1:
        return "male";
      case 2:
        return "female";
      default:
        return "unknown";
    }
  };

  const updateActiveStepHandler = async () => {
    if (activeStep !== 4 && !(activeStep === 3 && activePain !== null))
      return setActiveStep(activeStep + 1);

    if (!user?.id) {
      await handleCreateUser();
      return createAssessment();
    }
    // return handleCreateUser();
    await dispatchModifyUser({
      medicalInformation: {
        gender: getGender(),
        isPregnant,
      },
    });
    return createAssessment();
  };

  const navigationHandler = () => {
    switch (activeStep) {
      case 1: {
        navigate("/instructions");
        break;
      }
      case 2: {
        setActiveStep(1);
        break;
      }
      case 3: {
        setActiveStep(2);
        break;
      }
      case 4:
        setActivePain(null);
        setActiveStep(3);
        break;
      default: {
        navigate("/instructions");
      }
    }
  };

  const renderActiveStep = () => {
    switch (activeStep) {
      case 1: {
        return (
          <GenderQuestionView
            isPregnant={isPregnant}
            activeGender={activeGender}
            setIsPregnant={setIsPregnant}
            updateActiveGender={setActiveGender}
          />
        );
      }
      case 2: {
        return (
          <LocationQuestionView updateActiveLocation={setActiveLocation} />
        );
      }
      case 3: {
        // return (
        //   <AssessmentAnatomicalScreen
        //     painArea={activePain}
        //     setPainArea={setActivePain}
        //     btnRef={btnRef}
        //     gender={getGender()}
        //   />
        // );
        return <PainAreaQuestionView updateActivePain={setActivePain} />;
      }
      case 4: {
        return <PainAreaQuestionView updateActivePain={setActivePain} />;
      }
      default: {
        return (
          <GenderQuestionView
            isPregnant={isPregnant}
            activeGender={activeGender}
            setIsPregnant={setIsPregnant}
            updateActiveGender={setActiveGender}
          />
        );
      }
    }
  };

  const renderButton = () => {
    if (activeStep === 3 && activePain === null)
      return (
        <Button
          bgColor={theme.palette.BACKGROUND}
          isFullWidth
          isRoundedCorners={false}
          loading={loading}
          handleClick={updateActiveStepHandler}
        >
          <Text
            css={[
              theme.typography[theme.typography.FONT_NAME].REGULAR_BOLD_HEADING,
              { color: theme.palette.PRIMARY },
            ]}
          >
            Select a pain area to continue
          </Text>
        </Button>
      );

    return (
      <Button
        bgColor={theme.palette.BUTTON_PRIMARY}
        isFullWidth
        isRoundedCorners={false}
        loading={loading}
        isActive={!!isDisabled}
        handleClick={updateActiveStepHandler}
      >
        <Text
          css={[
            theme.typography[theme.typography.FONT_NAME].REGULAR_BOLD_HEADING,
            { color: theme.palette.WHITE },
          ]}
        >
          {t("btn_next")}
        </Text>
      </Button>
    );
  };

  return (
    <>
      <Header>
        <button type="button" onClick={navigationHandler}>
          <Icon kind={IconKind.BACK} />
        </button>
        <Heading
          color={theme.palette.TOP_BAR_TEXT}
          size="regular"
          bold
          text="Getting to know you"
          marginBottom="0px"
          style={{ lineHeight: theme.spacingPx(2) }}
        />
        <div />
      </Header>
      <Wrapper>{renderActiveStep()}</Wrapper>
      <Footer ref={btnRef}>{renderButton()}</Footer>
    </>
  );
};
