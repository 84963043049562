import "react-toastify/dist/ReactToastify.css";
import React from "react";
import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
// eslint-disable-next-line
import { PersistGate } from "redux-persist/integration/react";

import { store, storePersistor } from "shared-redux";
import { GlobalStyle } from "shared-utilities/src/ui";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ContextStore, StoreType } from "./store";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_ENDPOINT,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.01,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const contextStore: StoreType = {
  profile: {
    email: "",
  },
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={storePersistor}>
        <ContextStore.Provider value={contextStore as StoreType}>
          <App />
          <ToastContainer />
          <GlobalStyle />
        </ContextStore.Provider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
