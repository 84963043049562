import React from "react";
import { theme } from "shared-utilities";
import { css } from "styled-components";
import styled, { CSSProp, DefaultTheme } from "styled-components/macro";

interface ParagraphProps {
  size?: "small" | "tiny";
  text?: string;
  color?: string;
  marginBottom?: string;
  leftAlign?: boolean;
  bold?: boolean;
  style?: CSSProp<DefaultTheme>;
  onPress?: () => void;
}

const Text = styled.div``;

const Paragraph: React.FC<React.PropsWithChildren<ParagraphProps>> = ({
  size,
  text,
  color = theme.palette.PRIMARY,
  marginBottom = theme.spacingPx(1),
  leftAlign,
  bold,
  style,
  onPress,
  children,
}) => {
  // Methods
  const getFont = () => {
    switch (size) {
      case "small":
        return theme.typography[theme.typography.FONT_NAME].SMALL_TEXT;
      case "tiny":
        return theme.typography[theme.typography.FONT_NAME].TINY_TEXT;
      default:
        if (bold)
          return theme.typography[theme.typography.FONT_NAME].REGULAR_HEADING;
        return theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT;
    }
  };

  // Computed Variables
  const alignment = !leftAlign
    ? css`
        text-align: center;
      `
    : null;

  return (
    <Text
      css={[
        getFont(),
        css`
          margin-bottom: ${marginBottom};
          color: ${color};
        `,
        alignment,
        style,
      ]}
    >
      {children ?? text}
    </Text>
  );
};

export default Paragraph;
