import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import Lock from "../../assets/icons/Lock";

interface LearnCountdownProps {
  remainingArticles: number;
  nextArticleCountdown: number;
}

const LearnCountDownContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 15px;
  align-items: center;
  padding-bottom: 20px;
`;
const LearnCountDownInnerContainer = styled.div`
  height: 40px;
  border-radius: 20px;
  align-self: center;
  margin-top: 8px;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  border-style: solid;
  border-color: ${theme.palette.LIGHTEST_GREY};
  border-width: 1px;
`;
const LockContainer = styled.div`
  margin-top: 6px;
  align-items: center;
  flex-direction: row;
`;
const UnlockText = styled.span`
  padding-left: 15px;
  color: ${theme.palette.PRIMARY};
`;
const NextArticleText = styled.div`
  color: ${theme.palette.PRIMARY};
  text-align: center;
`;

export const LearnCountdown: React.FC<LearnCountdownProps> = ({
  remainingArticles,
  nextArticleCountdown,
}) => {
  // Computed variables
  const countdown = `Unlocks in ${nextArticleCountdown} workouts`;

  if (!remainingArticles) return null;
  return (
    <LearnCountDownContainer>
      <NextArticleText
        css={theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT}
      >
        {t("learn_next_article")}
      </NextArticleText>
      <LearnCountDownInnerContainer>
        <LockContainer>
          <Lock />
          <UnlockText
            css={
              theme.typography[theme.typography.FONT_NAME].SMALL_BOLD_HEADING
            }
          >
            {countdown}
          </UnlockText>
        </LockContainer>
      </LearnCountDownInnerContainer>
    </LearnCountDownContainer>
  );
};
