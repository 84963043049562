import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { IconProps } from 'shared-interfaces/src/common';
import { theme } from 'shared-utilities/src/theme/theme';

const DumbbellIcon: React.FC<SvgProps & IconProps> = ({
  size = theme.spacing(1.75),
  color = theme.palette.PRIMARY,
  ...props
}) => (
  <Svg width={size * 1.9285714285714286} height={size} viewBox="0 0 27 14" {...props}>
    <G stroke={color} strokeWidth={1.7} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <Path d="M5.704 12.708h-.53a1.5 1.5 0 0 1-1.5-1.5V2.44a1.5 1.5 0 0 1 1.5-1.5h.53a1.5 1.5 0 0 1 1.5 1.5v8.768a1.5 1.5 0 0 1-1.5 1.5zM3.203 8.981h-.588C1.64 8.981.85 8.191.85 7.216v-.784c0-.975.791-1.765 1.766-1.765h.588M22.973 4.667h.588c.975 0 1.766.79 1.766 1.765v.784c0 .975-.791 1.765-1.766 1.765h-.588M21.002 12.618h-.53a1.5 1.5 0 0 1-1.5-1.5V2.35a1.5 1.5 0 0 1 1.5-1.5h.53a1.5 1.5 0 0 1 1.5 1.5v8.768a1.5 1.5 0 0 1-1.5 1.5zM7.204 6.824h10.591" />
    </G>
  </Svg>
);

export default DumbbellIcon;
