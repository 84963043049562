export const scales = {
    GREEN: "#13C48E",
    LIGHTER_GREEN: "#DEF8F0",
    LIME: "#B9DC67",
    LIGHT_LIME: "#F2FADE",
    YELLOW: "#FFC44E",
    LIGHT_YELLOW: "#FFF9ED",
    ORANGE: "#FF974C",
    LIGHT_ORANGE: "#FFF2E9",
    RED: "#FF5A5A",
    LIGHT_RED: "#FFEFEF",
}
