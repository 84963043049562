import React from "react";
import { useSearchParams } from "react-router-dom";
import {
  useLearnMarkAsReadMutation,
  getArticles,
  useAppSelector,
} from "shared-redux";
import { theme } from "shared-utilities";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 720px;
`;
const ArticleContainer = styled.div`
  display: flex;
  margin: 0 20px;
`;
const HeaderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const Article = styled("div")({
  h1: {
    ...theme.typographyObj[theme.typographyObj.FONT_NAME].LARGER_HEADING,
    color: theme.palette.PRIMARY,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  h2: {
    ...theme.typographyObj[theme.typographyObj.FONT_NAME].REGULAR_HEADING,
    color: theme.palette.PRIMARY,
    paddingBottom: theme.spacing(3),
  },
  h3: {
    ...theme.typographyObj[theme.typographyObj.FONT_NAME].MEDIUM_BOLD_HEADING,
    color: theme.palette.PRIMARY,
    paddingBottom: theme.spacing(1),
  },
  h4: {
    ...theme.typographyObj[theme.typographyObj.FONT_NAME].REGULAR_HEADING,
    color: theme.palette.PRIMARY,
    paddingBottom: theme.spacing(1),
  },
  p: {
    ...theme.typographyObj[theme.typographyObj.FONT_NAME].REGULAR_TEXT,
    color: theme.palette.PRIMARY,
    paddingBottom: theme.spacing(1),
  },
  b: {
    ...theme.typographyObj[theme.typographyObj.FONT_NAME].REGULAR_BOLD_HEADING,
    color: theme.palette.PRIMARY,
  },
  li: {
    ...theme.typographyObj[theme.typographyObj.FONT_NAME].REGULAR_TEXT,
    color: theme.palette.PRIMARY,
    paddingLeft: -10,
  },
  img: {
    width: "100%",
  },
});

export const LearnArticleScreen = () => {
  // State
  const articles = useAppSelector(getArticles);

  const [dispatchReadArticle] = useLearnMarkAsReadMutation();

  // Computed Variables
  const [searchParams] = useSearchParams();
  const articleId = Number(searchParams.get("id"));
  const article = articles.find((a) => a.id === articleId);

  // Effects
  React.useEffect(() => {
    if (!articleId) return;
    dispatchReadArticle(articleId);
  }, [articleId]);

  if (!article) return <div>Article not found</div>;
  return (
    <Root>
      <HeaderImage src={article.article.fullImage} />
      <ArticleContainer>
        <Article dangerouslySetInnerHTML={{ __html: article.article.html }} />
      </ArticleContainer>
    </Root>
  );
};
