import * as React from "react";
import { theme as style } from "shared-utilities";
import { View, Panel, Button } from "shared-utilities/src/ui";
import styled from "styled-components";
import { InstructionModal } from "../components";
import { InvalidSessionMessage } from "../components/auth/InvalidSessionMessage";

const { REACT_APP_CLIENT } = process.env;

const Logo = styled.img`
  margin: 0;
  width: 300px;
`;

const Wrapper = styled.div`
  margin: 20px;
`;

const InvalidTokenScreen = () => {
  const [logo, fetchLogo] = React.useState<JSX.Element | null>(null);
  const [modal, setModal] = React.useState(false);

  React.useEffect(() => {
    import("shared-assets/src/themes/defaultTheme/icons/logo.svg").then(
      (res) => {
        fetchLogo(<Logo src={res.default} />);
      }
    );
  }, []);

  return (
    <View padding="0">
      <Wrapper>
        <Panel height="full">
          <InvalidSessionMessage client={REACT_APP_CLIENT} logo={logo} />
        </Panel>

        <Panel>
          <Button
            background={style.palette.BUTTON_SECONDARY}
            color={style.palette.PRIMARY}
            rounded
            onClick={() => setModal(true)}
          >
            <span>Contact support</span>
          </Button>
        </Panel>

        {modal && <InstructionModal closeModal={() => setModal(!modal)} />}
      </Wrapper>
    </View>
  );
};

export default InvalidTokenScreen;
