import React from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { useAppSelector, getProgress } from "shared-redux";
import { theme } from "shared-utilities/src";
import styled from "styled-components/macro";
import ProgressShoe from "../../assets/svg/icons/ProgressShoe";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 15px;
  position: relative;
`;

const Bar = styled.div`
  left: 0;
  right: 0;
  //top: 24.25px;
  height: 3px;
  background-color: ${theme.palette.LIGHTEST_GREY};
  position: absolute;
`;

const BarComplete = styled.div`
  left: 0;
  //top: 24.25px;
  height: 3px;
  background-color: ${theme.palette.PINK};
  position: absolute;
`;

const PhaseSpacer = styled.div`
  display: flex;
  flex: 1px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const Text = styled.div`
  font-family: ${theme.typography.FONT_NAME};
  font-size: 15px;
  letter-spacing: -0.2px;
  color: #145e7f;
`;

const PhaseText = styled.div`
  font-family: ${theme.typography.FONT_NAME};
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: ${theme.palette.PRIMARY};
`;

const CurrentPhaseText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const ShoeWrapper = styled.div`
  position: absolute;
  //left: 0;
  //top: 0;
  //right: 0;
  //bottom: 0;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;

interface JourneyProps {
  onShowPhase: () => void;
}

const Journey: React.FC<JourneyProps> = ({ onShowPhase }: JourneyProps) => {
  // Store
  const progress = useAppSelector(getProgress);
  // Computed Variables

  const currentPhase = progress?.currentPhase || 1;
  const completedBarWidth = `${((currentPhase - 1) * 33).toString()}%`;

  // Renderers
  const renderPhaseActiveCircle = () => {
    const phase = progress?.currentPhase || 1;
    const phaseObject = progress?.phases[phase - 1];
    const completedWorkouts = phaseObject?.completedWorkouts || 0;
    const totalWorkouts = phaseObject?.totalWorkouts || 5;
    const percentageDone = (completedWorkouts / totalWorkouts) * 100;

    return (
      <div style={{ backgroundColor: theme.palette.WHITE }}>
        <div style={{ width: 60, height: 60 }}>
          <CircularProgressbarWithChildren
            value={percentageDone}
            background
            styles={buildStyles({
              rotation: 0.742,
              // Colors
              strokeLinecap: "butt",
              pathColor: theme.palette.PINK,
              trailColor: theme.palette.LIGHTEST_GREY,
              backgroundColor: theme.palette.WHITE,
            })}
          >
            <ShoeWrapper onClick={onShowPhase}>
              <ProgressShoe />
            </ShoeWrapper>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  };

  const renderCompletedCircle = () => (
    <div style={{ backgroundColor: theme.palette.WHITE }}>
      <div style={{ width: 30, height: 30 }}>
        <CircularProgressbarWithChildren
          value={100}
          background
          styles={buildStyles({
            // Colors
            pathColor: theme.palette.PINK,
            trailColor: "#d6d6d6",
            backgroundColor: theme.palette.WHITE,
          })}
        />
      </div>
    </div>
  );

  const renderFutureCircle = () => (
    <div style={{ backgroundColor: theme.palette.WHITE }}>
      <div style={{ width: 30, height: 30 }}>
        <CircularProgressbarWithChildren
          value={0}
          background
          styles={buildStyles({
            // Colors
            trailColor: theme.palette.LIGHTEST_GREY,
            backgroundColor: theme.palette.WHITE,
          })}
        />
      </div>
    </div>
  );

  const renderPhaseText = (key: string | number) => {
    const phase = progress?.currentPhase || 1;
    const phaseObject = progress?.phases[phase - 1];
    const completedWorkouts = phaseObject?.completedWorkouts || 0;
    const totalWorkouts = phaseObject?.totalWorkouts || 5;

    return (
      <div style={{ alignItems: "center", justifyContent: "center" }} key={key}>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PhaseText>Phase {phase}</PhaseText>
          {completedWorkouts > 0 && (
            <Text>
              {completedWorkouts} out of {totalWorkouts}
            </Text>
          )}
        </div>
      </div>
    );
  };

  const renderPhaseInactiveCircle = (phase: number, currentPhase: number) => {
    if (phase < currentPhase) return renderCompletedCircle();
    return renderFutureCircle();
  };
  const renderPhase = (phase: number, current: number) =>
    currentPhase === phase
      ? renderPhaseActiveCircle()
      : renderPhaseInactiveCircle(phase, current);
  const renderPhaseTextMap = (_: undefined, i: number) =>
    currentPhase === i + 1 ? (
      renderPhaseText(i)
    ) : (
      <div key={i} style={{ flex: 1 }} />
    );

  return (
    <div style={{ padding: 10 }}>
      <Container>
        <Bar />
        <BarComplete style={{ width: completedBarWidth }} />
        {renderPhase(1, currentPhase)}
        <PhaseSpacer>
          {renderPhase(2, currentPhase)}
          {renderPhase(3, currentPhase)}
        </PhaseSpacer>
        {renderPhase(4, currentPhase)}
      </Container>
      <CurrentPhaseText>
        {[...new Array(4)].map(renderPhaseTextMap)}
      </CurrentPhaseText>
    </div>
  );
};

export default Journey;
