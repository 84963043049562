import { createApi } from '@reduxjs/toolkit/query/react';

import { Plan, SuccessResponse } from 'shared-interfaces';
import { createBaseQuery } from '../baseQuery';

export interface PlanResponse {
  plan: Plan;
}

export const planApi = createApi({
  reducerPath: 'planApi',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    planFetch: builder.query<SuccessResponse<PlanResponse>, void>({
      query: () => ({ url: 'user-plan' }),
    }),
  }),
});

export const { usePlanFetchQuery } = planApi;
