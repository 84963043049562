import React from "react";
import { t, theme } from "shared-utilities";
import styled from "styled-components/macro";
import Heading from "../texts/Heading";

const Root = styled.div`
  background-color: ${theme.palette.ORANGE};
  margin-top: 4px;
  padding-left: 7px;
  padding-right: 8px;
  border-radius: 10px;
  width: min-content;
`;

const NewTag: React.FC<React.PropsWithChildren> = () => (
  <Root>
    <Heading
      size="tiny"
      bold
      text={t("insession_script_new")}
      marginBottom="2px"
      color={theme.palette.WHITE}
      style={{ lineHeight: theme.spacingPx(1.5) }}
    />
  </Root>
);

export default React.memo(NewTag);
