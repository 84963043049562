import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Answer, CheckinQuestions, InteractiveAnswerValue } from 'shared-interfaces';
import { checkinApi } from '../api';
import { RootState } from '../store';
import { logout } from './auth';

// State Interface
export interface CheckinState {
  questions: CheckinQuestions | null;
  answers: Answer<InteractiveAnswerValue>[];
}

// Initial State
const initialState: CheckinState = {
  questions: null,
  answers: [],
};

// Slice
export const checkinSlice = createSlice({
  name: 'checkin',
  initialState,
  reducers: {
    setCheckinAnswer: (state, action: PayloadAction<Answer<InteractiveAnswerValue>>) => {
      const { payload } = action;
      const { question } = payload;
      state.answers = [...state.answers.filter((a) => a.question !== question), payload];
    },
    checkinReset: (state) => {
      state.answers = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.questions = null;
      state.answers = [];
    });
    builder.addMatcher(checkinApi.endpoints.checkinFetch.matchFulfilled, (state, action) => {
      state.questions = action.payload.data.questions;
    });
  },
});

// Actions
export const { setCheckinAnswer, checkinReset } = checkinSlice.actions;

// Selectors
export const getCheckinQuestions = (state: RootState): CheckinQuestions | null =>
  state.checkin.questions;
export const getCheckinAnswers = (state: RootState): Answer<InteractiveAnswerValue>[] =>
  state.checkin.answers;

// Reducer
export default checkinSlice.reducer;
