import * as React from "react";
import styled from "styled-components";

// TODO move
export type WithChildren = {
  children?: React.ReactNode;
};

const BottomNav = styled("div")({
  position: "fixed",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  bottom: 0,
  right: 0,
  backgroundColor: "white",
  zIndex: 1,
  height: "64px",
  borderTop: "1px solid #ebebeb",
});

interface BottomNavigationProps {
  showLabels: boolean;
  value: number;
  onChange: any; // TODO: type this
}
export const BottomNavigation: React.FC<
  BottomNavigationProps & WithChildren
> = ({ showLabels, value, onChange, children }) => {
  // console.log('BottomNavigation', showLabels, value, onChange);

  const stopScroll = function (e: TouchEvent) {
    e.preventDefault();
    console.log(0);
  };

  React.useEffect(() => {
    const navWrapper = document.getElementById("bottomNavigationWrapper");
    navWrapper?.removeEventListener("touchmove", stopScroll);
    navWrapper?.addEventListener("touchmove", stopScroll);
  }, []);

  const renderChildren = () => {
    return React.Children.map(children, (child, childIndex) => {
      if (!React.isValidElement(child)) return null;

      const childValue =
        child.props.value === undefined ? childIndex : child.props.value;

      return React.cloneElement<any>(child, {
        selected: childValue === value,
        showLabel:
          child.props.showLabel !== undefined
            ? child.props.showLabel
            : showLabels,
        value: childValue,
        onChange,
      });
    });
  };
  return <BottomNav id="bottomNavigationWrapper">{renderChildren()}</BottomNav>;
};
