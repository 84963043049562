import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Arrow } from "shared-assets/src/icons/LeftArrow.svg";
import { WorkoutFeedback } from "shared-interfaces";
import {
  useWorkoutFeedbackSubmitMutation,
  getProgress,
  getWorkouts,
  useAppSelector,
} from "shared-redux";
import { theme, t } from "shared-utilities";
import { Button, ScreenElement } from "src/components";
import { AnimationLoader } from "src/components/feedback/AnimationLoader";
import DetailedFeedbackControl from "src/components/feedback/DetailedFeedbackControl";
import styled from "styled-components/macro";
import Heading from "../components/texts/Heading";
import Paragraph from "../components/texts/Paragraph";

const BackWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 20px;
`;

const ExerciseWrapper = styled.div`
  border: 1px solid #d7e2de;
  border-radius: 5px;
  max-width: 345px;
  width: 100%;
  margin-bottom: 60px;

  div:last-child {
    border-bottom: none;
  }
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  gap: 16px;
  width: 100%;
`;

const Text = styled.div``;

const getTitle = (index: number): string | null => {
  switch (index) {
    case 1:
      return t("feedback_easy");
    case 2:
      return t("feedback_hard");
    case 3:
      return t("feedback_painful");
    default:
      return null;
  }
};

const getSubtitle = (index: number): string | null => {
  switch (index) {
    case 1:
      return t("feedback_easy_subtitle");
    case 2:
      return t("feedback_hard_subtitle");
    case 3:
      return t("feedback_painful_subtitle");
    default:
      return null;
  }
};

export const WorkoutDetailedFeedbackScreen = () => {
  // Navigation
  const navigate = useNavigate();
  const params = useParams();

  // Store
  const workouts = useAppSelector(getWorkouts);
  const progress = useAppSelector(getProgress);

  // State
  const [feedback, setFeedback] = React.useState<WorkoutFeedback[]>([]);
  const [dispatchWorkoutSubmitFeedback, { isSuccess, isLoading }] =
    useWorkoutFeedbackSubmitMutation();
  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  // Computed Variables
  const feedbackIndex = params?.index ? Number(params?.index) : 1;
  const workout = workouts[params?.workoutType as keyof typeof workouts];
  const title = getTitle(feedbackIndex);
  const subtitle = getSubtitle(feedbackIndex);

  // Methods

  const setFeedbackValue = (obj: WorkoutFeedback) =>
    setFeedback((values) => [
      ...values.filter((v) => v.exerciseId !== obj.exerciseId),
      obj,
    ]);

  const removeFeedbackValue = (exerciseId: number) =>
    setFeedback((values) => values.filter((v) => v.exerciseId !== exerciseId));

  const handleSubmitFeedback = async (): Promise<void> => {
    if (!workout) return;
    if (!progress?.workoutTracker) return;
    const latestId =
      progress.workoutTracker[progress.workoutTracker.length - 1]?.id;
    if (!latestId) return;
    try {
      dispatchWorkoutSubmitFeedback({ feedback, id: latestId });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    if (!isSuccess) return;
    setShowLoader(true);
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 3500);
  }, [isSuccess]);

  const renderHeader = React.useMemo(
    () => (
      <>
        <Heading
          size="large"
          text={title ?? ""}
          style={{ marginBottom: theme.spacing(2) }}
        />
        <Paragraph
          text={subtitle ?? ""}
          style={{ marginBottom: theme.spacing(2) }}
        />
      </>
    ),
    [title, subtitle, theme]
  );

  if (!workout) return null;

  return (
    <ScreenElement>
      {showLoader && <AnimationLoader />}
      <div
        style={{
          backgroundColor: theme.palette.WHITE,
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          paddingTop: theme.spacing(4),
          position: "sticky",
          top: 0,
          zIndex: 99,
        }}
      >
        <BackWrapper>
          <Arrow onClick={() => navigate(-1)} />
        </BackWrapper>
        {renderHeader}
      </div>
      <ExerciseWrapper>
        {workout.exercises?.map((exercise) => (
          <DetailedFeedbackControl
            key={exercise.exercise.id}
            exercise={exercise}
            value={feedback.find((f) => f.exerciseId === exercise.exercise.id)}
            feedbackIndex={feedbackIndex}
            removeFeedbackValue={removeFeedbackValue}
            setFeedbackValue={setFeedbackValue}
            // firstRow={index === 0}
            // lastRow={index === (workout?.exercises?.length ?? 0) - 1}
          />
        ))}
      </ExerciseWrapper>
      <Footer>
        <Button
          bgColor="#145E7F"
          isFullWidth
          loading={isLoading}
          isRoundedCorners={false}
          handleClick={handleSubmitFeedback}
        >
          <Text
            css={[
              theme.typography[theme.typography.FONT_NAME].REGULAR_BOLD_HEADING,
              { color: theme.palette.WHITE },
            ]}
          >
            {t("btn_next")}
          </Text>
        </Button>
      </Footer>
    </ScreenElement>
  );
};
