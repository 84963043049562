import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import data from 'shared-assets/src/data/assessmentData';
import {
  Answer,
  Assessment,
  AssessmentSection,
  PreAssessmentInfo,
  PreAssessmentSetter,
  MainQuestionConditionSet,
  AssessmentMedicalProfile,
  AssessmentAccountDetails,
} from 'shared-interfaces';
import { sortBy } from 'shared-utilities';
import { referralApi, assessmentApi } from '../api';

import { RootState } from '../store';

import { logout } from './auth';

const assessmentData = data as unknown as Assessment;

declare global {
  // State Interface
  interface AssessmentState {
    id: number | null;
    assessment: Assessment | null;
    answers: Answer[];
    medicalProfile: AssessmentMedicalProfile;
    accountDetails: AssessmentAccountDetails;
    preAssessmentInfo: Partial<PreAssessmentInfo>;
  }
}

// Initial State
const initialState: AssessmentState = {
  id: null,
  assessment: assessmentData,
  answers: [],
  medicalProfile: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  },
  accountDetails: {
    email: '',
    password: '',
  },
  preAssessmentInfo: {
    instructionsOfUse: undefined,
    gender: undefined,
    isPregnant: undefined,
    location: undefined,
    painArea: undefined,
  },
};

// Slice
export const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    addAnswer: (state, action: PayloadAction<Answer>) => {
      const { payload } = action;
      const { question } = payload;
      state.answers = [...state.answers.filter((a) => a.question !== question), payload];
    },
    setMedicalProfileFirstName: (state, action: PayloadAction<string>) => {
      state.medicalProfile.firstName = action.payload;
    },
    setMedicalProfileLastName: (state, action: PayloadAction<string>) => {
      state.medicalProfile.lastName = action.payload;
    },
    setMedicalProfileDateOfBirth: (state, action: PayloadAction<string>) => {
      state.medicalProfile.dateOfBirth = action.payload;
    },
    setAccountDetailsEmail: (state, action: PayloadAction<string>) => {
      state.accountDetails.email = action.payload;
    },
    setAccountDetailsPassword: (state, action: PayloadAction<string>) => {
      state.accountDetails.password = action.payload;
    },
    setPreAssessmentInfo: (state, action: PayloadAction<PreAssessmentSetter>) => {
      switch (action.payload.id) {
        case 'instructionsOfUse':
          state.preAssessmentInfo.instructionsOfUse = action.payload.value;
          return;
        case 'gender':
          state.preAssessmentInfo.gender = action.payload.value;
          return;
        case 'isPregnant':
          state.preAssessmentInfo.isPregnant = action.payload.value;
          return;
        case 'location':
          state.preAssessmentInfo.location = action.payload.value;
          return;
        default:
          state.preAssessmentInfo.painArea = action.payload.value;
      }
    },
    resetAssessment: (state) => {
      state.id = null;
      state.assessment = null;
      state.answers = [];
      state.medicalProfile = initialState.medicalProfile;
      state.accountDetails = initialState.accountDetails;
      state.preAssessmentInfo = initialState.preAssessmentInfo;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.id = null;
      state.assessment = null;
      state.answers = [];
      state.medicalProfile = initialState.medicalProfile;
      state.accountDetails = initialState.accountDetails;
      state.preAssessmentInfo = initialState.preAssessmentInfo;
    });
    builder.addMatcher(assessmentApi.endpoints.assessmentCreate.matchFulfilled, (state, action) => {
      state.id = action.payload.data.userAssessment.id;
      state.assessment = action.payload.data.assessment;
    });
    builder.addMatcher(referralApi.endpoints.referralCode.matchFulfilled, (state, action) => {
      if (!action.payload.patient) return;
      state.medicalProfile = {
        ...state.medicalProfile,
        firstName: action.payload.patient.firstName ?? state.medicalProfile.firstName,
        lastName: action.payload.patient.surname ?? state.medicalProfile.lastName,
        dateOfBirth: action.payload.patient.dateOfBirth ?? state.medicalProfile.dateOfBirth,
      };
      state.accountDetails = {
        ...state.accountDetails,
        email: action.payload.patient.emailAddress ?? state.accountDetails.email,
      };
    });
  },
});

// Actions
export const {
  addAnswer,
  setMedicalProfileFirstName,
  setMedicalProfileLastName,
  setMedicalProfileDateOfBirth,
  setAccountDetailsEmail,
  setAccountDetailsPassword,
  setPreAssessmentInfo,
  resetAssessment,
} = assessmentSlice.actions;

// Selectors
export const getAssessmentId = (state: RootState): number | null => state.assessment.id;
export const getAssessmentSections = (state: RootState): AssessmentSection[] =>
  [...(state.assessment.assessment?.questionSections ?? [])]?.sort(sortBy((s) => s.order));
export const getQuestionConditions = (state: RootState): MainQuestionConditionSet[] =>
  state.assessment.assessment?.questionConditions ?? [];
export const getAssessmentAnswers = (state: RootState): Answer[] => state.assessment.answers;
export const getAssessmentMedicalProfile = (state: RootState): AssessmentMedicalProfile =>
  state.assessment.medicalProfile;
export const getAssessmentAccountDetails = (state: RootState): AssessmentAccountDetails =>
  state.assessment.accountDetails;
export const getPreAssessmentInfo = (state: RootState): Partial<PreAssessmentInfo> =>
  state.assessment.preAssessmentInfo;

// Reducer
export default assessmentSlice.reducer;
