import React, { PropsWithChildren } from "react";
import { theme } from "shared-utilities";
import styled, { CSSProp, DefaultTheme } from "styled-components/macro";

const Text = styled.div``;

interface LinkButton {
  onPress: () => void;
  text?: string;
  color?: string;
  style?: CSSProp<DefaultTheme>;
  // trackingEvent?: string;
}

const LinkButton: React.FC<LinkButton & PropsWithChildren> = ({
  onPress,
  text,
  color = theme.palette.PRIMARY,
  style,
  children,
}) => {
  // Methods
  const handlePress = () => {
    // const buttonText = text ?? 'No Text Defined';
    // if (trackingEvent) Tracking.logClick(trackingEvent, { 'Button Text': buttonText });
    // trackButtonClick(buttonText);

    onPress();
  };

  return (
    <Text
      onClick={handlePress}
      css={[
        {
          ...theme.typographyObj[theme.typographyObj.FONT_NAME].REGULAR_HEADING,
          color,
        },
        style,
      ]}
    >
      {children ?? text}
    </Text>
  );
};

export default LinkButton;
