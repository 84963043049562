import React from 'react';

/**
 * Check if object is devoid of key/values
 * @param obj
 */
export const isEmptyObject = <T>(obj: Partial<Record<keyof T, T[keyof T]>>): boolean => Object.keys(obj).length > 0;

/**
 * Allow only unique values in an array
 * @param array
 */
export const uniqueArray = <T>(array: T[]): T[] => array.filter((elem, index, arr) => arr.indexOf(elem) === index);

/**
 * Remove provided item from array
 * @param item
 * @param array
 */
export const removeValueFromArray = <T>(item: T, array: T[]): T[] => {
  if (!Array.isArray(array)) return [];
  return array.filter((el) => el !== item);
};

/**
 * Create an array of arrays, split by a value, given a key
 * @param array
 * @param param
 */
export const splitArrayByUniqueProperty = <T>(array: T[], param: keyof T): T[][] => {
  const keyPredicate = (entry: Record<keyof T, T[keyof T]>): T[keyof T] => entry[param];
  const groupPredicate = (entry: T[keyof T]) => array.filter((object) => object[param] === entry);
  return [...new Set((array ?? []).map(keyPredicate))].map(groupPredicate);
};

/**
 * Must be a number, must not be a float, must not be equal to zero, must be odd
 * @param value
 */
export const isOdd = (value: number): boolean =>
  !Number.isNaN(value) && value % 1 === 0 && value !== 0 && value % 2 !== 0;

/**
 * Hook to contain a previous value
 * @param value
 */
export function usePrevious<T>(value: T): T | null {
  const ref = React.useRef<T | null>(null);
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

/**
 * Returns a promise that resolves after the number of milliseconds
 * provided in first argument have elapsed.
 * @param wait
 */
export const sleepFor = (wait: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, wait);
  });

/**
 * Function to get value of unknown dimension
 * @param screen
 * @param larger
 * @param smaller
 */
export const scaleImage = (screen: number, larger: number, smaller: number): number => screen * (smaller / larger);
